import { useGetSubscriptionsLazyQuery } from "@altra-apps/common/src/graphql/types";
import { PricingCard } from "@altra-apps/common/src/molecules/PricingCard";
import { useAppSelector } from "@altra-apps/common/src/redux/hook";
import { UserInfo } from "@altra-apps/common/src/redux/user/types";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import {
  CUSTOMERS,
  PRODUCTS,
} from "@altra-apps/common/src/util/LandingPageContent";
import { prettySubscription } from "@altra-apps/common/src/util/pretty-text";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { useAuth0 } from "@auth0/auth0-react";
import {
  CircularProgress,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import styled from "styled-components";

export const PricingTabs = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const user: UserInfo = useAppSelector((state) => state.user.userInfo);

  const [loadingPrice, setLoadingPrice] = useState<
    keyof typeof PRODUCTS | undefined
  >();

  const [tabValue, setTabValue] = React.useState(0);
  const [showAnnualPrice, setShowAnnualPrice] = React.useState(false);
  const isTabletOrMobile = useIsMobile();

  type returnedSub = {
    plan: string;
    monthly_price: number;
    yearly_price: number;
    description: string;
  };

  const [subscriptions, setSubscriptions] = useState<returnedSub[]>();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [getSubscriptions] = useGetSubscriptionsLazyQuery();

  // useEffect(() => {
  //   initialLoadOfSubscriptions();
  // }, []);
  //
  // const initialLoadOfSubscriptions = async () => {
  //   const res = await getSubscriptions();
  //
  //   if (res.data) {
  //     setSubscriptions(
  //       res.data.subscription?.map((sub) => ({
  //         plan: sub.plan || "",
  //         yearly_price: sub.yearly_price,
  //         description: sub.description || "",
  //         monthly_price: sub.monthly_price,
  //       }))
  //     );
  //   } else setSubscriptions([]);
  // };

  /**
   * Calls lambda function to generate stripe checkout URL
   * @param title
   */
  const createSubscription = async (title: string) => {
    if (title === "Altra Open") return;

    setLoadingPrice(title as keyof typeof PRODUCTS);

    if (
      process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_URL &&
      process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_API_KEY
    )
      fetch(process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_URL, {
        method: "POST",
        headers: {
          "X-Api-Key":
            process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_API_KEY,
        },
        body: JSON.stringify({
          product: title,
          isAnnual: showAnnualPrice,
          role: CUSTOMERS[tabValue],
        }),
      })
        .then((response) => {
          setLoadingPrice(undefined);
          return response.json();
        })
        .then((data) => {
          setLoadingPrice(undefined);
          return (window.location.href = data);
        });
  };

  return (
    <StyledPricingTabs>
      <StyledTabsHeaderContainer>
        <StyledInfoRow>
          <Typography
            style={{ marginBottom: 10 }}
            variant={"h1"}
            color={"secondary"}
          >
            Pricing
          </Typography>
          <Typography variant={"body1"} color={"textSecondary"}>
            Choose the plan that suits your role and requirements
          </Typography>
          <StyledCenteredGrid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant={"caption"} color={"textSecondary"}>
                Monthly
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={showAnnualPrice}
                onChange={(event, checked) => setShowAnnualPrice(checked)}
                name="checkedB"
                color="primary"
              />
            </Grid>
            <Grid item>
              <Typography variant={"caption"} color={"textSecondary"}>
                Annually
              </Typography>
            </Grid>
          </StyledCenteredGrid>
        </StyledInfoRow>
      </StyledTabsHeaderContainer>
      <StyledTabContent>
        {isTabletOrMobile && (
          <StyledDropdownContainer>
            <Autocomplete
              id="combo-box-demo"
              options={[
                { key: 0, value: "Teacher" },
                { key: 1, value: "Senior Management" },
                { key: 2, value: "Private Tutor" },
                { key: 3, value: "Parent & Student" },
              ]}
              value={{ key: tabValue, value: CUSTOMERS[tabValue] }}
              onChange={(event, value) => setTabValue(value?.key || 0)}
              getOptionLabel={(option) => option.value}
              style={{ width: "100%", marginBottom: 20 }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </StyledDropdownContainer>
        )}
        {!isTabletOrMobile && (
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Teacher" />
            <Tab label="Senior Management" />
            <Tab label="Private Tutor" />
            <Tab label="Parent & Student" />
          </Tabs>
        )}
        <StyledPricingTabsContent
          style={{ backgroundColor: altraTheme.palette.background.default }}
        >
          <StyledCenteredContainer
            style={{ width: isTabletOrMobile ? "90%" : "60%" }}
          >
            <StyledCenteredInnerContainer
              style={{
                flexDirection: isTabletOrMobile ? "column" : "row",
              }}
            >
              {subscriptions ? (
                subscriptions
                  .filter((s) => s.plan !== "institutionPremium")
                  .map((sub) => (
                    <div style={{ margin: "1em" }}>
                      <PricingCard
                        title={prettySubscription(sub.plan)}
                        monthlyPrice={sub.monthly_price.toString()}
                        annualPrice={sub.yearly_price.toString()}
                        description={sub.description?.toString() || ""}
                        buttonText={
                          sub.plan === "Altra Open"
                            ? "Cancel membership"
                            : "Change plan"
                        }
                        features={PRODUCTS[sub.plan].features || []}
                        buttonLink={
                          sub.plan === "Altra Open"
                            ? {
                                type: "DISABLED",
                              }
                            : {
                                type: "EXTERNAL",
                                onClick: () => createSubscription(sub.plan),
                                loadingPrice: loadingPrice,
                              }
                        }
                      />
                    </div>
                  ))
              ) : (
                <CircularProgress />
              )}
            </StyledCenteredInnerContainer>
          </StyledCenteredContainer>
        </StyledPricingTabsContent>
      </StyledTabContent>
    </StyledPricingTabs>
  );
};

const StyledPricingTabs = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: red;
`;

const StyledTabsHeaderContainer = styled.div`
  margin-top: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  //background-color: hotpink;
  align-items: center;
`;

const StyledTabContent = styled.div`
  //background-color: green;
  min-height: 300px;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
`;
const StyledInfoRow = styled.div`
  //background-color: blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledPricingTabsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
`;

const StyledCenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  //background-color: red;
  margin-top: 30px;
  margin-bottom: 40px;
`;

const StyledCenteredInnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  //background-color: hotpink;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledDropdownContainer = styled.div`
  width: 90%;
`;

const StyledCenteredGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
