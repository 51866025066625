import AppsIcon from "@mui/icons-material/Apps";
import {
  AppBar,
  Drawer,
  IconButton,
  ListItemButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { createContext, forwardRef, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { APP_ICON_LONG, APP_ICON_STACKED, APP_URL } from "../styling/constants";
import { ALTRA_APPS } from "../util/custom-types";
import { useIsMobile } from "../util/useIsMobile";
import AppBarSubCompoment from "./AppBarSubCompoment";
import AppBarSubCompomentMobile from "./AppBarSubCompomentMobile";

type AltraTopAppBarProps = {
  appName: ALTRA_APPS;
  ref: any;
  displaySubCompoments?: boolean;
};

/**
 * Top App bar with Altra Logo and links to other Altra products
 * @constructor
 */
export const AltraTopAppBar = forwardRef<any, AltraTopAppBarProps>(
  ({ appName, displaySubCompoments }, ref) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const history = useHistory();
    const isTabletOrMobile = useIsMobile();

    return (
      <AppBar color="default">
        <Toolbar>
          <IconButton
            style={{ marginRight: "10px" }}
            color="inherit"
            edge="end"
            onClick={() => setDrawerOpen(true)}
          >
            <AppsIcon />
          </IconButton>
          <Tooltip
            disableInteractive={appName !== ALTRA_APPS.RB}
            disableFocusListener={appName !== ALTRA_APPS.RB}
            disableTouchListener={appName !== ALTRA_APPS.RB}
            disableHoverListener={appName !== ALTRA_APPS.RB}
            placement={"bottom"}
            title={"View my resources"}
          >
            <StyliedRouterLink>
              <RouterLink
                to={
                  appName === ALTRA_APPS.RB &&
                  !history.location.pathname.includes("/myResources")
                    ? "/myResources"
                    : appName === ALTRA_APPS.CE
                    ? "/explorer"
                    : `/`
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <StyledImage src={APP_ICON_LONG[appName]} alt="Altra Logo" />
              </RouterLink>
            </StyliedRouterLink>
          </Tooltip>
          <Box sx={{ flexGrow: 2 }} />
          {!isTabletOrMobile ? (
            <AppBarSubCompoment appName={appName} />
          ) : (
            <AppBarSubCompomentMobile appName={appName} />
          )}
        </Toolbar>
        <div ref={ref as any} />
        <Drawer
          onClose={() => setDrawerOpen(false)}
          anchor={"left"}
          open={drawerOpen}
        >
          <StyledDrawerHeader>
            <Typography style={{ margin: 20 }} variant={"h5"}>
              Explore our products
            </Typography>
          </StyledDrawerHeader>

          <List>
            {Object.values(ALTRA_APPS)
              .filter((id) => id !== appName)
              .map((id) => (
                <ListItemButton
                  key={id}
                  onClick={() =>
                    window.open(process.env[APP_URL[id]], "_blank")
                  }
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    minHeight: "150px",
                  }}
                >
                  <img
                    style={{ maxWidth: "200px", marginBottom: 10 }}
                    src={APP_ICON_STACKED[id]}
                    alt="Altra Logo"
                  />
                </ListItemButton>
              ))}
          </List>
        </Drawer>
      </AppBar>
    );
  }
);

const StyliedRouterLink = styled("div")(({ theme }) => ({}));
const StyledImage = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  width: "300px",
  [theme.breakpoints.down("md")]: {
    width: "200px",
  },
}));

const StyledDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
}));

export const ToolbarContext = createContext({ current: undefined });
