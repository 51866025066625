import { ApolloClientContext } from "@altra-apps/common/src/providers/ApolloClientContext";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { MainRouter } from "./routers/MainRouter";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={altraTheme}>
        <ApolloClientContext>
          <BrowserRouter>
            <MainRouter />
          </BrowserRouter>
        </ApolloClientContext>
      </ThemeProvider>
    </div>
  );
}

export default App;
