import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { getTokenFromLocalStorage } from "../util/helpers";

const cache = new InMemoryCache();

const uploadHttpLink = createUploadLink({
  uri: process.env.REACT_APP_HARSURA_SERVER_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage, if exists
  const token = getTokenFromLocalStorage();
  return {
    headers: {
      ...headers,
    },
  };
});

// code for handling all server side errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.info(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
    //setError(msg);
    //setOpen(true);
  }
  if (networkError)
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
});

const authLinkForGuestUser = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-hasura-role": "user",
    },
  };
});

const authLinkForAdminUser = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them

  let hasuraAdminSecret = window.localStorage.getItem("hasuraAdminSecret");

  if (!hasuraAdminSecret) {
    hasuraAdminSecret = prompt("Enter hasura key");
    window.localStorage.setItem("hasuraAdminSecret", hasuraAdminSecret || "");
  }

  return {
    headers: {
      ...headers,
      "x-hasura-admin-secret": hasuraAdminSecret,
    },
  };
});

export const apolloClientForGuestUser = new ApolloClient({
  cache: cache,
  link: authLink
    .concat(authLinkForGuestUser as unknown as ApolloLink)
    .concat(uploadHttpLink as unknown as ApolloLink),
  //link: authLink.concat(uploadHttpLink),
  name: "altra-app-web-client",
  version: "1.0",
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export const apolloClientForAdminUser = new ApolloClient({
  cache: cache,
  link: authLink
    .concat(authLinkForAdminUser as unknown as ApolloLink)
    .concat(uploadHttpLink as unknown as ApolloLink),
  //link: authLink.concat(uploadHttpLink),
  name: "altra-app-web-client",
  version: "1.0",
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
