import { createTheme } from "@mui/material/styles";

export const FONT_WEIGHTS = {
  SUPER_BOLD_800: 800,
  BOLD_700: 700,
  SEMI_BOLD_600: 600,
  REGULAR_500: 500,
  LIGHT_400: 400,
};

const MOBILE_MAX_SIZE = "@media (max-width:768px)";

export const altraTheme = createTheme({
  palette: {
    primary: {
      main: "#007AFF",
      dark: "#1A73E8",
      contrastText: "#FFF",
    },
    secondary: { main: "#111137" },
    error: {
      main: "#F32013",
      contrastText: "#FFF",
    },
    success: {
      main: "#4BB543",
      contrastText: "#FFF",
    },
    warning: {
      main: "#DE4C38",
    },
    //TODO: Consider changing as not provided by Dan
    info: {
      main: "#0499BA",
    },
    defaultText: {
      main: "#111137",
    },
    secondaryText: {
      main: "#425466",
    },
    tertiaryText: {
      main: "#6B737B",
    },
    stroke: {
      main: "#9C9C9C",
    },
    secondaryStroke: {
      main: "#DBDBDB",
    },
    white: {
      main: "#FFF",
    },
    background: {
      default: "#F8F8F8",
    },
  },
  typography: {
    fontFamily: "Inter",
    textTransform: "none",
    title: {
      fontFamily: "Inter",
      fontWeight: FONT_WEIGHTS.SUPER_BOLD_800,
      fontSize: 42,
      letterSpacing: 0.3,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 24,
      // },
    },
    h1: {
      fontWeight: FONT_WEIGHTS.SUPER_BOLD_800,
      fontSize: 27,
      letterSpacing: 0.3,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 24,
      // },
    },
    h2: {
      fontWeight: FONT_WEIGHTS.BOLD_700,
      fontSize: 18,
      letterSpacing: 0.4,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 18,
      // },
    },
    h2Heavy: {
      fontFamily: "Inter",
      fontWeight: FONT_WEIGHTS.SUPER_BOLD_800,
      fontSize: 18,
      letterSpacing: 0.4,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 18,
      // },
    },
    h2Light: {
      fontFamily: "Inter",
      fontWeight: FONT_WEIGHTS.SEMI_BOLD_600,
      fontSize: 18,
      letterSpacing: 0.4,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 18,
      // },
    },
    h3: {
      fontWeight: FONT_WEIGHTS.REGULAR_500,
      fontSize: 16,
      letterSpacing: 0.4,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 12,
      // },
    },
    h4: {
      fontWeight: FONT_WEIGHTS.SUPER_BOLD_800,
      fontSize: 14,
      letterSpacing: 0.4,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 16,
      // },
    },
    h5: {
      fontWeight: FONT_WEIGHTS.SEMI_BOLD_600,
      fontSize: 14,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
    h6: {
      fontWeight: FONT_WEIGHTS.BOLD_700,
      fontSize: 13,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
    h7: {
      fontWeight: FONT_WEIGHTS.SEMI_BOLD_600,
      fontSize: 12,
      fontFamily: "Inter",
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
    subtitle1: {
      fontWeight: FONT_WEIGHTS.REGULAR_500,
      fontSize: 14,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
    subtitle2: {
      fontWeight: FONT_WEIGHTS.REGULAR_500,
      fontSize: 13,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
    body1: {
      fontWeight: FONT_WEIGHTS.REGULAR_500,
      fontSize: 16,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
    body2: {
      fontWeight: FONT_WEIGHTS.REGULAR_500,
      fontSize: 11,
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
    button: {
      textTransform: "none",
      fontWeight: FONT_WEIGHTS.SEMI_BOLD_600,
      fontSize: 10,
      lineHeight: "20px",
      letterSpacing: "2%",
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
    buttonSecondary: {
      fontWeight: FONT_WEIGHTS.MEDIUM,
      fontSize: 12,
      lineHeight: "20px",
      letterSpacing: "2%",
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 12,
      // },
    },
    buttonBig: {
      fontWeight: FONT_WEIGHTS.MEDIUM,
      fontSize: 10,
      lineHeight: "20px",
      letterSpacing: "2%",
      // [MOBILE_MAX_SIZE]: {
      //   fontSize: 10,
      // },
    },
  },
});
