import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Button, Theme, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";

type AppPageOverviewProps = {
  title: string;
  image: string;
  description: string;
  button1Text: string;
  button1Link: string;
  button2Text?: string;
  button2Link?: string;
  theme: Theme;
};

export const AppPageOverview: FC<AppPageOverviewProps> = ({
  description,
  button1Link,
  button1Text,
  button2Link,
  button2Text,
  title,
  theme,
  image,
}) => {
  const isTabletOrMobile = useIsMobile();

  return (
    <StyledLandingPageOverview>
      <StyledContentContainer
        style={{
          flexDirection: isTabletOrMobile ? "column-reverse" : "row",
          width: isTabletOrMobile ? "90%" : "60%",
        }}
      >
        <StyledInformationColumn
          style={{ width: isTabletOrMobile ? "100%" : "45%" }}
        >
          <Typography
            variant={"h1"}
            fontSize={38}
            fontWeight={900}
            style={{ color: theme.palette.primary.main }}
          >
            {title}
          </Typography>
          <Typography
            style={{ marginTop: 20 }}
            variant={"body1"}
            color={"secondary"}
          >
            {description}
          </Typography>
          <StyledButtonRow>
            <Button
              href={button1Link}
              size={"large"}
              variant={"contained"}
              style={{
                backgroundColor: theme.palette.primary.main,
                color: "#FFF",
              }}
            >
              {button1Text}
            </Button>
            {button2Text && button2Link && (
              <Button
                href={button2Link}
                size={"large"}
                variant={"outlined"}
                style={{
                  marginLeft: 10,
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                }}
              >
                {button2Text}
              </Button>
            )}
          </StyledButtonRow>
        </StyledInformationColumn>
        <StyledVideoColumn>
          <img
            style={{
              maxWidth: isTabletOrMobile ? "100%" : "300px",
              width: isTabletOrMobile ? "100%" : "300px",
              maxHeight: isTabletOrMobile ? "300px" : "auto",
            }}
            src={image}
            alt="TeacherFillerImage Logo"
          />
        </StyledVideoColumn>
      </StyledContentContainer>
    </StyledLandingPageOverview>
  );
};

const StyledLandingPageOverview = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "50px",
  minHeight: "60vh",
}));

const StyledContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  //background-color: red;
}));

const StyledInformationColumn = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  //background-color: purple;
  flexDirection: "column",
  marginBottom: "40px",
}));

const StyledVideoColumn = styled("div")(({ theme }) => ({
  display: "flex",
  width: "50%",
  justifyContent: "center",
  marginBottom: "40px",
  //background-color: yellow;
}));

const StyledButtonRow = styled("div")(({ theme }) => ({
  marginTop: "40px",
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
}));
