import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import { Avatar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCheckBlockIsPublicLazyQuery } from "../graphql/types";
import { LoadingStatus } from "../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { ALTRA_APPS, Status } from "../util/custom-types";

type AppBarSubComponentProps = {
  appName?: ALTRA_APPS;
};

/**
 * This compoment allows user to manage login, logout and select and filter Curriculum and Subject
 *
 */
const AppBarSubCompomentMobile: React.FC<AppBarSubComponentProps> = (prop) => {
  const theme = useTheme();
  const routerParams = useParams();
  const userId = useAppSelector((state) => state.user.userInfo.id);

  const [resourceInfo, setResourceInfo] =
    useState<{ author: number | null; public: boolean }>();
  const dispatch = useAppDispatch();
  const { user, loginWithRedirect, logout, isLoading, isAuthenticated } =
    useAuth0();
  const [
    getPublicCallback,
    { data: checkPublicData, error: checkPublicError },
  ] = useCheckBlockIsPublicLazyQuery();
  const getPublic = async () => {
    //@ts-expect-error
    routerParams?.id &&
      getPublicCallback({
        //@ts-expect-error
        variables: { id: routerParams?.id },
      });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (checkPublicError) {
      setResourceInfo({ author: null, public: false });
    } else if (checkPublicData && checkPublicData.block_by_pk) {
      setResourceInfo({
        author: checkPublicData.block_by_pk.created_by_id || null,
        public: checkPublicData.block_by_pk.publicly_accessible || false,
      });
    }
  }, [checkPublicData, checkPublicError]);

  useEffect(() => {
    getPublic();
  }, [routerParams]);
  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (checkPublicError) {
      setResourceInfo({ author: null, public: false });
    } else if (checkPublicData && checkPublicData.block_by_pk) {
      setResourceInfo({
        author: checkPublicData.block_by_pk.created_by_id || null,
        public: checkPublicData.block_by_pk.publicly_accessible || false,
      });
    }
  }, [checkPublicData, checkPublicError]);

  const loadingStatus: LoadingStatus = useAppSelector(
    (state) => state.applicationContext.loadingStatus
  );
  const userRole: string = useAppSelector(
    (state) => state.userAppInfo.role
  ) as string;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const accountMenuOpen = Boolean(anchorEl);
  const history = useHistory();
  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (history.location.pathname.includes("explorer/")) {
    return (
      <StyledTopRowContent>
        <IconButton
          color={"primary"}
          size="small"
          onClick={() =>
            //@ts-expect-error
            routerParams?.id && history.push(`/`)
          }
          disabled={loadingStatus.status === Status.LOADING}
        >
          <SearchIcon />
        </IconButton>

        <IconButton
          size="small"
          color={"primary"}
          onClick={() =>
            //@ts-expect-error
            routerParams?.id &&
            history.push(
              `/export/${
                //  @ts-expect-error
                routerParams?.id
              }`
            )
          }
          disabled={
            loadingStatus.status === Status.LOADING ||
            resourceInfo === undefined ||
            (!resourceInfo.public && resourceInfo.author !== userId)
          }
        >
          <FileDownloadIcon />
        </IconButton>
        <IconButton color={"primary"} onClick={handleSettingsClick}>
          <SettingsIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={accountMenuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/*{isAuthenticated ? (*/}
          {/*  <>*/}
          {/*    <MenuItem*/}
          {/*      onClick={() => {*/}
          {/*        history.push("/account/user");*/}
          {/*        handleMenuClose();*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      My account*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem*/}
          {/*      onClick={() => {*/}
          {/*        dispatch(updateUser(USER_INITIAL_STATE));*/}
          {/*        dispatch(userAppInfo(USER_APP_INFO_INITIAL_STATE));*/}
          {/*        dispatch(updateApplicationContext(INITIAL_STATE));*/}
          {/*        // delete persist from  local stoarge*/}
          {/*        cleanPersistDataFromLocalStorage();*/}
          {/*        logout({ returnTo: window.location.origin });*/}
          {/*        handleMenuClose();*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Log out*/}
          {/*    </MenuItem>*/}
          {/*  </>*/}
          {/*) : (*/}
          {/*  <MenuItem*/}
          {/*    onClick={() => {*/}
          {/*      dispatch(updateUser(USER_INITIAL_STATE));*/}
          {/*      loginWithRedirect();*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Sign In*/}
          {/*  </MenuItem>*/}
          {/*)}*/}
          <MenuItem
            onClick={() => {
              history.push("/curriculumSelection");
              handleMenuClose();
            }}
          >
            Change curriculum
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/topicSelection");
              handleMenuClose();
            }}
          >
            Change subject
          </MenuItem>
        </Menu>
      </StyledTopRowContent>
    );
  }

  return (
    <StyledTopRowContent>
      <IconButton
        color={"primary"}
        onClick={() => history.push("/curriculumSelection")}
      >
        <EditIcon />
      </IconButton>

      <IconButton
        size={"small"}
        color={"primary"}
        onClick={handleSettingsClick}
      >
        <Avatar sx={{ width: 24, height: 24 }} />
      </IconButton>
      {/*<Menu*/}
      {/*  id="basic-menu"*/}
      {/*  anchorEl={anchorEl}*/}
      {/*  open={accountMenuOpen}*/}
      {/*  onClose={handleMenuClose}*/}
      {/*  MenuListProps={{*/}
      {/*    "aria-labelledby": "basic-button",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {isAuthenticated ? (*/}
      {/*    <>*/}
      {/*      <MenuItem*/}
      {/*        onClick={() => {*/}
      {/*          history.push("/account/user");*/}
      {/*          handleMenuClose();*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        My account*/}
      {/*      </MenuItem>*/}
      {/*      <MenuItem*/}
      {/*        onClick={() => {*/}
      {/*          dispatch(updateUser(USER_INITIAL_STATE));*/}
      {/*          dispatch(userAppInfo(USER_APP_INFO_INITIAL_STATE));*/}
      {/*          dispatch(updateApplicationContext(INITIAL_STATE));*/}
      {/*          // delete persist from  local stoarge*/}
      {/*          cleanPersistDataFromLocalStorage();*/}
      {/*          logout({ returnTo: window.location.origin });*/}
      {/*          handleMenuClose();*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Log out*/}
      {/*      </MenuItem>*/}
      {/*    </>*/}
      {/*  ) : (*/}
      {/*    <MenuItem*/}
      {/*      onClick={() => {*/}
      {/*        dispatch(updateUser(USER_INITIAL_STATE));*/}
      {/*        loginWithRedirect();*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Sign In*/}
      {/*    </MenuItem>*/}
      {/*  )}*/}
      {/*</Menu>*/}
    </StyledTopRowContent>
  );
};

const StyledAppSubCompoment = styled("div")(({ theme }) => ({
  display: "flex",
}));

export default AppBarSubCompomentMobile;
const StyledTopRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flexEnd",
}));
