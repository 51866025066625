import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  createMigrate,
  getStoredState,
  persistReducer,
  persistStore,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { applicationContextReducer } from "./applicationContext/reducer";
import {
  userAppInfoReducer,
  userReducer,
  USER_APP_INFO_INITIAL_STATE,
} from "./user/reducer";

const migrations = {
  0: (state) => {
    return {
      state,
    };
  },
  //Migration to include importing existing blocks about in the editor and new initial flow for teachers and students
  1: (state) => {
    return {
      ...state,
      userAppInfo: USER_APP_INFO_INITIAL_STATE,
    };
  },
  //Migration to tutorial stages
  2: (state) => {
    console.log(USER_APP_INFO_INITIAL_STATE);
    console.log(state);
    return {
      ...state,
      userAppInfo: USER_APP_INFO_INITIAL_STATE,
    };
  },
};

function crossBrowserListener(store, persistConfig) {
  return async function () {
    let state = await getStoredState(persistConfig);

    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
    });
  };
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "userAppInfo", "applicationContext"],
  version: 2,
  //@ts-expect-error
  migrate: createMigrate(migrations, { debug: true }),
};
const rootReducer = combineReducers({
  user: userReducer,
  userAppInfo: userAppInfoReducer,
  applicationContext: applicationContextReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// console.log(persistConfig);
// console.log(rootReducer);
// console.log(rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  //middleware: new MiddlewareArray().concat(logger),
});

// console.log(persistedReducer);
window.addEventListener("storage", crossBrowserListener(store, persistConfig));

export const persistor = persistStore(store);

// console.log(persistor);
// console.log(persistor.getState());

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
