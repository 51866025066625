import { createReducer } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { Status } from "../../util/custom-types";
import { getUnionOfValuesForKeys } from "../../util/helpers";
import {
  addUserTags,
  appendToQueueForDatabaseUpdates,
  blocksSelectedForAddingToResource,
  createUser,
  deleteUserTags,
  getUserByEmail,
  resetChildBlocks,
  resetEditableBlockProps,
  resetQueueForDatabaseUpdates,
  toggledFiltersChangedReadyToSearch,
  updateDescriptionOfResourceBeingEdited,
  updateTitleOfResourceBeingEdited,
  updateUser,
  updateUserById,
  updateUserVerifiedStatus,
  userAppInfo,
  userAppInfoAuthorisedToEdit,
  userAppInfoCreateEditableBlockProps,
  userAppInfoCreateMultipleEditableBlockProps,
  userAppInfoCreateSubmission,
  userAppInfoCreateSubmissionAnswerFeedbackBlocks,
  userAppInfoCurriculumExplorerTutorial,
  userAppInfoDarkMode,
  userAppInfoExplorerBlockChildren,
  userAppInfoExplorerBlocks,
  userAppInfoExplorerBlocksChildren,
  userAppInfoFirstSearchExecuted,
  userAppInfoIsDragActive,
  userAppInfoManualBlockImport,
  userAppInfoManualBlockMove,
  userAppInfoOnlyShowVerifiedBlocks,
  userAppInfoRedirectPath,
  userAppInfoRemoveEditableBlockProp,
  userAppInfoRemoveMultipleEditableBlockProp,
  userAppInfoResetSelectedTopicsId,
  userAppInfoResourceBeingEdited,
  userAppInfoResourceBuilderTutorial,
  userAppInfoRole,
  userAppInfoSearchTerm,
  userAppInfoSelectedAuthors,
  userAppInfoSelectedBlocksId,
  userAppInfoSelectedCurriculumsId,
  userAppInfoSelectedTopicsId,
  userAppInfoTagModalOpen,
  userAppInfoTeacherOrStudent,
  userAppInfoToggleAnswers,
  userAppInfoTriggerResourceSave,
  userAppInfoUpdateEditableBlockPropsTags,
  userAppInfoUpdateSubmission,
  userAppInfoUpdateSubmissionAnswerFeedbackBlock,
  userAppInfoUserCreatedResources,
  userInfoRemoveSubscription,
  userInfoUpdateSubscription,
} from "./actions";
import { LoadedUserStatus, SubmissionIdNameTitle, UserAppInfo } from "./types";

export const USER_INITIAL_STATE: LoadedUserStatus = {
  loadingStatus: Status.INITIAL,
  error: "",
  userInfo: {
    active: true,
    verified: false,
    avatar_url: "",
    bio_data: "",
    display_name: "",
    email: "",
    id: undefined,
    institution_id: undefined,
    subscription_id: undefined,
    last_edited_time: undefined,
    created_time: undefined,
    stripe_id: undefined,
  },
};

export const USER_APP_INFO_INITIAL_STATE: UserAppInfo = {
  queueForDatabaseUpdates: [],
  tagModalOpen: undefined,
  manualEditorBlockMove: {
    status: "INACTIVE",
  },
  manualEditorBlockImport: {
    status: "INACTIVE",
  },
  blockCurrentlyBeingDragged: undefined,
  selectedCurriculumsId: [],
  firstSearchExecuted: false,
  selectedTopicsId: [],
  filtersAppliedReadyToSearch: true,
  authorisedToEditResource: false,
  toggleAnswers: undefined,
  role: "",
  userEditableBlockProps: [],
  userExplorerBlocks: [],
  userExplorerBlocksChildren: {},
  selectedBlocksId: [0],
  selectedAuthors: [],
  triggerResourceSave: false,
  searchTerm: "",
  userCreatedResources: [],
  blocksSelectedForAddingToResource: [],
  onlyShowVerifiedBlocks: true,
  redirectPath: undefined,
  submissions: {},
  darkMode: false,
  curriculumExplorerTutorial: 1,
  resourceBuilderTutorial: 1,
};

export const userAppInfoReducer = createReducer(
  USER_APP_INFO_INITIAL_STATE,
  (builder) => {
    builder
      .addCase(userAppInfo, (state, { payload }) => {
        Object.assign(state, payload);
      })
      .addCase(userAppInfoIsDragActive, (state, { payload }) => {
        state.blockCurrentlyBeingDragged = payload;
      })
      .addCase(userAppInfoManualBlockMove, (state, { payload }) => {
        state.manualEditorBlockMove = payload;
      })
      .addCase(userAppInfoCurriculumExplorerTutorial, (state, { payload }) => {
        console.log(payload);
        state.curriculumExplorerTutorial = payload;
      })
      .addCase(userAppInfoToggleAnswers, (state, { payload }) => {
        state.toggleAnswers = payload;
      })
      .addCase(userAppInfoResourceBuilderTutorial, (state, { payload }) => {
        state.resourceBuilderTutorial = payload;
      })
      .addCase(userAppInfoManualBlockImport, (state, { payload }) => {
        state.manualEditorBlockImport = payload;
      })
      .addCase(
        userAppInfoCreateSubmissionAnswerFeedbackBlocks,
        (state, { payload }) => {
          state.editableSubmissionBlocks = payload;
        }
      )
      .addCase(userAppInfoTeacherOrStudent, (state, { payload }) => {
        state.teacherOrStudent = payload;
      })
      .addCase(
        userAppInfoUpdateSubmissionAnswerFeedbackBlock,
        (state, { payload }) => {
          if (state.editableSubmissionBlocks) {
            let submissionBlocksForUpdate = Array.from(
              state?.editableSubmissionBlocks
            ).filter((block) => block.block_id !== payload.block_id);
            const blockInRedux = state?.editableSubmissionBlocks.find(
              (b) => b.block_id === payload.block_id
            );
            submissionBlocksForUpdate.push({
              block_id: payload.block_id,
              requester_answer:
                payload.requester_answer || blockInRedux?.requester_answer,
              marker_feedback:
                payload.marker_feedback || blockInRedux?.marker_feedback,
              //@ts-expect-error
              submission_id: blockInRedux?.submission_id,
            });
            state.editableSubmissionBlocks = submissionBlocksForUpdate;
          } else {
            state.editableSubmissionBlocks = [payload];
          }
        }
      )
      .addCase(userAppInfoRedirectPath, (state, { payload }) => {
        state.redirectPath = payload;
      })
      .addCase(userAppInfoOnlyShowVerifiedBlocks, (state, { payload }) => {
        state.onlyShowVerifiedBlocks = payload;
      })
      .addCase(userAppInfoSelectedCurriculumsId, (state, { payload }) => {
        const items: Array<number> = [];
        items.push(...payload);
        state.selectedCurriculumsId = items;
      })
      .addCase(userAppInfoSearchTerm, (state, { payload }) => {
        state.searchTerm = payload;
      })
      .addCase(userAppInfoExplorerBlocks, (state, { payload }) => {
        // console.log(payload);
        state.userExplorerBlocks = payload;
      })
      .addCase(userAppInfoResourceBeingEdited, (state, { payload }) => {
        // console.log(payload);
        state.resourceBeingEdited = payload;
      })
      .addCase(updateTitleOfResourceBeingEdited, (state, { payload }) => {
        if (state.resourceBeingEdited) {
          state.resourceBeingEdited.title = payload.title;
        } else {
          state.resourceBeingEdited = { ...payload, description: "" };
        }
      })
      .addCase(updateDescriptionOfResourceBeingEdited, (state, { payload }) => {
        if (state.resourceBeingEdited) {
          state.resourceBeingEdited.description = payload.description;
        } else {
          state.resourceBeingEdited = { ...payload, title: "" };
        }
      })

      .addCase(userAppInfoUserCreatedResources, (state, { payload }) => {
        state.userCreatedResources = payload;
      })
      .addCase(userAppInfoDarkMode, (state, { payload }) => {
        state.darkMode = payload;
      })
      .addCase(blocksSelectedForAddingToResource, (state, { payload }) => {
        state.blocksSelectedForAddingToResource = payload;
      })
      .addCase(userAppInfoCreateSubmission, (state, { payload }) => {
        const blockId = payload.block_id;
        const submissionId = payload.id;

        let submissions: { [blockId: number]: [SubmissionIdNameTitle] } =
          { ...state.submissions } || {};

        if (blockId && submissionId) {
          const blocksIdsInRedux = Object.keys(submissions || {}) || [];
          if (blocksIdsInRedux.includes(blockId.toString())) {
            const submissionsForBlock = submissions[blockId].concat([
              {
                submissionId: submissionId,
                requesterName: payload?.requester_name || "",
                markerName: payload?.marker_name || "",
                title: payload?.title || "",
              },
            ]);
            Object.assign(submissions[blockId], submissionsForBlock);
          } else {
            submissions[blockId] = [
              {
                submissionId: payload?.id,
                requesterName: payload?.requester_name || "",
                markerName: payload?.marker_name || "",
                title: payload?.title || "",
              },
            ];
          }
          state.submissions = submissions;
        }
      })
      .addCase(userAppInfoUpdateSubmission, (state, { payload }) => {
        const submissionId = payload.submissionId;

        let submissions: { [blockId: number]: [SubmissionIdNameTitle] } =
          { ...state.submissions } || {};

        if (submissionId) {
          const submissionToUpdate = getUnionOfValuesForKeys(submissions)?.find(
            (b) => b?.submissionId === submissionId
          );
          console.log("redux update", payload);
          if (submissionToUpdate) {
            submissionToUpdate.requesterName =
              payload?.requesterName === ""
                ? ""
                : payload?.requesterName ||
                  submissionToUpdate?.requesterName ||
                  "";
            submissionToUpdate.markerName =
              payload?.markerName === ""
                ? ""
                : payload?.markerName || submissionToUpdate?.markerName || "";
            submissionToUpdate.title =
              payload?.title === ""
                ? ""
                : payload?.title || submissionToUpdate?.title || "";
          }
        }
      })
      .addCase(appendToQueueForDatabaseUpdates, (state, { payload }) => {
        // console.log("add to queue");
        state.queueForDatabaseUpdates = [
          ...new Set(state.queueForDatabaseUpdates.concat(payload)),
        ];
      })
      .addCase(resetQueueForDatabaseUpdates, (state, { payload }) => {
        // console.log("reset queue");
        state.queueForDatabaseUpdates = [];
      })
      .addCase(userAppInfoExplorerBlockChildren, (state, { payload }) => {
        state.userExplorerBlocksChildren[payload.parentId] = payload.children;
      })
      .addCase(
        userAppInfoUpdateEditableBlockPropsTags,
        (state, { payload }) => {
          let block = state.userEditableBlockProps?.find(
            (b) =>
              b?.dbId?.toString() === payload.id || b.client_id === payload.id
          );
          if (block) {
            block.tags = payload.tags;
            state.userEditableBlockProps ===
              state.userEditableBlockProps
                ?.filter(
                  (b) =>
                    b?.dbId?.toString() !== payload.id &&
                    b.client_id !== payload.id
                )
                .concat(block);
          }
        }
      )
      .addCase(toggledFiltersChangedReadyToSearch, (state, { payload }) => {
        state.filtersAppliedReadyToSearch = payload;
      })
      .addCase(resetChildBlocks, (state, { payload }) => {
        state.userExplorerBlocksChildren = {};
      })
      .addCase(userAppInfoExplorerBlocksChildren, (state, { payload }) => {
        const newChildrenRedux = state.userExplorerBlocksChildren || {};
        payload.map((b) => (newChildrenRedux[b.parentId] = b.children));
        state.userExplorerBlocksChildren = newChildrenRedux;
      })
      .addCase(userAppInfoSelectedTopicsId, (state, { payload }) => {
        if (state.selectedTopicsId) {
          state.selectedTopicsId[payload.topicLabelId] = payload.topicIds;
        } else {
          console.log("set topics");
          state.selectedTopicsId = {};
          state.selectedTopicsId[payload.topicLabelId] = payload.topicIds;
        }
      })
      .addCase(userAppInfoResetSelectedTopicsId, (state, { payload }) => {
        state.selectedTopicsId = {};
      })
      .addCase(userAppInfoTagModalOpen, (state, { payload }) => {
        state.tagModalOpen = payload;
      })
      .addCase(userAppInfoAuthorisedToEdit, (state, { payload }) => {
        state.authorisedToEditResource = payload;
      })
      .addCase(userAppInfoSelectedAuthors, (state, { payload }) => {
        state.selectedAuthors = payload.authors;
      })
      .addCase(userAppInfoSelectedBlocksId, (state, { payload }) => {
        state.selectedBlocksId = payload.blockIds;
      })
      .addCase(userAppInfoRole, (state, { payload }) => {
        state.role = payload;
      })
      .addCase(userAppInfoFirstSearchExecuted, (state, { payload }) => {
        state.firstSearchExecuted = payload;
      })
      .addCase(userAppInfoRemoveEditableBlockProp, (state, { payload }) => {
        let localBlocks =
          state.userEditableBlockProps?.filter((b) => b.dbId !== payload) ||
          state.userEditableBlockProps;
        Object.assign(state.userEditableBlockProps, localBlocks);
      })
      .addCase(
        userAppInfoRemoveMultipleEditableBlockProp,
        (state, { payload }) => {
          let localBlocks = state.userEditableBlockProps?.filter(
            (b) =>
              !payload.some(
                (id) => id === b?.client_id || id === b?.dbId?.toString()
              )
          );
          console.log(localBlocks);
          Object.assign(
            state.userEditableBlockProps,
            uniqBy(localBlocks, "dbId")
          );
          // console.log("CREATE", payload);
          // console.log("CREATE", uniqBy(localBlocks, "dbId"));
        }
      )
      .addCase(userAppInfoTriggerResourceSave, (state, { payload }) => {
        state.triggerResourceSave = payload;
      })
      .addCase(userAppInfoCreateEditableBlockProps, (state, { payload }) => {
        let localBlocks =
          state.userEditableBlockProps?.filter(
            (b) => b.dbId !== payload.dbId
          ) || [];
        localBlocks.push(payload);
        state.userEditableBlockProps = localBlocks;
      })
      .addCase(
        userAppInfoCreateMultipleEditableBlockProps,
        (state, { payload }) => {
          const dbIds = payload.map((b) => b.dbId);
          const clientIds = payload.map((b) => b.client_id);

          let localBlocks =
            state.userEditableBlockProps?.filter(
              (b) =>
                !dbIds.some((id) => id === b.dbId) &&
                !clientIds.some((id) => id == b.client_id)
            ) || [];

          localBlocks = localBlocks.concat(payload);
          state.userEditableBlockProps = uniqBy(localBlocks, "dbId");
          // console.log("CREATE", payload);
          // console.log("CREATE", uniqBy(localBlocks, "dbId"));
        }
      )
      .addCase(resetEditableBlockProps, (state) => {
        state.userEditableBlockProps = [];
      });
  }
);

export const userReducer = createReducer(USER_INITIAL_STATE, (builder) => {
  builder
    .addCase(updateUser, (state, { payload }) => {
      Object.assign(state, payload);
    })
    .addCase(userInfoUpdateSubscription, (state, { payload }) => {
      state.userInfo.subscription_id = payload.subId;
      state.userInfo.stripe_id = payload.customerId;
    })
    .addCase(userInfoRemoveSubscription, (state, { payload }) => {
      state.userInfo.subscription_id = undefined;
    })
    .addCase(updateUserVerifiedStatus, (state, { payload }) => {
      // change only the verified element
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          verified: payload,
        },
      };
    })
    .addCase(getUserByEmail.pending, (state, action) => {
      state.loadingStatus = Status.LOADING;
      state.error = "";
    })
    .addCase(getUserByEmail.fulfilled, (state, { payload }) => {
      state.loadingStatus = Status.IDLE;
      state.error = "";
      state.userInfo = Object.assign(state.userInfo, payload);
      return state;
    })
    .addCase(getUserByEmail.rejected, (state, action) => {
      if (action.payload === undefined) {
        state.loadingStatus = Status.ERROR;
        state.error = action?.error?.message!;
      } else if (action.payload === Status.NOT_FOUND) {
        state.loadingStatus = Status.NOT_FOUND;
        state.error = action?.payload;
      } else {
        state.loadingStatus = Status.ERROR;
        let msg: string | undefined =
          typeof action.payload === "string"
            ? action.payload
            : action?.error?.message!;
        state.error = msg!;
      }
      return state;
    })
    .addCase(createUser.pending, (state, action) => {
      state.loadingStatus = Status.LOADING;
      state.error = "";
    })
    .addCase(createUser.fulfilled, (state, { payload }) => {
      state.userInfo = Object.assign(state.userInfo, payload);
      state.loadingStatus = Status.IDLE;
      state.error = "";
    })
    .addCase(createUser.rejected, (state, action) => {
      state.loadingStatus = Status.ERROR;
      let msg: string | undefined =
        typeof action.payload === "string"
          ? action.payload
          : action.error.message;
      state.error = msg!;
    })
    .addCase(updateUserById.rejected, (state, action) => {
      state.loadingStatus = Status.ERROR;
      let msg: string | undefined =
        typeof action.payload === "string" ? action.payload : undefined;
      state.error = msg!;
    })
    .addCase(updateUserById.fulfilled, (state, { payload }) => {
      state.loadingStatus = Status.IDLE;
      state.error = "";
      state.userInfo = Object.assign(state.userInfo, payload);
      return state;
    })
    .addCase(addUserTags, (state, { payload }) => {
      if (state.userInfo.tags === undefined) state.userInfo.tags = [];
      state.userInfo.tags?.push(...payload);
      return state;
    })
    .addCase(deleteUserTags, (state, { payload }) => {
      let tagIds: Array<number> = payload?.map((element) => {
        return element.id!;
      });
      state.userInfo.tags = state.userInfo.tags?.filter(
        (f) => !tagIds.includes(f.id!)
      );
      return state;
    });
});
