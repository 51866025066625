import SearchIcon from "@mui/icons-material/Search";
import { Button, Tooltip } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Block_Insert_Input,
  CreateSubmissionMutationVariables,
  InsertTagsMutationVariables,
  useCheckBlockIsPublicLazyQuery,
  useCreateBlockMutation,
  useCreateSubmissionMutation,
  useInsertTagsMutation,
} from "../graphql/types";
import {
  Curriculum,
  LoadingStatus,
  Topic,
  TopicLabel,
} from "../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { userAppInfoCreateSubmission } from "../redux/user/actions";
import { Block, UserInfo } from "../redux/user/types";
import { BLOCK_TYPES, Status } from "../util/custom-types";
import {
  getTopicLabelIdsForTier,
  getUnionOfValuesForKeys,
  openInNewTab,
} from "../util/helpers";
import { useIsMobile } from "../util/useIsMobile";
import { NonEditableDropdown } from "./NonEditableDropdown";

export const AppSubBarCurriculumExplorerContents: FC = () => {
  const routerParams = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useIsMobile();

  const topicLabels: { [curriculumId: number]: TopicLabel[] } = useAppSelector(
    (state) => state.applicationContext.topicLabels
  );
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext.topics
  );
  const curriculums: Array<Curriculum> = useAppSelector(
    (state) => state.applicationContext.curriculums
  );
  const selectedCurriculumsIds: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );
  const loadingStatus: LoadingStatus = useAppSelector(
    (state) => state.applicationContext.loadingStatus
  );
  const userExplorerBlocks: Block[] = useAppSelector(
    (state) => state.userAppInfo.userExplorerBlocks
  );
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);

  const [block, setBlock] = useState<Block>();
  const [createSubmissionMutation] = useCreateSubmissionMutation();

  const createSubmissionAndNavigate = async () => {
    const createVariables: CreateSubmissionMutationVariables = {
      submission_spec: {
        block_id: block?.id,
        requester_user_id: user && user?.id ? user?.id : undefined,
        publicly_accessible: false,
      },
    };

    const { data, errors } = await createSubmissionMutation({
      variables: createVariables,
    });

    if (data && data?.insert_submission_one?.id) {
      dispatch(userAppInfoCreateSubmission(data?.insert_submission_one));
      history.push(`/submission/${data?.insert_submission_one?.id}`);
    }
  };
  useEffect(() => {
    const localBlock = userExplorerBlocks.find(
      //@ts-expect-error
      (b) => b.id === parseInt(routerParams?.id)
    );
    localBlock && setBlock(localBlock);
  }, [userExplorerBlocks, routerParams]);

  /**
   * Get top tier topics by finding topic label with order_id 1
   */
  const topTierTopicsForSelectedCurriculums: Array<Topic> = useAppSelector(
    (state) => {
      const topTierTopicLevelIds: number[] = getTopicLabelIdsForTier(
        state.userAppInfo.selectedCurriculumsId || [],
        state.applicationContext.topicLabels,
        1
      );

      return getUnionOfValuesForKeys<Topic>(
        state.applicationContext.topics,
        state.userAppInfo?.selectedCurriculumsId
      ).filter((t) => topTierTopicLevelIds.includes(t.topic_label_id));
    }
  );

  const selectedTopTierTopicIds: Array<number> =
    getUnionOfValuesForKeys<number>(
      useAppSelector((state) => state.userAppInfo?.selectedTopicsId) || {},
      topTierTopicsForSelectedCurriculums.map((t) => t.topic_label_id)
    );

  const [creatingBlock, setCreatingBlock] = useState<number | null>(null);
  const verified = useAppSelector((state) => state.user.userInfo.verified);

  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);
  const userId = useAppSelector((state) => state.user.userInfo.id);

  const [createBlockMutation] = useCreateBlockMutation();
  const [insertTagMutation] = useInsertTagsMutation();

  const [resourceInfo, setResourceInfo] =
    useState<{ author: number | null; public: boolean }>();
  const [
    getPublicCallback,
    { data: checkPublicData, error: checkPublicError },
  ] = useCheckBlockIsPublicLazyQuery();
  const getPublic = async () => {
    //@ts-expect-error
    routerParams?.id &&
      getPublicCallback({
        //@ts-expect-error
        variables: { id: routerParams?.id },
      });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (checkPublicError) {
      setResourceInfo({ author: null, public: false });
    } else if (checkPublicData && checkPublicData.block_by_pk) {
      setResourceInfo({
        author: checkPublicData.block_by_pk.created_by_id || null,
        public: checkPublicData.block_by_pk.publicly_accessible || false,
      });
    }
  }, [checkPublicData, checkPublicError]);

  useEffect(() => {
    getPublic();
  }, [routerParams]);

  const insertTags = async (tags: InsertTagsMutationVariables) => {
    await insertTagMutation({
      variables: tags,
    });
  };

  const [shareExplorerResourceOpen, setShareExplorerResourceOpen] =
    useState(false);

  /**
   * Created child blocks in the backend and iterates to create nested children
   * @param childBlock
   * @param parentId
   */
  const createChildBlockInBackend = async (
    childBlock: Block,
    parentId: number
  ) => {
    const hasChildren = userExplorerBlocksChildren[childBlock.id]?.length > 0;
    if (block) {
      let blockInsertInput: Block_Insert_Input = {
        archived: false,
        active: true,
        block: childBlock.block,
        //@ts-expect-error
        type: childBlock.block.type,
        note: block.teacherNotes,
        created_time: Date.now(),
        has_children: hasChildren,
        publicly_accessible: false,
        last_edited_time: Date.now(),
        position: childBlock.position,
        parent_id: parentId,
        created_by_id: userId || null,
        verified: verified,
      };
      const response = await createBlockMutation({
        variables: {
          block_spec: blockInsertInput,
        },
      });
      if (response.errors) console.log(response.errors);
      if (response.data?.insert_block_one && hasChildren) {
        console.log("DUPLICATING TAGS");
        await insertTags({
          tags:
            childBlock.tags.map((tag) => ({
              //@ts-expect-error
              block_id: response.data?.insert_block_one.id,
              topic_id: tag.topicId,
            })) || [],
        });
        await Promise.all(
          userExplorerBlocksChildren[childBlock.id]?.map(async (b1) => {
            if (response.data?.insert_block_one?.id) {
              await createChildBlockInBackend(
                b1,
                response.data?.insert_block_one?.id
              );
            }
          })
        );
      }
      return response;
    }
  };

  /**
   * Create a copy of a block in the backend
   */
  const createExplorerBlockInBackend = async () => {
    if (block) {
      let resourceDbId: number | undefined = undefined;
      let sectionDbId: number | undefined = undefined;

      //Create resource
      // - If block being duplicated is not a resource block, create an empty resource block to contain children
      // -  If block being duplicated is a resource block, duplicate it and return
      let blockInsertInputResource: Block_Insert_Input = {
        archived: false,
        active: true,
        //@ts-expect-error
        block: block.block.type === BLOCK_TYPES.RESOURCE ? block.block : {},
        type: BLOCK_TYPES.RESOURCE,
        created_time: Date.now(),
        has_children: true,
        publicly_accessible: false,
        last_edited_time: Date.now(),
        position: block.position,
        parent_id: null,
        created_by_id: userId || null,
        note:
          //@ts-expect-error
          block.block.type === BLOCK_TYPES.RESOURCE ? block.teacherNotes : null,
        verified: verified,
      };
      const responseResource = await createBlockMutation({
        variables: {
          block_spec: blockInsertInputResource,
        },
      });
      if (responseResource.errors) console.log(responseResource.errors);
      if (responseResource.data?.insert_block_one) {
        resourceDbId = responseResource.data.insert_block_one.id;
        if (
          //@ts-expect-error
          block.block.type === BLOCK_TYPES.RESOURCE &&
          responseResource.data?.insert_block_one?.id !== undefined
        ) {
          console.log("DUPLICATING TAGS");
          await insertTags({
            tags:
              block.tags.map((tag) => ({
                //@ts-expect-error
                block_id: responseResource.data?.insert_block_one.id,
                topic_id: tag.topicId,
              })) || [],
          });

          console.log("CREATING RESOURCE");
          console.log(`CREATING RESOURCE CHILDREN WITH PARENT${resourceDbId}`);

          await Promise.all(
            userExplorerBlocksChildren[block.id].map(async (b1) => {
              if (resourceDbId) {
                await createChildBlockInBackend(b1, resourceDbId);
              }
            })
          );
          return resourceDbId;
        }
      }

      //If not a section block, create a section block to contain all child blocks and store ID
      if (
        //@ts-expect-error
        block.block.type !== BLOCK_TYPES.SECTION &&
        //@ts-expect-error
        block.block.type !== BLOCK_TYPES.RESOURCE
      ) {
        let blockInsertInputSection: Block_Insert_Input = {
          archived: false,
          active: true,
          block: {},
          type: BLOCK_TYPES.SECTION,
          created_time: Date.now(),
          has_children: true,
          publicly_accessible: false,
          last_edited_time: Date.now(),
          position: block.position,
          parent_id: resourceDbId,
          created_by_id: userId || null,
          verified: verified,
        };
        const responseSection = await createBlockMutation({
          variables: {
            block_spec: blockInsertInputSection,
          },
        });
        if (responseSection.errors) console.log(responseSection.errors);
        if (responseSection.data?.insert_block_one)
          sectionDbId = responseSection.data.insert_block_one.id;
      }

      const hasChildren = userExplorerBlocksChildren[block.id]?.length > 0;

      //Create explorer block
      let blockInsertInput: Block_Insert_Input = {
        archived: false,
        active: true,
        block: block.block,
        type: block.type,
        created_time: Date.now(),
        has_children: hasChildren,
        publicly_accessible: false,
        last_edited_time: Date.now(),
        position: block.position,
        parent_id:
          //@ts-expect-error
          block.block.type === BLOCK_TYPES.SECTION ? resourceDbId : sectionDbId,
        created_by_id: userId || null,
        note: block.teacherNotes,
        verified: verified,
      };
      const response = await createBlockMutation({
        variables: {
          block_spec: blockInsertInput,
        },
      });
      if (response.errors) console.log(response.errors);
      if (response.data?.insert_block_one && hasChildren) {
        console.log("DUPLICATING TAGS");
        await insertTags({
          tags:
            block.tags.map((tag) => ({
              //@ts-expect-error
              block_id: response.data?.insert_block_one.id,
              topic_id: tag.topicId,
            })) || [],
        });
        await Promise.all(
          userExplorerBlocksChildren[block.id].map(async (b1) => {
            response.data?.insert_block_one?.id &&
              (await createChildBlockInBackend(
                b1,
                response.data?.insert_block_one?.id
              ));
          })
        );
      }

      return resourceDbId;
    }
  };

  const createBlockFromExplorerBlock = async () => {
    if (block) {
      setCreatingBlock(block.id);
      const dbResourceId = await createExplorerBlockInBackend();
      openInNewTab(
        `${process.env.REACT_APP_RESOURCE_BUILDER_URL}/editor/${dbResourceId}`
      );
      setCreatingBlock(null);
    }
  };

  {
    if (history.location.pathname.includes("explorer/")) {
      return (
        <div>
          <Tooltip
            title={
              "Search and filter through high quality resources mapped to the curriculum"
            }
          >
            <Button
              size="small"
              variant={"contained"}
              onClick={() =>
                //@ts-expect-error
                routerParams?.id && history.push(`/explorer`)
              }
              sx={{ margin: "0 1em" }}
              startIcon={<SearchIcon />}
              disabled={loadingStatus.status === Status.LOADING}
            >
              Find similar resources
            </Button>
          </Tooltip>
          {/*<Tooltip*/}
          {/*  title={*/}
          {/*    "Download your resource as a word document, powerpoint or PDF"*/}
          {/*  }*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    size="small"*/}
          {/*    variant={"contained"}*/}
          {/*    onClick={() =>*/}
          {/*      //@ts-expect-error*/}
          {/*      routerParams?.id &&*/}
          {/*      history.push(*/}
          {/*        `/export/${*/}
          {/*          //  @ts-expect-error*/}
          {/*          routerParams?.id*/}
          {/*        }`*/}
          {/*      )*/}
          {/*    }*/}
          {/*    startIcon={<FileDownloadIcon />}*/}
          {/*    disabled={*/}
          {/*      loadingStatus.status === Status.LOADING ||*/}
          {/*      resourceInfo === undefined ||*/}
          {/*      (!resourceInfo.public && resourceInfo.author !== userId)*/}
          {/*    }*/}
          {/*  >*/}
          {/*    Export*/}
          {/*  </Button>*/}
          {/*</Tooltip>*/}
          {/*<Tooltip title={"Share a link to this resource"}>*/}
          {/*  <Button*/}
          {/*    size="small"*/}
          {/*    variant={"contained"}*/}
          {/*    sx={{ margin: "0 1em" }}*/}
          {/*    onClick={() => setShareExplorerResourceOpen(true)}*/}
          {/*    startIcon={<ShareIcon />}*/}
          {/*    disabled={loadingStatus.status === Status.LOADING}*/}
          {/*  >*/}
          {/*    Share*/}
          {/*  </Button>*/}
          {/*</Tooltip>*/}
          {/*<ShareExplorerResultPopper*/}
          {/*  open={shareExplorerResourceOpen}*/}
          {/*  onClose={() => setShareExplorerResourceOpen(false)}*/}
          {/*/>*/}
          {/*<Tooltip*/}
          {/*  title={*/}
          {/*    "Easily create your own copy of this resource, adapt it to your needs, import other users' content and export in no time at all"*/}
          {/*  }*/}
          {/*>*/}
          {/*  <LoadingButton*/}
          {/*    loading={creatingBlock !== null}*/}
          {/*    size="small"*/}
          {/*    variant={"contained"}*/}
          {/*    sx={{*/}
          {/*      "&:hover": {*/}
          {/*        backgroundColor:*/}
          {/*          altraResourceBuilderTheme.palette.primary.main,*/}
          {/*      },*/}
          {/*      backgroundColor: altraResourceBuilderTheme.palette.primary.dark,*/}
          {/*      color: altraResourceBuilderTheme.palette.primary.contrastText,*/}
          {/*      margin: "0 1em",*/}
          {/*    }}*/}
          {/*    onClick={*/}
          {/*      resourceInfo?.author === userId && block?.type === "resource"*/}
          {/*        ? () =>*/}
          {/*            window.open(*/}
          {/*              //@ts-expect-error*/}
          {/*              `${process.env.REACT_APP_RESOURCE_BUILDER_URL}/editor/${routerParams?.id}`*/}
          {/*            )*/}
          {/*        : createBlockFromExplorerBlock*/}
          {/*    }*/}
          {/*    startIcon={<EditIcon />}*/}
          {/*    disabled={*/}
          {/*      loadingStatus.status === Status.LOADING ||*/}
          {/*      !block ||*/}
          {/*      resourceInfo === undefined ||*/}
          {/*      (!resourceInfo.public && resourceInfo.author !== userId)*/}
          {/*    }*/}
          {/*  >*/}
          {/*    {resourceInfo?.author === userId && block?.type === "resource"*/}
          {/*      ? "Edit original"*/}
          {/*      : "Adapt"}*/}
          {/*  </LoadingButton>*/}
          {/*</Tooltip>*/}
          {/*{!isTabletOrMobile && (*/}
          {/*  <Tooltip*/}
          {/*    title={*/}
          {/*      "Practise answering questions within this resource, compare your answers and submit your answers for feedback"*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <Button*/}
          {/*      size="small"*/}
          {/*      onClick={createSubmissionAndNavigate}*/}
          {/*      sx={{*/}
          {/*        "&:hover": {*/}
          {/*          backgroundColor:*/}
          {/*            altraResourceBuilderTheme.palette.primary.main,*/}
          {/*        },*/}
          {/*        backgroundColor:*/}
          {/*          altraResourceBuilderTheme.palette.primary.dark,*/}
          {/*        color: altraResourceBuilderTheme.palette.primary.contrastText,*/}
          {/*      }}*/}
          {/*      color={"primary"}*/}
          {/*      variant="contained"*/}
          {/*      startIcon={<QuizIcon />}*/}
          {/*    >*/}
          {/*      Practise*/}
          {/*    </Button>*/}
          {/*  </Tooltip>*/}
          {/*)}*/}
          {/*<Tooltip title={"Report any problems with this resource"}>*/}
          {/*  <Button*/}
          {/*    sx={{ margin: "0 1em" }}*/}
          {/*    size="small"*/}
          {/*    startIcon={<ErrorOutlineIcon />}*/}
          {/*    color={"warning"}*/}
          {/*    variant={"contained"}*/}
          {/*    onClick={() =>*/}
          {/*      openInNewTab(*/}
          {/*        `https://2uluvvlx386.typeform.com/to/rhLlAWSr#name=${user.display_name}&block=${block?.id}&email=${user.email}`*/}
          {/*      )*/}
          {/*    }*/}
          {/*  >*/}
          {/*    Issue*/}
          {/*  </Button>*/}
          {/*</Tooltip>*/}
        </div>
      );
    }
  }
  // if (
  //   history.location.pathname.includes("submission/") ||
  //   history.location.pathname.includes("reviewed/") ||
  //   history.location.pathname.includes("review/")
  // ) {
  //   return (
  //     <div>
  //       <Tooltip
  //         title={"Your submission is saved automatically every few seconds"}
  //       >
  //         <Button
  //           size="small"
  //           variant={"contained"}
  //           onClick={() => dispatch(userAppInfoTriggerResourceSave(true))}
  //           sx={{ margin: "0 1em", width: "90px" }}
  //           startIcon={<SaveIcon />}
  //           disabled={loadingStatus.status === Status.LOADING}
  //         >
  //           {loadingStatus.status === Status.IDLE && "Save"}
  //           {loadingStatus.status === Status.LOADING && "Saving"}
  //         </Button>
  //       </Tooltip>
  //
  //       {history.location.pathname.includes("reviewed/") && (
  //         <Tooltip
  //           title={
  //             "Search and filter through high quality resources mapped to the curriculum"
  //           }
  //         >
  //           <Button
  //             size="small"
  //             variant={"contained"}
  //             onClick={() =>
  //               //@ts-expect-error
  //               routerParams?.id && history.push(`/explorer`)
  //             }
  //             sx={{ margin: "0 1em" }}
  //             startIcon={<SearchIcon />}
  //             disabled={loadingStatus.status === Status.LOADING}
  //           >
  //             Find similar resources
  //           </Button>
  //         </Tooltip>
  //       )}
  //
  //       {!history.location.pathname.includes("reviewed/") ? (
  //         <ShareSubmissionPopper />
  //       ) : (
  //         <Tooltip title={"Edit your original submission"}>
  //           <Button
  //             sx={{ margin: "0 1em" }}
  //             size="small"
  //             variant={"contained"}
  //             //@ts-expect-error
  //             onClick={() => history.push(`/submission/${routerParams?.id}`)}
  //             disabled={loadingStatus.status === Status.LOADING}
  //           >
  //             Edit submission
  //           </Button>
  //         </Tooltip>
  //       )}
  //
  //       {(history.location.pathname.includes("submission/") ||
  //         history.location.pathname.includes("reviewed/")) && (
  //         <Tooltip title={"Show the answers to all the questions"}>
  //           <Button
  //             size="small"
  //             variant={"contained"}
  //             onClick={() =>
  //               history.push(
  //                 history.location.pathname.includes("submission/")
  //                   ? //@ts-expect-error
  //                     `/reviewed/${routerParams?.id}`
  //                   : //@ts-expect-error
  //                     `/submission/${routerParams?.id}`
  //               )
  //             }
  //             disabled={loadingStatus.status === Status.LOADING}
  //           >
  //             {history.location.pathname.includes("submission/")
  //               ? "Show answers"
  //               : "Hide answers"}
  //           </Button>
  //         </Tooltip>
  //       )}
  //     </div>
  //   );
  // }
  if (
    history.location.pathname.includes("/submission") ||
    history.location.pathname.includes("/reviewed") ||
    history.location.pathname.includes("/review") ||
    history.location.pathname.includes("/topicSelection") ||
    history.location.pathname.includes("/curriculumSelection") ||
    history.location.pathname.includes("/appSelection") ||
    history.location.pathname === "/"
  ) {
    return <div />;
  } else {
    return (
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <NonEditableDropdown
          onClick={() => history.push("/curriculumSelection")}
          label={"Curriculum"}
          value={selectedCurriculumsIds
            .map(
              (scId) =>
                curriculums.find((c) => c.id === scId)?.short_title || ""
            )
            .join(", ")}
        />
        <NonEditableDropdown
          onClick={() => history.push("/topicSelection")}
          label={"Subject"}
          value={
            selectedTopTierTopicIds
              ?.filter(
                (tId) =>
                  getUnionOfValuesForKeys(topicLabels)
                    ?.find(
                      (tl) =>
                        tl.id ===
                        getUnionOfValuesForKeys(topics).find(
                          (t) => t.id === tId
                        )?.topic_label_id
                    )
                    ?.title?.toLowerCase() !== "quick search"
              )
              ?.map(
                (scId) =>
                  topTierTopicsForSelectedCurriculums.find((c) => c.id === scId)
                    ?.title || ""
              )
              .join(", ") || ""
          }
        />
      </div>
    );
  }
};
