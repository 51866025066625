import { createTheme } from "@mui/material/styles";
import { altraTheme } from "./altra-theme";

export const alterCurriculumExplorerTheme = createTheme({
  ...altraTheme,
  ...{
    palette: {
      primary: {
        main: "#5928E5",
        dark: "#414BCC",
        light: "rgba(89,40,229,0.31)",
        contrastText: "#FFF",
      },
    },
  },
});

// Another way to manage global mobilr theme
/*alterCurriculumExplorerTheme.typography.h4 = {
  //fontSize: "1.2rem",
  fontSize: 24,
  letterSpacing: 0.4,
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [alterCurriculumExplorerTheme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};*/
