export const GetTopTierTopics = /* GraphQL */ `
  query getTopTierTopics($curriculumIds: [bigint!]) {
    topic(
      where: {
        curriculum_id: { _in: $curriculumIds }
        active: { _eq: true }
        topic_label: { order_id: { _eq: 1 } }
      }
    ) {
      curriculum_id
      description
      url
      has_children
      active
      id
      parent_id
      title
      topic_label_id
      topic_unit_title
      topic_label {
        curriculum_id
        id
        title
        order_id
      }
    }
  }
`;

export const GetBlocksWithChildren = /* GraphQL */ `
  query getBlocksWithChildren($ids: _int4) {
    get_blocks_with_children(args: { ids: $ids }) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      note
      has_children
    }
  }
`;

export const GetSubmission = /* GraphQL */ `
  query getSubmission($id: Int!) {
    submission_by_pk(id: $id) {
      id
      block_id
      requester_user_id
      marker_user_id
      submission_answers
      questions_feedback
      general_comments
      marks
      publicly_accessible
      requester_name
      marker_name
      title
    }
  }
`;

export const GetTopicsWithChildren = /* GraphQL */ `
  query getTopicsWithChildren($ids: _int4) {
    get_topics_with_children(args: { ids: $ids }) {
      curriculum_id
      description
      has_children
      active
      id
      parent_id
      title
      topic_label_id
      topic_unit_title
      url
      topic_label {
        id
        title
        curriculum_id
        order_id
      }
    }
  }
`;

export const GetExistingTagsForTopicId = /* GraphQL */ `
  query getExistingTagsForTopicId($topicId: bigint) {
    tag(where: { topic_id: { _eq: $topicId } }) {
      id
      block {
        id
        type
        block
      }
    }
  }
`;

export const GetExistingBlocksForTopicId = /* GraphQL */ `
  query getExistingBlocksForTopicId($topicIds: [bigint!]) {
    block(where: { tags: { topic_id: { _in: $topicIds } } }) {
      id
      type
      created_by_id
      publicly_accessible
      verified
    }
  }
`;

export const GetSubscriptions = /* GraphQL */ `
  query GetSubscriptions {
    subscription {
      id
      plan
      monthly_price
      quarterly_price
      yearly_price
      description
    }
  }
`;

export const SearchUsers = /* GraphQL */ `
  query SearchUsers($searchTerm: String) {
    search_users(args: { search: $searchTerm }) {
      display_name
      id
      avatar_url
    }
  }
`;

export const GetUsersWithName = /* GraphQL */ `
  query getUsersWithName($searchName: String) {
    users(where: { display_name: { _eq: $searchName } }) {
      display_name
      id
      institution_id
    }
  }
`;

export const GetBlocksForUser = /* GraphQL */ `
  query GetBlocksForUser($userId: bigint!, $blockIds: [String!], $limit: Int) {
    block(
      where: { created_by_id: { _eq: $userId }, type: { _in: $blockIds } }
      limit: $limit
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      note
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      has_children
    }
  }
`;

export const getChildrenOfParentWithId = /* GraphQL */ `
  query getChildrenOfParentWithId($parentId: bigint, $limit: Int) {
    block(where: { parent_id: { _eq: $parentId } }, limit: $limit) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      note
      created_by_id
      created_time
      has_children
    }
  }
`;

export const freeTextSearchOnBlock = /* GraphQL */ `
  query FreeTextSearchOnBlock($search: String) {
    search_blocks(
      args: { search: $search }
      where: { publicly_accessible: { _eq: true } }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      note
      has_children
    }
  }
`;

export const searchAndFilterOnBlocks = /* GraphQL */ `
  query SearchAndFilterBlocks(
    $search: String
    $topicIds: [bigint!]
    $blockIds: [String!]
    $limit: Int!
    $offset: Int!
  ) {
    search_blocks(
      limit: $limit
      offset: $offset
      args: { search: $search }
      where: {
        tags: { topic_id: { _in: $topicIds, _is_null: false } }
        type: { _in: $blockIds }
        publicly_accessible: { _eq: true }
      }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      note
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      has_children
    }
  }
`;
export const searchAndFilterOnBlocksVerified = /* GraphQL */ `
  query SearchAndFilterBlocksVerified(
    $search: String
    $topicIds: [bigint!]
    $blockIds: [String!]
    $limit: Int!
    $offset: Int!
  ) {
    search_blocks(
      limit: $limit
      offset: $offset
      args: { search: $search }
      where: {
        tags: { topic_id: { _in: $topicIds, _is_null: false } }
        type: { _in: $blockIds }
        publicly_accessible: { _eq: true }
        verified: { _eq: true }
      }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      note
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      has_children
    }
  }
`;
export const searchAndFilterWithAuthorOnBlocks = /* GraphQL */ `
  query SearchAndFilterWithAuthorBlocks(
    $search: String
    $topicIds: [bigint!]
    $blockIds: [String!]
    $authorIds: [bigint!]
    $limit: Int!
    $offset: Int!
  ) {
    search_blocks(
      limit: $limit
      offset: $offset
      args: { search: $search }
      where: {
        tags: { topic_id: { _in: $topicIds, _is_null: false } }
        type: { _in: $blockIds }
        created_by_id: { _in: $authorIds }
        publicly_accessible: { _eq: true }
      }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      note
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      has_children
    }
  }
`;
export const searchAndFilterWithAuthorOnBlocksVerified = /* GraphQL */ `
  query SearchAndFilterWithAuthorBlocksVerified(
    $search: String
    $topicIds: [bigint!]
    $blockIds: [String!]
    $authorIds: [bigint!]
    $limit: Int!
    $offset: Int!
  ) {
    search_blocks(
      limit: $limit
      offset: $offset
      args: { search: $search }
      where: {
        tags: { topic_id: { _in: $topicIds, _is_null: false } }
        type: { _in: $blockIds }
        created_by_id: { _in: $authorIds }
        publicly_accessible: { _eq: true }
        verified: { _eq: true }
      }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      note
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      has_children
    }
  }
`;

export const filterBlocks = /* GraphQL */ `
  query filterBlocks(
    $topiclistone: _int4
    $topiclisttwo: _int4
    $topiclistthree: _int4
    $topiclistfour: _int4
    $topiclistfive: _int4
    $topiclistsix: _int4
    $topiclistseven: _int4
    $topiclisteight: _int4
    $topiclistnine: _int4
    $topiclistten: _int4
    $topiclisteleven: _int4
    $topiclisttwelve: _int4
    $topiclistthirteen: _int4
    $topiclistfourteen: _int4
    $topiclistfifteen: _int4
    $blockIds: String
    $limit: Int!
    $offset: Int!
    $verified: bit
    $blockauthorids: _int4
    $searchterm: String
  ) {
    filter_blocks(
      args: {
        searchterm: $searchterm
        block_limit: $limit
        block_offset: $offset
        blockverified: $verified
        blockauthorids: $blockauthorids
        blockidlist: $blockIds
        topiclistone: $topiclistone
        topiclisttwo: $topiclisttwo
        topiclistthree: $topiclistthree
        topiclistfour: $topiclistfour
        topiclistfive: $topiclistfive
        topiclistsix: $topiclistsix
        topiclistseven: $topiclistseven
        topiclisteight: $topiclisteight
        topiclistnine: $topiclistnine
        topiclistten: $topiclistten
        topiclisteleven: $topiclisteleven
        topiclisttwelve: $topiclisttwelve
        topiclistthirteen: $topiclistthirteen
        topiclistfourteen: $topiclistfourteen
        topiclistfifteen: $topiclistfifteen
      }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      note
      has_children
    }
  }
`;
export const filterOnBlocksVerified = /* GraphQL */ `
  query FilterBlocksVerified(
    $topicIds: [bigint!]
    $blockIds: [String!]
    $limit: Int!
    $offset: Int!
  ) {
    block(
      limit: $limit
      offset: $offset
      where: {
        tags: { topic_id: { _in: $topicIds, _is_null: false } }
        type: { _in: $blockIds }
        publicly_accessible: { _eq: true }
        verified: { _eq: true }
      }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      note
      has_children
    }
  }
`;

export const filterOnBlocksWithAuthor = /* GraphQL */ `
  query FilterBlocksWithAuthor(
    $topicIds: [bigint!]
    $authorIds: [bigint!]
    $blockIds: [String!]
    $limit: Int!
    $offset: Int!
  ) {
    block(
      limit: $limit
      offset: $offset
      where: {
        created_by_id: { _in: $authorIds }
        tags: { topic_id: { _in: $topicIds, _is_null: false } }
        type: { _in: $blockIds }
        publicly_accessible: { _eq: true }
      }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      note
      has_children
    }
  }
`;
export const filterOnBlocksWithAuthorVerified = /* GraphQL */ `
  query FilterBlocksWithAuthorVerified(
    $topicIds: [bigint!]
    $authorIds: [bigint!]
    $blockIds: [String!]
    $limit: Int!
    $offset: Int!
  ) {
    block(
      limit: $limit
      offset: $offset
      where: {
        created_by_id: { _in: $authorIds }
        tags: { topic_id: { _in: $topicIds, _is_null: false } }
        type: { _in: $blockIds }
        publicly_accessible: { _eq: true }
        verified: { _eq: true }
      }
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      created_by_id
      created_time
      note
      has_children
    }
  }
`;

export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($email: String) {
    users(where: { email: { _eq: $email } }) {
      avatar_url
      bio_data
      verified
      school
      active
      created_time
      display_name
      email
      id
      institution_id
      last_edited_time
      subscription_id
      stripe_id
      tags {
        id
        curriculum_id
        topic_id
        topic {
          parent_id
          title
          topic_unit_title
          topic_label_id
          id
        }
        curriculum {
          name
          id
          short_title
        }
      }
    }
  }
`;

export const getAllUsers = /* GraphQL */ `
  query GetAllUsers($limit: Int, $offset: Int) {
    users(limit: $limit, offset: $offset) {
      active
      display_name
      email
      id
      verified
      subscription_id
      subscription {
        plan
      }
      institution_id
      institution {
        owner
        display_name
      }
    }
  }
`;

export const getAllInstitution = /* GraphQL */ `
  query GetAllInstitution($limit: Int, $offset: Int) {
    institution(limit: $limit, offset: $offset) {
      bio
      active
      display_name
      id
      owner
    }
  }
`;

export const getApplicationContext = /* GraphQL */ `
  query ApplicationContext {
    country {
      code
      id
      name
    }
    language {
      id
      name
      code
    }
    subscription {
      id
      plan
      quarterly_price
      yearly_price
      monthly_price
      last_edited_time
      discount
      description
      created_time
    }
    institution {
      id
      display_name
      bio
      owner
      users {
        active
        display_name
        email
        id
      }
      tags {
        curriculum_id
        id
        topic_id
      }
    }
  }
`;

export const getTagForBlock = /* GraphQL */ `
  query getTagForBlock($blockId: bigint) {
    tag(where: { block_id: { _eq: $blockId } }) {
      id
      topic_id
      block_id
    }
  }
`;

export const getTopicsAndLabelsForCurriculums = /* GraphQL */ `
  query getTopicsAndLabelsForCurriculums($curriculumIds: [bigint!]) {
    topic(where: { curriculum_id: { _in: $curriculumIds } }) {
      curriculum_id
      description
      has_children
      active
      url
      id
      parent_id
      title
      topic_label_id
      topic_unit_title
    }
    topic_label(where: { curriculum_id: { _in: $curriculumIds } }) {
      curriculum_id
      id
      title
      order_id
    }
  }
`;

export const getActiveTopicsAndLabelsForCurriculums = /* GraphQL */ `
  query getActiveTopicsAndLabelsForCurriculums($curriculumIds: [bigint!]) {
    topic(
      where: { curriculum_id: { _in: $curriculumIds }, active: { _eq: true } }
    ) {
      curriculum_id
      description
      has_children
      active
      id
      parent_id
      title
      topic_label_id
      topic_unit_title
    }
    topic_label(where: { curriculum_id: { _in: $curriculumIds } }) {
      curriculum_id
      id
      title
      order_id
    }
  }
`;

export const getActiveCurriculums = /* GraphQL */ `
  query getActiveCurriculums {
    curriculum(where: { publicly_accessible: { _eq: true } }) {
      country_id
      created_time
      description
      id
      institution_id
      last_edited_time
      name
      url
      publicly_accessible
      ref_curriculum_id
      short_title
    }
  }
`;

export const getAllCurriculums = /* GraphQL */ `
  query getAllCurriculums {
    curriculum {
      country_id
      created_time
      description
      id
      institution_id
      last_edited_time
      name
      publicly_accessible
      ref_curriculum_id
      short_title
      url
    }
  }
`;

export const getAuthorOfBlockWithId = /* GraphQL */ `
  query getAuthorOfBlockWithId($blockId: Int!) {
    block_by_pk(id: $blockId) {
      id
      created_by_id
      type
    }
  }
`;

export const getBlockWithId = /* GraphQL */ `
  query getBlockWithId($blockId: Int) {
    block(where: { id: { _eq: $blockId } }) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      note
      publicly_accessible
      ref_block_id
      note
      topic_id
      url
      created_by_id
      created_time
      has_children
    }
  }
`;

export const checkBlockIsPublic = /* GraphQL */ `
  query checkBlockIsPublic($id: Int!) {
    block_by_pk(id: $id) {
      id
      publicly_accessible
      created_by_id
    }
  }
`;

export const getChildrenOfParentWithIdExcludingAnswers = /* GraphQL */ `
  query getChildrenOfParentWithIdExcludingAnswers(
    $parentId: bigint
    $limit: Int
  ) {
    block(
      where: {
        parent_id: { _eq: $parentId }
        type: {
          _nin: [
            "answer"
            "answer-text"
            "potential-solutions"
            "generic-guidance"
            "additional-guidance"
            "solution"
          ]
        }
      }
      limit: $limit
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      note
      created_by_id
      created_time
      has_children
    }
  }
`;

export const getChildrenOfParentWithIdAnswersOnly = /* GraphQL */ `
  query getChildrenOfParentWithIdAnswersOnly($parentId: bigint, $limit: Int) {
    block(
      where: {
        parent_id: { _eq: $parentId }
        type: {
          _in: [
            "answer"
            "answer-text"
            "potential-solutions"
            "generic-guidance"
            "additional-guidance"
            "solution"
          ]
        }
      }
      limit: $limit
    ) {
      id
      tags {
        id
        topic {
          id
          title
        }
      }
      type
      block
      active
      language_id
      last_edited_time
      parent_id
      position
      publicly_accessible
      ref_block_id
      topic_id
      url
      note
      created_by_id
      created_time
      has_children
    }
  }
`;
