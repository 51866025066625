import { apolloClientForAdminUser } from "@altra-apps/common/src/providers/apolloClient";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import {
  CreateUserDocument,
  CreateUserMutation,
  GetUserByEmailDocument,
  GetUserByEmailQuery,
  UpdateUserByIdDocument,
  UpdateUserByIdMutation,
  Users_Obj_Rel_Insert_Input,
  Users_Set_Input,
} from "../../graphql/types";
import { EditableBlockProp } from "./types";

/**
 * Fetches user using email
 * @param email The email of the user
 */
export const getUserByEmailApi = async (
  email: string
): Promise<ApolloQueryResult<GetUserByEmailQuery>> => {
  const users: ApolloQueryResult<GetUserByEmailQuery> =
    await apolloClientForAdminUser.query({
      query: GetUserByEmailDocument,
      variables: { email: email },
    });
  return users;
};

/**
 * Create user
 */
export const createUserApi = async (
  userInput: Users_Obj_Rel_Insert_Input
): Promise<FetchResult<CreateUserMutation>> => {
  const result: FetchResult<CreateUserMutation> =
    await apolloClientForAdminUser.mutate({
      mutation: CreateUserDocument,
      variables: {
        data: userInput.data,
        on_conflict: userInput.on_conflict,
      },
    });
  return result;
};

/**
 * Update user
 */
export const updateUserByIdApi = async (
  userInput: Users_Set_Input
): Promise<FetchResult<UpdateUserByIdMutation>> => {
  const result: FetchResult<UpdateUserByIdMutation> =
    await apolloClientForAdminUser.mutate({
      mutation: UpdateUserByIdDocument,
      variables: {
        userInput: userInput,
        userId: userInput.id!,
      },
    });
  return result;
};

/**
 * Update user
 */
export const saveResourceApi = async (
  mutation: string,
  mutationToClientIdMap: { string?: string }
): Promise<EditableBlockProp[]> => {
  let localEditableBlocksForUpdatePayload: EditableBlockProp[] = [];

  // console.log(mutation);

  const result: FetchResult = await apolloClientForAdminUser.mutate({
    mutation: gql`
      ${mutation}
    `,
  });

  if (result.data) {
    Object.keys(result.data).map((mutationId, index) => {
      const dbBlock = result.data && result.data[mutationId];
      if (dbBlock.id) {
        const payloadForReduxBlock = {
          client_id: mutationToClientIdMap[mutationId].toString(),
          dbId: dbBlock.id,
          has_children:
            dbBlock.children !== null && dbBlock.children?.length !== 0,
          parent_id: dbBlock.parent_id,
          position: dbBlock.position,
          type: dbBlock.type,
          publicly_accessible: dbBlock.publicly_accessible,
          tags:
            dbBlock?.tags?.map((tag) => ({
              id: tag.id || undefined,
              topicId: tag.topic.id || undefined,
              label: tag.topic.title,
            })) || [],
        };
        localEditableBlocksForUpdatePayload.push(payloadForReduxBlock);
      }
    });
    return localEditableBlocksForUpdatePayload;
  }
  if (result.errors) {
    console.log(result.errors);
  }

  return [];
};
