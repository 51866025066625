import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import { LandingPageFooter } from "../components/organisms/LandingPageFooter";
import { LandingPageTopAppBar } from "../components/organisms/LandingPageTopAppBar";

export const ContactScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isTabletOrMobile = useIsMobile();

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderCalendlyTab = () => (
    <StyledCalendlyTab>
      <Button
        href={"https://calendly.com/jackow1998/altra-chat"}
        target={"_blank"}
        variant={"contained"}
        color={"primary"}
      >
        Schedule a call
      </Button>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/jackow1998/altra-chat"
        style={{
          width: "100vw",
          height: "100vh",
        }}
      />
    </StyledCalendlyTab>
  );

  const renderContactForm = () => (
    <StyledForm
      style={{
        flexDirection: isTabletOrMobile ? "column" : "row",
        alignItems: "center",
        width: isTabletOrMobile ? "95%" : "60%",
      }}
    >
      <div style={{ width: "80%" }} id="mc_embed_signup">
        <form
          action="https://space.us1.list-manage.com/subscribe/post?u=fe2509e94a02731ad3cb89581&amp;id=1a7e6c2fe8"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <div className="indicates-required">
              <span className="asterisk">*</span>{" "}
              <Typography variant={"caption"}>Indicates required</Typography>
            </div>
            <StyledGroup className="mc-field-group">
              <Typography variant={"caption"}>
                Name <span className="asterisk">*</span>
              </Typography>
              <TextField
                type="text"
                name="NAME"
                className="required"
                id="mce-NAME"
              />
            </StyledGroup>
            <StyledGroup className="mc-field-group">
              <Typography variant={"caption"}>
                Email <span className="asterisk">*</span>
              </Typography>
              <TextField
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
              />
            </StyledGroup>
            <StyledGroup className="mc-field-group">
              <Typography variant={"caption"}>Role</Typography>
              <select name="ROLE" className="" id="mce-ROLE">
                <option value="" />
                <option value="Teacher">Teacher</option>
                <option value="Senior Management">Senior Management</option>
                <option value="Private Tutor">Private Tutor</option>
                <option value="Parent">Parent</option>
                <option value="Student">Student</option>
              </select>
            </StyledGroup>
            <StyledGroup className="mc-field-group">
              <Typography variant={"caption"}>
                Message <span className="asterisk">*</span>
              </Typography>
              <TextField
                type="text"
                name="MESSAGE"
                className="required"
                id="mce-MESSAGE"
              />
            </StyledGroup>
            <Button
              variant={"contained"}
              color={"primary"}
              type="submit"
              value="Submit"
              name="Submit"
              id="mc-embedded-subscribe"
            >
              Submit
            </Button>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              />
            </div>
            <div
              style={{ position: "absolute", left: " -5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_fe2509e94a02731ad3cb89581_1a7e6c2fe8"
                value=""
              />
            </div>
          </div>
        </form>
      </div>
    </StyledForm>
  );

  return (
    <StyledContactScreen>
      <LandingPageTopAppBar />

      <Toolbar />
      <StyledTabsHeaderContainer>
        <StyledInfoRow>
          <Typography
            style={{ marginBottom: 10 }}
            variant={"h1"}
            color={"secondary"}
          >
            Get in touch
          </Typography>
          <Typography variant={"body1"} color={"textSecondary"}>
            Any questions or remarks? Book a chat or send us an email.
          </Typography>
        </StyledInfoRow>
      </StyledTabsHeaderContainer>
      <StyledTabContent>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Book a chat" />
          <Tab label="Send a message" />
        </Tabs>
        <StyledTabsContentInner
          style={{ backgroundColor: altraTheme.palette.background.default }}
        >
          <StyledCenteredContainer>
            {tabValue === 0 ? renderCalendlyTab() : renderContactForm()}
          </StyledCenteredContainer>
        </StyledTabsContentInner>
      </StyledTabContent>
      <LandingPageFooter />
    </StyledContactScreen>
  );
};

const StyledContactScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTabsHeaderContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  //background-color: hotpink;
  align-items: center;
`;

const StyledTabContent = styled.div`
  //background-color: green;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
`;
const StyledInfoRow = styled.div`
  //background-color: blue;
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
`;

const StyledTabsContentInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
`;

const StyledCenteredContainer = styled.div`
  //background-color: red;
  margin-bottom: 40px;
  min-height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
`;

const StyledCalendlyTab = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled.div`
  //background-color: orange;
  margin: 10px;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  margin-top: 30px;

  justify-content: center;
`;

const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
