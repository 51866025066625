import { ErrorSnackbar } from "@altra-apps/common/src/atoms/ErrorSnackbar";
import {
  Users_Constraint,
  Users_Obj_Rel_Insert_Input,
  Users_Update_Column,
} from "@altra-apps/common/src/graphql/types";
import { Subscription } from "@altra-apps/common/src/redux/applicationContext/types";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import {
  createUser,
  getUserByEmail,
} from "@altra-apps/common/src/redux/user/actions";
import {
  AppSubscriptionPlan,
  Status,
} from "@altra-apps/common/src/util/custom-types";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { useAuth0 } from "@auth0/auth0-react";
import { Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { LandingPageAppOverviews } from "../components/organisms/LandingPageAppOverviews";
import { LandingPageFooter } from "../components/organisms/LandingPageFooter";
import { LandingPageOverview } from "../components/organisms/LandingPageOverview";
import { LandingPagePartners } from "../components/organisms/LandingPagePartners";
import { LandingPageTeacherQuotes } from "../components/organisms/LandingPageTeacherQuotes";
import { LandingPageTopAppBar } from "../components/organisms/LandingPageTopAppBar";

/**
 * Welcome page
 * @constructor
 */
export const HomeScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isTabletOrMobile = useIsMobile();

  const history = useHistory();
  const dispatch = useAppDispatch();

  // use to set runtime error
  const [error, setError] = useState<string>();
  // snackbar state handler to make it open or close
  const [snackbarState, setSnackbarState] = useState<boolean>(false);
  const { isAuthenticated, user, getIdTokenClaims } = useAuth0();
  // use for redirecting user to the next screen
  const [isUserRedirectState, setIsUserRedirectState] =
    useState<boolean>(false);
  const userLoadingStatusError: string = useAppSelector(
    (state) => state.user.error
  );

  const freeAccountSubscription: Subscription = useAppSelector((state) =>
    state.applicationContext.subscriptions?.find(
      (element) => element.plan === AppSubscriptionPlan.FREE
    )
  ) as Subscription;

  // Create new user with user Auth0 details
  async function createNewUser() {
    let userInput: Users_Obj_Rel_Insert_Input = {
      data: {
        email: user?.email,
        created_time: Date.now(),
        display_name: user?.given_name,
        last_edited_time: Date.now(),
        avatar_url: user?.picture,
        active: true,
        verified: false,
        subscription_id: freeAccountSubscription?.id,
      },
      on_conflict: {
        constraint: Users_Constraint.Uk_6dotkott2kjsp8vw4d0m25fb7,
        update_columns: [
          Users_Update_Column.CreatedTime,
          Users_Update_Column.DisplayName,
          Users_Update_Column.LastEditedTime,
          Users_Update_Column.AvatarUrl,
        ],
      },
    };
    const resultActionForCreateUser = await dispatch(createUser(userInput));
    if (createUser.fulfilled.match(resultActionForCreateUser)) {
      // User created in DB. Load the select curriculum page
      // console.log(
      //   ">>>>> USER HAS BEEN CREATED IN DB",
      //   resultActionForCreateUser.payload
      // );
      setIsUserRedirectState(true);
    } else {
      setIsUserRedirectState(false);
      // console.log(">>>>> SERVER ERROR", resultActionForCreateUser.payload);
      setError(userLoadingStatusError);
      setSnackbarState(true);
    }
  }

  useEffect(() => {
    if (isAuthenticated && user) {
      (async () => {
        // reset error and snackbar state
        setError(undefined);
        setSnackbarState(false);
        //After successfully login, check user existence in backend, if not, create one and set user redirect state to tue
        const resultActionForGetUser = await dispatch(
          getUserByEmail(user?.email!)
        );
        if (getUserByEmail.fulfilled.match(resultActionForGetUser)) {
          // User found in DB. Load the landing page
          // console.log(">>>>> USER FOUND IN DB", resultActionForGetUser.payload);
          setIsUserRedirectState(true);
        } else if (getUserByEmail.rejected.match(resultActionForGetUser)) {
          setIsUserRedirectState(false);
          if (resultActionForGetUser.payload === Status.NOT_FOUND) {
            //user not found, create one in DB
            // console.log(">>>>> USER NOT FOUND, NEED TO CREATE NEW IN DB");
            await createNewUser();
          } else {
            // server error, show error message to user using snackbar
            setError(userLoadingStatusError);
            setSnackbarState(true);
          }
        }
      })();
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    // console.log("REDIRECT");
    history.push("/");
  }, [isUserRedirectState]);

  return (
    <StyledHomeScreen>
      <LandingPageTopAppBar />
      <Toolbar />
      <ErrorSnackbar error={error!} severity={"error"} state={snackbarState} />
      <Toolbar />

      <LandingPageOverview />
      <LandingPagePartners />
      <LandingPageAppOverviews />
      <LandingPageTeacherQuotes />
      {/*<LandingPageCustomerTabs />*/}
      {/*<LandingPagePricing*/}
      {/*  theme={altraTheme}*/}
      {/*  bodyText={LandingPageContent.LANDING_PAGE_PRICING}*/}
      {/*/>*/}
      <LandingPageFooter />
    </StyledHomeScreen>
  );
};

const StyledHomeScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
