import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  bigint: number;
  bit: any;
  float8: number;
  jsonb: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ExportResourceInput = {
  format: Scalars['String'];
  resourceId: Scalars['String'];
  userId: Scalars['String'];
};

export type ExportResourceOutput = {
  __typename?: 'ExportResourceOutput';
  url: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "block" */
export type Block = {
  __typename?: 'block';
  active: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  block: Scalars['jsonb'];
  /** An object relationship */
  blockByParentId?: Maybe<Block>;
  /** An array relationship */
  block_change_requests: Array<Block_Change_Request>;
  /** An aggregate relationship */
  block_change_requests_aggregate: Block_Change_Request_Aggregate;
  /** An array relationship */
  blocks: Array<Block>;
  /** An aggregate relationship */
  blocks_aggregate: Block_Aggregate;
  created_by_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  has_children: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An object relationship */
  language?: Maybe<Language>;
  language_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  note?: Maybe<Scalars['jsonb']>;
  parent_id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['bigint']>;
  publicly_accessible: Scalars['Boolean'];
  ref_block_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  submissions: Array<Submission>;
  /** An aggregate relationship */
  submissions_aggregate: Submission_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: Tag_Aggregate;
  /** An object relationship */
  topic?: Maybe<Topic>;
  topic_id?: Maybe<Scalars['bigint']>;
  type: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  verified?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  votes: Array<Vote>;
  /** An aggregate relationship */
  votes_aggregate: Vote_Aggregate;
};


/** columns and relationships of "block" */
export type BlockBlockArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "block" */
export type BlockBlock_Change_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Change_Request_Order_By>>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockBlock_Change_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Change_Request_Order_By>>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockBlocksArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockBlocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockNoteArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "block" */
export type BlockSubmissionsArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockSubmissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockVotesArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


/** columns and relationships of "block" */
export type BlockVotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};

/** aggregated selection of "block" */
export type Block_Aggregate = {
  __typename?: 'block_aggregate';
  aggregate?: Maybe<Block_Aggregate_Fields>;
  nodes: Array<Block>;
};

/** aggregate fields of "block" */
export type Block_Aggregate_Fields = {
  __typename?: 'block_aggregate_fields';
  avg?: Maybe<Block_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Block_Max_Fields>;
  min?: Maybe<Block_Min_Fields>;
  stddev?: Maybe<Block_Stddev_Fields>;
  stddev_pop?: Maybe<Block_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Block_Stddev_Samp_Fields>;
  sum?: Maybe<Block_Sum_Fields>;
  var_pop?: Maybe<Block_Var_Pop_Fields>;
  var_samp?: Maybe<Block_Var_Samp_Fields>;
  variance?: Maybe<Block_Variance_Fields>;
};


/** aggregate fields of "block" */
export type Block_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Block_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "block" */
export type Block_Aggregate_Order_By = {
  avg?: InputMaybe<Block_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Block_Max_Order_By>;
  min?: InputMaybe<Block_Min_Order_By>;
  stddev?: InputMaybe<Block_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Block_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Block_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Block_Sum_Order_By>;
  var_pop?: InputMaybe<Block_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Block_Var_Samp_Order_By>;
  variance?: InputMaybe<Block_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Block_Append_Input = {
  block?: InputMaybe<Scalars['jsonb']>;
  note?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "block" */
export type Block_Arr_Rel_Insert_Input = {
  data: Array<Block_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Block_On_Conflict>;
};

/** aggregate avg on columns */
export type Block_Avg_Fields = {
  __typename?: 'block_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  language_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "block" */
export type Block_Avg_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "block". All fields are combined with a logical 'AND'. */
export type Block_Bool_Exp = {
  _and?: InputMaybe<Array<Block_Bool_Exp>>;
  _not?: InputMaybe<Block_Bool_Exp>;
  _or?: InputMaybe<Array<Block_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  block?: InputMaybe<Jsonb_Comparison_Exp>;
  blockByParentId?: InputMaybe<Block_Bool_Exp>;
  block_change_requests?: InputMaybe<Block_Change_Request_Bool_Exp>;
  blocks?: InputMaybe<Block_Bool_Exp>;
  created_by_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_time?: InputMaybe<Bigint_Comparison_Exp>;
  has_children?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  language?: InputMaybe<Language_Bool_Exp>;
  language_id?: InputMaybe<Bigint_Comparison_Exp>;
  last_edited_time?: InputMaybe<Bigint_Comparison_Exp>;
  note?: InputMaybe<Jsonb_Comparison_Exp>;
  parent_id?: InputMaybe<Bigint_Comparison_Exp>;
  position?: InputMaybe<Bigint_Comparison_Exp>;
  publicly_accessible?: InputMaybe<Boolean_Comparison_Exp>;
  ref_block_id?: InputMaybe<String_Comparison_Exp>;
  submissions?: InputMaybe<Submission_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  topic?: InputMaybe<Topic_Bool_Exp>;
  topic_id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
  votes?: InputMaybe<Vote_Bool_Exp>;
};

/** columns and relationships of "block_change_request" */
export type Block_Change_Request = {
  __typename?: 'block_change_request';
  /** An object relationship */
  block?: Maybe<Block>;
  block_id?: Maybe<Scalars['bigint']>;
  block_type: Scalars['String'];
  change_description: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  created_time: Scalars['bigint'];
  id: Scalars['Int'];
  ref_object_id: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Users;
  /** An array relationship */
  user_comments: Array<User_Comment>;
  /** An aggregate relationship */
  user_comments_aggregate: User_Comment_Aggregate;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "block_change_request" */
export type Block_Change_RequestUser_CommentsArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};


/** columns and relationships of "block_change_request" */
export type Block_Change_RequestUser_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};

/** aggregated selection of "block_change_request" */
export type Block_Change_Request_Aggregate = {
  __typename?: 'block_change_request_aggregate';
  aggregate?: Maybe<Block_Change_Request_Aggregate_Fields>;
  nodes: Array<Block_Change_Request>;
};

/** aggregate fields of "block_change_request" */
export type Block_Change_Request_Aggregate_Fields = {
  __typename?: 'block_change_request_aggregate_fields';
  avg?: Maybe<Block_Change_Request_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Block_Change_Request_Max_Fields>;
  min?: Maybe<Block_Change_Request_Min_Fields>;
  stddev?: Maybe<Block_Change_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Block_Change_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Block_Change_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Block_Change_Request_Sum_Fields>;
  var_pop?: Maybe<Block_Change_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Block_Change_Request_Var_Samp_Fields>;
  variance?: Maybe<Block_Change_Request_Variance_Fields>;
};


/** aggregate fields of "block_change_request" */
export type Block_Change_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "block_change_request" */
export type Block_Change_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Block_Change_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Block_Change_Request_Max_Order_By>;
  min?: InputMaybe<Block_Change_Request_Min_Order_By>;
  stddev?: InputMaybe<Block_Change_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Block_Change_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Block_Change_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Block_Change_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Block_Change_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Block_Change_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Block_Change_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "block_change_request" */
export type Block_Change_Request_Arr_Rel_Insert_Input = {
  data: Array<Block_Change_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Block_Change_Request_On_Conflict>;
};

/** aggregate avg on columns */
export type Block_Change_Request_Avg_Fields = {
  __typename?: 'block_change_request_avg_fields';
  block_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "block_change_request" */
export type Block_Change_Request_Avg_Order_By = {
  block_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "block_change_request". All fields are combined with a logical 'AND'. */
export type Block_Change_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Block_Change_Request_Bool_Exp>>;
  _not?: InputMaybe<Block_Change_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Block_Change_Request_Bool_Exp>>;
  block?: InputMaybe<Block_Bool_Exp>;
  block_id?: InputMaybe<Bigint_Comparison_Exp>;
  block_type?: InputMaybe<String_Comparison_Exp>;
  change_description?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_time?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ref_object_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_comments?: InputMaybe<User_Comment_Bool_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "block_change_request" */
export enum Block_Change_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  BlockChangeRequestPkey = 'block_change_request_pkey'
}

/** input type for incrementing numeric columns in table "block_change_request" */
export type Block_Change_Request_Inc_Input = {
  block_id?: InputMaybe<Scalars['bigint']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "block_change_request" */
export type Block_Change_Request_Insert_Input = {
  block?: InputMaybe<Block_Obj_Rel_Insert_Input>;
  block_id?: InputMaybe<Scalars['bigint']>;
  block_type?: InputMaybe<Scalars['String']>;
  change_description?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  ref_object_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_comments?: InputMaybe<User_Comment_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Block_Change_Request_Max_Fields = {
  __typename?: 'block_change_request_max_fields';
  block_id?: Maybe<Scalars['bigint']>;
  block_type?: Maybe<Scalars['String']>;
  change_description?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  ref_object_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "block_change_request" */
export type Block_Change_Request_Max_Order_By = {
  block_id?: InputMaybe<Order_By>;
  block_type?: InputMaybe<Order_By>;
  change_description?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Block_Change_Request_Min_Fields = {
  __typename?: 'block_change_request_min_fields';
  block_id?: Maybe<Scalars['bigint']>;
  block_type?: Maybe<Scalars['String']>;
  change_description?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  ref_object_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "block_change_request" */
export type Block_Change_Request_Min_Order_By = {
  block_id?: InputMaybe<Order_By>;
  block_type?: InputMaybe<Order_By>;
  change_description?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "block_change_request" */
export type Block_Change_Request_Mutation_Response = {
  __typename?: 'block_change_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Block_Change_Request>;
};

/** input type for inserting object relation for remote table "block_change_request" */
export type Block_Change_Request_Obj_Rel_Insert_Input = {
  data: Block_Change_Request_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Block_Change_Request_On_Conflict>;
};

/** on_conflict condition type for table "block_change_request" */
export type Block_Change_Request_On_Conflict = {
  constraint: Block_Change_Request_Constraint;
  update_columns?: Array<Block_Change_Request_Update_Column>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "block_change_request". */
export type Block_Change_Request_Order_By = {
  block?: InputMaybe<Block_Order_By>;
  block_id?: InputMaybe<Order_By>;
  block_type?: InputMaybe<Order_By>;
  change_description?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_comments_aggregate?: InputMaybe<User_Comment_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: block_change_request */
export type Block_Change_Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "block_change_request" */
export enum Block_Change_Request_Select_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  BlockType = 'block_type',
  /** column name */
  ChangeDescription = 'change_description',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  Id = 'id',
  /** column name */
  RefObjectId = 'ref_object_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "block_change_request" */
export type Block_Change_Request_Set_Input = {
  block_id?: InputMaybe<Scalars['bigint']>;
  block_type?: InputMaybe<Scalars['String']>;
  change_description?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  ref_object_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Block_Change_Request_Stddev_Fields = {
  __typename?: 'block_change_request_stddev_fields';
  block_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "block_change_request" */
export type Block_Change_Request_Stddev_Order_By = {
  block_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Block_Change_Request_Stddev_Pop_Fields = {
  __typename?: 'block_change_request_stddev_pop_fields';
  block_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "block_change_request" */
export type Block_Change_Request_Stddev_Pop_Order_By = {
  block_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Block_Change_Request_Stddev_Samp_Fields = {
  __typename?: 'block_change_request_stddev_samp_fields';
  block_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "block_change_request" */
export type Block_Change_Request_Stddev_Samp_Order_By = {
  block_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Block_Change_Request_Sum_Fields = {
  __typename?: 'block_change_request_sum_fields';
  block_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "block_change_request" */
export type Block_Change_Request_Sum_Order_By = {
  block_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "block_change_request" */
export enum Block_Change_Request_Update_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  BlockType = 'block_type',
  /** column name */
  ChangeDescription = 'change_description',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  Id = 'id',
  /** column name */
  RefObjectId = 'ref_object_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Block_Change_Request_Var_Pop_Fields = {
  __typename?: 'block_change_request_var_pop_fields';
  block_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "block_change_request" */
export type Block_Change_Request_Var_Pop_Order_By = {
  block_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Block_Change_Request_Var_Samp_Fields = {
  __typename?: 'block_change_request_var_samp_fields';
  block_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "block_change_request" */
export type Block_Change_Request_Var_Samp_Order_By = {
  block_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Block_Change_Request_Variance_Fields = {
  __typename?: 'block_change_request_variance_fields';
  block_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "block_change_request" */
export type Block_Change_Request_Variance_Order_By = {
  block_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "block" */
export enum Block_Constraint {
  /** unique or primary key constraint on columns "id" */
  BlockPkey = 'block_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Block_Delete_At_Path_Input = {
  block?: InputMaybe<Array<Scalars['String']>>;
  note?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Block_Delete_Elem_Input = {
  block?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Block_Delete_Key_Input = {
  block?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "block" */
export type Block_Inc_Input = {
  created_by_id?: InputMaybe<Scalars['bigint']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  language_id?: InputMaybe<Scalars['bigint']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  parent_id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['bigint']>;
  topic_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "block" */
export type Block_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  block?: InputMaybe<Scalars['jsonb']>;
  blockByParentId?: InputMaybe<Block_Obj_Rel_Insert_Input>;
  block_change_requests?: InputMaybe<Block_Change_Request_Arr_Rel_Insert_Input>;
  blocks?: InputMaybe<Block_Arr_Rel_Insert_Input>;
  created_by_id?: InputMaybe<Scalars['bigint']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  has_children?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Language_Obj_Rel_Insert_Input>;
  language_id?: InputMaybe<Scalars['bigint']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  note?: InputMaybe<Scalars['jsonb']>;
  parent_id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['bigint']>;
  publicly_accessible?: InputMaybe<Scalars['Boolean']>;
  ref_block_id?: InputMaybe<Scalars['String']>;
  submissions?: InputMaybe<Submission_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  topic?: InputMaybe<Topic_Obj_Rel_Insert_Input>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  verified?: InputMaybe<Scalars['Boolean']>;
  votes?: InputMaybe<Vote_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Block_Max_Fields = {
  __typename?: 'block_max_fields';
  created_by_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  language_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['bigint']>;
  ref_block_id?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "block" */
export type Block_Max_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  ref_block_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Block_Min_Fields = {
  __typename?: 'block_min_fields';
  created_by_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  language_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['bigint']>;
  ref_block_id?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "block" */
export type Block_Min_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  ref_block_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "block" */
export type Block_Mutation_Response = {
  __typename?: 'block_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Block>;
};

/** input type for inserting object relation for remote table "block" */
export type Block_Obj_Rel_Insert_Input = {
  data: Block_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Block_On_Conflict>;
};

/** on_conflict condition type for table "block" */
export type Block_On_Conflict = {
  constraint: Block_Constraint;
  update_columns?: Array<Block_Update_Column>;
  where?: InputMaybe<Block_Bool_Exp>;
};

/** Ordering options when selecting data from "block". */
export type Block_Order_By = {
  active?: InputMaybe<Order_By>;
  archived?: InputMaybe<Order_By>;
  block?: InputMaybe<Order_By>;
  blockByParentId?: InputMaybe<Block_Order_By>;
  block_change_requests_aggregate?: InputMaybe<Block_Change_Request_Aggregate_Order_By>;
  blocks_aggregate?: InputMaybe<Block_Aggregate_Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  has_children?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Language_Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  publicly_accessible?: InputMaybe<Order_By>;
  ref_block_id?: InputMaybe<Order_By>;
  submissions_aggregate?: InputMaybe<Submission_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  topic?: InputMaybe<Topic_Order_By>;
  topic_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  verified?: InputMaybe<Order_By>;
  votes_aggregate?: InputMaybe<Vote_Aggregate_Order_By>;
};

/** primary key columns input for table: block */
export type Block_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Block_Prepend_Input = {
  block?: InputMaybe<Scalars['jsonb']>;
  note?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "block_revision" */
export type Block_Revision = {
  __typename?: 'block_revision';
  block: Scalars['jsonb'];
  id: Scalars['Int'];
  revision_at: Scalars['bigint'];
  revision_no: Scalars['Int'];
};


/** columns and relationships of "block_revision" */
export type Block_RevisionBlockArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "block_revision" */
export type Block_Revision_Aggregate = {
  __typename?: 'block_revision_aggregate';
  aggregate?: Maybe<Block_Revision_Aggregate_Fields>;
  nodes: Array<Block_Revision>;
};

/** aggregate fields of "block_revision" */
export type Block_Revision_Aggregate_Fields = {
  __typename?: 'block_revision_aggregate_fields';
  avg?: Maybe<Block_Revision_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Block_Revision_Max_Fields>;
  min?: Maybe<Block_Revision_Min_Fields>;
  stddev?: Maybe<Block_Revision_Stddev_Fields>;
  stddev_pop?: Maybe<Block_Revision_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Block_Revision_Stddev_Samp_Fields>;
  sum?: Maybe<Block_Revision_Sum_Fields>;
  var_pop?: Maybe<Block_Revision_Var_Pop_Fields>;
  var_samp?: Maybe<Block_Revision_Var_Samp_Fields>;
  variance?: Maybe<Block_Revision_Variance_Fields>;
};


/** aggregate fields of "block_revision" */
export type Block_Revision_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Block_Revision_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Block_Revision_Append_Input = {
  block?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Block_Revision_Avg_Fields = {
  __typename?: 'block_revision_avg_fields';
  id?: Maybe<Scalars['Float']>;
  revision_at?: Maybe<Scalars['Float']>;
  revision_no?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "block_revision". All fields are combined with a logical 'AND'. */
export type Block_Revision_Bool_Exp = {
  _and?: InputMaybe<Array<Block_Revision_Bool_Exp>>;
  _not?: InputMaybe<Block_Revision_Bool_Exp>;
  _or?: InputMaybe<Array<Block_Revision_Bool_Exp>>;
  block?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  revision_at?: InputMaybe<Bigint_Comparison_Exp>;
  revision_no?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "block_revision" */
export enum Block_Revision_Constraint {
  /** unique or primary key constraint on columns "id" */
  BlockRevisionPkey = 'block_revision_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Block_Revision_Delete_At_Path_Input = {
  block?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Block_Revision_Delete_Elem_Input = {
  block?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Block_Revision_Delete_Key_Input = {
  block?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "block_revision" */
export type Block_Revision_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  revision_at?: InputMaybe<Scalars['bigint']>;
  revision_no?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "block_revision" */
export type Block_Revision_Insert_Input = {
  block?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  revision_at?: InputMaybe<Scalars['bigint']>;
  revision_no?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Block_Revision_Max_Fields = {
  __typename?: 'block_revision_max_fields';
  id?: Maybe<Scalars['Int']>;
  revision_at?: Maybe<Scalars['bigint']>;
  revision_no?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Block_Revision_Min_Fields = {
  __typename?: 'block_revision_min_fields';
  id?: Maybe<Scalars['Int']>;
  revision_at?: Maybe<Scalars['bigint']>;
  revision_no?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "block_revision" */
export type Block_Revision_Mutation_Response = {
  __typename?: 'block_revision_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Block_Revision>;
};

/** on_conflict condition type for table "block_revision" */
export type Block_Revision_On_Conflict = {
  constraint: Block_Revision_Constraint;
  update_columns?: Array<Block_Revision_Update_Column>;
  where?: InputMaybe<Block_Revision_Bool_Exp>;
};

/** Ordering options when selecting data from "block_revision". */
export type Block_Revision_Order_By = {
  block?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  revision_at?: InputMaybe<Order_By>;
  revision_no?: InputMaybe<Order_By>;
};

/** primary key columns input for table: block_revision */
export type Block_Revision_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Block_Revision_Prepend_Input = {
  block?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "block_revision" */
export enum Block_Revision_Select_Column {
  /** column name */
  Block = 'block',
  /** column name */
  Id = 'id',
  /** column name */
  RevisionAt = 'revision_at',
  /** column name */
  RevisionNo = 'revision_no'
}

/** input type for updating data in table "block_revision" */
export type Block_Revision_Set_Input = {
  block?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  revision_at?: InputMaybe<Scalars['bigint']>;
  revision_no?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Block_Revision_Stddev_Fields = {
  __typename?: 'block_revision_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  revision_at?: Maybe<Scalars['Float']>;
  revision_no?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Block_Revision_Stddev_Pop_Fields = {
  __typename?: 'block_revision_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision_at?: Maybe<Scalars['Float']>;
  revision_no?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Block_Revision_Stddev_Samp_Fields = {
  __typename?: 'block_revision_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision_at?: Maybe<Scalars['Float']>;
  revision_no?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Block_Revision_Sum_Fields = {
  __typename?: 'block_revision_sum_fields';
  id?: Maybe<Scalars['Int']>;
  revision_at?: Maybe<Scalars['bigint']>;
  revision_no?: Maybe<Scalars['Int']>;
};

/** update columns of table "block_revision" */
export enum Block_Revision_Update_Column {
  /** column name */
  Block = 'block',
  /** column name */
  Id = 'id',
  /** column name */
  RevisionAt = 'revision_at',
  /** column name */
  RevisionNo = 'revision_no'
}

/** aggregate var_pop on columns */
export type Block_Revision_Var_Pop_Fields = {
  __typename?: 'block_revision_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision_at?: Maybe<Scalars['Float']>;
  revision_no?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Block_Revision_Var_Samp_Fields = {
  __typename?: 'block_revision_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision_at?: Maybe<Scalars['Float']>;
  revision_no?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Block_Revision_Variance_Fields = {
  __typename?: 'block_revision_variance_fields';
  id?: Maybe<Scalars['Float']>;
  revision_at?: Maybe<Scalars['Float']>;
  revision_no?: Maybe<Scalars['Float']>;
};

/** select columns of table "block" */
export enum Block_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Archived = 'archived',
  /** column name */
  Block = 'block',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  HasChildren = 'has_children',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  LastEditedTime = 'last_edited_time',
  /** column name */
  Note = 'note',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Position = 'position',
  /** column name */
  PubliclyAccessible = 'publicly_accessible',
  /** column name */
  RefBlockId = 'ref_block_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "block" */
export type Block_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  block?: InputMaybe<Scalars['jsonb']>;
  created_by_id?: InputMaybe<Scalars['bigint']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  has_children?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  language_id?: InputMaybe<Scalars['bigint']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  note?: InputMaybe<Scalars['jsonb']>;
  parent_id?: InputMaybe<Scalars['bigint']>;
  position?: InputMaybe<Scalars['bigint']>;
  publicly_accessible?: InputMaybe<Scalars['Boolean']>;
  ref_block_id?: InputMaybe<Scalars['String']>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Block_Stddev_Fields = {
  __typename?: 'block_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  language_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "block" */
export type Block_Stddev_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Block_Stddev_Pop_Fields = {
  __typename?: 'block_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  language_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "block" */
export type Block_Stddev_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Block_Stddev_Samp_Fields = {
  __typename?: 'block_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  language_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "block" */
export type Block_Stddev_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Block_Sum_Fields = {
  __typename?: 'block_sum_fields';
  created_by_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  language_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  parent_id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['bigint']>;
  topic_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "block" */
export type Block_Sum_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** update columns of table "block" */
export enum Block_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Archived = 'archived',
  /** column name */
  Block = 'block',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  HasChildren = 'has_children',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  LastEditedTime = 'last_edited_time',
  /** column name */
  Note = 'note',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Position = 'position',
  /** column name */
  PubliclyAccessible = 'publicly_accessible',
  /** column name */
  RefBlockId = 'ref_block_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url',
  /** column name */
  Verified = 'verified'
}

/** aggregate var_pop on columns */
export type Block_Var_Pop_Fields = {
  __typename?: 'block_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  language_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "block" */
export type Block_Var_Pop_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Block_Var_Samp_Fields = {
  __typename?: 'block_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  language_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "block" */
export type Block_Var_Samp_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Block_Variance_Fields = {
  __typename?: 'block_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  language_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "block" */
export type Block_Variance_Order_By = {
  created_by_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'country';
  code: Scalars['String'];
  /** An array relationship */
  curriculums: Array<Curriculum>;
  /** An aggregate relationship */
  curriculums_aggregate: Curriculum_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
};


/** columns and relationships of "country" */
export type CountryCurriculumsArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Order_By>>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryCurriculums_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Order_By>>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: 'country_aggregate';
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: 'country_aggregate_fields';
  avg?: Maybe<Country_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
  stddev?: Maybe<Country_Stddev_Fields>;
  stddev_pop?: Maybe<Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Country_Stddev_Samp_Fields>;
  sum?: Maybe<Country_Sum_Fields>;
  var_pop?: Maybe<Country_Var_Pop_Fields>;
  var_samp?: Maybe<Country_Var_Samp_Fields>;
  variance?: Maybe<Country_Variance_Fields>;
};


/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Country_Avg_Fields = {
  __typename?: 'country_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Bool_Exp>>;
  _not?: InputMaybe<Country_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  curriculums?: InputMaybe<Curriculum_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "id" */
  CountryPkey = 'country_pkey'
}

/** input type for incrementing numeric columns in table "country" */
export type Country_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  curriculums?: InputMaybe<Curriculum_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: 'country_max_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: 'country_min_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: 'country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: InputMaybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  code?: InputMaybe<Order_By>;
  curriculums_aggregate?: InputMaybe<Curriculum_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Country_Stddev_Fields = {
  __typename?: 'country_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Country_Stddev_Pop_Fields = {
  __typename?: 'country_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Country_Stddev_Samp_Fields = {
  __typename?: 'country_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Country_Sum_Fields = {
  __typename?: 'country_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Country_Var_Pop_Fields = {
  __typename?: 'country_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Country_Var_Samp_Fields = {
  __typename?: 'country_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Country_Variance_Fields = {
  __typename?: 'country_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "curriculum" */
export type Curriculum = {
  __typename?: 'curriculum';
  /** An object relationship */
  country?: Maybe<Country>;
  country_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  curriculum_change_requests: Array<Curriculum_Change_Request>;
  /** An aggregate relationship */
  curriculum_change_requests_aggregate: Curriculum_Change_Request_Aggregate;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  institution?: Maybe<Institution>;
  institution_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  publicly_accessible: Scalars['Boolean'];
  ref_curriculum_id?: Maybe<Scalars['String']>;
  short_title: Scalars['String'];
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: Tag_Aggregate;
  /** An array relationship */
  topic_labels: Array<Topic_Label>;
  /** An aggregate relationship */
  topic_labels_aggregate: Topic_Label_Aggregate;
  /** An array relationship */
  topics: Array<Topic>;
  /** An aggregate relationship */
  topics_aggregate: Topic_Aggregate;
  url?: Maybe<Scalars['String']>;
  /** An array relationship */
  user_curriculum_accesses: Array<User_Curriculum_Access>;
  /** An aggregate relationship */
  user_curriculum_accesses_aggregate: User_Curriculum_Access_Aggregate;
};


/** columns and relationships of "curriculum" */
export type CurriculumCurriculum_Change_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Change_Request_Order_By>>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumCurriculum_Change_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Change_Request_Order_By>>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumTopic_LabelsArgs = {
  distinct_on?: InputMaybe<Array<Topic_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Label_Order_By>>;
  where?: InputMaybe<Topic_Label_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumTopic_Labels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Label_Order_By>>;
  where?: InputMaybe<Topic_Label_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumTopicsArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumTopics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumUser_Curriculum_AccessesArgs = {
  distinct_on?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Curriculum_Access_Order_By>>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};


/** columns and relationships of "curriculum" */
export type CurriculumUser_Curriculum_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Curriculum_Access_Order_By>>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};

/** aggregated selection of "curriculum" */
export type Curriculum_Aggregate = {
  __typename?: 'curriculum_aggregate';
  aggregate?: Maybe<Curriculum_Aggregate_Fields>;
  nodes: Array<Curriculum>;
};

/** aggregate fields of "curriculum" */
export type Curriculum_Aggregate_Fields = {
  __typename?: 'curriculum_aggregate_fields';
  avg?: Maybe<Curriculum_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Curriculum_Max_Fields>;
  min?: Maybe<Curriculum_Min_Fields>;
  stddev?: Maybe<Curriculum_Stddev_Fields>;
  stddev_pop?: Maybe<Curriculum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Curriculum_Stddev_Samp_Fields>;
  sum?: Maybe<Curriculum_Sum_Fields>;
  var_pop?: Maybe<Curriculum_Var_Pop_Fields>;
  var_samp?: Maybe<Curriculum_Var_Samp_Fields>;
  variance?: Maybe<Curriculum_Variance_Fields>;
};


/** aggregate fields of "curriculum" */
export type Curriculum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Curriculum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "curriculum" */
export type Curriculum_Aggregate_Order_By = {
  avg?: InputMaybe<Curriculum_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Curriculum_Max_Order_By>;
  min?: InputMaybe<Curriculum_Min_Order_By>;
  stddev?: InputMaybe<Curriculum_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Curriculum_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Curriculum_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Curriculum_Sum_Order_By>;
  var_pop?: InputMaybe<Curriculum_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Curriculum_Var_Samp_Order_By>;
  variance?: InputMaybe<Curriculum_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "curriculum" */
export type Curriculum_Arr_Rel_Insert_Input = {
  data: Array<Curriculum_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Curriculum_On_Conflict>;
};

/** aggregate avg on columns */
export type Curriculum_Avg_Fields = {
  __typename?: 'curriculum_avg_fields';
  country_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "curriculum" */
export type Curriculum_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "curriculum". All fields are combined with a logical 'AND'. */
export type Curriculum_Bool_Exp = {
  _and?: InputMaybe<Array<Curriculum_Bool_Exp>>;
  _not?: InputMaybe<Curriculum_Bool_Exp>;
  _or?: InputMaybe<Array<Curriculum_Bool_Exp>>;
  country?: InputMaybe<Country_Bool_Exp>;
  country_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_time?: InputMaybe<Bigint_Comparison_Exp>;
  curriculum_change_requests?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  institution?: InputMaybe<Institution_Bool_Exp>;
  institution_id?: InputMaybe<Bigint_Comparison_Exp>;
  last_edited_time?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  publicly_accessible?: InputMaybe<Boolean_Comparison_Exp>;
  ref_curriculum_id?: InputMaybe<String_Comparison_Exp>;
  short_title?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  topic_labels?: InputMaybe<Topic_Label_Bool_Exp>;
  topics?: InputMaybe<Topic_Bool_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user_curriculum_accesses?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};

/** columns and relationships of "curriculum_change_request" */
export type Curriculum_Change_Request = {
  __typename?: 'curriculum_change_request';
  change_description: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  created_time: Scalars['bigint'];
  /** An object relationship */
  curriculum?: Maybe<Curriculum>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id: Scalars['Int'];
  object_type: Scalars['String'];
  ref_object_id: Scalars['Int'];
  status: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  user: Users;
  /** An array relationship */
  user_comments: Array<User_Comment>;
  /** An aggregate relationship */
  user_comments_aggregate: User_Comment_Aggregate;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "curriculum_change_request" */
export type Curriculum_Change_RequestUser_CommentsArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};


/** columns and relationships of "curriculum_change_request" */
export type Curriculum_Change_RequestUser_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};

/** aggregated selection of "curriculum_change_request" */
export type Curriculum_Change_Request_Aggregate = {
  __typename?: 'curriculum_change_request_aggregate';
  aggregate?: Maybe<Curriculum_Change_Request_Aggregate_Fields>;
  nodes: Array<Curriculum_Change_Request>;
};

/** aggregate fields of "curriculum_change_request" */
export type Curriculum_Change_Request_Aggregate_Fields = {
  __typename?: 'curriculum_change_request_aggregate_fields';
  avg?: Maybe<Curriculum_Change_Request_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Curriculum_Change_Request_Max_Fields>;
  min?: Maybe<Curriculum_Change_Request_Min_Fields>;
  stddev?: Maybe<Curriculum_Change_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Curriculum_Change_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Curriculum_Change_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Curriculum_Change_Request_Sum_Fields>;
  var_pop?: Maybe<Curriculum_Change_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Curriculum_Change_Request_Var_Samp_Fields>;
  variance?: Maybe<Curriculum_Change_Request_Variance_Fields>;
};


/** aggregate fields of "curriculum_change_request" */
export type Curriculum_Change_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "curriculum_change_request" */
export type Curriculum_Change_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Curriculum_Change_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Curriculum_Change_Request_Max_Order_By>;
  min?: InputMaybe<Curriculum_Change_Request_Min_Order_By>;
  stddev?: InputMaybe<Curriculum_Change_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Curriculum_Change_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Curriculum_Change_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Curriculum_Change_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Curriculum_Change_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Curriculum_Change_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Curriculum_Change_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "curriculum_change_request" */
export type Curriculum_Change_Request_Arr_Rel_Insert_Input = {
  data: Array<Curriculum_Change_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Curriculum_Change_Request_On_Conflict>;
};

/** aggregate avg on columns */
export type Curriculum_Change_Request_Avg_Fields = {
  __typename?: 'curriculum_change_request_avg_fields';
  created_time?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_object_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Avg_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "curriculum_change_request". All fields are combined with a logical 'AND'. */
export type Curriculum_Change_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Curriculum_Change_Request_Bool_Exp>>;
  _not?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Curriculum_Change_Request_Bool_Exp>>;
  change_description?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_time?: InputMaybe<Bigint_Comparison_Exp>;
  curriculum?: InputMaybe<Curriculum_Bool_Exp>;
  curriculum_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  object_type?: InputMaybe<String_Comparison_Exp>;
  ref_object_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_comments?: InputMaybe<User_Comment_Bool_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "curriculum_change_request" */
export enum Curriculum_Change_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  CurriculumChangeRequestPkey = 'curriculum_change_request_pkey'
}

/** input type for incrementing numeric columns in table "curriculum_change_request" */
export type Curriculum_Change_Request_Inc_Input = {
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  ref_object_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "curriculum_change_request" */
export type Curriculum_Change_Request_Insert_Input = {
  change_description?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum?: InputMaybe<Curriculum_Obj_Rel_Insert_Input>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  object_type?: InputMaybe<Scalars['String']>;
  ref_object_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_comments?: InputMaybe<User_Comment_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Curriculum_Change_Request_Max_Fields = {
  __typename?: 'curriculum_change_request_max_fields';
  change_description?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  object_type?: Maybe<Scalars['String']>;
  ref_object_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Max_Order_By = {
  change_description?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_type?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Curriculum_Change_Request_Min_Fields = {
  __typename?: 'curriculum_change_request_min_fields';
  change_description?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  object_type?: Maybe<Scalars['String']>;
  ref_object_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Min_Order_By = {
  change_description?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_type?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "curriculum_change_request" */
export type Curriculum_Change_Request_Mutation_Response = {
  __typename?: 'curriculum_change_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Curriculum_Change_Request>;
};

/** input type for inserting object relation for remote table "curriculum_change_request" */
export type Curriculum_Change_Request_Obj_Rel_Insert_Input = {
  data: Curriculum_Change_Request_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Curriculum_Change_Request_On_Conflict>;
};

/** on_conflict condition type for table "curriculum_change_request" */
export type Curriculum_Change_Request_On_Conflict = {
  constraint: Curriculum_Change_Request_Constraint;
  update_columns?: Array<Curriculum_Change_Request_Update_Column>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "curriculum_change_request". */
export type Curriculum_Change_Request_Order_By = {
  change_description?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum?: InputMaybe<Curriculum_Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_type?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_comments_aggregate?: InputMaybe<User_Comment_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: curriculum_change_request */
export type Curriculum_Change_Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "curriculum_change_request" */
export enum Curriculum_Change_Request_Select_Column {
  /** column name */
  ChangeDescription = 'change_description',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  RefObjectId = 'ref_object_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "curriculum_change_request" */
export type Curriculum_Change_Request_Set_Input = {
  change_description?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  object_type?: InputMaybe<Scalars['String']>;
  ref_object_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Curriculum_Change_Request_Stddev_Fields = {
  __typename?: 'curriculum_change_request_stddev_fields';
  created_time?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_object_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Stddev_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Curriculum_Change_Request_Stddev_Pop_Fields = {
  __typename?: 'curriculum_change_request_stddev_pop_fields';
  created_time?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_object_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Stddev_Pop_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Curriculum_Change_Request_Stddev_Samp_Fields = {
  __typename?: 'curriculum_change_request_stddev_samp_fields';
  created_time?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_object_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Stddev_Samp_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Curriculum_Change_Request_Sum_Fields = {
  __typename?: 'curriculum_change_request_sum_fields';
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  ref_object_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Sum_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "curriculum_change_request" */
export enum Curriculum_Change_Request_Update_Column {
  /** column name */
  ChangeDescription = 'change_description',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  RefObjectId = 'ref_object_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Curriculum_Change_Request_Var_Pop_Fields = {
  __typename?: 'curriculum_change_request_var_pop_fields';
  created_time?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_object_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Var_Pop_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Curriculum_Change_Request_Var_Samp_Fields = {
  __typename?: 'curriculum_change_request_var_samp_fields';
  created_time?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_object_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Var_Samp_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Curriculum_Change_Request_Variance_Fields = {
  __typename?: 'curriculum_change_request_variance_fields';
  created_time?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ref_object_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "curriculum_change_request" */
export type Curriculum_Change_Request_Variance_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ref_object_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "curriculum" */
export enum Curriculum_Constraint {
  /** unique or primary key constraint on columns "id" */
  CurriculumPkey = 'curriculum_pkey'
}

/** input type for incrementing numeric columns in table "curriculum" */
export type Curriculum_Inc_Input = {
  country_id?: InputMaybe<Scalars['bigint']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  institution_id?: InputMaybe<Scalars['bigint']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "curriculum" */
export type Curriculum_Insert_Input = {
  country?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['bigint']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_change_requests?: InputMaybe<Curriculum_Change_Request_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  institution?: InputMaybe<Institution_Obj_Rel_Insert_Input>;
  institution_id?: InputMaybe<Scalars['bigint']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  publicly_accessible?: InputMaybe<Scalars['Boolean']>;
  ref_curriculum_id?: InputMaybe<Scalars['String']>;
  short_title?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  topic_labels?: InputMaybe<Topic_Label_Arr_Rel_Insert_Input>;
  topics?: InputMaybe<Topic_Arr_Rel_Insert_Input>;
  url?: InputMaybe<Scalars['String']>;
  user_curriculum_accesses?: InputMaybe<User_Curriculum_Access_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Curriculum_Max_Fields = {
  __typename?: 'curriculum_max_fields';
  country_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  institution_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ref_curriculum_id?: Maybe<Scalars['String']>;
  short_title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "curriculum" */
export type Curriculum_Max_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_curriculum_id?: InputMaybe<Order_By>;
  short_title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Curriculum_Min_Fields = {
  __typename?: 'curriculum_min_fields';
  country_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  institution_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  ref_curriculum_id?: Maybe<Scalars['String']>;
  short_title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "curriculum" */
export type Curriculum_Min_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ref_curriculum_id?: InputMaybe<Order_By>;
  short_title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "curriculum" */
export type Curriculum_Mutation_Response = {
  __typename?: 'curriculum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Curriculum>;
};

/** input type for inserting object relation for remote table "curriculum" */
export type Curriculum_Obj_Rel_Insert_Input = {
  data: Curriculum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Curriculum_On_Conflict>;
};

/** on_conflict condition type for table "curriculum" */
export type Curriculum_On_Conflict = {
  constraint: Curriculum_Constraint;
  update_columns?: Array<Curriculum_Update_Column>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};

/** Ordering options when selecting data from "curriculum". */
export type Curriculum_Order_By = {
  country?: InputMaybe<Country_Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_change_requests_aggregate?: InputMaybe<Curriculum_Change_Request_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution?: InputMaybe<Institution_Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  publicly_accessible?: InputMaybe<Order_By>;
  ref_curriculum_id?: InputMaybe<Order_By>;
  short_title?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  topic_labels_aggregate?: InputMaybe<Topic_Label_Aggregate_Order_By>;
  topics_aggregate?: InputMaybe<Topic_Aggregate_Order_By>;
  url?: InputMaybe<Order_By>;
  user_curriculum_accesses_aggregate?: InputMaybe<User_Curriculum_Access_Aggregate_Order_By>;
};

/** primary key columns input for table: curriculum */
export type Curriculum_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "curriculum" */
export enum Curriculum_Select_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  LastEditedTime = 'last_edited_time',
  /** column name */
  Name = 'name',
  /** column name */
  PubliclyAccessible = 'publicly_accessible',
  /** column name */
  RefCurriculumId = 'ref_curriculum_id',
  /** column name */
  ShortTitle = 'short_title',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "curriculum" */
export type Curriculum_Set_Input = {
  country_id?: InputMaybe<Scalars['bigint']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  institution_id?: InputMaybe<Scalars['bigint']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  publicly_accessible?: InputMaybe<Scalars['Boolean']>;
  ref_curriculum_id?: InputMaybe<Scalars['String']>;
  short_title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Curriculum_Stddev_Fields = {
  __typename?: 'curriculum_stddev_fields';
  country_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "curriculum" */
export type Curriculum_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Curriculum_Stddev_Pop_Fields = {
  __typename?: 'curriculum_stddev_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "curriculum" */
export type Curriculum_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Curriculum_Stddev_Samp_Fields = {
  __typename?: 'curriculum_stddev_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "curriculum" */
export type Curriculum_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Curriculum_Sum_Fields = {
  __typename?: 'curriculum_sum_fields';
  country_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  institution_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "curriculum" */
export type Curriculum_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
};

/** update columns of table "curriculum" */
export enum Curriculum_Update_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  LastEditedTime = 'last_edited_time',
  /** column name */
  Name = 'name',
  /** column name */
  PubliclyAccessible = 'publicly_accessible',
  /** column name */
  RefCurriculumId = 'ref_curriculum_id',
  /** column name */
  ShortTitle = 'short_title',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Curriculum_Var_Pop_Fields = {
  __typename?: 'curriculum_var_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "curriculum" */
export type Curriculum_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Curriculum_Var_Samp_Fields = {
  __typename?: 'curriculum_var_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "curriculum" */
export type Curriculum_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Curriculum_Variance_Fields = {
  __typename?: 'curriculum_variance_fields';
  country_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "curriculum" */
export type Curriculum_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
};

export type Filter_Blocks_Args = {
  block_limit?: InputMaybe<Scalars['Int']>;
  block_offset?: InputMaybe<Scalars['Int']>;
  blockauthorids?: InputMaybe<Scalars['_int4']>;
  blockidlist?: InputMaybe<Scalars['String']>;
  blockverified?: InputMaybe<Scalars['bit']>;
  searchterm?: InputMaybe<Scalars['String']>;
  topiclisteight?: InputMaybe<Scalars['_int4']>;
  topiclisteleven?: InputMaybe<Scalars['_int4']>;
  topiclistfifteen?: InputMaybe<Scalars['_int4']>;
  topiclistfive?: InputMaybe<Scalars['_int4']>;
  topiclistfour?: InputMaybe<Scalars['_int4']>;
  topiclistfourteen?: InputMaybe<Scalars['_int4']>;
  topiclistnine?: InputMaybe<Scalars['_int4']>;
  topiclistone?: InputMaybe<Scalars['_int4']>;
  topiclistseven?: InputMaybe<Scalars['_int4']>;
  topiclistsix?: InputMaybe<Scalars['_int4']>;
  topiclistten?: InputMaybe<Scalars['_int4']>;
  topiclistthirteen?: InputMaybe<Scalars['_int4']>;
  topiclistthree?: InputMaybe<Scalars['_int4']>;
  topiclisttwelve?: InputMaybe<Scalars['_int4']>;
  topiclisttwo?: InputMaybe<Scalars['_int4']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Get_Blocks_With_Children_Args = {
  ids?: InputMaybe<Scalars['_int4']>;
};

export type Get_Topics_With_Children_Args = {
  ids?: InputMaybe<Scalars['_int4']>;
};

/** columns and relationships of "identical_topic" */
export type Identical_Topic = {
  __typename?: 'identical_topic';
  id: Scalars['Int'];
  /** An object relationship */
  topic?: Maybe<Topic>;
  topic_id?: Maybe<Scalars['bigint']>;
  type: Scalars['String'];
};

/** aggregated selection of "identical_topic" */
export type Identical_Topic_Aggregate = {
  __typename?: 'identical_topic_aggregate';
  aggregate?: Maybe<Identical_Topic_Aggregate_Fields>;
  nodes: Array<Identical_Topic>;
};

/** aggregate fields of "identical_topic" */
export type Identical_Topic_Aggregate_Fields = {
  __typename?: 'identical_topic_aggregate_fields';
  avg?: Maybe<Identical_Topic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Identical_Topic_Max_Fields>;
  min?: Maybe<Identical_Topic_Min_Fields>;
  stddev?: Maybe<Identical_Topic_Stddev_Fields>;
  stddev_pop?: Maybe<Identical_Topic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identical_Topic_Stddev_Samp_Fields>;
  sum?: Maybe<Identical_Topic_Sum_Fields>;
  var_pop?: Maybe<Identical_Topic_Var_Pop_Fields>;
  var_samp?: Maybe<Identical_Topic_Var_Samp_Fields>;
  variance?: Maybe<Identical_Topic_Variance_Fields>;
};


/** aggregate fields of "identical_topic" */
export type Identical_Topic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Identical_Topic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "identical_topic" */
export type Identical_Topic_Aggregate_Order_By = {
  avg?: InputMaybe<Identical_Topic_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Identical_Topic_Max_Order_By>;
  min?: InputMaybe<Identical_Topic_Min_Order_By>;
  stddev?: InputMaybe<Identical_Topic_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Identical_Topic_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Identical_Topic_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Identical_Topic_Sum_Order_By>;
  var_pop?: InputMaybe<Identical_Topic_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Identical_Topic_Var_Samp_Order_By>;
  variance?: InputMaybe<Identical_Topic_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "identical_topic" */
export type Identical_Topic_Arr_Rel_Insert_Input = {
  data: Array<Identical_Topic_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Identical_Topic_On_Conflict>;
};

/** aggregate avg on columns */
export type Identical_Topic_Avg_Fields = {
  __typename?: 'identical_topic_avg_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "identical_topic" */
export type Identical_Topic_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "identical_topic". All fields are combined with a logical 'AND'. */
export type Identical_Topic_Bool_Exp = {
  _and?: InputMaybe<Array<Identical_Topic_Bool_Exp>>;
  _not?: InputMaybe<Identical_Topic_Bool_Exp>;
  _or?: InputMaybe<Array<Identical_Topic_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  topic?: InputMaybe<Topic_Bool_Exp>;
  topic_id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "identical_topic" */
export enum Identical_Topic_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdenticalTopicPkey = 'identical_topic_pkey'
}

/** input type for incrementing numeric columns in table "identical_topic" */
export type Identical_Topic_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  topic_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "identical_topic" */
export type Identical_Topic_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  topic?: InputMaybe<Topic_Obj_Rel_Insert_Input>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Identical_Topic_Max_Fields = {
  __typename?: 'identical_topic_max_fields';
  id?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "identical_topic" */
export type Identical_Topic_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Identical_Topic_Min_Fields = {
  __typename?: 'identical_topic_min_fields';
  id?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "identical_topic" */
export type Identical_Topic_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "identical_topic" */
export type Identical_Topic_Mutation_Response = {
  __typename?: 'identical_topic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Identical_Topic>;
};

/** on_conflict condition type for table "identical_topic" */
export type Identical_Topic_On_Conflict = {
  constraint: Identical_Topic_Constraint;
  update_columns?: Array<Identical_Topic_Update_Column>;
  where?: InputMaybe<Identical_Topic_Bool_Exp>;
};

/** Ordering options when selecting data from "identical_topic". */
export type Identical_Topic_Order_By = {
  id?: InputMaybe<Order_By>;
  topic?: InputMaybe<Topic_Order_By>;
  topic_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: identical_topic */
export type Identical_Topic_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "identical_topic" */
export enum Identical_Topic_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "identical_topic" */
export type Identical_Topic_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Identical_Topic_Stddev_Fields = {
  __typename?: 'identical_topic_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "identical_topic" */
export type Identical_Topic_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Identical_Topic_Stddev_Pop_Fields = {
  __typename?: 'identical_topic_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "identical_topic" */
export type Identical_Topic_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Identical_Topic_Stddev_Samp_Fields = {
  __typename?: 'identical_topic_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "identical_topic" */
export type Identical_Topic_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Identical_Topic_Sum_Fields = {
  __typename?: 'identical_topic_sum_fields';
  id?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "identical_topic" */
export type Identical_Topic_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** update columns of table "identical_topic" */
export enum Identical_Topic_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Identical_Topic_Var_Pop_Fields = {
  __typename?: 'identical_topic_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "identical_topic" */
export type Identical_Topic_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Identical_Topic_Var_Samp_Fields = {
  __typename?: 'identical_topic_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "identical_topic" */
export type Identical_Topic_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Identical_Topic_Variance_Fields = {
  __typename?: 'identical_topic_variance_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "identical_topic" */
export type Identical_Topic_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "institution" */
export type Institution = {
  __typename?: 'institution';
  active: Scalars['Boolean'];
  bio?: Maybe<Scalars['String']>;
  /** An array relationship */
  curriculums: Array<Curriculum>;
  /** An aggregate relationship */
  curriculums_aggregate: Curriculum_Aggregate;
  display_name: Scalars['String'];
  id: Scalars['Int'];
  owner?: Maybe<Scalars['bigint']>;
  ref_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: Tag_Aggregate;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "institution" */
export type InstitutionCurriculumsArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Order_By>>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};


/** columns and relationships of "institution" */
export type InstitutionCurriculums_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Order_By>>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};


/** columns and relationships of "institution" */
export type InstitutionTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "institution" */
export type InstitutionTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "institution" */
export type InstitutionUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "institution" */
export type InstitutionUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "institution" */
export type Institution_Aggregate = {
  __typename?: 'institution_aggregate';
  aggregate?: Maybe<Institution_Aggregate_Fields>;
  nodes: Array<Institution>;
};

/** aggregate fields of "institution" */
export type Institution_Aggregate_Fields = {
  __typename?: 'institution_aggregate_fields';
  avg?: Maybe<Institution_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Institution_Max_Fields>;
  min?: Maybe<Institution_Min_Fields>;
  stddev?: Maybe<Institution_Stddev_Fields>;
  stddev_pop?: Maybe<Institution_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Institution_Stddev_Samp_Fields>;
  sum?: Maybe<Institution_Sum_Fields>;
  var_pop?: Maybe<Institution_Var_Pop_Fields>;
  var_samp?: Maybe<Institution_Var_Samp_Fields>;
  variance?: Maybe<Institution_Variance_Fields>;
};


/** aggregate fields of "institution" */
export type Institution_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Institution_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "institution" */
export type Institution_Aggregate_Order_By = {
  avg?: InputMaybe<Institution_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Institution_Max_Order_By>;
  min?: InputMaybe<Institution_Min_Order_By>;
  stddev?: InputMaybe<Institution_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Institution_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Institution_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Institution_Sum_Order_By>;
  var_pop?: InputMaybe<Institution_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Institution_Var_Samp_Order_By>;
  variance?: InputMaybe<Institution_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "institution" */
export type Institution_Arr_Rel_Insert_Input = {
  data: Array<Institution_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Institution_On_Conflict>;
};

/** aggregate avg on columns */
export type Institution_Avg_Fields = {
  __typename?: 'institution_avg_fields';
  id?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "institution" */
export type Institution_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "institution". All fields are combined with a logical 'AND'. */
export type Institution_Bool_Exp = {
  _and?: InputMaybe<Array<Institution_Bool_Exp>>;
  _not?: InputMaybe<Institution_Bool_Exp>;
  _or?: InputMaybe<Array<Institution_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  bio?: InputMaybe<String_Comparison_Exp>;
  curriculums?: InputMaybe<Curriculum_Bool_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  owner?: InputMaybe<Bigint_Comparison_Exp>;
  ref_id?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "institution" */
export enum Institution_Constraint {
  /** unique or primary key constraint on columns "id" */
  InstitutionPkey = 'institution_pkey'
}

/** input type for incrementing numeric columns in table "institution" */
export type Institution_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "institution" */
export type Institution_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  bio?: InputMaybe<Scalars['String']>;
  curriculums?: InputMaybe<Curriculum_Arr_Rel_Insert_Input>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['bigint']>;
  ref_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Institution_Max_Fields = {
  __typename?: 'institution_max_fields';
  bio?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['bigint']>;
  ref_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "institution" */
export type Institution_Max_Order_By = {
  bio?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  ref_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Institution_Min_Fields = {
  __typename?: 'institution_min_fields';
  bio?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['bigint']>;
  ref_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "institution" */
export type Institution_Min_Order_By = {
  bio?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  ref_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "institution" */
export type Institution_Mutation_Response = {
  __typename?: 'institution_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Institution>;
};

/** input type for inserting object relation for remote table "institution" */
export type Institution_Obj_Rel_Insert_Input = {
  data: Institution_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Institution_On_Conflict>;
};

/** on_conflict condition type for table "institution" */
export type Institution_On_Conflict = {
  constraint: Institution_Constraint;
  update_columns?: Array<Institution_Update_Column>;
  where?: InputMaybe<Institution_Bool_Exp>;
};

/** Ordering options when selecting data from "institution". */
export type Institution_Order_By = {
  active?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  curriculums_aggregate?: InputMaybe<Curriculum_Aggregate_Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  ref_id?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: institution */
export type Institution_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "institution" */
export enum Institution_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Bio = 'bio',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner',
  /** column name */
  RefId = 'ref_id'
}

/** input type for updating data in table "institution" */
export type Institution_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  bio?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['bigint']>;
  ref_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Institution_Stddev_Fields = {
  __typename?: 'institution_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "institution" */
export type Institution_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Institution_Stddev_Pop_Fields = {
  __typename?: 'institution_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "institution" */
export type Institution_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Institution_Stddev_Samp_Fields = {
  __typename?: 'institution_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "institution" */
export type Institution_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Institution_Sum_Fields = {
  __typename?: 'institution_sum_fields';
  id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "institution" */
export type Institution_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
};

/** update columns of table "institution" */
export enum Institution_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Bio = 'bio',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner',
  /** column name */
  RefId = 'ref_id'
}

/** aggregate var_pop on columns */
export type Institution_Var_Pop_Fields = {
  __typename?: 'institution_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "institution" */
export type Institution_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Institution_Var_Samp_Fields = {
  __typename?: 'institution_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "institution" */
export type Institution_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Institution_Variance_Fields = {
  __typename?: 'institution_variance_fields';
  id?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "institution" */
export type Institution_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "language" */
export type Language = {
  __typename?: 'language';
  /** An array relationship */
  blocks: Array<Block>;
  /** An aggregate relationship */
  blocks_aggregate: Block_Aggregate;
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};


/** columns and relationships of "language" */
export type LanguageBlocksArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


/** columns and relationships of "language" */
export type LanguageBlocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};

/** aggregated selection of "language" */
export type Language_Aggregate = {
  __typename?: 'language_aggregate';
  aggregate?: Maybe<Language_Aggregate_Fields>;
  nodes: Array<Language>;
};

/** aggregate fields of "language" */
export type Language_Aggregate_Fields = {
  __typename?: 'language_aggregate_fields';
  avg?: Maybe<Language_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Language_Max_Fields>;
  min?: Maybe<Language_Min_Fields>;
  stddev?: Maybe<Language_Stddev_Fields>;
  stddev_pop?: Maybe<Language_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Language_Stddev_Samp_Fields>;
  sum?: Maybe<Language_Sum_Fields>;
  var_pop?: Maybe<Language_Var_Pop_Fields>;
  var_samp?: Maybe<Language_Var_Samp_Fields>;
  variance?: Maybe<Language_Variance_Fields>;
};


/** aggregate fields of "language" */
export type Language_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Language_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Language_Avg_Fields = {
  __typename?: 'language_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "language". All fields are combined with a logical 'AND'. */
export type Language_Bool_Exp = {
  _and?: InputMaybe<Array<Language_Bool_Exp>>;
  _not?: InputMaybe<Language_Bool_Exp>;
  _or?: InputMaybe<Array<Language_Bool_Exp>>;
  blocks?: InputMaybe<Block_Bool_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "language" */
export enum Language_Constraint {
  /** unique or primary key constraint on columns "id" */
  LanguagePkey = 'language_pkey',
  /** unique or primary key constraint on columns "code" */
  Uk_5h2eb4yggd9jjo1x9kd594t02 = 'uk_5h2eb4yggd9jjo1x9kd594t02',
  /** unique or primary key constraint on columns "name" */
  UkG8hr207ijpxlwu10pewyo65gv = 'uk_g8hr207ijpxlwu10pewyo65gv'
}

/** input type for incrementing numeric columns in table "language" */
export type Language_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "language" */
export type Language_Insert_Input = {
  blocks?: InputMaybe<Block_Arr_Rel_Insert_Input>;
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Language_Max_Fields = {
  __typename?: 'language_max_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Language_Min_Fields = {
  __typename?: 'language_min_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "language" */
export type Language_Mutation_Response = {
  __typename?: 'language_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Language>;
};

/** input type for inserting object relation for remote table "language" */
export type Language_Obj_Rel_Insert_Input = {
  data: Language_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Language_On_Conflict>;
};

/** on_conflict condition type for table "language" */
export type Language_On_Conflict = {
  constraint: Language_Constraint;
  update_columns?: Array<Language_Update_Column>;
  where?: InputMaybe<Language_Bool_Exp>;
};

/** Ordering options when selecting data from "language". */
export type Language_Order_By = {
  blocks_aggregate?: InputMaybe<Block_Aggregate_Order_By>;
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: language */
export type Language_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "language" */
export enum Language_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "language" */
export type Language_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Language_Stddev_Fields = {
  __typename?: 'language_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Language_Stddev_Pop_Fields = {
  __typename?: 'language_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Language_Stddev_Samp_Fields = {
  __typename?: 'language_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Language_Sum_Fields = {
  __typename?: 'language_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "language" */
export enum Language_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Language_Var_Pop_Fields = {
  __typename?: 'language_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Language_Var_Samp_Fields = {
  __typename?: 'language_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Language_Variance_Fields = {
  __typename?: 'language_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "block" */
  delete_block?: Maybe<Block_Mutation_Response>;
  /** delete single row from the table: "block" */
  delete_block_by_pk?: Maybe<Block>;
  /** delete data from the table: "block_change_request" */
  delete_block_change_request?: Maybe<Block_Change_Request_Mutation_Response>;
  /** delete single row from the table: "block_change_request" */
  delete_block_change_request_by_pk?: Maybe<Block_Change_Request>;
  /** delete data from the table: "block_revision" */
  delete_block_revision?: Maybe<Block_Revision_Mutation_Response>;
  /** delete single row from the table: "block_revision" */
  delete_block_revision_by_pk?: Maybe<Block_Revision>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "curriculum" */
  delete_curriculum?: Maybe<Curriculum_Mutation_Response>;
  /** delete single row from the table: "curriculum" */
  delete_curriculum_by_pk?: Maybe<Curriculum>;
  /** delete data from the table: "curriculum_change_request" */
  delete_curriculum_change_request?: Maybe<Curriculum_Change_Request_Mutation_Response>;
  /** delete single row from the table: "curriculum_change_request" */
  delete_curriculum_change_request_by_pk?: Maybe<Curriculum_Change_Request>;
  /** delete data from the table: "identical_topic" */
  delete_identical_topic?: Maybe<Identical_Topic_Mutation_Response>;
  /** delete single row from the table: "identical_topic" */
  delete_identical_topic_by_pk?: Maybe<Identical_Topic>;
  /** delete data from the table: "institution" */
  delete_institution?: Maybe<Institution_Mutation_Response>;
  /** delete single row from the table: "institution" */
  delete_institution_by_pk?: Maybe<Institution>;
  /** delete data from the table: "language" */
  delete_language?: Maybe<Language_Mutation_Response>;
  /** delete single row from the table: "language" */
  delete_language_by_pk?: Maybe<Language>;
  /** delete data from the table: "submission" */
  delete_submission?: Maybe<Submission_Mutation_Response>;
  /** delete single row from the table: "submission" */
  delete_submission_by_pk?: Maybe<Submission>;
  /** delete data from the table: "subscription" */
  delete_subscription?: Maybe<Subscription_Mutation_Response>;
  /** delete single row from the table: "subscription" */
  delete_subscription_by_pk?: Maybe<Subscription>;
  /** delete data from the table: "tag" */
  delete_tag?: Maybe<Tag_Mutation_Response>;
  /** delete single row from the table: "tag" */
  delete_tag_by_pk?: Maybe<Tag>;
  /** delete data from the table: "topic" */
  delete_topic?: Maybe<Topic_Mutation_Response>;
  /** delete single row from the table: "topic" */
  delete_topic_by_pk?: Maybe<Topic>;
  /** delete data from the table: "topic_label" */
  delete_topic_label?: Maybe<Topic_Label_Mutation_Response>;
  /** delete single row from the table: "topic_label" */
  delete_topic_label_by_pk?: Maybe<Topic_Label>;
  /** delete data from the table: "user_comment" */
  delete_user_comment?: Maybe<User_Comment_Mutation_Response>;
  /** delete single row from the table: "user_comment" */
  delete_user_comment_by_pk?: Maybe<User_Comment>;
  /** delete data from the table: "user_curriculum_access" */
  delete_user_curriculum_access?: Maybe<User_Curriculum_Access_Mutation_Response>;
  /** delete single row from the table: "user_curriculum_access" */
  delete_user_curriculum_access_by_pk?: Maybe<User_Curriculum_Access>;
  /** delete data from the table: "user_invitation" */
  delete_user_invitation?: Maybe<User_Invitation_Mutation_Response>;
  /** delete single row from the table: "user_invitation" */
  delete_user_invitation_by_pk?: Maybe<User_Invitation>;
  /** delete data from the table: "user_topic_access" */
  delete_user_topic_access?: Maybe<User_Topic_Access_Mutation_Response>;
  /** delete single row from the table: "user_topic_access" */
  delete_user_topic_access_by_pk?: Maybe<User_Topic_Access>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vote" */
  delete_vote?: Maybe<Vote_Mutation_Response>;
  /** delete single row from the table: "vote" */
  delete_vote_by_pk?: Maybe<Vote>;
  exportResource?: Maybe<ExportResourceOutput>;
  /** insert data into the table: "block" */
  insert_block?: Maybe<Block_Mutation_Response>;
  /** insert data into the table: "block_change_request" */
  insert_block_change_request?: Maybe<Block_Change_Request_Mutation_Response>;
  /** insert a single row into the table: "block_change_request" */
  insert_block_change_request_one?: Maybe<Block_Change_Request>;
  /** insert a single row into the table: "block" */
  insert_block_one?: Maybe<Block>;
  /** insert data into the table: "block_revision" */
  insert_block_revision?: Maybe<Block_Revision_Mutation_Response>;
  /** insert a single row into the table: "block_revision" */
  insert_block_revision_one?: Maybe<Block_Revision>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "curriculum" */
  insert_curriculum?: Maybe<Curriculum_Mutation_Response>;
  /** insert data into the table: "curriculum_change_request" */
  insert_curriculum_change_request?: Maybe<Curriculum_Change_Request_Mutation_Response>;
  /** insert a single row into the table: "curriculum_change_request" */
  insert_curriculum_change_request_one?: Maybe<Curriculum_Change_Request>;
  /** insert a single row into the table: "curriculum" */
  insert_curriculum_one?: Maybe<Curriculum>;
  /** insert data into the table: "identical_topic" */
  insert_identical_topic?: Maybe<Identical_Topic_Mutation_Response>;
  /** insert a single row into the table: "identical_topic" */
  insert_identical_topic_one?: Maybe<Identical_Topic>;
  /** insert data into the table: "institution" */
  insert_institution?: Maybe<Institution_Mutation_Response>;
  /** insert a single row into the table: "institution" */
  insert_institution_one?: Maybe<Institution>;
  /** insert data into the table: "language" */
  insert_language?: Maybe<Language_Mutation_Response>;
  /** insert a single row into the table: "language" */
  insert_language_one?: Maybe<Language>;
  /** insert data into the table: "submission" */
  insert_submission?: Maybe<Submission_Mutation_Response>;
  /** insert a single row into the table: "submission" */
  insert_submission_one?: Maybe<Submission>;
  /** insert data into the table: "subscription" */
  insert_subscription?: Maybe<Subscription_Mutation_Response>;
  /** insert a single row into the table: "subscription" */
  insert_subscription_one?: Maybe<Subscription>;
  /** insert data into the table: "tag" */
  insert_tag?: Maybe<Tag_Mutation_Response>;
  /** insert a single row into the table: "tag" */
  insert_tag_one?: Maybe<Tag>;
  /** insert data into the table: "topic" */
  insert_topic?: Maybe<Topic_Mutation_Response>;
  /** insert data into the table: "topic_label" */
  insert_topic_label?: Maybe<Topic_Label_Mutation_Response>;
  /** insert a single row into the table: "topic_label" */
  insert_topic_label_one?: Maybe<Topic_Label>;
  /** insert a single row into the table: "topic" */
  insert_topic_one?: Maybe<Topic>;
  /** insert data into the table: "user_comment" */
  insert_user_comment?: Maybe<User_Comment_Mutation_Response>;
  /** insert a single row into the table: "user_comment" */
  insert_user_comment_one?: Maybe<User_Comment>;
  /** insert data into the table: "user_curriculum_access" */
  insert_user_curriculum_access?: Maybe<User_Curriculum_Access_Mutation_Response>;
  /** insert a single row into the table: "user_curriculum_access" */
  insert_user_curriculum_access_one?: Maybe<User_Curriculum_Access>;
  /** insert data into the table: "user_invitation" */
  insert_user_invitation?: Maybe<User_Invitation_Mutation_Response>;
  /** insert a single row into the table: "user_invitation" */
  insert_user_invitation_one?: Maybe<User_Invitation>;
  /** insert data into the table: "user_topic_access" */
  insert_user_topic_access?: Maybe<User_Topic_Access_Mutation_Response>;
  /** insert a single row into the table: "user_topic_access" */
  insert_user_topic_access_one?: Maybe<User_Topic_Access>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vote" */
  insert_vote?: Maybe<Vote_Mutation_Response>;
  /** insert a single row into the table: "vote" */
  insert_vote_one?: Maybe<Vote>;
  /** update data of the table: "block" */
  update_block?: Maybe<Block_Mutation_Response>;
  /** update single row of the table: "block" */
  update_block_by_pk?: Maybe<Block>;
  /** update data of the table: "block_change_request" */
  update_block_change_request?: Maybe<Block_Change_Request_Mutation_Response>;
  /** update single row of the table: "block_change_request" */
  update_block_change_request_by_pk?: Maybe<Block_Change_Request>;
  /** update data of the table: "block_revision" */
  update_block_revision?: Maybe<Block_Revision_Mutation_Response>;
  /** update single row of the table: "block_revision" */
  update_block_revision_by_pk?: Maybe<Block_Revision>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update data of the table: "curriculum" */
  update_curriculum?: Maybe<Curriculum_Mutation_Response>;
  /** update single row of the table: "curriculum" */
  update_curriculum_by_pk?: Maybe<Curriculum>;
  /** update data of the table: "curriculum_change_request" */
  update_curriculum_change_request?: Maybe<Curriculum_Change_Request_Mutation_Response>;
  /** update single row of the table: "curriculum_change_request" */
  update_curriculum_change_request_by_pk?: Maybe<Curriculum_Change_Request>;
  /** update data of the table: "identical_topic" */
  update_identical_topic?: Maybe<Identical_Topic_Mutation_Response>;
  /** update single row of the table: "identical_topic" */
  update_identical_topic_by_pk?: Maybe<Identical_Topic>;
  /** update data of the table: "institution" */
  update_institution?: Maybe<Institution_Mutation_Response>;
  /** update single row of the table: "institution" */
  update_institution_by_pk?: Maybe<Institution>;
  /** update data of the table: "language" */
  update_language?: Maybe<Language_Mutation_Response>;
  /** update single row of the table: "language" */
  update_language_by_pk?: Maybe<Language>;
  /** update data of the table: "submission" */
  update_submission?: Maybe<Submission_Mutation_Response>;
  /** update single row of the table: "submission" */
  update_submission_by_pk?: Maybe<Submission>;
  /** update data of the table: "subscription" */
  update_subscription?: Maybe<Subscription_Mutation_Response>;
  /** update single row of the table: "subscription" */
  update_subscription_by_pk?: Maybe<Subscription>;
  /** update data of the table: "tag" */
  update_tag?: Maybe<Tag_Mutation_Response>;
  /** update single row of the table: "tag" */
  update_tag_by_pk?: Maybe<Tag>;
  /** update data of the table: "topic" */
  update_topic?: Maybe<Topic_Mutation_Response>;
  /** update single row of the table: "topic" */
  update_topic_by_pk?: Maybe<Topic>;
  /** update data of the table: "topic_label" */
  update_topic_label?: Maybe<Topic_Label_Mutation_Response>;
  /** update single row of the table: "topic_label" */
  update_topic_label_by_pk?: Maybe<Topic_Label>;
  /** update data of the table: "user_comment" */
  update_user_comment?: Maybe<User_Comment_Mutation_Response>;
  /** update single row of the table: "user_comment" */
  update_user_comment_by_pk?: Maybe<User_Comment>;
  /** update data of the table: "user_curriculum_access" */
  update_user_curriculum_access?: Maybe<User_Curriculum_Access_Mutation_Response>;
  /** update single row of the table: "user_curriculum_access" */
  update_user_curriculum_access_by_pk?: Maybe<User_Curriculum_Access>;
  /** update data of the table: "user_invitation" */
  update_user_invitation?: Maybe<User_Invitation_Mutation_Response>;
  /** update single row of the table: "user_invitation" */
  update_user_invitation_by_pk?: Maybe<User_Invitation>;
  /** update data of the table: "user_topic_access" */
  update_user_topic_access?: Maybe<User_Topic_Access_Mutation_Response>;
  /** update single row of the table: "user_topic_access" */
  update_user_topic_access_by_pk?: Maybe<User_Topic_Access>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "vote" */
  update_vote?: Maybe<Vote_Mutation_Response>;
  /** update single row of the table: "vote" */
  update_vote_by_pk?: Maybe<Vote>;
};


/** mutation root */
export type Mutation_RootDelete_BlockArgs = {
  where: Block_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Block_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Block_Change_RequestArgs = {
  where: Block_Change_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Block_Change_Request_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Block_RevisionArgs = {
  where: Block_Revision_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Block_Revision_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CurriculumArgs = {
  where: Curriculum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Curriculum_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Curriculum_Change_RequestArgs = {
  where: Curriculum_Change_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Curriculum_Change_Request_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Identical_TopicArgs = {
  where: Identical_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Identical_Topic_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InstitutionArgs = {
  where: Institution_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Institution_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LanguageArgs = {
  where: Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Language_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubmissionArgs = {
  where: Submission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Submission_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionArgs = {
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TagArgs = {
  where: Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TopicArgs = {
  where: Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Topic_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Topic_LabelArgs = {
  where: Topic_Label_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Topic_Label_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_CommentArgs = {
  where: User_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Comment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_Curriculum_AccessArgs = {
  where: User_Curriculum_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Curriculum_Access_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_InvitationArgs = {
  where: User_Invitation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Invitation_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_Topic_AccessArgs = {
  where: User_Topic_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Topic_Access_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VoteArgs = {
  where: Vote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vote_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootExportResourceArgs = {
  exportResourceInput: ExportResourceInput;
};


/** mutation root */
export type Mutation_RootInsert_BlockArgs = {
  objects: Array<Block_Insert_Input>;
  on_conflict?: InputMaybe<Block_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Block_Change_RequestArgs = {
  objects: Array<Block_Change_Request_Insert_Input>;
  on_conflict?: InputMaybe<Block_Change_Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Block_Change_Request_OneArgs = {
  object: Block_Change_Request_Insert_Input;
  on_conflict?: InputMaybe<Block_Change_Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Block_OneArgs = {
  object: Block_Insert_Input;
  on_conflict?: InputMaybe<Block_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Block_RevisionArgs = {
  objects: Array<Block_Revision_Insert_Input>;
  on_conflict?: InputMaybe<Block_Revision_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Block_Revision_OneArgs = {
  object: Block_Revision_Insert_Input;
  on_conflict?: InputMaybe<Block_Revision_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CurriculumArgs = {
  objects: Array<Curriculum_Insert_Input>;
  on_conflict?: InputMaybe<Curriculum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Curriculum_Change_RequestArgs = {
  objects: Array<Curriculum_Change_Request_Insert_Input>;
  on_conflict?: InputMaybe<Curriculum_Change_Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Curriculum_Change_Request_OneArgs = {
  object: Curriculum_Change_Request_Insert_Input;
  on_conflict?: InputMaybe<Curriculum_Change_Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Curriculum_OneArgs = {
  object: Curriculum_Insert_Input;
  on_conflict?: InputMaybe<Curriculum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Identical_TopicArgs = {
  objects: Array<Identical_Topic_Insert_Input>;
  on_conflict?: InputMaybe<Identical_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Identical_Topic_OneArgs = {
  object: Identical_Topic_Insert_Input;
  on_conflict?: InputMaybe<Identical_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InstitutionArgs = {
  objects: Array<Institution_Insert_Input>;
  on_conflict?: InputMaybe<Institution_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Institution_OneArgs = {
  object: Institution_Insert_Input;
  on_conflict?: InputMaybe<Institution_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LanguageArgs = {
  objects: Array<Language_Insert_Input>;
  on_conflict?: InputMaybe<Language_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Language_OneArgs = {
  object: Language_Insert_Input;
  on_conflict?: InputMaybe<Language_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubmissionArgs = {
  objects: Array<Submission_Insert_Input>;
  on_conflict?: InputMaybe<Submission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_OneArgs = {
  object: Submission_Insert_Input;
  on_conflict?: InputMaybe<Submission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionArgs = {
  objects: Array<Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_OneArgs = {
  object: Subscription_Insert_Input;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagArgs = {
  objects: Array<Tag_Insert_Input>;
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_OneArgs = {
  object: Tag_Insert_Input;
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TopicArgs = {
  objects: Array<Topic_Insert_Input>;
  on_conflict?: InputMaybe<Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Topic_LabelArgs = {
  objects: Array<Topic_Label_Insert_Input>;
  on_conflict?: InputMaybe<Topic_Label_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Topic_Label_OneArgs = {
  object: Topic_Label_Insert_Input;
  on_conflict?: InputMaybe<Topic_Label_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Topic_OneArgs = {
  object: Topic_Insert_Input;
  on_conflict?: InputMaybe<Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_CommentArgs = {
  objects: Array<User_Comment_Insert_Input>;
  on_conflict?: InputMaybe<User_Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Comment_OneArgs = {
  object: User_Comment_Insert_Input;
  on_conflict?: InputMaybe<User_Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Curriculum_AccessArgs = {
  objects: Array<User_Curriculum_Access_Insert_Input>;
  on_conflict?: InputMaybe<User_Curriculum_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Curriculum_Access_OneArgs = {
  object: User_Curriculum_Access_Insert_Input;
  on_conflict?: InputMaybe<User_Curriculum_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InvitationArgs = {
  objects: Array<User_Invitation_Insert_Input>;
  on_conflict?: InputMaybe<User_Invitation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Invitation_OneArgs = {
  object: User_Invitation_Insert_Input;
  on_conflict?: InputMaybe<User_Invitation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Topic_AccessArgs = {
  objects: Array<User_Topic_Access_Insert_Input>;
  on_conflict?: InputMaybe<User_Topic_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Topic_Access_OneArgs = {
  object: User_Topic_Access_Insert_Input;
  on_conflict?: InputMaybe<User_Topic_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoteArgs = {
  objects: Array<Vote_Insert_Input>;
  on_conflict?: InputMaybe<Vote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vote_OneArgs = {
  object: Vote_Insert_Input;
  on_conflict?: InputMaybe<Vote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_BlockArgs = {
  _append?: InputMaybe<Block_Append_Input>;
  _delete_at_path?: InputMaybe<Block_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Block_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Block_Delete_Key_Input>;
  _inc?: InputMaybe<Block_Inc_Input>;
  _prepend?: InputMaybe<Block_Prepend_Input>;
  _set?: InputMaybe<Block_Set_Input>;
  where: Block_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Block_By_PkArgs = {
  _append?: InputMaybe<Block_Append_Input>;
  _delete_at_path?: InputMaybe<Block_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Block_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Block_Delete_Key_Input>;
  _inc?: InputMaybe<Block_Inc_Input>;
  _prepend?: InputMaybe<Block_Prepend_Input>;
  _set?: InputMaybe<Block_Set_Input>;
  pk_columns: Block_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Block_Change_RequestArgs = {
  _inc?: InputMaybe<Block_Change_Request_Inc_Input>;
  _set?: InputMaybe<Block_Change_Request_Set_Input>;
  where: Block_Change_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Block_Change_Request_By_PkArgs = {
  _inc?: InputMaybe<Block_Change_Request_Inc_Input>;
  _set?: InputMaybe<Block_Change_Request_Set_Input>;
  pk_columns: Block_Change_Request_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Block_RevisionArgs = {
  _append?: InputMaybe<Block_Revision_Append_Input>;
  _delete_at_path?: InputMaybe<Block_Revision_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Block_Revision_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Block_Revision_Delete_Key_Input>;
  _inc?: InputMaybe<Block_Revision_Inc_Input>;
  _prepend?: InputMaybe<Block_Revision_Prepend_Input>;
  _set?: InputMaybe<Block_Revision_Set_Input>;
  where: Block_Revision_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Block_Revision_By_PkArgs = {
  _append?: InputMaybe<Block_Revision_Append_Input>;
  _delete_at_path?: InputMaybe<Block_Revision_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Block_Revision_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Block_Revision_Delete_Key_Input>;
  _inc?: InputMaybe<Block_Revision_Inc_Input>;
  _prepend?: InputMaybe<Block_Revision_Prepend_Input>;
  _set?: InputMaybe<Block_Revision_Set_Input>;
  pk_columns: Block_Revision_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _inc?: InputMaybe<Country_Inc_Input>;
  _set?: InputMaybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _inc?: InputMaybe<Country_Inc_Input>;
  _set?: InputMaybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CurriculumArgs = {
  _inc?: InputMaybe<Curriculum_Inc_Input>;
  _set?: InputMaybe<Curriculum_Set_Input>;
  where: Curriculum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Curriculum_By_PkArgs = {
  _inc?: InputMaybe<Curriculum_Inc_Input>;
  _set?: InputMaybe<Curriculum_Set_Input>;
  pk_columns: Curriculum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Curriculum_Change_RequestArgs = {
  _inc?: InputMaybe<Curriculum_Change_Request_Inc_Input>;
  _set?: InputMaybe<Curriculum_Change_Request_Set_Input>;
  where: Curriculum_Change_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Curriculum_Change_Request_By_PkArgs = {
  _inc?: InputMaybe<Curriculum_Change_Request_Inc_Input>;
  _set?: InputMaybe<Curriculum_Change_Request_Set_Input>;
  pk_columns: Curriculum_Change_Request_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Identical_TopicArgs = {
  _inc?: InputMaybe<Identical_Topic_Inc_Input>;
  _set?: InputMaybe<Identical_Topic_Set_Input>;
  where: Identical_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Identical_Topic_By_PkArgs = {
  _inc?: InputMaybe<Identical_Topic_Inc_Input>;
  _set?: InputMaybe<Identical_Topic_Set_Input>;
  pk_columns: Identical_Topic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InstitutionArgs = {
  _inc?: InputMaybe<Institution_Inc_Input>;
  _set?: InputMaybe<Institution_Set_Input>;
  where: Institution_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Institution_By_PkArgs = {
  _inc?: InputMaybe<Institution_Inc_Input>;
  _set?: InputMaybe<Institution_Set_Input>;
  pk_columns: Institution_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LanguageArgs = {
  _inc?: InputMaybe<Language_Inc_Input>;
  _set?: InputMaybe<Language_Set_Input>;
  where: Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Language_By_PkArgs = {
  _inc?: InputMaybe<Language_Inc_Input>;
  _set?: InputMaybe<Language_Set_Input>;
  pk_columns: Language_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubmissionArgs = {
  _append?: InputMaybe<Submission_Append_Input>;
  _delete_at_path?: InputMaybe<Submission_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Submission_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Submission_Delete_Key_Input>;
  _inc?: InputMaybe<Submission_Inc_Input>;
  _prepend?: InputMaybe<Submission_Prepend_Input>;
  _set?: InputMaybe<Submission_Set_Input>;
  where: Submission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_By_PkArgs = {
  _append?: InputMaybe<Submission_Append_Input>;
  _delete_at_path?: InputMaybe<Submission_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Submission_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Submission_Delete_Key_Input>;
  _inc?: InputMaybe<Submission_Inc_Input>;
  _prepend?: InputMaybe<Submission_Prepend_Input>;
  _set?: InputMaybe<Submission_Set_Input>;
  pk_columns: Submission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_By_PkArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  pk_columns: Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TagArgs = {
  _inc?: InputMaybe<Tag_Inc_Input>;
  _set?: InputMaybe<Tag_Set_Input>;
  where: Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_By_PkArgs = {
  _inc?: InputMaybe<Tag_Inc_Input>;
  _set?: InputMaybe<Tag_Set_Input>;
  pk_columns: Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TopicArgs = {
  _inc?: InputMaybe<Topic_Inc_Input>;
  _set?: InputMaybe<Topic_Set_Input>;
  where: Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Topic_By_PkArgs = {
  _inc?: InputMaybe<Topic_Inc_Input>;
  _set?: InputMaybe<Topic_Set_Input>;
  pk_columns: Topic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Topic_LabelArgs = {
  _inc?: InputMaybe<Topic_Label_Inc_Input>;
  _set?: InputMaybe<Topic_Label_Set_Input>;
  where: Topic_Label_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Topic_Label_By_PkArgs = {
  _inc?: InputMaybe<Topic_Label_Inc_Input>;
  _set?: InputMaybe<Topic_Label_Set_Input>;
  pk_columns: Topic_Label_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_CommentArgs = {
  _inc?: InputMaybe<User_Comment_Inc_Input>;
  _set?: InputMaybe<User_Comment_Set_Input>;
  where: User_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Comment_By_PkArgs = {
  _inc?: InputMaybe<User_Comment_Inc_Input>;
  _set?: InputMaybe<User_Comment_Set_Input>;
  pk_columns: User_Comment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Curriculum_AccessArgs = {
  _inc?: InputMaybe<User_Curriculum_Access_Inc_Input>;
  _set?: InputMaybe<User_Curriculum_Access_Set_Input>;
  where: User_Curriculum_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Curriculum_Access_By_PkArgs = {
  _inc?: InputMaybe<User_Curriculum_Access_Inc_Input>;
  _set?: InputMaybe<User_Curriculum_Access_Set_Input>;
  pk_columns: User_Curriculum_Access_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_InvitationArgs = {
  _inc?: InputMaybe<User_Invitation_Inc_Input>;
  _set?: InputMaybe<User_Invitation_Set_Input>;
  where: User_Invitation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invitation_By_PkArgs = {
  _inc?: InputMaybe<User_Invitation_Inc_Input>;
  _set?: InputMaybe<User_Invitation_Set_Input>;
  pk_columns: User_Invitation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Topic_AccessArgs = {
  _inc?: InputMaybe<User_Topic_Access_Inc_Input>;
  _set?: InputMaybe<User_Topic_Access_Set_Input>;
  where: User_Topic_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Topic_Access_By_PkArgs = {
  _inc?: InputMaybe<User_Topic_Access_Inc_Input>;
  _set?: InputMaybe<User_Topic_Access_Set_Input>;
  pk_columns: User_Topic_Access_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VoteArgs = {
  _inc?: InputMaybe<Vote_Inc_Input>;
  _set?: InputMaybe<Vote_Set_Input>;
  where: Vote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vote_By_PkArgs = {
  _inc?: InputMaybe<Vote_Inc_Input>;
  _set?: InputMaybe<Vote_Set_Input>;
  pk_columns: Vote_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "block" */
  block: Array<Block>;
  /** fetch aggregated fields from the table: "block" */
  block_aggregate: Block_Aggregate;
  /** fetch data from the table: "block" using primary key columns */
  block_by_pk?: Maybe<Block>;
  /** fetch data from the table: "block_change_request" */
  block_change_request: Array<Block_Change_Request>;
  /** fetch aggregated fields from the table: "block_change_request" */
  block_change_request_aggregate: Block_Change_Request_Aggregate;
  /** fetch data from the table: "block_change_request" using primary key columns */
  block_change_request_by_pk?: Maybe<Block_Change_Request>;
  /** fetch data from the table: "block_revision" */
  block_revision: Array<Block_Revision>;
  /** fetch aggregated fields from the table: "block_revision" */
  block_revision_aggregate: Block_Revision_Aggregate;
  /** fetch data from the table: "block_revision" using primary key columns */
  block_revision_by_pk?: Maybe<Block_Revision>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "curriculum" */
  curriculum: Array<Curriculum>;
  /** fetch aggregated fields from the table: "curriculum" */
  curriculum_aggregate: Curriculum_Aggregate;
  /** fetch data from the table: "curriculum" using primary key columns */
  curriculum_by_pk?: Maybe<Curriculum>;
  /** fetch data from the table: "curriculum_change_request" */
  curriculum_change_request: Array<Curriculum_Change_Request>;
  /** fetch aggregated fields from the table: "curriculum_change_request" */
  curriculum_change_request_aggregate: Curriculum_Change_Request_Aggregate;
  /** fetch data from the table: "curriculum_change_request" using primary key columns */
  curriculum_change_request_by_pk?: Maybe<Curriculum_Change_Request>;
  /** execute function "filter_blocks" which returns "block" */
  filter_blocks: Array<Block>;
  /** execute function "filter_blocks" and query aggregates on result of table type "block" */
  filter_blocks_aggregate: Block_Aggregate;
  /** execute function "get_blocks_with_children" which returns "block" */
  get_blocks_with_children: Array<Block>;
  /** execute function "get_blocks_with_children" and query aggregates on result of table type "block" */
  get_blocks_with_children_aggregate: Block_Aggregate;
  /** execute function "get_topics_with_children" which returns "topic" */
  get_topics_with_children: Array<Topic>;
  /** execute function "get_topics_with_children" and query aggregates on result of table type "topic" */
  get_topics_with_children_aggregate: Topic_Aggregate;
  /** fetch data from the table: "identical_topic" */
  identical_topic: Array<Identical_Topic>;
  /** fetch aggregated fields from the table: "identical_topic" */
  identical_topic_aggregate: Identical_Topic_Aggregate;
  /** fetch data from the table: "identical_topic" using primary key columns */
  identical_topic_by_pk?: Maybe<Identical_Topic>;
  /** fetch data from the table: "institution" */
  institution: Array<Institution>;
  /** fetch aggregated fields from the table: "institution" */
  institution_aggregate: Institution_Aggregate;
  /** fetch data from the table: "institution" using primary key columns */
  institution_by_pk?: Maybe<Institution>;
  /** fetch data from the table: "language" */
  language: Array<Language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: Language_Aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<Language>;
  /** execute function "search_blocks" which returns "block" */
  search_blocks: Array<Block>;
  /** execute function "search_blocks" and query aggregates on result of table type "block" */
  search_blocks_aggregate: Block_Aggregate;
  /** execute function "search_users" which returns "users" */
  search_users: Array<Users>;
  /** execute function "search_users" and query aggregates on result of table type "users" */
  search_users_aggregate: Users_Aggregate;
  /** fetch data from the table: "submission" */
  submission: Array<Submission>;
  /** fetch aggregated fields from the table: "submission" */
  submission_aggregate: Submission_Aggregate;
  /** fetch data from the table: "submission" using primary key columns */
  submission_by_pk?: Maybe<Submission>;
  /** fetch data from the table: "subscription" */
  subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table: "topic" */
  topic: Array<Topic>;
  /** fetch aggregated fields from the table: "topic" */
  topic_aggregate: Topic_Aggregate;
  /** fetch data from the table: "topic" using primary key columns */
  topic_by_pk?: Maybe<Topic>;
  /** fetch data from the table: "topic_label" */
  topic_label: Array<Topic_Label>;
  /** fetch aggregated fields from the table: "topic_label" */
  topic_label_aggregate: Topic_Label_Aggregate;
  /** fetch data from the table: "topic_label" using primary key columns */
  topic_label_by_pk?: Maybe<Topic_Label>;
  /** fetch data from the table: "user_comment" */
  user_comment: Array<User_Comment>;
  /** fetch aggregated fields from the table: "user_comment" */
  user_comment_aggregate: User_Comment_Aggregate;
  /** fetch data from the table: "user_comment" using primary key columns */
  user_comment_by_pk?: Maybe<User_Comment>;
  /** fetch data from the table: "user_curriculum_access" */
  user_curriculum_access: Array<User_Curriculum_Access>;
  /** fetch aggregated fields from the table: "user_curriculum_access" */
  user_curriculum_access_aggregate: User_Curriculum_Access_Aggregate;
  /** fetch data from the table: "user_curriculum_access" using primary key columns */
  user_curriculum_access_by_pk?: Maybe<User_Curriculum_Access>;
  /** fetch data from the table: "user_invitation" */
  user_invitation: Array<User_Invitation>;
  /** fetch aggregated fields from the table: "user_invitation" */
  user_invitation_aggregate: User_Invitation_Aggregate;
  /** fetch data from the table: "user_invitation" using primary key columns */
  user_invitation_by_pk?: Maybe<User_Invitation>;
  /** fetch data from the table: "user_topic_access" */
  user_topic_access: Array<User_Topic_Access>;
  /** fetch aggregated fields from the table: "user_topic_access" */
  user_topic_access_aggregate: User_Topic_Access_Aggregate;
  /** fetch data from the table: "user_topic_access" using primary key columns */
  user_topic_access_by_pk?: Maybe<User_Topic_Access>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vote" */
  vote: Array<Vote>;
  /** fetch aggregated fields from the table: "vote" */
  vote_aggregate: Vote_Aggregate;
  /** fetch data from the table: "vote" using primary key columns */
  vote_by_pk?: Maybe<Vote>;
};


export type Query_RootBlockArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootBlock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootBlock_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBlock_Change_RequestArgs = {
  distinct_on?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Change_Request_Order_By>>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};


export type Query_RootBlock_Change_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Change_Request_Order_By>>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};


export type Query_RootBlock_Change_Request_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBlock_RevisionArgs = {
  distinct_on?: InputMaybe<Array<Block_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Revision_Order_By>>;
  where?: InputMaybe<Block_Revision_Bool_Exp>;
};


export type Query_RootBlock_Revision_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Revision_Order_By>>;
  where?: InputMaybe<Block_Revision_Bool_Exp>;
};


export type Query_RootBlock_Revision_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCurriculumArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Order_By>>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};


export type Query_RootCurriculum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Order_By>>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};


export type Query_RootCurriculum_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCurriculum_Change_RequestArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Change_Request_Order_By>>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};


export type Query_RootCurriculum_Change_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Change_Request_Order_By>>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};


export type Query_RootCurriculum_Change_Request_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFilter_BlocksArgs = {
  args: Filter_Blocks_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootFilter_Blocks_AggregateArgs = {
  args: Filter_Blocks_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootGet_Blocks_With_ChildrenArgs = {
  args: Get_Blocks_With_Children_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootGet_Blocks_With_Children_AggregateArgs = {
  args: Get_Blocks_With_Children_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootGet_Topics_With_ChildrenArgs = {
  args: Get_Topics_With_Children_Args;
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Query_RootGet_Topics_With_Children_AggregateArgs = {
  args: Get_Topics_With_Children_Args;
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Query_RootIdentical_TopicArgs = {
  distinct_on?: InputMaybe<Array<Identical_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identical_Topic_Order_By>>;
  where?: InputMaybe<Identical_Topic_Bool_Exp>;
};


export type Query_RootIdentical_Topic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identical_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identical_Topic_Order_By>>;
  where?: InputMaybe<Identical_Topic_Bool_Exp>;
};


export type Query_RootIdentical_Topic_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInstitutionArgs = {
  distinct_on?: InputMaybe<Array<Institution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Institution_Order_By>>;
  where?: InputMaybe<Institution_Bool_Exp>;
};


export type Query_RootInstitution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Institution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Institution_Order_By>>;
  where?: InputMaybe<Institution_Bool_Exp>;
};


export type Query_RootInstitution_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLanguageArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Query_RootLanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Query_RootLanguage_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSearch_BlocksArgs = {
  args: Search_Blocks_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootSearch_Blocks_AggregateArgs = {
  args: Search_Blocks_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Query_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootSubmissionArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


export type Query_RootSubmission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


export type Query_RootSubmission_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Query_RootTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Query_RootTag_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTopicArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Query_RootTopic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Query_RootTopic_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTopic_LabelArgs = {
  distinct_on?: InputMaybe<Array<Topic_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Label_Order_By>>;
  where?: InputMaybe<Topic_Label_Bool_Exp>;
};


export type Query_RootTopic_Label_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Label_Order_By>>;
  where?: InputMaybe<Topic_Label_Bool_Exp>;
};


export type Query_RootTopic_Label_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUser_CommentArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};


export type Query_RootUser_Comment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};


export type Query_RootUser_Comment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUser_Curriculum_AccessArgs = {
  distinct_on?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Curriculum_Access_Order_By>>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};


export type Query_RootUser_Curriculum_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Curriculum_Access_Order_By>>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};


export type Query_RootUser_Curriculum_Access_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUser_InvitationArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};


export type Query_RootUser_Invitation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};


export type Query_RootUser_Invitation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUser_Topic_AccessArgs = {
  distinct_on?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Topic_Access_Order_By>>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};


export type Query_RootUser_Topic_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Topic_Access_Order_By>>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};


export type Query_RootUser_Topic_Access_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVoteArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Query_RootVote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Query_RootVote_By_PkArgs = {
  id: Scalars['Int'];
};

export type Search_Blocks_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Users_Args = {
  search?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "submission" */
export type Submission = {
  __typename?: 'submission';
  /** An object relationship */
  block?: Maybe<Block>;
  block_id?: Maybe<Scalars['bigint']>;
  general_comments?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  marker_name?: Maybe<Scalars['String']>;
  marker_user_id?: Maybe<Scalars['bigint']>;
  marks?: Maybe<Scalars['jsonb']>;
  publicly_accessible?: Maybe<Scalars['Boolean']>;
  questions_feedback?: Maybe<Scalars['jsonb']>;
  requester_name?: Maybe<Scalars['String']>;
  requester_user_id?: Maybe<Scalars['bigint']>;
  submission_answers?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByRequesterUserId?: Maybe<Users>;
};


/** columns and relationships of "submission" */
export type SubmissionGeneral_CommentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "submission" */
export type SubmissionMarksArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "submission" */
export type SubmissionQuestions_FeedbackArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "submission" */
export type SubmissionSubmission_AnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "submission" */
export type Submission_Aggregate = {
  __typename?: 'submission_aggregate';
  aggregate?: Maybe<Submission_Aggregate_Fields>;
  nodes: Array<Submission>;
};

/** aggregate fields of "submission" */
export type Submission_Aggregate_Fields = {
  __typename?: 'submission_aggregate_fields';
  avg?: Maybe<Submission_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Submission_Max_Fields>;
  min?: Maybe<Submission_Min_Fields>;
  stddev?: Maybe<Submission_Stddev_Fields>;
  stddev_pop?: Maybe<Submission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Submission_Stddev_Samp_Fields>;
  sum?: Maybe<Submission_Sum_Fields>;
  var_pop?: Maybe<Submission_Var_Pop_Fields>;
  var_samp?: Maybe<Submission_Var_Samp_Fields>;
  variance?: Maybe<Submission_Variance_Fields>;
};


/** aggregate fields of "submission" */
export type Submission_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Submission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "submission" */
export type Submission_Aggregate_Order_By = {
  avg?: InputMaybe<Submission_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Submission_Max_Order_By>;
  min?: InputMaybe<Submission_Min_Order_By>;
  stddev?: InputMaybe<Submission_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Submission_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Submission_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Submission_Sum_Order_By>;
  var_pop?: InputMaybe<Submission_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Submission_Var_Samp_Order_By>;
  variance?: InputMaybe<Submission_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Submission_Append_Input = {
  general_comments?: InputMaybe<Scalars['jsonb']>;
  marks?: InputMaybe<Scalars['jsonb']>;
  questions_feedback?: InputMaybe<Scalars['jsonb']>;
  submission_answers?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "submission" */
export type Submission_Arr_Rel_Insert_Input = {
  data: Array<Submission_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Submission_On_Conflict>;
};

/** aggregate avg on columns */
export type Submission_Avg_Fields = {
  __typename?: 'submission_avg_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marker_user_id?: Maybe<Scalars['Float']>;
  requester_user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "submission" */
export type Submission_Avg_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "submission". All fields are combined with a logical 'AND'. */
export type Submission_Bool_Exp = {
  _and?: InputMaybe<Array<Submission_Bool_Exp>>;
  _not?: InputMaybe<Submission_Bool_Exp>;
  _or?: InputMaybe<Array<Submission_Bool_Exp>>;
  block?: InputMaybe<Block_Bool_Exp>;
  block_id?: InputMaybe<Bigint_Comparison_Exp>;
  general_comments?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  marker_name?: InputMaybe<String_Comparison_Exp>;
  marker_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  marks?: InputMaybe<Jsonb_Comparison_Exp>;
  publicly_accessible?: InputMaybe<Boolean_Comparison_Exp>;
  questions_feedback?: InputMaybe<Jsonb_Comparison_Exp>;
  requester_name?: InputMaybe<String_Comparison_Exp>;
  requester_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  submission_answers?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByRequesterUserId?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "submission" */
export enum Submission_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubmissionPkey = 'submission_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Submission_Delete_At_Path_Input = {
  general_comments?: InputMaybe<Array<Scalars['String']>>;
  marks?: InputMaybe<Array<Scalars['String']>>;
  questions_feedback?: InputMaybe<Array<Scalars['String']>>;
  submission_answers?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Submission_Delete_Elem_Input = {
  general_comments?: InputMaybe<Scalars['Int']>;
  marks?: InputMaybe<Scalars['Int']>;
  questions_feedback?: InputMaybe<Scalars['Int']>;
  submission_answers?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Submission_Delete_Key_Input = {
  general_comments?: InputMaybe<Scalars['String']>;
  marks?: InputMaybe<Scalars['String']>;
  questions_feedback?: InputMaybe<Scalars['String']>;
  submission_answers?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "submission" */
export type Submission_Inc_Input = {
  block_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  marker_user_id?: InputMaybe<Scalars['bigint']>;
  requester_user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "submission" */
export type Submission_Insert_Input = {
  block?: InputMaybe<Block_Obj_Rel_Insert_Input>;
  block_id?: InputMaybe<Scalars['bigint']>;
  general_comments?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  marker_name?: InputMaybe<Scalars['String']>;
  marker_user_id?: InputMaybe<Scalars['bigint']>;
  marks?: InputMaybe<Scalars['jsonb']>;
  publicly_accessible?: InputMaybe<Scalars['Boolean']>;
  questions_feedback?: InputMaybe<Scalars['jsonb']>;
  requester_name?: InputMaybe<Scalars['String']>;
  requester_user_id?: InputMaybe<Scalars['bigint']>;
  submission_answers?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByRequesterUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Submission_Max_Fields = {
  __typename?: 'submission_max_fields';
  block_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  marker_name?: Maybe<Scalars['String']>;
  marker_user_id?: Maybe<Scalars['bigint']>;
  requester_name?: Maybe<Scalars['String']>;
  requester_user_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "submission" */
export type Submission_Max_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_name?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_name?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Submission_Min_Fields = {
  __typename?: 'submission_min_fields';
  block_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  marker_name?: Maybe<Scalars['String']>;
  marker_user_id?: Maybe<Scalars['bigint']>;
  requester_name?: Maybe<Scalars['String']>;
  requester_user_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "submission" */
export type Submission_Min_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_name?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_name?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "submission" */
export type Submission_Mutation_Response = {
  __typename?: 'submission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Submission>;
};

/** on_conflict condition type for table "submission" */
export type Submission_On_Conflict = {
  constraint: Submission_Constraint;
  update_columns?: Array<Submission_Update_Column>;
  where?: InputMaybe<Submission_Bool_Exp>;
};

/** Ordering options when selecting data from "submission". */
export type Submission_Order_By = {
  block?: InputMaybe<Block_Order_By>;
  block_id?: InputMaybe<Order_By>;
  general_comments?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_name?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  marks?: InputMaybe<Order_By>;
  publicly_accessible?: InputMaybe<Order_By>;
  questions_feedback?: InputMaybe<Order_By>;
  requester_name?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
  submission_answers?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByRequesterUserId?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: submission */
export type Submission_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Submission_Prepend_Input = {
  general_comments?: InputMaybe<Scalars['jsonb']>;
  marks?: InputMaybe<Scalars['jsonb']>;
  questions_feedback?: InputMaybe<Scalars['jsonb']>;
  submission_answers?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "submission" */
export enum Submission_Select_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  GeneralComments = 'general_comments',
  /** column name */
  Id = 'id',
  /** column name */
  MarkerName = 'marker_name',
  /** column name */
  MarkerUserId = 'marker_user_id',
  /** column name */
  Marks = 'marks',
  /** column name */
  PubliclyAccessible = 'publicly_accessible',
  /** column name */
  QuestionsFeedback = 'questions_feedback',
  /** column name */
  RequesterName = 'requester_name',
  /** column name */
  RequesterUserId = 'requester_user_id',
  /** column name */
  SubmissionAnswers = 'submission_answers',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "submission" */
export type Submission_Set_Input = {
  block_id?: InputMaybe<Scalars['bigint']>;
  general_comments?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  marker_name?: InputMaybe<Scalars['String']>;
  marker_user_id?: InputMaybe<Scalars['bigint']>;
  marks?: InputMaybe<Scalars['jsonb']>;
  publicly_accessible?: InputMaybe<Scalars['Boolean']>;
  questions_feedback?: InputMaybe<Scalars['jsonb']>;
  requester_name?: InputMaybe<Scalars['String']>;
  requester_user_id?: InputMaybe<Scalars['bigint']>;
  submission_answers?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Submission_Stddev_Fields = {
  __typename?: 'submission_stddev_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marker_user_id?: Maybe<Scalars['Float']>;
  requester_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "submission" */
export type Submission_Stddev_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Submission_Stddev_Pop_Fields = {
  __typename?: 'submission_stddev_pop_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marker_user_id?: Maybe<Scalars['Float']>;
  requester_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "submission" */
export type Submission_Stddev_Pop_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Submission_Stddev_Samp_Fields = {
  __typename?: 'submission_stddev_samp_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marker_user_id?: Maybe<Scalars['Float']>;
  requester_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "submission" */
export type Submission_Stddev_Samp_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Submission_Sum_Fields = {
  __typename?: 'submission_sum_fields';
  block_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  marker_user_id?: Maybe<Scalars['bigint']>;
  requester_user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "submission" */
export type Submission_Sum_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** update columns of table "submission" */
export enum Submission_Update_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  GeneralComments = 'general_comments',
  /** column name */
  Id = 'id',
  /** column name */
  MarkerName = 'marker_name',
  /** column name */
  MarkerUserId = 'marker_user_id',
  /** column name */
  Marks = 'marks',
  /** column name */
  PubliclyAccessible = 'publicly_accessible',
  /** column name */
  QuestionsFeedback = 'questions_feedback',
  /** column name */
  RequesterName = 'requester_name',
  /** column name */
  RequesterUserId = 'requester_user_id',
  /** column name */
  SubmissionAnswers = 'submission_answers',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Submission_Var_Pop_Fields = {
  __typename?: 'submission_var_pop_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marker_user_id?: Maybe<Scalars['Float']>;
  requester_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "submission" */
export type Submission_Var_Pop_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Submission_Var_Samp_Fields = {
  __typename?: 'submission_var_samp_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marker_user_id?: Maybe<Scalars['Float']>;
  requester_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "submission" */
export type Submission_Var_Samp_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Submission_Variance_Fields = {
  __typename?: 'submission_variance_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marker_user_id?: Maybe<Scalars['Float']>;
  requester_user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "submission" */
export type Submission_Variance_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marker_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "subscription" */
export type Subscription = {
  __typename?: 'subscription';
  created_time?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  discount: Scalars['float8'];
  id: Scalars['Int'];
  last_edited_time?: Maybe<Scalars['bigint']>;
  monthly_price: Scalars['float8'];
  plan: Scalars['String'];
  quarterly_price: Scalars['float8'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  yearly_price: Scalars['float8'];
};


/** columns and relationships of "subscription" */
export type SubscriptionUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "subscription" */
export type Subscription_Aggregate = {
  __typename?: 'subscription_aggregate';
  aggregate?: Maybe<Subscription_Aggregate_Fields>;
  nodes: Array<Subscription>;
};

/** aggregate fields of "subscription" */
export type Subscription_Aggregate_Fields = {
  __typename?: 'subscription_aggregate_fields';
  avg?: Maybe<Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subscription_Max_Fields>;
  min?: Maybe<Subscription_Min_Fields>;
  stddev?: Maybe<Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Sum_Fields>;
  var_pop?: Maybe<Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Variance_Fields>;
};


/** aggregate fields of "subscription" */
export type Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subscription_Avg_Fields = {
  __typename?: 'subscription_avg_fields';
  created_time?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  quarterly_price?: Maybe<Scalars['Float']>;
  yearly_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "subscription". All fields are combined with a logical 'AND'. */
export type Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Bool_Exp>>;
  created_time?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discount?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_edited_time?: InputMaybe<Bigint_Comparison_Exp>;
  monthly_price?: InputMaybe<Float8_Comparison_Exp>;
  plan?: InputMaybe<String_Comparison_Exp>;
  quarterly_price?: InputMaybe<Float8_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  yearly_price?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription" */
export enum Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionPkey = 'subscription_pkey'
}

/** input type for incrementing numeric columns in table "subscription" */
export type Subscription_Inc_Input = {
  created_time?: InputMaybe<Scalars['bigint']>;
  discount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  monthly_price?: InputMaybe<Scalars['float8']>;
  quarterly_price?: InputMaybe<Scalars['float8']>;
  yearly_price?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "subscription" */
export type Subscription_Insert_Input = {
  created_time?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  monthly_price?: InputMaybe<Scalars['float8']>;
  plan?: InputMaybe<Scalars['String']>;
  quarterly_price?: InputMaybe<Scalars['float8']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  yearly_price?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Subscription_Max_Fields = {
  __typename?: 'subscription_max_fields';
  created_time?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  monthly_price?: Maybe<Scalars['float8']>;
  plan?: Maybe<Scalars['String']>;
  quarterly_price?: Maybe<Scalars['float8']>;
  yearly_price?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Subscription_Min_Fields = {
  __typename?: 'subscription_min_fields';
  created_time?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  monthly_price?: Maybe<Scalars['float8']>;
  plan?: Maybe<Scalars['String']>;
  quarterly_price?: Maybe<Scalars['float8']>;
  yearly_price?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "subscription" */
export type Subscription_Mutation_Response = {
  __typename?: 'subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription>;
};

/** input type for inserting object relation for remote table "subscription" */
export type Subscription_Obj_Rel_Insert_Input = {
  data: Subscription_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

/** on_conflict condition type for table "subscription" */
export type Subscription_On_Conflict = {
  constraint: Subscription_Constraint;
  update_columns?: Array<Subscription_Update_Column>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription". */
export type Subscription_Order_By = {
  created_time?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  monthly_price?: InputMaybe<Order_By>;
  plan?: InputMaybe<Order_By>;
  quarterly_price?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  yearly_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription */
export type Subscription_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "block" */
  block: Array<Block>;
  /** fetch aggregated fields from the table: "block" */
  block_aggregate: Block_Aggregate;
  /** fetch data from the table: "block" using primary key columns */
  block_by_pk?: Maybe<Block>;
  /** fetch data from the table: "block_change_request" */
  block_change_request: Array<Block_Change_Request>;
  /** fetch aggregated fields from the table: "block_change_request" */
  block_change_request_aggregate: Block_Change_Request_Aggregate;
  /** fetch data from the table: "block_change_request" using primary key columns */
  block_change_request_by_pk?: Maybe<Block_Change_Request>;
  /** fetch data from the table: "block_revision" */
  block_revision: Array<Block_Revision>;
  /** fetch aggregated fields from the table: "block_revision" */
  block_revision_aggregate: Block_Revision_Aggregate;
  /** fetch data from the table: "block_revision" using primary key columns */
  block_revision_by_pk?: Maybe<Block_Revision>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "curriculum" */
  curriculum: Array<Curriculum>;
  /** fetch aggregated fields from the table: "curriculum" */
  curriculum_aggregate: Curriculum_Aggregate;
  /** fetch data from the table: "curriculum" using primary key columns */
  curriculum_by_pk?: Maybe<Curriculum>;
  /** fetch data from the table: "curriculum_change_request" */
  curriculum_change_request: Array<Curriculum_Change_Request>;
  /** fetch aggregated fields from the table: "curriculum_change_request" */
  curriculum_change_request_aggregate: Curriculum_Change_Request_Aggregate;
  /** fetch data from the table: "curriculum_change_request" using primary key columns */
  curriculum_change_request_by_pk?: Maybe<Curriculum_Change_Request>;
  /** execute function "filter_blocks" which returns "block" */
  filter_blocks: Array<Block>;
  /** execute function "filter_blocks" and query aggregates on result of table type "block" */
  filter_blocks_aggregate: Block_Aggregate;
  /** execute function "get_blocks_with_children" which returns "block" */
  get_blocks_with_children: Array<Block>;
  /** execute function "get_blocks_with_children" and query aggregates on result of table type "block" */
  get_blocks_with_children_aggregate: Block_Aggregate;
  /** execute function "get_topics_with_children" which returns "topic" */
  get_topics_with_children: Array<Topic>;
  /** execute function "get_topics_with_children" and query aggregates on result of table type "topic" */
  get_topics_with_children_aggregate: Topic_Aggregate;
  /** fetch data from the table: "identical_topic" */
  identical_topic: Array<Identical_Topic>;
  /** fetch aggregated fields from the table: "identical_topic" */
  identical_topic_aggregate: Identical_Topic_Aggregate;
  /** fetch data from the table: "identical_topic" using primary key columns */
  identical_topic_by_pk?: Maybe<Identical_Topic>;
  /** fetch data from the table: "institution" */
  institution: Array<Institution>;
  /** fetch aggregated fields from the table: "institution" */
  institution_aggregate: Institution_Aggregate;
  /** fetch data from the table: "institution" using primary key columns */
  institution_by_pk?: Maybe<Institution>;
  /** fetch data from the table: "language" */
  language: Array<Language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: Language_Aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<Language>;
  /** execute function "search_blocks" which returns "block" */
  search_blocks: Array<Block>;
  /** execute function "search_blocks" and query aggregates on result of table type "block" */
  search_blocks_aggregate: Block_Aggregate;
  /** execute function "search_users" which returns "users" */
  search_users: Array<Users>;
  /** execute function "search_users" and query aggregates on result of table type "users" */
  search_users_aggregate: Users_Aggregate;
  /** fetch data from the table: "submission" */
  submission: Array<Submission>;
  /** fetch aggregated fields from the table: "submission" */
  submission_aggregate: Submission_Aggregate;
  /** fetch data from the table: "submission" using primary key columns */
  submission_by_pk?: Maybe<Submission>;
  /** fetch data from the table: "subscription" */
  subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table: "topic" */
  topic: Array<Topic>;
  /** fetch aggregated fields from the table: "topic" */
  topic_aggregate: Topic_Aggregate;
  /** fetch data from the table: "topic" using primary key columns */
  topic_by_pk?: Maybe<Topic>;
  /** fetch data from the table: "topic_label" */
  topic_label: Array<Topic_Label>;
  /** fetch aggregated fields from the table: "topic_label" */
  topic_label_aggregate: Topic_Label_Aggregate;
  /** fetch data from the table: "topic_label" using primary key columns */
  topic_label_by_pk?: Maybe<Topic_Label>;
  /** fetch data from the table: "user_comment" */
  user_comment: Array<User_Comment>;
  /** fetch aggregated fields from the table: "user_comment" */
  user_comment_aggregate: User_Comment_Aggregate;
  /** fetch data from the table: "user_comment" using primary key columns */
  user_comment_by_pk?: Maybe<User_Comment>;
  /** fetch data from the table: "user_curriculum_access" */
  user_curriculum_access: Array<User_Curriculum_Access>;
  /** fetch aggregated fields from the table: "user_curriculum_access" */
  user_curriculum_access_aggregate: User_Curriculum_Access_Aggregate;
  /** fetch data from the table: "user_curriculum_access" using primary key columns */
  user_curriculum_access_by_pk?: Maybe<User_Curriculum_Access>;
  /** fetch data from the table: "user_invitation" */
  user_invitation: Array<User_Invitation>;
  /** fetch aggregated fields from the table: "user_invitation" */
  user_invitation_aggregate: User_Invitation_Aggregate;
  /** fetch data from the table: "user_invitation" using primary key columns */
  user_invitation_by_pk?: Maybe<User_Invitation>;
  /** fetch data from the table: "user_topic_access" */
  user_topic_access: Array<User_Topic_Access>;
  /** fetch aggregated fields from the table: "user_topic_access" */
  user_topic_access_aggregate: User_Topic_Access_Aggregate;
  /** fetch data from the table: "user_topic_access" using primary key columns */
  user_topic_access_by_pk?: Maybe<User_Topic_Access>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vote" */
  vote: Array<Vote>;
  /** fetch aggregated fields from the table: "vote" */
  vote_aggregate: Vote_Aggregate;
  /** fetch data from the table: "vote" using primary key columns */
  vote_by_pk?: Maybe<Vote>;
};


export type Subscription_RootBlockArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootBlock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootBlock_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBlock_Change_RequestArgs = {
  distinct_on?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Change_Request_Order_By>>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};


export type Subscription_RootBlock_Change_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Change_Request_Order_By>>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};


export type Subscription_RootBlock_Change_Request_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBlock_RevisionArgs = {
  distinct_on?: InputMaybe<Array<Block_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Revision_Order_By>>;
  where?: InputMaybe<Block_Revision_Bool_Exp>;
};


export type Subscription_RootBlock_Revision_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Revision_Order_By>>;
  where?: InputMaybe<Block_Revision_Bool_Exp>;
};


export type Subscription_RootBlock_Revision_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCurriculumArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Order_By>>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};


export type Subscription_RootCurriculum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Order_By>>;
  where?: InputMaybe<Curriculum_Bool_Exp>;
};


export type Subscription_RootCurriculum_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCurriculum_Change_RequestArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Change_Request_Order_By>>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};


export type Subscription_RootCurriculum_Change_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Change_Request_Order_By>>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};


export type Subscription_RootCurriculum_Change_Request_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFilter_BlocksArgs = {
  args: Filter_Blocks_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootFilter_Blocks_AggregateArgs = {
  args: Filter_Blocks_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootGet_Blocks_With_ChildrenArgs = {
  args: Get_Blocks_With_Children_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootGet_Blocks_With_Children_AggregateArgs = {
  args: Get_Blocks_With_Children_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootGet_Topics_With_ChildrenArgs = {
  args: Get_Topics_With_Children_Args;
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Subscription_RootGet_Topics_With_Children_AggregateArgs = {
  args: Get_Topics_With_Children_Args;
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Subscription_RootIdentical_TopicArgs = {
  distinct_on?: InputMaybe<Array<Identical_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identical_Topic_Order_By>>;
  where?: InputMaybe<Identical_Topic_Bool_Exp>;
};


export type Subscription_RootIdentical_Topic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identical_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identical_Topic_Order_By>>;
  where?: InputMaybe<Identical_Topic_Bool_Exp>;
};


export type Subscription_RootIdentical_Topic_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInstitutionArgs = {
  distinct_on?: InputMaybe<Array<Institution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Institution_Order_By>>;
  where?: InputMaybe<Institution_Bool_Exp>;
};


export type Subscription_RootInstitution_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Institution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Institution_Order_By>>;
  where?: InputMaybe<Institution_Bool_Exp>;
};


export type Subscription_RootInstitution_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLanguageArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Subscription_RootLanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Subscription_RootLanguage_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSearch_BlocksArgs = {
  args: Search_Blocks_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootSearch_Blocks_AggregateArgs = {
  args: Search_Blocks_Args;
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


export type Subscription_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootSubmissionArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


export type Subscription_RootSubmission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


export type Subscription_RootSubmission_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTag_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTopicArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Subscription_RootTopic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Subscription_RootTopic_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTopic_LabelArgs = {
  distinct_on?: InputMaybe<Array<Topic_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Label_Order_By>>;
  where?: InputMaybe<Topic_Label_Bool_Exp>;
};


export type Subscription_RootTopic_Label_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Label_Order_By>>;
  where?: InputMaybe<Topic_Label_Bool_Exp>;
};


export type Subscription_RootTopic_Label_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_CommentArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};


export type Subscription_RootUser_Comment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};


export type Subscription_RootUser_Comment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_Curriculum_AccessArgs = {
  distinct_on?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Curriculum_Access_Order_By>>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};


export type Subscription_RootUser_Curriculum_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Curriculum_Access_Order_By>>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};


export type Subscription_RootUser_Curriculum_Access_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_InvitationArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};


export type Subscription_RootUser_Invitation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};


export type Subscription_RootUser_Invitation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_Topic_AccessArgs = {
  distinct_on?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Topic_Access_Order_By>>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};


export type Subscription_RootUser_Topic_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Topic_Access_Order_By>>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};


export type Subscription_RootUser_Topic_Access_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVoteArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Subscription_RootVote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


export type Subscription_RootVote_By_PkArgs = {
  id: Scalars['Int'];
};

/** select columns of table "subscription" */
export enum Subscription_Select_Column {
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  LastEditedTime = 'last_edited_time',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  Plan = 'plan',
  /** column name */
  QuarterlyPrice = 'quarterly_price',
  /** column name */
  YearlyPrice = 'yearly_price'
}

/** input type for updating data in table "subscription" */
export type Subscription_Set_Input = {
  created_time?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  monthly_price?: InputMaybe<Scalars['float8']>;
  plan?: InputMaybe<Scalars['String']>;
  quarterly_price?: InputMaybe<Scalars['float8']>;
  yearly_price?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Subscription_Stddev_Fields = {
  __typename?: 'subscription_stddev_fields';
  created_time?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  quarterly_price?: Maybe<Scalars['Float']>;
  yearly_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Stddev_Pop_Fields = {
  __typename?: 'subscription_stddev_pop_fields';
  created_time?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  quarterly_price?: Maybe<Scalars['Float']>;
  yearly_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Stddev_Samp_Fields = {
  __typename?: 'subscription_stddev_samp_fields';
  created_time?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  quarterly_price?: Maybe<Scalars['Float']>;
  yearly_price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subscription_Sum_Fields = {
  __typename?: 'subscription_sum_fields';
  created_time?: Maybe<Scalars['bigint']>;
  discount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  monthly_price?: Maybe<Scalars['float8']>;
  quarterly_price?: Maybe<Scalars['float8']>;
  yearly_price?: Maybe<Scalars['float8']>;
};

/** update columns of table "subscription" */
export enum Subscription_Update_Column {
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  LastEditedTime = 'last_edited_time',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  Plan = 'plan',
  /** column name */
  QuarterlyPrice = 'quarterly_price',
  /** column name */
  YearlyPrice = 'yearly_price'
}

/** aggregate var_pop on columns */
export type Subscription_Var_Pop_Fields = {
  __typename?: 'subscription_var_pop_fields';
  created_time?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  quarterly_price?: Maybe<Scalars['Float']>;
  yearly_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subscription_Var_Samp_Fields = {
  __typename?: 'subscription_var_samp_fields';
  created_time?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  quarterly_price?: Maybe<Scalars['Float']>;
  yearly_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subscription_Variance_Fields = {
  __typename?: 'subscription_variance_fields';
  created_time?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  quarterly_price?: Maybe<Scalars['Float']>;
  yearly_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "tag" */
export type Tag = {
  __typename?: 'tag';
  /** An object relationship */
  block?: Maybe<Block>;
  block_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  curriculum?: Maybe<Curriculum>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id: Scalars['Int'];
  /** An object relationship */
  institution?: Maybe<Institution>;
  institution_interests_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  topic?: Maybe<Topic>;
  topic_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_interests_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "tag" */
export type Tag_Aggregate = {
  __typename?: 'tag_aggregate';
  aggregate?: Maybe<Tag_Aggregate_Fields>;
  nodes: Array<Tag>;
};

/** aggregate fields of "tag" */
export type Tag_Aggregate_Fields = {
  __typename?: 'tag_aggregate_fields';
  avg?: Maybe<Tag_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tag_Max_Fields>;
  min?: Maybe<Tag_Min_Fields>;
  stddev?: Maybe<Tag_Stddev_Fields>;
  stddev_pop?: Maybe<Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tag_Stddev_Samp_Fields>;
  sum?: Maybe<Tag_Sum_Fields>;
  var_pop?: Maybe<Tag_Var_Pop_Fields>;
  var_samp?: Maybe<Tag_Var_Samp_Fields>;
  variance?: Maybe<Tag_Variance_Fields>;
};


/** aggregate fields of "tag" */
export type Tag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tag" */
export type Tag_Aggregate_Order_By = {
  avg?: InputMaybe<Tag_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tag_Max_Order_By>;
  min?: InputMaybe<Tag_Min_Order_By>;
  stddev?: InputMaybe<Tag_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tag_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tag_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tag_Sum_Order_By>;
  var_pop?: InputMaybe<Tag_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tag_Var_Samp_Order_By>;
  variance?: InputMaybe<Tag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tag" */
export type Tag_Arr_Rel_Insert_Input = {
  data: Array<Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};

/** aggregate avg on columns */
export type Tag_Avg_Fields = {
  __typename?: 'tag_avg_fields';
  block_id?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_interests_id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_interests_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tag" */
export type Tag_Avg_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type Tag_Bool_Exp = {
  _and?: InputMaybe<Array<Tag_Bool_Exp>>;
  _not?: InputMaybe<Tag_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Bool_Exp>>;
  block?: InputMaybe<Block_Bool_Exp>;
  block_id?: InputMaybe<Bigint_Comparison_Exp>;
  curriculum?: InputMaybe<Curriculum_Bool_Exp>;
  curriculum_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  institution?: InputMaybe<Institution_Bool_Exp>;
  institution_interests_id?: InputMaybe<Bigint_Comparison_Exp>;
  topic?: InputMaybe<Topic_Bool_Exp>;
  topic_id?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_interests_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag" */
export enum Tag_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagPkey = 'tag_pkey'
}

/** input type for incrementing numeric columns in table "tag" */
export type Tag_Inc_Input = {
  block_id?: InputMaybe<Scalars['bigint']>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  institution_interests_id?: InputMaybe<Scalars['bigint']>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  user_interests_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tag" */
export type Tag_Insert_Input = {
  block?: InputMaybe<Block_Obj_Rel_Insert_Input>;
  block_id?: InputMaybe<Scalars['bigint']>;
  curriculum?: InputMaybe<Curriculum_Obj_Rel_Insert_Input>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  institution?: InputMaybe<Institution_Obj_Rel_Insert_Input>;
  institution_interests_id?: InputMaybe<Scalars['bigint']>;
  topic?: InputMaybe<Topic_Obj_Rel_Insert_Input>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_interests_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Tag_Max_Fields = {
  __typename?: 'tag_max_fields';
  block_id?: Maybe<Scalars['bigint']>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  institution_interests_id?: Maybe<Scalars['bigint']>;
  topic_id?: Maybe<Scalars['bigint']>;
  user_interests_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "tag" */
export type Tag_Max_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tag_Min_Fields = {
  __typename?: 'tag_min_fields';
  block_id?: Maybe<Scalars['bigint']>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  institution_interests_id?: Maybe<Scalars['bigint']>;
  topic_id?: Maybe<Scalars['bigint']>;
  user_interests_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "tag" */
export type Tag_Min_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tag" */
export type Tag_Mutation_Response = {
  __typename?: 'tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag>;
};

/** on_conflict condition type for table "tag" */
export type Tag_On_Conflict = {
  constraint: Tag_Constraint;
  update_columns?: Array<Tag_Update_Column>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "tag". */
export type Tag_Order_By = {
  block?: InputMaybe<Block_Order_By>;
  block_id?: InputMaybe<Order_By>;
  curriculum?: InputMaybe<Curriculum_Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution?: InputMaybe<Institution_Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic?: InputMaybe<Topic_Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tag */
export type Tag_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "tag" */
export enum Tag_Select_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionInterestsId = 'institution_interests_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserInterestsId = 'user_interests_id'
}

/** input type for updating data in table "tag" */
export type Tag_Set_Input = {
  block_id?: InputMaybe<Scalars['bigint']>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  institution_interests_id?: InputMaybe<Scalars['bigint']>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  user_interests_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Tag_Stddev_Fields = {
  __typename?: 'tag_stddev_fields';
  block_id?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_interests_id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_interests_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tag" */
export type Tag_Stddev_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tag_Stddev_Pop_Fields = {
  __typename?: 'tag_stddev_pop_fields';
  block_id?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_interests_id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_interests_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tag" */
export type Tag_Stddev_Pop_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tag_Stddev_Samp_Fields = {
  __typename?: 'tag_stddev_samp_fields';
  block_id?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_interests_id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_interests_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tag" */
export type Tag_Stddev_Samp_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Tag_Sum_Fields = {
  __typename?: 'tag_sum_fields';
  block_id?: Maybe<Scalars['bigint']>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  institution_interests_id?: Maybe<Scalars['bigint']>;
  topic_id?: Maybe<Scalars['bigint']>;
  user_interests_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tag" */
export type Tag_Sum_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** update columns of table "tag" */
export enum Tag_Update_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionInterestsId = 'institution_interests_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserInterestsId = 'user_interests_id'
}

/** aggregate var_pop on columns */
export type Tag_Var_Pop_Fields = {
  __typename?: 'tag_var_pop_fields';
  block_id?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_interests_id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_interests_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tag" */
export type Tag_Var_Pop_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tag_Var_Samp_Fields = {
  __typename?: 'tag_var_samp_fields';
  block_id?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_interests_id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_interests_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tag" */
export type Tag_Var_Samp_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tag_Variance_Fields = {
  __typename?: 'tag_variance_fields';
  block_id?: Maybe<Scalars['Float']>;
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_interests_id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_interests_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tag" */
export type Tag_Variance_Order_By = {
  block_id?: InputMaybe<Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_interests_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_interests_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "topic" */
export type Topic = {
  __typename?: 'topic';
  active: Scalars['Boolean'];
  /** An array relationship */
  blocks: Array<Block>;
  /** An aggregate relationship */
  blocks_aggregate: Block_Aggregate;
  /** An object relationship */
  curriculum?: Maybe<Curriculum>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  has_children: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An array relationship */
  identical_topics: Array<Identical_Topic>;
  /** An aggregate relationship */
  identical_topics_aggregate: Identical_Topic_Aggregate;
  parent_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: Tag_Aggregate;
  title: Scalars['String'];
  /** An object relationship */
  topic?: Maybe<Topic>;
  /** An object relationship */
  topic_label?: Maybe<Topic_Label>;
  topic_label_id?: Maybe<Scalars['bigint']>;
  topic_unit_title: Scalars['String'];
  /** An array relationship */
  topics: Array<Topic>;
  /** An aggregate relationship */
  topics_aggregate: Topic_Aggregate;
  url?: Maybe<Scalars['String']>;
  /** An array relationship */
  user_topic_accesses: Array<User_Topic_Access>;
  /** An aggregate relationship */
  user_topic_accesses_aggregate: User_Topic_Access_Aggregate;
};


/** columns and relationships of "topic" */
export type TopicBlocksArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicBlocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicIdentical_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Identical_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identical_Topic_Order_By>>;
  where?: InputMaybe<Identical_Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicIdentical_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identical_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identical_Topic_Order_By>>;
  where?: InputMaybe<Identical_Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicTopicsArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicTopics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicUser_Topic_AccessesArgs = {
  distinct_on?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Topic_Access_Order_By>>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicUser_Topic_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Topic_Access_Order_By>>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};

/** aggregated selection of "topic" */
export type Topic_Aggregate = {
  __typename?: 'topic_aggregate';
  aggregate?: Maybe<Topic_Aggregate_Fields>;
  nodes: Array<Topic>;
};

/** aggregate fields of "topic" */
export type Topic_Aggregate_Fields = {
  __typename?: 'topic_aggregate_fields';
  avg?: Maybe<Topic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Topic_Max_Fields>;
  min?: Maybe<Topic_Min_Fields>;
  stddev?: Maybe<Topic_Stddev_Fields>;
  stddev_pop?: Maybe<Topic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Topic_Stddev_Samp_Fields>;
  sum?: Maybe<Topic_Sum_Fields>;
  var_pop?: Maybe<Topic_Var_Pop_Fields>;
  var_samp?: Maybe<Topic_Var_Samp_Fields>;
  variance?: Maybe<Topic_Variance_Fields>;
};


/** aggregate fields of "topic" */
export type Topic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Topic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "topic" */
export type Topic_Aggregate_Order_By = {
  avg?: InputMaybe<Topic_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Topic_Max_Order_By>;
  min?: InputMaybe<Topic_Min_Order_By>;
  stddev?: InputMaybe<Topic_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Topic_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Topic_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Topic_Sum_Order_By>;
  var_pop?: InputMaybe<Topic_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Topic_Var_Samp_Order_By>;
  variance?: InputMaybe<Topic_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "topic" */
export type Topic_Arr_Rel_Insert_Input = {
  data: Array<Topic_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Topic_On_Conflict>;
};

/** aggregate avg on columns */
export type Topic_Avg_Fields = {
  __typename?: 'topic_avg_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  topic_label_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "topic" */
export type Topic_Avg_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "topic". All fields are combined with a logical 'AND'. */
export type Topic_Bool_Exp = {
  _and?: InputMaybe<Array<Topic_Bool_Exp>>;
  _not?: InputMaybe<Topic_Bool_Exp>;
  _or?: InputMaybe<Array<Topic_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  blocks?: InputMaybe<Block_Bool_Exp>;
  curriculum?: InputMaybe<Curriculum_Bool_Exp>;
  curriculum_id?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  has_children?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identical_topics?: InputMaybe<Identical_Topic_Bool_Exp>;
  parent_id?: InputMaybe<Bigint_Comparison_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  topic?: InputMaybe<Topic_Bool_Exp>;
  topic_label?: InputMaybe<Topic_Label_Bool_Exp>;
  topic_label_id?: InputMaybe<Bigint_Comparison_Exp>;
  topic_unit_title?: InputMaybe<String_Comparison_Exp>;
  topics?: InputMaybe<Topic_Bool_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user_topic_accesses?: InputMaybe<User_Topic_Access_Bool_Exp>;
};

/** unique or primary key constraints on table "topic" */
export enum Topic_Constraint {
  /** unique or primary key constraint on columns "id" */
  TopicPkey = 'topic_pkey'
}

/** input type for incrementing numeric columns in table "topic" */
export type Topic_Inc_Input = {
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['bigint']>;
  topic_label_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "topic" */
export type Topic_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  blocks?: InputMaybe<Block_Arr_Rel_Insert_Input>;
  curriculum?: InputMaybe<Curriculum_Obj_Rel_Insert_Input>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  has_children?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identical_topics?: InputMaybe<Identical_Topic_Arr_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars['bigint']>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Topic_Obj_Rel_Insert_Input>;
  topic_label?: InputMaybe<Topic_Label_Obj_Rel_Insert_Input>;
  topic_label_id?: InputMaybe<Scalars['bigint']>;
  topic_unit_title?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<Topic_Arr_Rel_Insert_Input>;
  url?: InputMaybe<Scalars['String']>;
  user_topic_accesses?: InputMaybe<User_Topic_Access_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "topic_label" */
export type Topic_Label = {
  __typename?: 'topic_label';
  /** An object relationship */
  curriculum?: Maybe<Curriculum>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  id: Scalars['Int'];
  order_id: Scalars['Int'];
  title: Scalars['String'];
  /** An array relationship */
  topics: Array<Topic>;
  /** An aggregate relationship */
  topics_aggregate: Topic_Aggregate;
};


/** columns and relationships of "topic_label" */
export type Topic_LabelTopicsArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


/** columns and relationships of "topic_label" */
export type Topic_LabelTopics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};

/** aggregated selection of "topic_label" */
export type Topic_Label_Aggregate = {
  __typename?: 'topic_label_aggregate';
  aggregate?: Maybe<Topic_Label_Aggregate_Fields>;
  nodes: Array<Topic_Label>;
};

/** aggregate fields of "topic_label" */
export type Topic_Label_Aggregate_Fields = {
  __typename?: 'topic_label_aggregate_fields';
  avg?: Maybe<Topic_Label_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Topic_Label_Max_Fields>;
  min?: Maybe<Topic_Label_Min_Fields>;
  stddev?: Maybe<Topic_Label_Stddev_Fields>;
  stddev_pop?: Maybe<Topic_Label_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Topic_Label_Stddev_Samp_Fields>;
  sum?: Maybe<Topic_Label_Sum_Fields>;
  var_pop?: Maybe<Topic_Label_Var_Pop_Fields>;
  var_samp?: Maybe<Topic_Label_Var_Samp_Fields>;
  variance?: Maybe<Topic_Label_Variance_Fields>;
};


/** aggregate fields of "topic_label" */
export type Topic_Label_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Topic_Label_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "topic_label" */
export type Topic_Label_Aggregate_Order_By = {
  avg?: InputMaybe<Topic_Label_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Topic_Label_Max_Order_By>;
  min?: InputMaybe<Topic_Label_Min_Order_By>;
  stddev?: InputMaybe<Topic_Label_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Topic_Label_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Topic_Label_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Topic_Label_Sum_Order_By>;
  var_pop?: InputMaybe<Topic_Label_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Topic_Label_Var_Samp_Order_By>;
  variance?: InputMaybe<Topic_Label_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "topic_label" */
export type Topic_Label_Arr_Rel_Insert_Input = {
  data: Array<Topic_Label_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Topic_Label_On_Conflict>;
};

/** aggregate avg on columns */
export type Topic_Label_Avg_Fields = {
  __typename?: 'topic_label_avg_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "topic_label" */
export type Topic_Label_Avg_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "topic_label". All fields are combined with a logical 'AND'. */
export type Topic_Label_Bool_Exp = {
  _and?: InputMaybe<Array<Topic_Label_Bool_Exp>>;
  _not?: InputMaybe<Topic_Label_Bool_Exp>;
  _or?: InputMaybe<Array<Topic_Label_Bool_Exp>>;
  curriculum?: InputMaybe<Curriculum_Bool_Exp>;
  curriculum_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  topics?: InputMaybe<Topic_Bool_Exp>;
};

/** unique or primary key constraints on table "topic_label" */
export enum Topic_Label_Constraint {
  /** unique or primary key constraint on columns "id" */
  TopicLabelPkey = 'topic_label_pkey'
}

/** input type for incrementing numeric columns in table "topic_label" */
export type Topic_Label_Inc_Input = {
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "topic_label" */
export type Topic_Label_Insert_Input = {
  curriculum?: InputMaybe<Curriculum_Obj_Rel_Insert_Input>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<Topic_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Topic_Label_Max_Fields = {
  __typename?: 'topic_label_max_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "topic_label" */
export type Topic_Label_Max_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Topic_Label_Min_Fields = {
  __typename?: 'topic_label_min_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "topic_label" */
export type Topic_Label_Min_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "topic_label" */
export type Topic_Label_Mutation_Response = {
  __typename?: 'topic_label_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Topic_Label>;
};

/** input type for inserting object relation for remote table "topic_label" */
export type Topic_Label_Obj_Rel_Insert_Input = {
  data: Topic_Label_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Topic_Label_On_Conflict>;
};

/** on_conflict condition type for table "topic_label" */
export type Topic_Label_On_Conflict = {
  constraint: Topic_Label_Constraint;
  update_columns?: Array<Topic_Label_Update_Column>;
  where?: InputMaybe<Topic_Label_Bool_Exp>;
};

/** Ordering options when selecting data from "topic_label". */
export type Topic_Label_Order_By = {
  curriculum?: InputMaybe<Curriculum_Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  topics_aggregate?: InputMaybe<Topic_Aggregate_Order_By>;
};

/** primary key columns input for table: topic_label */
export type Topic_Label_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "topic_label" */
export enum Topic_Label_Select_Column {
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "topic_label" */
export type Topic_Label_Set_Input = {
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Topic_Label_Stddev_Fields = {
  __typename?: 'topic_label_stddev_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "topic_label" */
export type Topic_Label_Stddev_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Topic_Label_Stddev_Pop_Fields = {
  __typename?: 'topic_label_stddev_pop_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "topic_label" */
export type Topic_Label_Stddev_Pop_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Topic_Label_Stddev_Samp_Fields = {
  __typename?: 'topic_label_stddev_samp_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "topic_label" */
export type Topic_Label_Stddev_Samp_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Topic_Label_Sum_Fields = {
  __typename?: 'topic_label_sum_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "topic_label" */
export type Topic_Label_Sum_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** update columns of table "topic_label" */
export enum Topic_Label_Update_Column {
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Topic_Label_Var_Pop_Fields = {
  __typename?: 'topic_label_var_pop_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "topic_label" */
export type Topic_Label_Var_Pop_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Topic_Label_Var_Samp_Fields = {
  __typename?: 'topic_label_var_samp_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "topic_label" */
export type Topic_Label_Var_Samp_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Topic_Label_Variance_Fields = {
  __typename?: 'topic_label_variance_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "topic_label" */
export type Topic_Label_Variance_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Topic_Max_Fields = {
  __typename?: 'topic_max_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  topic_label_id?: Maybe<Scalars['bigint']>;
  topic_unit_title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "topic" */
export type Topic_Max_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
  topic_unit_title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Topic_Min_Fields = {
  __typename?: 'topic_min_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  topic_label_id?: Maybe<Scalars['bigint']>;
  topic_unit_title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "topic" */
export type Topic_Min_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
  topic_unit_title?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "topic" */
export type Topic_Mutation_Response = {
  __typename?: 'topic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Topic>;
};

/** input type for inserting object relation for remote table "topic" */
export type Topic_Obj_Rel_Insert_Input = {
  data: Topic_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Topic_On_Conflict>;
};

/** on_conflict condition type for table "topic" */
export type Topic_On_Conflict = {
  constraint: Topic_Constraint;
  update_columns?: Array<Topic_Update_Column>;
  where?: InputMaybe<Topic_Bool_Exp>;
};

/** Ordering options when selecting data from "topic". */
export type Topic_Order_By = {
  active?: InputMaybe<Order_By>;
  blocks_aggregate?: InputMaybe<Block_Aggregate_Order_By>;
  curriculum?: InputMaybe<Curriculum_Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  has_children?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identical_topics_aggregate?: InputMaybe<Identical_Topic_Aggregate_Order_By>;
  parent_id?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  topic?: InputMaybe<Topic_Order_By>;
  topic_label?: InputMaybe<Topic_Label_Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
  topic_unit_title?: InputMaybe<Order_By>;
  topics_aggregate?: InputMaybe<Topic_Aggregate_Order_By>;
  url?: InputMaybe<Order_By>;
  user_topic_accesses_aggregate?: InputMaybe<User_Topic_Access_Aggregate_Order_By>;
};

/** primary key columns input for table: topic */
export type Topic_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "topic" */
export enum Topic_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  Description = 'description',
  /** column name */
  HasChildren = 'has_children',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicLabelId = 'topic_label_id',
  /** column name */
  TopicUnitTitle = 'topic_unit_title',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "topic" */
export type Topic_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  description?: InputMaybe<Scalars['String']>;
  has_children?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  topic_label_id?: InputMaybe<Scalars['bigint']>;
  topic_unit_title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Topic_Stddev_Fields = {
  __typename?: 'topic_stddev_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  topic_label_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "topic" */
export type Topic_Stddev_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Topic_Stddev_Pop_Fields = {
  __typename?: 'topic_stddev_pop_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  topic_label_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "topic" */
export type Topic_Stddev_Pop_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Topic_Stddev_Samp_Fields = {
  __typename?: 'topic_stddev_samp_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  topic_label_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "topic" */
export type Topic_Stddev_Samp_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Topic_Sum_Fields = {
  __typename?: 'topic_sum_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['bigint']>;
  topic_label_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "topic" */
export type Topic_Sum_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
};

/** update columns of table "topic" */
export enum Topic_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  Description = 'description',
  /** column name */
  HasChildren = 'has_children',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicLabelId = 'topic_label_id',
  /** column name */
  TopicUnitTitle = 'topic_unit_title',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Topic_Var_Pop_Fields = {
  __typename?: 'topic_var_pop_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  topic_label_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "topic" */
export type Topic_Var_Pop_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Topic_Var_Samp_Fields = {
  __typename?: 'topic_var_samp_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  topic_label_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "topic" */
export type Topic_Var_Samp_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Topic_Variance_Fields = {
  __typename?: 'topic_variance_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_id?: Maybe<Scalars['Float']>;
  topic_label_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "topic" */
export type Topic_Variance_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  topic_label_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_comment" */
export type User_Comment = {
  __typename?: 'user_comment';
  /** An object relationship */
  block_change_request?: Maybe<Block_Change_Request>;
  block_cr_id?: Maybe<Scalars['bigint']>;
  comment: Scalars['String'];
  created_time: Scalars['bigint'];
  /** An object relationship */
  curriculum_change_request?: Maybe<Curriculum_Change_Request>;
  curriculum_cr_id?: Maybe<Scalars['bigint']>;
  id: Scalars['Int'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "user_comment" */
export type User_Comment_Aggregate = {
  __typename?: 'user_comment_aggregate';
  aggregate?: Maybe<User_Comment_Aggregate_Fields>;
  nodes: Array<User_Comment>;
};

/** aggregate fields of "user_comment" */
export type User_Comment_Aggregate_Fields = {
  __typename?: 'user_comment_aggregate_fields';
  avg?: Maybe<User_Comment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Comment_Max_Fields>;
  min?: Maybe<User_Comment_Min_Fields>;
  stddev?: Maybe<User_Comment_Stddev_Fields>;
  stddev_pop?: Maybe<User_Comment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Comment_Stddev_Samp_Fields>;
  sum?: Maybe<User_Comment_Sum_Fields>;
  var_pop?: Maybe<User_Comment_Var_Pop_Fields>;
  var_samp?: Maybe<User_Comment_Var_Samp_Fields>;
  variance?: Maybe<User_Comment_Variance_Fields>;
};


/** aggregate fields of "user_comment" */
export type User_Comment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Comment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_comment" */
export type User_Comment_Aggregate_Order_By = {
  avg?: InputMaybe<User_Comment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Comment_Max_Order_By>;
  min?: InputMaybe<User_Comment_Min_Order_By>;
  stddev?: InputMaybe<User_Comment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Comment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Comment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Comment_Sum_Order_By>;
  var_pop?: InputMaybe<User_Comment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Comment_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Comment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_comment" */
export type User_Comment_Arr_Rel_Insert_Input = {
  data: Array<User_Comment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Comment_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Comment_Avg_Fields = {
  __typename?: 'user_comment_avg_fields';
  block_cr_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  curriculum_cr_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_comment" */
export type User_Comment_Avg_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_comment". All fields are combined with a logical 'AND'. */
export type User_Comment_Bool_Exp = {
  _and?: InputMaybe<Array<User_Comment_Bool_Exp>>;
  _not?: InputMaybe<User_Comment_Bool_Exp>;
  _or?: InputMaybe<Array<User_Comment_Bool_Exp>>;
  block_change_request?: InputMaybe<Block_Change_Request_Bool_Exp>;
  block_cr_id?: InputMaybe<Bigint_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_time?: InputMaybe<Bigint_Comparison_Exp>;
  curriculum_change_request?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
  curriculum_cr_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_comment" */
export enum User_Comment_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCommentPkey = 'user_comment_pkey'
}

/** input type for incrementing numeric columns in table "user_comment" */
export type User_Comment_Inc_Input = {
  block_cr_id?: InputMaybe<Scalars['bigint']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_cr_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_comment" */
export type User_Comment_Insert_Input = {
  block_change_request?: InputMaybe<Block_Change_Request_Obj_Rel_Insert_Input>;
  block_cr_id?: InputMaybe<Scalars['bigint']>;
  comment?: InputMaybe<Scalars['String']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_change_request?: InputMaybe<Curriculum_Change_Request_Obj_Rel_Insert_Input>;
  curriculum_cr_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type User_Comment_Max_Fields = {
  __typename?: 'user_comment_max_fields';
  block_cr_id?: Maybe<Scalars['bigint']>;
  comment?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_cr_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "user_comment" */
export type User_Comment_Max_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Comment_Min_Fields = {
  __typename?: 'user_comment_min_fields';
  block_cr_id?: Maybe<Scalars['bigint']>;
  comment?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_cr_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "user_comment" */
export type User_Comment_Min_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_comment" */
export type User_Comment_Mutation_Response = {
  __typename?: 'user_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Comment>;
};

/** on_conflict condition type for table "user_comment" */
export type User_Comment_On_Conflict = {
  constraint: User_Comment_Constraint;
  update_columns?: Array<User_Comment_Update_Column>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};

/** Ordering options when selecting data from "user_comment". */
export type User_Comment_Order_By = {
  block_change_request?: InputMaybe<Block_Change_Request_Order_By>;
  block_cr_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_change_request?: InputMaybe<Curriculum_Change_Request_Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_comment */
export type User_Comment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_comment" */
export enum User_Comment_Select_Column {
  /** column name */
  BlockCrId = 'block_cr_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  CurriculumCrId = 'curriculum_cr_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_comment" */
export type User_Comment_Set_Input = {
  block_cr_id?: InputMaybe<Scalars['bigint']>;
  comment?: InputMaybe<Scalars['String']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_cr_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type User_Comment_Stddev_Fields = {
  __typename?: 'user_comment_stddev_fields';
  block_cr_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  curriculum_cr_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_comment" */
export type User_Comment_Stddev_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Comment_Stddev_Pop_Fields = {
  __typename?: 'user_comment_stddev_pop_fields';
  block_cr_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  curriculum_cr_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_comment" */
export type User_Comment_Stddev_Pop_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Comment_Stddev_Samp_Fields = {
  __typename?: 'user_comment_stddev_samp_fields';
  block_cr_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  curriculum_cr_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_comment" */
export type User_Comment_Stddev_Samp_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Comment_Sum_Fields = {
  __typename?: 'user_comment_sum_fields';
  block_cr_id?: Maybe<Scalars['bigint']>;
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_cr_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_comment" */
export type User_Comment_Sum_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_comment" */
export enum User_Comment_Update_Column {
  /** column name */
  BlockCrId = 'block_cr_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  CurriculumCrId = 'curriculum_cr_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type User_Comment_Var_Pop_Fields = {
  __typename?: 'user_comment_var_pop_fields';
  block_cr_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  curriculum_cr_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_comment" */
export type User_Comment_Var_Pop_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Comment_Var_Samp_Fields = {
  __typename?: 'user_comment_var_samp_fields';
  block_cr_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  curriculum_cr_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_comment" */
export type User_Comment_Var_Samp_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Comment_Variance_Fields = {
  __typename?: 'user_comment_variance_fields';
  block_cr_id?: Maybe<Scalars['Float']>;
  created_time?: Maybe<Scalars['Float']>;
  curriculum_cr_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_comment" */
export type User_Comment_Variance_Order_By = {
  block_cr_id?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_cr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_curriculum_access" */
export type User_Curriculum_Access = {
  __typename?: 'user_curriculum_access';
  /** An object relationship */
  curriculum?: Maybe<Curriculum>;
  curriculum_id?: Maybe<Scalars['bigint']>;
  has_active: Scalars['Boolean'];
  has_verified: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_curriculum_access_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "user_curriculum_access" */
export type User_Curriculum_Access_Aggregate = {
  __typename?: 'user_curriculum_access_aggregate';
  aggregate?: Maybe<User_Curriculum_Access_Aggregate_Fields>;
  nodes: Array<User_Curriculum_Access>;
};

/** aggregate fields of "user_curriculum_access" */
export type User_Curriculum_Access_Aggregate_Fields = {
  __typename?: 'user_curriculum_access_aggregate_fields';
  avg?: Maybe<User_Curriculum_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Curriculum_Access_Max_Fields>;
  min?: Maybe<User_Curriculum_Access_Min_Fields>;
  stddev?: Maybe<User_Curriculum_Access_Stddev_Fields>;
  stddev_pop?: Maybe<User_Curriculum_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Curriculum_Access_Stddev_Samp_Fields>;
  sum?: Maybe<User_Curriculum_Access_Sum_Fields>;
  var_pop?: Maybe<User_Curriculum_Access_Var_Pop_Fields>;
  var_samp?: Maybe<User_Curriculum_Access_Var_Samp_Fields>;
  variance?: Maybe<User_Curriculum_Access_Variance_Fields>;
};


/** aggregate fields of "user_curriculum_access" */
export type User_Curriculum_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_curriculum_access" */
export type User_Curriculum_Access_Aggregate_Order_By = {
  avg?: InputMaybe<User_Curriculum_Access_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Curriculum_Access_Max_Order_By>;
  min?: InputMaybe<User_Curriculum_Access_Min_Order_By>;
  stddev?: InputMaybe<User_Curriculum_Access_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Curriculum_Access_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Curriculum_Access_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Curriculum_Access_Sum_Order_By>;
  var_pop?: InputMaybe<User_Curriculum_Access_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Curriculum_Access_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Curriculum_Access_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_curriculum_access" */
export type User_Curriculum_Access_Arr_Rel_Insert_Input = {
  data: Array<User_Curriculum_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Curriculum_Access_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Curriculum_Access_Avg_Fields = {
  __typename?: 'user_curriculum_access_avg_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_curriculum_access_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Avg_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_curriculum_access". All fields are combined with a logical 'AND'. */
export type User_Curriculum_Access_Bool_Exp = {
  _and?: InputMaybe<Array<User_Curriculum_Access_Bool_Exp>>;
  _not?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
  _or?: InputMaybe<Array<User_Curriculum_Access_Bool_Exp>>;
  curriculum?: InputMaybe<Curriculum_Bool_Exp>;
  curriculum_id?: InputMaybe<Bigint_Comparison_Exp>;
  has_active?: InputMaybe<Boolean_Comparison_Exp>;
  has_verified?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_curriculum_access_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_curriculum_access" */
export enum User_Curriculum_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCurriculumAccessPkey = 'user_curriculum_access_pkey'
}

/** input type for incrementing numeric columns in table "user_curriculum_access" */
export type User_Curriculum_Access_Inc_Input = {
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user_curriculum_access_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_curriculum_access" */
export type User_Curriculum_Access_Insert_Input = {
  curriculum?: InputMaybe<Curriculum_Obj_Rel_Insert_Input>;
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  has_active?: InputMaybe<Scalars['Boolean']>;
  has_verified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_curriculum_access_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type User_Curriculum_Access_Max_Fields = {
  __typename?: 'user_curriculum_access_max_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_curriculum_access_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Max_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Curriculum_Access_Min_Fields = {
  __typename?: 'user_curriculum_access_min_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_curriculum_access_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Min_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_curriculum_access" */
export type User_Curriculum_Access_Mutation_Response = {
  __typename?: 'user_curriculum_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Curriculum_Access>;
};

/** on_conflict condition type for table "user_curriculum_access" */
export type User_Curriculum_Access_On_Conflict = {
  constraint: User_Curriculum_Access_Constraint;
  update_columns?: Array<User_Curriculum_Access_Update_Column>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "user_curriculum_access". */
export type User_Curriculum_Access_Order_By = {
  curriculum?: InputMaybe<Curriculum_Order_By>;
  curriculum_id?: InputMaybe<Order_By>;
  has_active?: InputMaybe<Order_By>;
  has_verified?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_curriculum_access */
export type User_Curriculum_Access_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_curriculum_access" */
export enum User_Curriculum_Access_Select_Column {
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  HasActive = 'has_active',
  /** column name */
  HasVerified = 'has_verified',
  /** column name */
  Id = 'id',
  /** column name */
  UserCurriculumAccessId = 'user_curriculum_access_id'
}

/** input type for updating data in table "user_curriculum_access" */
export type User_Curriculum_Access_Set_Input = {
  curriculum_id?: InputMaybe<Scalars['bigint']>;
  has_active?: InputMaybe<Scalars['Boolean']>;
  has_verified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  user_curriculum_access_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type User_Curriculum_Access_Stddev_Fields = {
  __typename?: 'user_curriculum_access_stddev_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_curriculum_access_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Stddev_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Curriculum_Access_Stddev_Pop_Fields = {
  __typename?: 'user_curriculum_access_stddev_pop_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_curriculum_access_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Stddev_Pop_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Curriculum_Access_Stddev_Samp_Fields = {
  __typename?: 'user_curriculum_access_stddev_samp_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_curriculum_access_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Stddev_Samp_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Curriculum_Access_Sum_Fields = {
  __typename?: 'user_curriculum_access_sum_fields';
  curriculum_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_curriculum_access_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Sum_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_curriculum_access" */
export enum User_Curriculum_Access_Update_Column {
  /** column name */
  CurriculumId = 'curriculum_id',
  /** column name */
  HasActive = 'has_active',
  /** column name */
  HasVerified = 'has_verified',
  /** column name */
  Id = 'id',
  /** column name */
  UserCurriculumAccessId = 'user_curriculum_access_id'
}

/** aggregate var_pop on columns */
export type User_Curriculum_Access_Var_Pop_Fields = {
  __typename?: 'user_curriculum_access_var_pop_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_curriculum_access_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Var_Pop_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Curriculum_Access_Var_Samp_Fields = {
  __typename?: 'user_curriculum_access_var_samp_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_curriculum_access_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Var_Samp_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Curriculum_Access_Variance_Fields = {
  __typename?: 'user_curriculum_access_variance_fields';
  curriculum_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_curriculum_access_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_curriculum_access" */
export type User_Curriculum_Access_Variance_Order_By = {
  curriculum_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_curriculum_access_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_invitation" */
export type User_Invitation = {
  __typename?: 'user_invitation';
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_ids?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invited_user_email: Scalars['String'];
  topic_ids?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_invitation_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "user_invitation" */
export type User_Invitation_Aggregate = {
  __typename?: 'user_invitation_aggregate';
  aggregate?: Maybe<User_Invitation_Aggregate_Fields>;
  nodes: Array<User_Invitation>;
};

/** aggregate fields of "user_invitation" */
export type User_Invitation_Aggregate_Fields = {
  __typename?: 'user_invitation_aggregate_fields';
  avg?: Maybe<User_Invitation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Invitation_Max_Fields>;
  min?: Maybe<User_Invitation_Min_Fields>;
  stddev?: Maybe<User_Invitation_Stddev_Fields>;
  stddev_pop?: Maybe<User_Invitation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Invitation_Stddev_Samp_Fields>;
  sum?: Maybe<User_Invitation_Sum_Fields>;
  var_pop?: Maybe<User_Invitation_Var_Pop_Fields>;
  var_samp?: Maybe<User_Invitation_Var_Samp_Fields>;
  variance?: Maybe<User_Invitation_Variance_Fields>;
};


/** aggregate fields of "user_invitation" */
export type User_Invitation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Invitation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_invitation" */
export type User_Invitation_Aggregate_Order_By = {
  avg?: InputMaybe<User_Invitation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Invitation_Max_Order_By>;
  min?: InputMaybe<User_Invitation_Min_Order_By>;
  stddev?: InputMaybe<User_Invitation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Invitation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Invitation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Invitation_Sum_Order_By>;
  var_pop?: InputMaybe<User_Invitation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Invitation_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Invitation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_invitation" */
export type User_Invitation_Arr_Rel_Insert_Input = {
  data: Array<User_Invitation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Invitation_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Invitation_Avg_Fields = {
  __typename?: 'user_invitation_avg_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_invitation_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_invitation" */
export type User_Invitation_Avg_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_invitation". All fields are combined with a logical 'AND'. */
export type User_Invitation_Bool_Exp = {
  _and?: InputMaybe<Array<User_Invitation_Bool_Exp>>;
  _not?: InputMaybe<User_Invitation_Bool_Exp>;
  _or?: InputMaybe<Array<User_Invitation_Bool_Exp>>;
  created_time?: InputMaybe<Bigint_Comparison_Exp>;
  curriculum_ids?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invited_user_email?: InputMaybe<String_Comparison_Exp>;
  topic_ids?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_invitation_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_invitation" */
export enum User_Invitation_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserInvitationPkey = 'user_invitation_pkey'
}

/** input type for incrementing numeric columns in table "user_invitation" */
export type User_Invitation_Inc_Input = {
  created_time?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user_invitation_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_invitation" */
export type User_Invitation_Insert_Input = {
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_ids?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  invited_user_email?: InputMaybe<Scalars['String']>;
  topic_ids?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_invitation_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type User_Invitation_Max_Fields = {
  __typename?: 'user_invitation_max_fields';
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_ids?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invited_user_email?: Maybe<Scalars['String']>;
  topic_ids?: Maybe<Scalars['String']>;
  user_invitation_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "user_invitation" */
export type User_Invitation_Max_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_ids?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_user_email?: InputMaybe<Order_By>;
  topic_ids?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Invitation_Min_Fields = {
  __typename?: 'user_invitation_min_fields';
  created_time?: Maybe<Scalars['bigint']>;
  curriculum_ids?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invited_user_email?: Maybe<Scalars['String']>;
  topic_ids?: Maybe<Scalars['String']>;
  user_invitation_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "user_invitation" */
export type User_Invitation_Min_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_ids?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_user_email?: InputMaybe<Order_By>;
  topic_ids?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_invitation" */
export type User_Invitation_Mutation_Response = {
  __typename?: 'user_invitation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Invitation>;
};

/** on_conflict condition type for table "user_invitation" */
export type User_Invitation_On_Conflict = {
  constraint: User_Invitation_Constraint;
  update_columns?: Array<User_Invitation_Update_Column>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};

/** Ordering options when selecting data from "user_invitation". */
export type User_Invitation_Order_By = {
  created_time?: InputMaybe<Order_By>;
  curriculum_ids?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_user_email?: InputMaybe<Order_By>;
  topic_ids?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_invitation */
export type User_Invitation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_invitation" */
export enum User_Invitation_Select_Column {
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  CurriculumIds = 'curriculum_ids',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedUserEmail = 'invited_user_email',
  /** column name */
  TopicIds = 'topic_ids',
  /** column name */
  UserInvitationId = 'user_invitation_id'
}

/** input type for updating data in table "user_invitation" */
export type User_Invitation_Set_Input = {
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_ids?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  invited_user_email?: InputMaybe<Scalars['String']>;
  topic_ids?: InputMaybe<Scalars['String']>;
  user_invitation_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type User_Invitation_Stddev_Fields = {
  __typename?: 'user_invitation_stddev_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_invitation" */
export type User_Invitation_Stddev_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Invitation_Stddev_Pop_Fields = {
  __typename?: 'user_invitation_stddev_pop_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_invitation" */
export type User_Invitation_Stddev_Pop_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Invitation_Stddev_Samp_Fields = {
  __typename?: 'user_invitation_stddev_samp_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_invitation" */
export type User_Invitation_Stddev_Samp_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Invitation_Sum_Fields = {
  __typename?: 'user_invitation_sum_fields';
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_invitation_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_invitation" */
export type User_Invitation_Sum_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_invitation" */
export enum User_Invitation_Update_Column {
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  CurriculumIds = 'curriculum_ids',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedUserEmail = 'invited_user_email',
  /** column name */
  TopicIds = 'topic_ids',
  /** column name */
  UserInvitationId = 'user_invitation_id'
}

/** aggregate var_pop on columns */
export type User_Invitation_Var_Pop_Fields = {
  __typename?: 'user_invitation_var_pop_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_invitation_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_invitation" */
export type User_Invitation_Var_Pop_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Invitation_Var_Samp_Fields = {
  __typename?: 'user_invitation_var_samp_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_invitation_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_invitation" */
export type User_Invitation_Var_Samp_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Invitation_Variance_Fields = {
  __typename?: 'user_invitation_variance_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_invitation_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_invitation" */
export type User_Invitation_Variance_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_invitation_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_topic_access" */
export type User_Topic_Access = {
  __typename?: 'user_topic_access';
  has_active: Scalars['Boolean'];
  has_verified: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An object relationship */
  topic?: Maybe<Topic>;
  topic_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_topic_access_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "user_topic_access" */
export type User_Topic_Access_Aggregate = {
  __typename?: 'user_topic_access_aggregate';
  aggregate?: Maybe<User_Topic_Access_Aggregate_Fields>;
  nodes: Array<User_Topic_Access>;
};

/** aggregate fields of "user_topic_access" */
export type User_Topic_Access_Aggregate_Fields = {
  __typename?: 'user_topic_access_aggregate_fields';
  avg?: Maybe<User_Topic_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Topic_Access_Max_Fields>;
  min?: Maybe<User_Topic_Access_Min_Fields>;
  stddev?: Maybe<User_Topic_Access_Stddev_Fields>;
  stddev_pop?: Maybe<User_Topic_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Topic_Access_Stddev_Samp_Fields>;
  sum?: Maybe<User_Topic_Access_Sum_Fields>;
  var_pop?: Maybe<User_Topic_Access_Var_Pop_Fields>;
  var_samp?: Maybe<User_Topic_Access_Var_Samp_Fields>;
  variance?: Maybe<User_Topic_Access_Variance_Fields>;
};


/** aggregate fields of "user_topic_access" */
export type User_Topic_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_topic_access" */
export type User_Topic_Access_Aggregate_Order_By = {
  avg?: InputMaybe<User_Topic_Access_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Topic_Access_Max_Order_By>;
  min?: InputMaybe<User_Topic_Access_Min_Order_By>;
  stddev?: InputMaybe<User_Topic_Access_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Topic_Access_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Topic_Access_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Topic_Access_Sum_Order_By>;
  var_pop?: InputMaybe<User_Topic_Access_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Topic_Access_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Topic_Access_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_topic_access" */
export type User_Topic_Access_Arr_Rel_Insert_Input = {
  data: Array<User_Topic_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Topic_Access_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Topic_Access_Avg_Fields = {
  __typename?: 'user_topic_access_avg_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_topic_access_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_topic_access" */
export type User_Topic_Access_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_topic_access". All fields are combined with a logical 'AND'. */
export type User_Topic_Access_Bool_Exp = {
  _and?: InputMaybe<Array<User_Topic_Access_Bool_Exp>>;
  _not?: InputMaybe<User_Topic_Access_Bool_Exp>;
  _or?: InputMaybe<Array<User_Topic_Access_Bool_Exp>>;
  has_active?: InputMaybe<Boolean_Comparison_Exp>;
  has_verified?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  topic?: InputMaybe<Topic_Bool_Exp>;
  topic_id?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_topic_access_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_topic_access" */
export enum User_Topic_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserTopicAccessPkey = 'user_topic_access_pkey'
}

/** input type for incrementing numeric columns in table "user_topic_access" */
export type User_Topic_Access_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  user_topic_access_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_topic_access" */
export type User_Topic_Access_Insert_Input = {
  has_active?: InputMaybe<Scalars['Boolean']>;
  has_verified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  topic?: InputMaybe<Topic_Obj_Rel_Insert_Input>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_topic_access_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type User_Topic_Access_Max_Fields = {
  __typename?: 'user_topic_access_max_fields';
  id?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['bigint']>;
  user_topic_access_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "user_topic_access" */
export type User_Topic_Access_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Topic_Access_Min_Fields = {
  __typename?: 'user_topic_access_min_fields';
  id?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['bigint']>;
  user_topic_access_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "user_topic_access" */
export type User_Topic_Access_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_topic_access" */
export type User_Topic_Access_Mutation_Response = {
  __typename?: 'user_topic_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Topic_Access>;
};

/** on_conflict condition type for table "user_topic_access" */
export type User_Topic_Access_On_Conflict = {
  constraint: User_Topic_Access_Constraint;
  update_columns?: Array<User_Topic_Access_Update_Column>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "user_topic_access". */
export type User_Topic_Access_Order_By = {
  has_active?: InputMaybe<Order_By>;
  has_verified?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  topic?: InputMaybe<Topic_Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_topic_access */
export type User_Topic_Access_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_topic_access" */
export enum User_Topic_Access_Select_Column {
  /** column name */
  HasActive = 'has_active',
  /** column name */
  HasVerified = 'has_verified',
  /** column name */
  Id = 'id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserTopicAccessId = 'user_topic_access_id'
}

/** input type for updating data in table "user_topic_access" */
export type User_Topic_Access_Set_Input = {
  has_active?: InputMaybe<Scalars['Boolean']>;
  has_verified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  topic_id?: InputMaybe<Scalars['bigint']>;
  user_topic_access_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type User_Topic_Access_Stddev_Fields = {
  __typename?: 'user_topic_access_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_topic_access_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_topic_access" */
export type User_Topic_Access_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Topic_Access_Stddev_Pop_Fields = {
  __typename?: 'user_topic_access_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_topic_access_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_topic_access" */
export type User_Topic_Access_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Topic_Access_Stddev_Samp_Fields = {
  __typename?: 'user_topic_access_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_topic_access_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_topic_access" */
export type User_Topic_Access_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Topic_Access_Sum_Fields = {
  __typename?: 'user_topic_access_sum_fields';
  id?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['bigint']>;
  user_topic_access_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_topic_access" */
export type User_Topic_Access_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_topic_access" */
export enum User_Topic_Access_Update_Column {
  /** column name */
  HasActive = 'has_active',
  /** column name */
  HasVerified = 'has_verified',
  /** column name */
  Id = 'id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserTopicAccessId = 'user_topic_access_id'
}

/** aggregate var_pop on columns */
export type User_Topic_Access_Var_Pop_Fields = {
  __typename?: 'user_topic_access_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_topic_access_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_topic_access" */
export type User_Topic_Access_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Topic_Access_Var_Samp_Fields = {
  __typename?: 'user_topic_access_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_topic_access_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_topic_access" */
export type User_Topic_Access_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Topic_Access_Variance_Fields = {
  __typename?: 'user_topic_access_variance_fields';
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  user_topic_access_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_topic_access" */
export type User_Topic_Access_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  user_topic_access_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  active: Scalars['Boolean'];
  avatar_url?: Maybe<Scalars['String']>;
  bio_data?: Maybe<Scalars['String']>;
  /** An array relationship */
  block_change_requests: Array<Block_Change_Request>;
  /** An aggregate relationship */
  block_change_requests_aggregate: Block_Change_Request_Aggregate;
  /** An array relationship */
  blocks: Array<Block>;
  /** An aggregate relationship */
  blocks_aggregate: Block_Aggregate;
  created_time?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  curriculum_change_requests: Array<Curriculum_Change_Request>;
  /** An aggregate relationship */
  curriculum_change_requests_aggregate: Curriculum_Change_Request_Aggregate;
  display_name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  institution?: Maybe<Institution>;
  institution_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  institutions: Array<Institution>;
  /** An aggregate relationship */
  institutions_aggregate: Institution_Aggregate;
  last_edited_time?: Maybe<Scalars['bigint']>;
  school?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  submissions: Array<Submission>;
  /** An array relationship */
  submissionsByRequesterUserId: Array<Submission>;
  /** An aggregate relationship */
  submissionsByRequesterUserId_aggregate: Submission_Aggregate;
  /** An aggregate relationship */
  submissions_aggregate: Submission_Aggregate;
  /** An object relationship */
  subscription?: Maybe<Subscription>;
  subscription_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: Tag_Aggregate;
  /** An array relationship */
  user_comments: Array<User_Comment>;
  /** An aggregate relationship */
  user_comments_aggregate: User_Comment_Aggregate;
  /** An array relationship */
  user_curriculum_accesses: Array<User_Curriculum_Access>;
  /** An aggregate relationship */
  user_curriculum_accesses_aggregate: User_Curriculum_Access_Aggregate;
  /** An array relationship */
  user_invitations: Array<User_Invitation>;
  /** An aggregate relationship */
  user_invitations_aggregate: User_Invitation_Aggregate;
  /** An array relationship */
  user_topic_accesses: Array<User_Topic_Access>;
  /** An aggregate relationship */
  user_topic_accesses_aggregate: User_Topic_Access_Aggregate;
  verified: Scalars['Boolean'];
  /** An array relationship */
  votes: Array<Vote>;
  /** An aggregate relationship */
  votes_aggregate: Vote_Aggregate;
};


/** columns and relationships of "users" */
export type UsersBlock_Change_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Change_Request_Order_By>>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersBlock_Change_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Change_Request_Order_By>>;
  where?: InputMaybe<Block_Change_Request_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersBlocksArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersBlocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Block_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Block_Order_By>>;
  where?: InputMaybe<Block_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCurriculum_Change_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Change_Request_Order_By>>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCurriculum_Change_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Curriculum_Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Curriculum_Change_Request_Order_By>>;
  where?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersInstitutionsArgs = {
  distinct_on?: InputMaybe<Array<Institution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Institution_Order_By>>;
  where?: InputMaybe<Institution_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersInstitutions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Institution_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Institution_Order_By>>;
  where?: InputMaybe<Institution_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubmissionsArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubmissionsByRequesterUserIdArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubmissionsByRequesterUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubmissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submission_Order_By>>;
  where?: InputMaybe<Submission_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_CommentsArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Comment_Order_By>>;
  where?: InputMaybe<User_Comment_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Curriculum_AccessesArgs = {
  distinct_on?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Curriculum_Access_Order_By>>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Curriculum_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Curriculum_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Curriculum_Access_Order_By>>;
  where?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Topic_AccessesArgs = {
  distinct_on?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Topic_Access_Order_By>>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Topic_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Topic_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Topic_Access_Order_By>>;
  where?: InputMaybe<User_Topic_Access_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVotesArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vote_Order_By>>;
  where?: InputMaybe<Vote_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  avatar_url?: InputMaybe<String_Comparison_Exp>;
  bio_data?: InputMaybe<String_Comparison_Exp>;
  block_change_requests?: InputMaybe<Block_Change_Request_Bool_Exp>;
  blocks?: InputMaybe<Block_Bool_Exp>;
  created_time?: InputMaybe<Bigint_Comparison_Exp>;
  curriculum_change_requests?: InputMaybe<Curriculum_Change_Request_Bool_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  institution?: InputMaybe<Institution_Bool_Exp>;
  institution_id?: InputMaybe<Bigint_Comparison_Exp>;
  institutions?: InputMaybe<Institution_Bool_Exp>;
  last_edited_time?: InputMaybe<Bigint_Comparison_Exp>;
  school?: InputMaybe<String_Comparison_Exp>;
  stripe_id?: InputMaybe<String_Comparison_Exp>;
  submissions?: InputMaybe<Submission_Bool_Exp>;
  submissionsByRequesterUserId?: InputMaybe<Submission_Bool_Exp>;
  subscription?: InputMaybe<Subscription_Bool_Exp>;
  subscription_id?: InputMaybe<Bigint_Comparison_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  user_comments?: InputMaybe<User_Comment_Bool_Exp>;
  user_curriculum_accesses?: InputMaybe<User_Curriculum_Access_Bool_Exp>;
  user_invitations?: InputMaybe<User_Invitation_Bool_Exp>;
  user_topic_accesses?: InputMaybe<User_Topic_Access_Bool_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
  votes?: InputMaybe<Vote_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  Uk_6dotkott2kjsp8vw4d0m25fb7 = 'uk_6dotkott2kjsp8vw4d0m25fb7',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  created_time?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  institution_id?: InputMaybe<Scalars['bigint']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  subscription_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  avatar_url?: InputMaybe<Scalars['String']>;
  bio_data?: InputMaybe<Scalars['String']>;
  block_change_requests?: InputMaybe<Block_Change_Request_Arr_Rel_Insert_Input>;
  blocks?: InputMaybe<Block_Arr_Rel_Insert_Input>;
  created_time?: InputMaybe<Scalars['bigint']>;
  curriculum_change_requests?: InputMaybe<Curriculum_Change_Request_Arr_Rel_Insert_Input>;
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  institution?: InputMaybe<Institution_Obj_Rel_Insert_Input>;
  institution_id?: InputMaybe<Scalars['bigint']>;
  institutions?: InputMaybe<Institution_Arr_Rel_Insert_Input>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  school?: InputMaybe<Scalars['String']>;
  stripe_id?: InputMaybe<Scalars['String']>;
  submissions?: InputMaybe<Submission_Arr_Rel_Insert_Input>;
  submissionsByRequesterUserId?: InputMaybe<Submission_Arr_Rel_Insert_Input>;
  subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars['bigint']>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  user_comments?: InputMaybe<User_Comment_Arr_Rel_Insert_Input>;
  user_curriculum_accesses?: InputMaybe<User_Curriculum_Access_Arr_Rel_Insert_Input>;
  user_invitations?: InputMaybe<User_Invitation_Arr_Rel_Insert_Input>;
  user_topic_accesses?: InputMaybe<User_Topic_Access_Arr_Rel_Insert_Input>;
  verified?: InputMaybe<Scalars['Boolean']>;
  votes?: InputMaybe<Vote_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  bio_data?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['bigint']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  institution_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  school?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  avatar_url?: InputMaybe<Order_By>;
  bio_data?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  bio_data?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['bigint']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  institution_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  school?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  avatar_url?: InputMaybe<Order_By>;
  bio_data?: InputMaybe<Order_By>;
  created_time?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  active?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  bio_data?: InputMaybe<Order_By>;
  block_change_requests_aggregate?: InputMaybe<Block_Change_Request_Aggregate_Order_By>;
  blocks_aggregate?: InputMaybe<Block_Aggregate_Order_By>;
  created_time?: InputMaybe<Order_By>;
  curriculum_change_requests_aggregate?: InputMaybe<Curriculum_Change_Request_Aggregate_Order_By>;
  display_name?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution?: InputMaybe<Institution_Order_By>;
  institution_id?: InputMaybe<Order_By>;
  institutions_aggregate?: InputMaybe<Institution_Aggregate_Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  submissionsByRequesterUserId_aggregate?: InputMaybe<Submission_Aggregate_Order_By>;
  submissions_aggregate?: InputMaybe<Submission_Aggregate_Order_By>;
  subscription?: InputMaybe<Subscription_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  user_comments_aggregate?: InputMaybe<User_Comment_Aggregate_Order_By>;
  user_curriculum_accesses_aggregate?: InputMaybe<User_Curriculum_Access_Aggregate_Order_By>;
  user_invitations_aggregate?: InputMaybe<User_Invitation_Aggregate_Order_By>;
  user_topic_accesses_aggregate?: InputMaybe<User_Topic_Access_Aggregate_Order_By>;
  verified?: InputMaybe<Order_By>;
  votes_aggregate?: InputMaybe<Vote_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  BioData = 'bio_data',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  LastEditedTime = 'last_edited_time',
  /** column name */
  School = 'school',
  /** column name */
  StripeId = 'stripe_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  avatar_url?: InputMaybe<Scalars['String']>;
  bio_data?: InputMaybe<Scalars['String']>;
  created_time?: InputMaybe<Scalars['bigint']>;
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  institution_id?: InputMaybe<Scalars['bigint']>;
  last_edited_time?: InputMaybe<Scalars['bigint']>;
  school?: InputMaybe<Scalars['String']>;
  stripe_id?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['bigint']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  created_time?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  institution_id?: Maybe<Scalars['bigint']>;
  last_edited_time?: Maybe<Scalars['bigint']>;
  subscription_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  BioData = 'bio_data',
  /** column name */
  CreatedTime = 'created_time',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  LastEditedTime = 'last_edited_time',
  /** column name */
  School = 'school',
  /** column name */
  StripeId = 'stripe_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Verified = 'verified'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  created_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  institution_id?: Maybe<Scalars['Float']>;
  last_edited_time?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  created_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_id?: InputMaybe<Order_By>;
  last_edited_time?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "vote" */
export type Vote = {
  __typename?: 'vote';
  /** An object relationship */
  block?: Maybe<Block>;
  block_id?: Maybe<Scalars['bigint']>;
  id: Scalars['Int'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_comment?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  voting_minus?: Maybe<Scalars['bigint']>;
  voting_plus?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "vote" */
export type Vote_Aggregate = {
  __typename?: 'vote_aggregate';
  aggregate?: Maybe<Vote_Aggregate_Fields>;
  nodes: Array<Vote>;
};

/** aggregate fields of "vote" */
export type Vote_Aggregate_Fields = {
  __typename?: 'vote_aggregate_fields';
  avg?: Maybe<Vote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vote_Max_Fields>;
  min?: Maybe<Vote_Min_Fields>;
  stddev?: Maybe<Vote_Stddev_Fields>;
  stddev_pop?: Maybe<Vote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vote_Stddev_Samp_Fields>;
  sum?: Maybe<Vote_Sum_Fields>;
  var_pop?: Maybe<Vote_Var_Pop_Fields>;
  var_samp?: Maybe<Vote_Var_Samp_Fields>;
  variance?: Maybe<Vote_Variance_Fields>;
};


/** aggregate fields of "vote" */
export type Vote_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vote_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vote" */
export type Vote_Aggregate_Order_By = {
  avg?: InputMaybe<Vote_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vote_Max_Order_By>;
  min?: InputMaybe<Vote_Min_Order_By>;
  stddev?: InputMaybe<Vote_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vote_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vote_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vote_Sum_Order_By>;
  var_pop?: InputMaybe<Vote_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vote_Var_Samp_Order_By>;
  variance?: InputMaybe<Vote_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vote" */
export type Vote_Arr_Rel_Insert_Input = {
  data: Array<Vote_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vote_On_Conflict>;
};

/** aggregate avg on columns */
export type Vote_Avg_Fields = {
  __typename?: 'vote_avg_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  voting_minus?: Maybe<Scalars['Float']>;
  voting_plus?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vote" */
export type Vote_Avg_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vote". All fields are combined with a logical 'AND'. */
export type Vote_Bool_Exp = {
  _and?: InputMaybe<Array<Vote_Bool_Exp>>;
  _not?: InputMaybe<Vote_Bool_Exp>;
  _or?: InputMaybe<Array<Vote_Bool_Exp>>;
  block?: InputMaybe<Block_Bool_Exp>;
  block_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_comment?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  voting_minus?: InputMaybe<Bigint_Comparison_Exp>;
  voting_plus?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "vote" */
export enum Vote_Constraint {
  /** unique or primary key constraint on columns "id" */
  VotePkey = 'vote_pkey'
}

/** input type for incrementing numeric columns in table "vote" */
export type Vote_Inc_Input = {
  block_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  voting_minus?: InputMaybe<Scalars['bigint']>;
  voting_plus?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "vote" */
export type Vote_Insert_Input = {
  block?: InputMaybe<Block_Obj_Rel_Insert_Input>;
  block_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_comment?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  voting_minus?: InputMaybe<Scalars['bigint']>;
  voting_plus?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Vote_Max_Fields = {
  __typename?: 'vote_max_fields';
  block_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_comment?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  voting_minus?: Maybe<Scalars['bigint']>;
  voting_plus?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "vote" */
export type Vote_Max_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_comment?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vote_Min_Fields = {
  __typename?: 'vote_min_fields';
  block_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_comment?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  voting_minus?: Maybe<Scalars['bigint']>;
  voting_plus?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "vote" */
export type Vote_Min_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_comment?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vote" */
export type Vote_Mutation_Response = {
  __typename?: 'vote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vote>;
};

/** on_conflict condition type for table "vote" */
export type Vote_On_Conflict = {
  constraint: Vote_Constraint;
  update_columns?: Array<Vote_Update_Column>;
  where?: InputMaybe<Vote_Bool_Exp>;
};

/** Ordering options when selecting data from "vote". */
export type Vote_Order_By = {
  block?: InputMaybe<Block_Order_By>;
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_comment?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vote */
export type Vote_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "vote" */
export enum Vote_Select_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserComment = 'user_comment',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VotingMinus = 'voting_minus',
  /** column name */
  VotingPlus = 'voting_plus'
}

/** input type for updating data in table "vote" */
export type Vote_Set_Input = {
  block_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  user_comment?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  voting_minus?: InputMaybe<Scalars['bigint']>;
  voting_plus?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Vote_Stddev_Fields = {
  __typename?: 'vote_stddev_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  voting_minus?: Maybe<Scalars['Float']>;
  voting_plus?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vote" */
export type Vote_Stddev_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vote_Stddev_Pop_Fields = {
  __typename?: 'vote_stddev_pop_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  voting_minus?: Maybe<Scalars['Float']>;
  voting_plus?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vote" */
export type Vote_Stddev_Pop_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vote_Stddev_Samp_Fields = {
  __typename?: 'vote_stddev_samp_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  voting_minus?: Maybe<Scalars['Float']>;
  voting_plus?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vote" */
export type Vote_Stddev_Samp_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Vote_Sum_Fields = {
  __typename?: 'vote_sum_fields';
  block_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
  voting_minus?: Maybe<Scalars['bigint']>;
  voting_plus?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "vote" */
export type Vote_Sum_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** update columns of table "vote" */
export enum Vote_Update_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserComment = 'user_comment',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VotingMinus = 'voting_minus',
  /** column name */
  VotingPlus = 'voting_plus'
}

/** aggregate var_pop on columns */
export type Vote_Var_Pop_Fields = {
  __typename?: 'vote_var_pop_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  voting_minus?: Maybe<Scalars['Float']>;
  voting_plus?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vote" */
export type Vote_Var_Pop_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vote_Var_Samp_Fields = {
  __typename?: 'vote_var_samp_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  voting_minus?: Maybe<Scalars['Float']>;
  voting_plus?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vote" */
export type Vote_Var_Samp_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vote_Variance_Fields = {
  __typename?: 'vote_variance_fields';
  block_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  voting_minus?: Maybe<Scalars['Float']>;
  voting_plus?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vote" */
export type Vote_Variance_Order_By = {
  block_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  voting_minus?: InputMaybe<Order_By>;
  voting_plus?: InputMaybe<Order_By>;
};

export type ExportResourceMutationVariables = Exact<{
  format: Scalars['String'];
  userId: Scalars['String'];
  resourceId: Scalars['String'];
}>;


export type ExportResourceMutation = { __typename?: 'mutation_root', exportResource?: { __typename?: 'ExportResourceOutput', url: string } | null };

export type DeleteTagsByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteTagsByIdsMutation = { __typename?: 'mutation_root', delete_tag?: { __typename?: 'tag_mutation_response', returning: Array<{ __typename?: 'tag', id: number }> } | null };

export type DeleteBlocksByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteBlocksByIdsMutation = { __typename?: 'mutation_root', delete_block?: { __typename?: 'block_mutation_response', returning: Array<{ __typename?: 'block', id: number }> } | null };

export type CreateBlocksMutationVariables = Exact<{
  block_spec: Array<Block_Insert_Input> | Block_Insert_Input;
}>;


export type CreateBlocksMutation = { __typename?: 'mutation_root', insert_block?: { __typename?: 'block_mutation_response', returning: Array<{ __typename?: 'block', block: any, active: boolean, archived: boolean, type: string, created_time?: number | null, has_children: boolean, publicly_accessible: boolean, id: number, topic_id?: number | null, url?: string | null, last_edited_time?: number | null, language_id?: number | null, parent_id?: number | null, position?: number | null, votes: Array<{ __typename?: 'vote', id: number, user_id?: number | null, user_comment?: string | null }> }> } | null };

export type CreateBlockMutationVariables = Exact<{
  block_spec: Block_Insert_Input;
}>;


export type CreateBlockMutation = { __typename?: 'mutation_root', insert_block_one?: { __typename?: 'block', block: any, active: boolean, archived: boolean, type: string, created_time?: number | null, has_children: boolean, publicly_accessible: boolean, id: number, topic_id?: number | null, url?: string | null, last_edited_time?: number | null, language_id?: number | null, parent_id?: number | null, position?: number | null, votes: Array<{ __typename?: 'vote', id: number, user_id?: number | null, user_comment?: string | null }> } | null };

export type CreateSubmissionMutationVariables = Exact<{
  submission_spec: Submission_Insert_Input;
}>;


export type CreateSubmissionMutation = { __typename?: 'mutation_root', insert_submission_one?: { __typename?: 'submission', id: number, block_id?: number | null, requester_user_id?: number | null, marker_user_id?: number | null, submission_answers?: any | null, questions_feedback?: any | null, general_comments?: any | null, marks?: any | null, publicly_accessible?: boolean | null, requester_name?: string | null, marker_name?: string | null, title?: string | null } | null };

export type UpdateSubmissionMutationVariables = Exact<{
  submission_spec: Submission_Set_Input;
  submission_id: Scalars['Int'];
}>;


export type UpdateSubmissionMutation = { __typename?: 'mutation_root', update_submission_by_pk?: { __typename?: 'submission', id: number, block_id?: number | null, requester_user_id?: number | null, marker_user_id?: number | null, submission_answers?: any | null, questions_feedback?: any | null, general_comments?: any | null, marks?: any | null, publicly_accessible?: boolean | null, requester_name?: string | null, marker_name?: string | null, title?: string | null } | null };

export type UpdateBlockByIdMutationVariables = Exact<{
  blockInput: Block_Set_Input;
  blockId: Scalars['Int'];
}>;


export type UpdateBlockByIdMutation = { __typename?: 'mutation_root', update_block_by_pk?: { __typename?: 'block', id: number, block: any, active: boolean, archived: boolean, type: string, created_time?: number | null, has_children: boolean, publicly_accessible: boolean, topic_id?: number | null, url?: string | null, last_edited_time?: number | null, language_id?: number | null, parent_id?: number | null, position?: number | null, votes: Array<{ __typename?: 'vote', id: number, user_id?: number | null, user_comment?: string | null }> } | null };

export type InsertTagsMutationVariables = Exact<{
  tags: Array<Tag_Insert_Input> | Tag_Insert_Input;
}>;


export type InsertTagsMutation = { __typename?: 'mutation_root', insert_tag?: { __typename?: 'tag_mutation_response', returning: Array<{ __typename?: 'tag', id: number, topic_id?: number | null, user_interests_id?: number | null, curriculum_id?: number | null, block_id?: number | null, topic?: { __typename?: 'topic', id: number, title: string } | null }> } | null };

export type CreateCurriculumMutationVariables = Exact<{
  curriculum: Curriculum_Insert_Input;
}>;


export type CreateCurriculumMutation = { __typename?: 'mutation_root', insert_curriculum_one?: { __typename?: 'curriculum', id: number, country_id?: number | null, description?: string | null, institution_id?: number | null, name: string, publicly_accessible: boolean, short_title: string } | null };

export type UpdateCurriculumByIdMutationVariables = Exact<{
  curriculumId: Scalars['Int'];
  curriculum: Curriculum_Set_Input;
}>;


export type UpdateCurriculumByIdMutation = { __typename?: 'mutation_root', update_curriculum_by_pk?: { __typename?: 'curriculum', country_id?: number | null, created_time?: number | null, description?: string | null, id: number, institution_id?: number | null, last_edited_time?: number | null, name: string, publicly_accessible: boolean, ref_curriculum_id?: string | null, short_title: string } | null };

export type UpdateTopicByIdMutationVariables = Exact<{
  topicId: Scalars['Int'];
  topic: Topic_Set_Input;
}>;


export type UpdateTopicByIdMutation = { __typename?: 'mutation_root', update_topic_by_pk?: { __typename?: 'topic', id: number, title: string, topic_label_id?: number | null, description?: string | null, parent_id?: number | null, curriculum_id?: number | null, has_children: boolean, topic_unit_title: string, active: boolean } | null };

export type DeleteTopicByIdMutationVariables = Exact<{
  topicId: Scalars['Int'];
}>;


export type DeleteTopicByIdMutation = { __typename?: 'mutation_root', delete_topic?: { __typename?: 'topic_mutation_response', returning: Array<{ __typename?: 'topic', id: number }> } | null };

export type UpdateTopicLabelByIdMutationVariables = Exact<{
  topicLabelId: Scalars['Int'];
  topicLabel: Topic_Label_Set_Input;
}>;


export type UpdateTopicLabelByIdMutation = { __typename?: 'mutation_root', update_topic_label_by_pk?: { __typename?: 'topic_label', id: number, title: string, curriculum_id?: number | null, order_id: number } | null };

export type CreateTopicLabelMutationVariables = Exact<{
  topicLabel: Topic_Label_Insert_Input;
}>;


export type CreateTopicLabelMutation = { __typename?: 'mutation_root', insert_topic_label_one?: { __typename?: 'topic_label', curriculum_id?: number | null, order_id: number, title: string, id: number } | null };

export type CreateTopicMutationVariables = Exact<{
  topic: Topic_Insert_Input;
}>;


export type CreateTopicMutation = { __typename?: 'mutation_root', insert_topic_one?: { __typename?: 'topic', id: number, curriculum_id?: number | null, description?: string | null, has_children: boolean, parent_id?: number | null, title: string, topic_label_id?: number | null, topic_unit_title: string } | null };

export type CreateInstitutionMutationVariables = Exact<{
  institution: Institution_Insert_Input;
}>;


export type CreateInstitutionMutation = { __typename?: 'mutation_root', insert_institution_one?: { __typename?: 'institution', bio?: string | null, id: number, display_name: string, owner?: number | null, ref_id?: string | null, tags: Array<{ __typename?: 'tag', curriculum_id?: number | null, id: number, topic_id?: number | null }> } | null };

export type UpdateInstitutionMutationVariables = Exact<{
  institutionId: Scalars['Int'];
  institution: Institution_Set_Input;
}>;


export type UpdateInstitutionMutation = { __typename?: 'mutation_root', update_institution_by_pk?: { __typename?: 'institution', bio?: string | null, id: number, display_name: string, owner?: number | null, ref_id?: string | null, tags: Array<{ __typename?: 'tag', curriculum_id?: number | null, id: number, topic_id?: number | null }> } | null };

export type CreateUserMutationVariables = Exact<{
  data: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
}>;


export type CreateUserMutation = { __typename?: 'mutation_root', insert_users_one?: { __typename?: 'users', id: number, avatar_url?: string | null, bio_data?: string | null, created_time?: number | null, display_name?: string | null, email: string, institution_id?: number | null, last_edited_time?: number | null, school?: string | null, active: boolean, subscription_id?: number | null } | null };

export type UpdateUserByIdMutationVariables = Exact<{
  userInput: Users_Set_Input;
  userId: Scalars['Int'];
}>;


export type UpdateUserByIdMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: number, verified: boolean, avatar_url?: string | null, bio_data?: string | null, display_name?: string | null, school?: string | null, active: boolean, email: string, stripe_id?: string | null, institution_id?: number | null, institution?: { __typename?: 'institution', display_name: string, id: number } | null } | null };

export type DeleteUserCurriculumTagsMutationVariables = Exact<{
  curriculum_ids: Array<Scalars['bigint']> | Scalars['bigint'];
  user_interests_ids: Array<Scalars['bigint']> | Scalars['bigint'];
}>;


export type DeleteUserCurriculumTagsMutation = { __typename?: 'mutation_root', delete_tag?: { __typename?: 'tag_mutation_response', returning: Array<{ __typename?: 'tag', id: number, topic_id?: number | null, user_interests_id?: number | null, curriculum_id?: number | null, block_id?: number | null }> } | null };

export type DeleteUserTopicTagsMutationVariables = Exact<{
  topic_ids: Array<Scalars['bigint']> | Scalars['bigint'];
  user_interests_ids: Array<Scalars['bigint']> | Scalars['bigint'];
}>;


export type DeleteUserTopicTagsMutation = { __typename?: 'mutation_root', delete_tag?: { __typename?: 'tag_mutation_response', returning: Array<{ __typename?: 'tag', id: number, topic_id?: number | null, user_interests_id?: number | null, curriculum_id?: number | null, block_id?: number | null }> } | null };

export type GetTopTierTopicsQueryVariables = Exact<{
  curriculumIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
}>;


export type GetTopTierTopicsQuery = { __typename?: 'query_root', topic: Array<{ __typename?: 'topic', curriculum_id?: number | null, description?: string | null, url?: string | null, has_children: boolean, active: boolean, id: number, parent_id?: number | null, title: string, topic_label_id?: number | null, topic_unit_title: string, topic_label?: { __typename?: 'topic_label', curriculum_id?: number | null, id: number, title: string, order_id: number } | null }> };

export type GetBlocksWithChildrenQueryVariables = Exact<{
  ids?: InputMaybe<Scalars['_int4']>;
}>;


export type GetBlocksWithChildrenQuery = { __typename?: 'query_root', get_blocks_with_children: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, note?: any | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type GetSubmissionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSubmissionQuery = { __typename?: 'query_root', submission_by_pk?: { __typename?: 'submission', id: number, block_id?: number | null, requester_user_id?: number | null, marker_user_id?: number | null, submission_answers?: any | null, questions_feedback?: any | null, general_comments?: any | null, marks?: any | null, publicly_accessible?: boolean | null, requester_name?: string | null, marker_name?: string | null, title?: string | null } | null };

export type GetTopicsWithChildrenQueryVariables = Exact<{
  ids?: InputMaybe<Scalars['_int4']>;
}>;


export type GetTopicsWithChildrenQuery = { __typename?: 'query_root', get_topics_with_children: Array<{ __typename?: 'topic', curriculum_id?: number | null, description?: string | null, has_children: boolean, active: boolean, id: number, parent_id?: number | null, title: string, topic_label_id?: number | null, topic_unit_title: string, url?: string | null, topic_label?: { __typename?: 'topic_label', id: number, title: string, curriculum_id?: number | null, order_id: number } | null }> };

export type GetExistingTagsForTopicIdQueryVariables = Exact<{
  topicId?: InputMaybe<Scalars['bigint']>;
}>;


export type GetExistingTagsForTopicIdQuery = { __typename?: 'query_root', tag: Array<{ __typename?: 'tag', id: number, block?: { __typename?: 'block', id: number, type: string, block: any } | null }> };

export type GetExistingBlocksForTopicIdQueryVariables = Exact<{
  topicIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
}>;


export type GetExistingBlocksForTopicIdQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, created_by_id?: number | null, publicly_accessible: boolean, verified?: boolean | null }> };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'query_root', subscription: Array<{ __typename?: 'subscription', id: number, plan: string, monthly_price: number, quarterly_price: number, yearly_price: number, description?: string | null }> };

export type SearchUsersQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type SearchUsersQuery = { __typename?: 'query_root', search_users: Array<{ __typename?: 'users', display_name?: string | null, id: number, avatar_url?: string | null }> };

export type GetUsersWithNameQueryVariables = Exact<{
  searchName?: InputMaybe<Scalars['String']>;
}>;


export type GetUsersWithNameQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', display_name?: string | null, id: number, institution_id?: number | null }> };

export type GetBlocksForUserQueryVariables = Exact<{
  userId: Scalars['bigint'];
  blockIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetBlocksForUserQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, block: any, note?: any | null, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type GetChildrenOfParentWithIdQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['bigint']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetChildrenOfParentWithIdQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, note?: any | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type FreeTextSearchOnBlockQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type FreeTextSearchOnBlockQuery = { __typename?: 'query_root', search_blocks: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, note?: any | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type SearchAndFilterBlocksQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  topicIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  blockIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SearchAndFilterBlocksQuery = { __typename?: 'query_root', search_blocks: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, note?: any | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type SearchAndFilterBlocksVerifiedQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  topicIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  blockIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SearchAndFilterBlocksVerifiedQuery = { __typename?: 'query_root', search_blocks: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, note?: any | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type SearchAndFilterWithAuthorBlocksQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  topicIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  blockIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  authorIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SearchAndFilterWithAuthorBlocksQuery = { __typename?: 'query_root', search_blocks: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, note?: any | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type SearchAndFilterWithAuthorBlocksVerifiedQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  topicIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  blockIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  authorIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SearchAndFilterWithAuthorBlocksVerifiedQuery = { __typename?: 'query_root', search_blocks: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, note?: any | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type FilterBlocksQueryVariables = Exact<{
  topiclistone?: InputMaybe<Scalars['_int4']>;
  topiclisttwo?: InputMaybe<Scalars['_int4']>;
  topiclistthree?: InputMaybe<Scalars['_int4']>;
  topiclistfour?: InputMaybe<Scalars['_int4']>;
  topiclistfive?: InputMaybe<Scalars['_int4']>;
  topiclistsix?: InputMaybe<Scalars['_int4']>;
  topiclistseven?: InputMaybe<Scalars['_int4']>;
  topiclisteight?: InputMaybe<Scalars['_int4']>;
  topiclistnine?: InputMaybe<Scalars['_int4']>;
  topiclistten?: InputMaybe<Scalars['_int4']>;
  topiclisteleven?: InputMaybe<Scalars['_int4']>;
  topiclisttwelve?: InputMaybe<Scalars['_int4']>;
  topiclistthirteen?: InputMaybe<Scalars['_int4']>;
  topiclistfourteen?: InputMaybe<Scalars['_int4']>;
  topiclistfifteen?: InputMaybe<Scalars['_int4']>;
  blockIds?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  verified?: InputMaybe<Scalars['bit']>;
  blockauthorids?: InputMaybe<Scalars['_int4']>;
  searchterm?: InputMaybe<Scalars['String']>;
}>;


export type FilterBlocksQuery = { __typename?: 'query_root', filter_blocks: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, note?: any | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type FilterBlocksVerifiedQueryVariables = Exact<{
  topicIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  blockIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type FilterBlocksVerifiedQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, note?: any | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type FilterBlocksWithAuthorQueryVariables = Exact<{
  topicIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  authorIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  blockIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type FilterBlocksWithAuthorQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, note?: any | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type FilterBlocksWithAuthorVerifiedQueryVariables = Exact<{
  topicIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  authorIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  blockIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type FilterBlocksWithAuthorVerifiedQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, note?: any | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type GetUserByEmailQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
}>;


export type GetUserByEmailQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', avatar_url?: string | null, bio_data?: string | null, verified: boolean, school?: string | null, active: boolean, created_time?: number | null, display_name?: string | null, email: string, id: number, institution_id?: number | null, last_edited_time?: number | null, subscription_id?: number | null, stripe_id?: string | null, tags: Array<{ __typename?: 'tag', id: number, curriculum_id?: number | null, topic_id?: number | null, topic?: { __typename?: 'topic', parent_id?: number | null, title: string, topic_unit_title: string, topic_label_id?: number | null, id: number } | null, curriculum?: { __typename?: 'curriculum', name: string, id: number, short_title: string } | null }> }> };

export type GetAllUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllUsersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', active: boolean, display_name?: string | null, email: string, id: number, verified: boolean, subscription_id?: number | null, institution_id?: number | null, subscription?: { __typename?: 'subscription', plan: string } | null, institution?: { __typename?: 'institution', owner?: number | null, display_name: string } | null }> };

export type GetAllInstitutionQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllInstitutionQuery = { __typename?: 'query_root', institution: Array<{ __typename?: 'institution', bio?: string | null, active: boolean, display_name: string, id: number, owner?: number | null }> };

export type ApplicationContextQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationContextQuery = { __typename?: 'query_root', country: Array<{ __typename?: 'country', code: string, id: number, name: string }>, language: Array<{ __typename?: 'language', id: number, name: string, code: string }>, subscription: Array<{ __typename?: 'subscription', id: number, plan: string, quarterly_price: number, yearly_price: number, monthly_price: number, last_edited_time?: number | null, discount: number, description?: string | null, created_time?: number | null }>, institution: Array<{ __typename?: 'institution', id: number, display_name: string, bio?: string | null, owner?: number | null, users: Array<{ __typename?: 'users', active: boolean, display_name?: string | null, email: string, id: number }>, tags: Array<{ __typename?: 'tag', curriculum_id?: number | null, id: number, topic_id?: number | null }> }> };

export type GetTagForBlockQueryVariables = Exact<{
  blockId?: InputMaybe<Scalars['bigint']>;
}>;


export type GetTagForBlockQuery = { __typename?: 'query_root', tag: Array<{ __typename?: 'tag', id: number, topic_id?: number | null, block_id?: number | null }> };

export type GetTopicsAndLabelsForCurriculumsQueryVariables = Exact<{
  curriculumIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
}>;


export type GetTopicsAndLabelsForCurriculumsQuery = { __typename?: 'query_root', topic: Array<{ __typename?: 'topic', curriculum_id?: number | null, description?: string | null, has_children: boolean, active: boolean, url?: string | null, id: number, parent_id?: number | null, title: string, topic_label_id?: number | null, topic_unit_title: string }>, topic_label: Array<{ __typename?: 'topic_label', curriculum_id?: number | null, id: number, title: string, order_id: number }> };

export type GetActiveTopicsAndLabelsForCurriculumsQueryVariables = Exact<{
  curriculumIds?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
}>;


export type GetActiveTopicsAndLabelsForCurriculumsQuery = { __typename?: 'query_root', topic: Array<{ __typename?: 'topic', curriculum_id?: number | null, description?: string | null, has_children: boolean, active: boolean, id: number, parent_id?: number | null, title: string, topic_label_id?: number | null, topic_unit_title: string }>, topic_label: Array<{ __typename?: 'topic_label', curriculum_id?: number | null, id: number, title: string, order_id: number }> };

export type GetActiveCurriculumsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveCurriculumsQuery = { __typename?: 'query_root', curriculum: Array<{ __typename?: 'curriculum', country_id?: number | null, created_time?: number | null, description?: string | null, id: number, institution_id?: number | null, last_edited_time?: number | null, name: string, url?: string | null, publicly_accessible: boolean, ref_curriculum_id?: string | null, short_title: string }> };

export type GetAllCurriculumsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCurriculumsQuery = { __typename?: 'query_root', curriculum: Array<{ __typename?: 'curriculum', country_id?: number | null, created_time?: number | null, description?: string | null, id: number, institution_id?: number | null, last_edited_time?: number | null, name: string, publicly_accessible: boolean, ref_curriculum_id?: string | null, short_title: string }> };

export type GetAuthorOfBlockWithIdQueryVariables = Exact<{
  blockId: Scalars['Int'];
}>;


export type GetAuthorOfBlockWithIdQuery = { __typename?: 'query_root', block_by_pk?: { __typename?: 'block', id: number, created_by_id?: number | null, type: string } | null };

export type GetBlockWithIdQueryVariables = Exact<{
  blockId?: InputMaybe<Scalars['Int']>;
}>;


export type GetBlockWithIdQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, note?: any | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type CheckBlockIsPublicQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CheckBlockIsPublicQuery = { __typename?: 'query_root', block_by_pk?: { __typename?: 'block', id: number, publicly_accessible: boolean, created_by_id?: number | null } | null };

export type GetChildrenOfParentWithIdExcludingAnswersQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['bigint']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetChildrenOfParentWithIdExcludingAnswersQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, note?: any | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };

export type GetChildrenOfParentWithIdAnswersOnlyQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['bigint']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetChildrenOfParentWithIdAnswersOnlyQuery = { __typename?: 'query_root', block: Array<{ __typename?: 'block', id: number, type: string, block: any, active: boolean, language_id?: number | null, last_edited_time?: number | null, parent_id?: number | null, position?: number | null, publicly_accessible: boolean, ref_block_id?: string | null, topic_id?: number | null, url?: string | null, note?: any | null, created_by_id?: number | null, created_time?: number | null, has_children: boolean, tags: Array<{ __typename?: 'tag', id: number, topic?: { __typename?: 'topic', id: number, title: string } | null }> }> };


export const ExportResourceDocument = gql`
    mutation exportResource($format: String!, $userId: String!, $resourceId: String!) {
  exportResource(
    exportResourceInput: {format: $format, resourceId: $resourceId, userId: $userId}
  ) {
    url
  }
}
    `;
export type ExportResourceMutationFn = Apollo.MutationFunction<ExportResourceMutation, ExportResourceMutationVariables>;

/**
 * __useExportResourceMutation__
 *
 * To run a mutation, you first call `useExportResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportResourceMutation, { data, loading, error }] = useExportResourceMutation({
 *   variables: {
 *      format: // value for 'format'
 *      userId: // value for 'userId'
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useExportResourceMutation(baseOptions?: Apollo.MutationHookOptions<ExportResourceMutation, ExportResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportResourceMutation, ExportResourceMutationVariables>(ExportResourceDocument, options);
      }
export type ExportResourceMutationHookResult = ReturnType<typeof useExportResourceMutation>;
export type ExportResourceMutationResult = Apollo.MutationResult<ExportResourceMutation>;
export type ExportResourceMutationOptions = Apollo.BaseMutationOptions<ExportResourceMutation, ExportResourceMutationVariables>;
export const DeleteTagsByIdsDocument = gql`
    mutation deleteTagsByIds($ids: [Int!]) {
  delete_tag(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteTagsByIdsMutationFn = Apollo.MutationFunction<DeleteTagsByIdsMutation, DeleteTagsByIdsMutationVariables>;

/**
 * __useDeleteTagsByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteTagsByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagsByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagsByIdsMutation, { data, loading, error }] = useDeleteTagsByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTagsByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagsByIdsMutation, DeleteTagsByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagsByIdsMutation, DeleteTagsByIdsMutationVariables>(DeleteTagsByIdsDocument, options);
      }
export type DeleteTagsByIdsMutationHookResult = ReturnType<typeof useDeleteTagsByIdsMutation>;
export type DeleteTagsByIdsMutationResult = Apollo.MutationResult<DeleteTagsByIdsMutation>;
export type DeleteTagsByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteTagsByIdsMutation, DeleteTagsByIdsMutationVariables>;
export const DeleteBlocksByIdsDocument = gql`
    mutation deleteBlocksByIds($ids: [Int!]) {
  delete_block(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteBlocksByIdsMutationFn = Apollo.MutationFunction<DeleteBlocksByIdsMutation, DeleteBlocksByIdsMutationVariables>;

/**
 * __useDeleteBlocksByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteBlocksByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlocksByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlocksByIdsMutation, { data, loading, error }] = useDeleteBlocksByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteBlocksByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlocksByIdsMutation, DeleteBlocksByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlocksByIdsMutation, DeleteBlocksByIdsMutationVariables>(DeleteBlocksByIdsDocument, options);
      }
export type DeleteBlocksByIdsMutationHookResult = ReturnType<typeof useDeleteBlocksByIdsMutation>;
export type DeleteBlocksByIdsMutationResult = Apollo.MutationResult<DeleteBlocksByIdsMutation>;
export type DeleteBlocksByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteBlocksByIdsMutation, DeleteBlocksByIdsMutationVariables>;
export const CreateBlocksDocument = gql`
    mutation CreateBlocks($block_spec: [block_insert_input!]!) {
  insert_block(objects: $block_spec) {
    returning {
      block
      active
      archived
      type
      created_time
      has_children
      publicly_accessible
      id
      topic_id
      url
      votes {
        id
        user_id
        user_comment
      }
      last_edited_time
      language_id
      parent_id
      position
    }
  }
}
    `;
export type CreateBlocksMutationFn = Apollo.MutationFunction<CreateBlocksMutation, CreateBlocksMutationVariables>;

/**
 * __useCreateBlocksMutation__
 *
 * To run a mutation, you first call `useCreateBlocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlocksMutation, { data, loading, error }] = useCreateBlocksMutation({
 *   variables: {
 *      block_spec: // value for 'block_spec'
 *   },
 * });
 */
export function useCreateBlocksMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlocksMutation, CreateBlocksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlocksMutation, CreateBlocksMutationVariables>(CreateBlocksDocument, options);
      }
export type CreateBlocksMutationHookResult = ReturnType<typeof useCreateBlocksMutation>;
export type CreateBlocksMutationResult = Apollo.MutationResult<CreateBlocksMutation>;
export type CreateBlocksMutationOptions = Apollo.BaseMutationOptions<CreateBlocksMutation, CreateBlocksMutationVariables>;
export const CreateBlockDocument = gql`
    mutation CreateBlock($block_spec: block_insert_input!) {
  insert_block_one(object: $block_spec) {
    block
    active
    archived
    type
    created_time
    has_children
    publicly_accessible
    id
    topic_id
    url
    votes {
      id
      user_id
      user_comment
    }
    last_edited_time
    language_id
    parent_id
    position
  }
}
    `;
export type CreateBlockMutationFn = Apollo.MutationFunction<CreateBlockMutation, CreateBlockMutationVariables>;

/**
 * __useCreateBlockMutation__
 *
 * To run a mutation, you first call `useCreateBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockMutation, { data, loading, error }] = useCreateBlockMutation({
 *   variables: {
 *      block_spec: // value for 'block_spec'
 *   },
 * });
 */
export function useCreateBlockMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlockMutation, CreateBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlockMutation, CreateBlockMutationVariables>(CreateBlockDocument, options);
      }
export type CreateBlockMutationHookResult = ReturnType<typeof useCreateBlockMutation>;
export type CreateBlockMutationResult = Apollo.MutationResult<CreateBlockMutation>;
export type CreateBlockMutationOptions = Apollo.BaseMutationOptions<CreateBlockMutation, CreateBlockMutationVariables>;
export const CreateSubmissionDocument = gql`
    mutation createSubmission($submission_spec: submission_insert_input!) {
  insert_submission_one(object: $submission_spec) {
    id
    block_id
    requester_user_id
    marker_user_id
    submission_answers
    questions_feedback
    general_comments
    marks
    publicly_accessible
    requester_name
    marker_name
    title
  }
}
    `;
export type CreateSubmissionMutationFn = Apollo.MutationFunction<CreateSubmissionMutation, CreateSubmissionMutationVariables>;

/**
 * __useCreateSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionMutation, { data, loading, error }] = useCreateSubmissionMutation({
 *   variables: {
 *      submission_spec: // value for 'submission_spec'
 *   },
 * });
 */
export function useCreateSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubmissionMutation, CreateSubmissionMutationVariables>(CreateSubmissionDocument, options);
      }
export type CreateSubmissionMutationHookResult = ReturnType<typeof useCreateSubmissionMutation>;
export type CreateSubmissionMutationResult = Apollo.MutationResult<CreateSubmissionMutation>;
export type CreateSubmissionMutationOptions = Apollo.BaseMutationOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>;
export const UpdateSubmissionDocument = gql`
    mutation updateSubmission($submission_spec: submission_set_input!, $submission_id: Int!) {
  update_submission_by_pk(
    _set: $submission_spec
    pk_columns: {id: $submission_id}
  ) {
    id
    block_id
    requester_user_id
    marker_user_id
    submission_answers
    questions_feedback
    general_comments
    marks
    publicly_accessible
    requester_name
    marker_name
    title
  }
}
    `;
export type UpdateSubmissionMutationFn = Apollo.MutationFunction<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>;

/**
 * __useUpdateSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionMutation, { data, loading, error }] = useUpdateSubmissionMutation({
 *   variables: {
 *      submission_spec: // value for 'submission_spec'
 *      submission_id: // value for 'submission_id'
 *   },
 * });
 */
export function useUpdateSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>(UpdateSubmissionDocument, options);
      }
export type UpdateSubmissionMutationHookResult = ReturnType<typeof useUpdateSubmissionMutation>;
export type UpdateSubmissionMutationResult = Apollo.MutationResult<UpdateSubmissionMutation>;
export type UpdateSubmissionMutationOptions = Apollo.BaseMutationOptions<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>;
export const UpdateBlockByIdDocument = gql`
    mutation UpdateBlockById($blockInput: block_set_input!, $blockId: Int!) {
  update_block_by_pk(_set: $blockInput, pk_columns: {id: $blockId}) {
    id
    block
    active
    archived
    type
    created_time
    has_children
    publicly_accessible
    id
    topic_id
    url
    votes {
      id
      user_id
      user_comment
    }
    last_edited_time
    language_id
    parent_id
    position
  }
}
    `;
export type UpdateBlockByIdMutationFn = Apollo.MutationFunction<UpdateBlockByIdMutation, UpdateBlockByIdMutationVariables>;

/**
 * __useUpdateBlockByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBlockByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlockByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlockByIdMutation, { data, loading, error }] = useUpdateBlockByIdMutation({
 *   variables: {
 *      blockInput: // value for 'blockInput'
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useUpdateBlockByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlockByIdMutation, UpdateBlockByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlockByIdMutation, UpdateBlockByIdMutationVariables>(UpdateBlockByIdDocument, options);
      }
export type UpdateBlockByIdMutationHookResult = ReturnType<typeof useUpdateBlockByIdMutation>;
export type UpdateBlockByIdMutationResult = Apollo.MutationResult<UpdateBlockByIdMutation>;
export type UpdateBlockByIdMutationOptions = Apollo.BaseMutationOptions<UpdateBlockByIdMutation, UpdateBlockByIdMutationVariables>;
export const InsertTagsDocument = gql`
    mutation InsertTags($tags: [tag_insert_input!]!) {
  insert_tag(objects: $tags) {
    returning {
      id
      topic_id
      user_interests_id
      curriculum_id
      block_id
      topic {
        id
        title
      }
    }
  }
}
    `;
export type InsertTagsMutationFn = Apollo.MutationFunction<InsertTagsMutation, InsertTagsMutationVariables>;

/**
 * __useInsertTagsMutation__
 *
 * To run a mutation, you first call `useInsertTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTagsMutation, { data, loading, error }] = useInsertTagsMutation({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useInsertTagsMutation(baseOptions?: Apollo.MutationHookOptions<InsertTagsMutation, InsertTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTagsMutation, InsertTagsMutationVariables>(InsertTagsDocument, options);
      }
export type InsertTagsMutationHookResult = ReturnType<typeof useInsertTagsMutation>;
export type InsertTagsMutationResult = Apollo.MutationResult<InsertTagsMutation>;
export type InsertTagsMutationOptions = Apollo.BaseMutationOptions<InsertTagsMutation, InsertTagsMutationVariables>;
export const CreateCurriculumDocument = gql`
    mutation createCurriculum($curriculum: curriculum_insert_input!) {
  insert_curriculum_one(object: $curriculum) {
    id
    country_id
    description
    institution_id
    name
    publicly_accessible
    short_title
  }
}
    `;
export type CreateCurriculumMutationFn = Apollo.MutationFunction<CreateCurriculumMutation, CreateCurriculumMutationVariables>;

/**
 * __useCreateCurriculumMutation__
 *
 * To run a mutation, you first call `useCreateCurriculumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurriculumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurriculumMutation, { data, loading, error }] = useCreateCurriculumMutation({
 *   variables: {
 *      curriculum: // value for 'curriculum'
 *   },
 * });
 */
export function useCreateCurriculumMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurriculumMutation, CreateCurriculumMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurriculumMutation, CreateCurriculumMutationVariables>(CreateCurriculumDocument, options);
      }
export type CreateCurriculumMutationHookResult = ReturnType<typeof useCreateCurriculumMutation>;
export type CreateCurriculumMutationResult = Apollo.MutationResult<CreateCurriculumMutation>;
export type CreateCurriculumMutationOptions = Apollo.BaseMutationOptions<CreateCurriculumMutation, CreateCurriculumMutationVariables>;
export const UpdateCurriculumByIdDocument = gql`
    mutation updateCurriculumById($curriculumId: Int!, $curriculum: curriculum_set_input!) {
  update_curriculum_by_pk(pk_columns: {id: $curriculumId}, _set: $curriculum) {
    country_id
    created_time
    description
    id
    institution_id
    last_edited_time
    name
    publicly_accessible
    ref_curriculum_id
    short_title
  }
}
    `;
export type UpdateCurriculumByIdMutationFn = Apollo.MutationFunction<UpdateCurriculumByIdMutation, UpdateCurriculumByIdMutationVariables>;

/**
 * __useUpdateCurriculumByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCurriculumByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurriculumByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurriculumByIdMutation, { data, loading, error }] = useUpdateCurriculumByIdMutation({
 *   variables: {
 *      curriculumId: // value for 'curriculumId'
 *      curriculum: // value for 'curriculum'
 *   },
 * });
 */
export function useUpdateCurriculumByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurriculumByIdMutation, UpdateCurriculumByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurriculumByIdMutation, UpdateCurriculumByIdMutationVariables>(UpdateCurriculumByIdDocument, options);
      }
export type UpdateCurriculumByIdMutationHookResult = ReturnType<typeof useUpdateCurriculumByIdMutation>;
export type UpdateCurriculumByIdMutationResult = Apollo.MutationResult<UpdateCurriculumByIdMutation>;
export type UpdateCurriculumByIdMutationOptions = Apollo.BaseMutationOptions<UpdateCurriculumByIdMutation, UpdateCurriculumByIdMutationVariables>;
export const UpdateTopicByIdDocument = gql`
    mutation updateTopicById($topicId: Int!, $topic: topic_set_input!) {
  update_topic_by_pk(pk_columns: {id: $topicId}, _set: $topic) {
    id
    title
    topic_label_id
    description
    parent_id
    curriculum_id
    description
    has_children
    topic_unit_title
    active
  }
}
    `;
export type UpdateTopicByIdMutationFn = Apollo.MutationFunction<UpdateTopicByIdMutation, UpdateTopicByIdMutationVariables>;

/**
 * __useUpdateTopicByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTopicByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicByIdMutation, { data, loading, error }] = useUpdateTopicByIdMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useUpdateTopicByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicByIdMutation, UpdateTopicByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicByIdMutation, UpdateTopicByIdMutationVariables>(UpdateTopicByIdDocument, options);
      }
export type UpdateTopicByIdMutationHookResult = ReturnType<typeof useUpdateTopicByIdMutation>;
export type UpdateTopicByIdMutationResult = Apollo.MutationResult<UpdateTopicByIdMutation>;
export type UpdateTopicByIdMutationOptions = Apollo.BaseMutationOptions<UpdateTopicByIdMutation, UpdateTopicByIdMutationVariables>;
export const DeleteTopicByIdDocument = gql`
    mutation deleteTopicById($topicId: Int!) {
  delete_topic(where: {id: {_eq: $topicId}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteTopicByIdMutationFn = Apollo.MutationFunction<DeleteTopicByIdMutation, DeleteTopicByIdMutationVariables>;

/**
 * __useDeleteTopicByIdMutation__
 *
 * To run a mutation, you first call `useDeleteTopicByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicByIdMutation, { data, loading, error }] = useDeleteTopicByIdMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useDeleteTopicByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicByIdMutation, DeleteTopicByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicByIdMutation, DeleteTopicByIdMutationVariables>(DeleteTopicByIdDocument, options);
      }
export type DeleteTopicByIdMutationHookResult = ReturnType<typeof useDeleteTopicByIdMutation>;
export type DeleteTopicByIdMutationResult = Apollo.MutationResult<DeleteTopicByIdMutation>;
export type DeleteTopicByIdMutationOptions = Apollo.BaseMutationOptions<DeleteTopicByIdMutation, DeleteTopicByIdMutationVariables>;
export const UpdateTopicLabelByIdDocument = gql`
    mutation updateTopicLabelById($topicLabelId: Int!, $topicLabel: topic_label_set_input!) {
  update_topic_label_by_pk(pk_columns: {id: $topicLabelId}, _set: $topicLabel) {
    id
    title
    curriculum_id
    order_id
  }
}
    `;
export type UpdateTopicLabelByIdMutationFn = Apollo.MutationFunction<UpdateTopicLabelByIdMutation, UpdateTopicLabelByIdMutationVariables>;

/**
 * __useUpdateTopicLabelByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTopicLabelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicLabelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicLabelByIdMutation, { data, loading, error }] = useUpdateTopicLabelByIdMutation({
 *   variables: {
 *      topicLabelId: // value for 'topicLabelId'
 *      topicLabel: // value for 'topicLabel'
 *   },
 * });
 */
export function useUpdateTopicLabelByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicLabelByIdMutation, UpdateTopicLabelByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicLabelByIdMutation, UpdateTopicLabelByIdMutationVariables>(UpdateTopicLabelByIdDocument, options);
      }
export type UpdateTopicLabelByIdMutationHookResult = ReturnType<typeof useUpdateTopicLabelByIdMutation>;
export type UpdateTopicLabelByIdMutationResult = Apollo.MutationResult<UpdateTopicLabelByIdMutation>;
export type UpdateTopicLabelByIdMutationOptions = Apollo.BaseMutationOptions<UpdateTopicLabelByIdMutation, UpdateTopicLabelByIdMutationVariables>;
export const CreateTopicLabelDocument = gql`
    mutation createTopicLabel($topicLabel: topic_label_insert_input!) {
  insert_topic_label_one(object: $topicLabel) {
    curriculum_id
    order_id
    title
    id
  }
}
    `;
export type CreateTopicLabelMutationFn = Apollo.MutationFunction<CreateTopicLabelMutation, CreateTopicLabelMutationVariables>;

/**
 * __useCreateTopicLabelMutation__
 *
 * To run a mutation, you first call `useCreateTopicLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicLabelMutation, { data, loading, error }] = useCreateTopicLabelMutation({
 *   variables: {
 *      topicLabel: // value for 'topicLabel'
 *   },
 * });
 */
export function useCreateTopicLabelMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopicLabelMutation, CreateTopicLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTopicLabelMutation, CreateTopicLabelMutationVariables>(CreateTopicLabelDocument, options);
      }
export type CreateTopicLabelMutationHookResult = ReturnType<typeof useCreateTopicLabelMutation>;
export type CreateTopicLabelMutationResult = Apollo.MutationResult<CreateTopicLabelMutation>;
export type CreateTopicLabelMutationOptions = Apollo.BaseMutationOptions<CreateTopicLabelMutation, CreateTopicLabelMutationVariables>;
export const CreateTopicDocument = gql`
    mutation createTopic($topic: topic_insert_input!) {
  insert_topic_one(object: $topic) {
    id
    curriculum_id
    description
    has_children
    id
    parent_id
    title
    topic_label_id
    topic_unit_title
  }
}
    `;
export type CreateTopicMutationFn = Apollo.MutationFunction<CreateTopicMutation, CreateTopicMutationVariables>;

/**
 * __useCreateTopicMutation__
 *
 * To run a mutation, you first call `useCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicMutation, { data, loading, error }] = useCreateTopicMutation({
 *   variables: {
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useCreateTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopicMutation, CreateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTopicMutation, CreateTopicMutationVariables>(CreateTopicDocument, options);
      }
export type CreateTopicMutationHookResult = ReturnType<typeof useCreateTopicMutation>;
export type CreateTopicMutationResult = Apollo.MutationResult<CreateTopicMutation>;
export type CreateTopicMutationOptions = Apollo.BaseMutationOptions<CreateTopicMutation, CreateTopicMutationVariables>;
export const CreateInstitutionDocument = gql`
    mutation CreateInstitution($institution: institution_insert_input!) {
  insert_institution_one(object: $institution) {
    bio
    id
    display_name
    owner
    ref_id
    tags {
      curriculum_id
      id
      topic_id
    }
  }
}
    `;
export type CreateInstitutionMutationFn = Apollo.MutationFunction<CreateInstitutionMutation, CreateInstitutionMutationVariables>;

/**
 * __useCreateInstitutionMutation__
 *
 * To run a mutation, you first call `useCreateInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstitutionMutation, { data, loading, error }] = useCreateInstitutionMutation({
 *   variables: {
 *      institution: // value for 'institution'
 *   },
 * });
 */
export function useCreateInstitutionMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstitutionMutation, CreateInstitutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstitutionMutation, CreateInstitutionMutationVariables>(CreateInstitutionDocument, options);
      }
export type CreateInstitutionMutationHookResult = ReturnType<typeof useCreateInstitutionMutation>;
export type CreateInstitutionMutationResult = Apollo.MutationResult<CreateInstitutionMutation>;
export type CreateInstitutionMutationOptions = Apollo.BaseMutationOptions<CreateInstitutionMutation, CreateInstitutionMutationVariables>;
export const UpdateInstitutionDocument = gql`
    mutation UpdateInstitution($institutionId: Int!, $institution: institution_set_input!) {
  update_institution_by_pk(pk_columns: {id: $institutionId}, _set: $institution) {
    bio
    id
    display_name
    owner
    ref_id
    tags {
      curriculum_id
      id
      topic_id
    }
  }
}
    `;
export type UpdateInstitutionMutationFn = Apollo.MutationFunction<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>;

/**
 * __useUpdateInstitutionMutation__
 *
 * To run a mutation, you first call `useUpdateInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstitutionMutation, { data, loading, error }] = useUpdateInstitutionMutation({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *      institution: // value for 'institution'
 *   },
 * });
 */
export function useUpdateInstitutionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>(UpdateInstitutionDocument, options);
      }
export type UpdateInstitutionMutationHookResult = ReturnType<typeof useUpdateInstitutionMutation>;
export type UpdateInstitutionMutationResult = Apollo.MutationResult<UpdateInstitutionMutation>;
export type UpdateInstitutionMutationOptions = Apollo.BaseMutationOptions<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($data: users_insert_input!, $on_conflict: users_on_conflict) {
  insert_users_one(object: $data, on_conflict: $on_conflict) {
    id
    avatar_url
    bio_data
    created_time
    display_name
    email
    institution_id
    last_edited_time
    school
    active
    subscription_id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserByIdDocument = gql`
    mutation UpdateUserById($userInput: users_set_input!, $userId: Int!) {
  update_users_by_pk(pk_columns: {id: $userId}, _set: $userInput) {
    id
    verified
    avatar_url
    bio_data
    display_name
    school
    active
    email
    verified
    stripe_id
    institution_id
    institution {
      display_name
      id
    }
  }
}
    `;
export type UpdateUserByIdMutationFn = Apollo.MutationFunction<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;

/**
 * __useUpdateUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByIdMutation, { data, loading, error }] = useUpdateUserByIdMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>(UpdateUserByIdDocument, options);
      }
export type UpdateUserByIdMutationHookResult = ReturnType<typeof useUpdateUserByIdMutation>;
export type UpdateUserByIdMutationResult = Apollo.MutationResult<UpdateUserByIdMutation>;
export type UpdateUserByIdMutationOptions = Apollo.BaseMutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;
export const DeleteUserCurriculumTagsDocument = gql`
    mutation DeleteUserCurriculumTags($curriculum_ids: [bigint!]!, $user_interests_ids: [bigint!]!) {
  delete_tag(
    where: {curriculum_id: {_in: $curriculum_ids}, user_interests_id: {_in: $user_interests_ids}}
  ) {
    returning {
      id
      topic_id
      user_interests_id
      curriculum_id
      block_id
    }
  }
}
    `;
export type DeleteUserCurriculumTagsMutationFn = Apollo.MutationFunction<DeleteUserCurriculumTagsMutation, DeleteUserCurriculumTagsMutationVariables>;

/**
 * __useDeleteUserCurriculumTagsMutation__
 *
 * To run a mutation, you first call `useDeleteUserCurriculumTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCurriculumTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCurriculumTagsMutation, { data, loading, error }] = useDeleteUserCurriculumTagsMutation({
 *   variables: {
 *      curriculum_ids: // value for 'curriculum_ids'
 *      user_interests_ids: // value for 'user_interests_ids'
 *   },
 * });
 */
export function useDeleteUserCurriculumTagsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserCurriculumTagsMutation, DeleteUserCurriculumTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserCurriculumTagsMutation, DeleteUserCurriculumTagsMutationVariables>(DeleteUserCurriculumTagsDocument, options);
      }
export type DeleteUserCurriculumTagsMutationHookResult = ReturnType<typeof useDeleteUserCurriculumTagsMutation>;
export type DeleteUserCurriculumTagsMutationResult = Apollo.MutationResult<DeleteUserCurriculumTagsMutation>;
export type DeleteUserCurriculumTagsMutationOptions = Apollo.BaseMutationOptions<DeleteUserCurriculumTagsMutation, DeleteUserCurriculumTagsMutationVariables>;
export const DeleteUserTopicTagsDocument = gql`
    mutation DeleteUserTopicTags($topic_ids: [bigint!]!, $user_interests_ids: [bigint!]!) {
  delete_tag(
    where: {topic_id: {_in: $topic_ids}, user_interests_id: {_in: $user_interests_ids}}
  ) {
    returning {
      id
      topic_id
      user_interests_id
      curriculum_id
      block_id
    }
  }
}
    `;
export type DeleteUserTopicTagsMutationFn = Apollo.MutationFunction<DeleteUserTopicTagsMutation, DeleteUserTopicTagsMutationVariables>;

/**
 * __useDeleteUserTopicTagsMutation__
 *
 * To run a mutation, you first call `useDeleteUserTopicTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserTopicTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserTopicTagsMutation, { data, loading, error }] = useDeleteUserTopicTagsMutation({
 *   variables: {
 *      topic_ids: // value for 'topic_ids'
 *      user_interests_ids: // value for 'user_interests_ids'
 *   },
 * });
 */
export function useDeleteUserTopicTagsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserTopicTagsMutation, DeleteUserTopicTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserTopicTagsMutation, DeleteUserTopicTagsMutationVariables>(DeleteUserTopicTagsDocument, options);
      }
export type DeleteUserTopicTagsMutationHookResult = ReturnType<typeof useDeleteUserTopicTagsMutation>;
export type DeleteUserTopicTagsMutationResult = Apollo.MutationResult<DeleteUserTopicTagsMutation>;
export type DeleteUserTopicTagsMutationOptions = Apollo.BaseMutationOptions<DeleteUserTopicTagsMutation, DeleteUserTopicTagsMutationVariables>;
export const GetTopTierTopicsDocument = gql`
    query getTopTierTopics($curriculumIds: [bigint!]) {
  topic(
    where: {curriculum_id: {_in: $curriculumIds}, active: {_eq: true}, topic_label: {order_id: {_eq: 1}}}
  ) {
    curriculum_id
    description
    url
    has_children
    active
    id
    parent_id
    title
    topic_label_id
    topic_unit_title
    topic_label {
      curriculum_id
      id
      title
      order_id
    }
  }
}
    `;

/**
 * __useGetTopTierTopicsQuery__
 *
 * To run a query within a React component, call `useGetTopTierTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopTierTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopTierTopicsQuery({
 *   variables: {
 *      curriculumIds: // value for 'curriculumIds'
 *   },
 * });
 */
export function useGetTopTierTopicsQuery(baseOptions?: Apollo.QueryHookOptions<GetTopTierTopicsQuery, GetTopTierTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopTierTopicsQuery, GetTopTierTopicsQueryVariables>(GetTopTierTopicsDocument, options);
      }
export function useGetTopTierTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopTierTopicsQuery, GetTopTierTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopTierTopicsQuery, GetTopTierTopicsQueryVariables>(GetTopTierTopicsDocument, options);
        }
export type GetTopTierTopicsQueryHookResult = ReturnType<typeof useGetTopTierTopicsQuery>;
export type GetTopTierTopicsLazyQueryHookResult = ReturnType<typeof useGetTopTierTopicsLazyQuery>;
export type GetTopTierTopicsQueryResult = Apollo.QueryResult<GetTopTierTopicsQuery, GetTopTierTopicsQueryVariables>;
export const GetBlocksWithChildrenDocument = gql`
    query getBlocksWithChildren($ids: _int4) {
  get_blocks_with_children(args: {ids: $ids}) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    note
    has_children
  }
}
    `;

/**
 * __useGetBlocksWithChildrenQuery__
 *
 * To run a query within a React component, call `useGetBlocksWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlocksWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlocksWithChildrenQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetBlocksWithChildrenQuery(baseOptions?: Apollo.QueryHookOptions<GetBlocksWithChildrenQuery, GetBlocksWithChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlocksWithChildrenQuery, GetBlocksWithChildrenQueryVariables>(GetBlocksWithChildrenDocument, options);
      }
export function useGetBlocksWithChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlocksWithChildrenQuery, GetBlocksWithChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlocksWithChildrenQuery, GetBlocksWithChildrenQueryVariables>(GetBlocksWithChildrenDocument, options);
        }
export type GetBlocksWithChildrenQueryHookResult = ReturnType<typeof useGetBlocksWithChildrenQuery>;
export type GetBlocksWithChildrenLazyQueryHookResult = ReturnType<typeof useGetBlocksWithChildrenLazyQuery>;
export type GetBlocksWithChildrenQueryResult = Apollo.QueryResult<GetBlocksWithChildrenQuery, GetBlocksWithChildrenQueryVariables>;
export const GetSubmissionDocument = gql`
    query getSubmission($id: Int!) {
  submission_by_pk(id: $id) {
    id
    block_id
    requester_user_id
    marker_user_id
    submission_answers
    questions_feedback
    general_comments
    marks
    publicly_accessible
    requester_name
    marker_name
    title
  }
}
    `;

/**
 * __useGetSubmissionQuery__
 *
 * To run a query within a React component, call `useGetSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubmissionQuery(baseOptions: Apollo.QueryHookOptions<GetSubmissionQuery, GetSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmissionQuery, GetSubmissionQueryVariables>(GetSubmissionDocument, options);
      }
export function useGetSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmissionQuery, GetSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmissionQuery, GetSubmissionQueryVariables>(GetSubmissionDocument, options);
        }
export type GetSubmissionQueryHookResult = ReturnType<typeof useGetSubmissionQuery>;
export type GetSubmissionLazyQueryHookResult = ReturnType<typeof useGetSubmissionLazyQuery>;
export type GetSubmissionQueryResult = Apollo.QueryResult<GetSubmissionQuery, GetSubmissionQueryVariables>;
export const GetTopicsWithChildrenDocument = gql`
    query getTopicsWithChildren($ids: _int4) {
  get_topics_with_children(args: {ids: $ids}) {
    curriculum_id
    description
    has_children
    active
    id
    parent_id
    title
    topic_label_id
    topic_unit_title
    url
    topic_label {
      id
      title
      curriculum_id
      order_id
    }
  }
}
    `;

/**
 * __useGetTopicsWithChildrenQuery__
 *
 * To run a query within a React component, call `useGetTopicsWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsWithChildrenQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetTopicsWithChildrenQuery(baseOptions?: Apollo.QueryHookOptions<GetTopicsWithChildrenQuery, GetTopicsWithChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicsWithChildrenQuery, GetTopicsWithChildrenQueryVariables>(GetTopicsWithChildrenDocument, options);
      }
export function useGetTopicsWithChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsWithChildrenQuery, GetTopicsWithChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicsWithChildrenQuery, GetTopicsWithChildrenQueryVariables>(GetTopicsWithChildrenDocument, options);
        }
export type GetTopicsWithChildrenQueryHookResult = ReturnType<typeof useGetTopicsWithChildrenQuery>;
export type GetTopicsWithChildrenLazyQueryHookResult = ReturnType<typeof useGetTopicsWithChildrenLazyQuery>;
export type GetTopicsWithChildrenQueryResult = Apollo.QueryResult<GetTopicsWithChildrenQuery, GetTopicsWithChildrenQueryVariables>;
export const GetExistingTagsForTopicIdDocument = gql`
    query getExistingTagsForTopicId($topicId: bigint) {
  tag(where: {topic_id: {_eq: $topicId}}) {
    id
    block {
      id
      type
      block
    }
  }
}
    `;

/**
 * __useGetExistingTagsForTopicIdQuery__
 *
 * To run a query within a React component, call `useGetExistingTagsForTopicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingTagsForTopicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingTagsForTopicIdQuery({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useGetExistingTagsForTopicIdQuery(baseOptions?: Apollo.QueryHookOptions<GetExistingTagsForTopicIdQuery, GetExistingTagsForTopicIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingTagsForTopicIdQuery, GetExistingTagsForTopicIdQueryVariables>(GetExistingTagsForTopicIdDocument, options);
      }
export function useGetExistingTagsForTopicIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingTagsForTopicIdQuery, GetExistingTagsForTopicIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingTagsForTopicIdQuery, GetExistingTagsForTopicIdQueryVariables>(GetExistingTagsForTopicIdDocument, options);
        }
export type GetExistingTagsForTopicIdQueryHookResult = ReturnType<typeof useGetExistingTagsForTopicIdQuery>;
export type GetExistingTagsForTopicIdLazyQueryHookResult = ReturnType<typeof useGetExistingTagsForTopicIdLazyQuery>;
export type GetExistingTagsForTopicIdQueryResult = Apollo.QueryResult<GetExistingTagsForTopicIdQuery, GetExistingTagsForTopicIdQueryVariables>;
export const GetExistingBlocksForTopicIdDocument = gql`
    query getExistingBlocksForTopicId($topicIds: [bigint!]) {
  block(where: {tags: {topic_id: {_in: $topicIds}}}) {
    id
    type
    created_by_id
    publicly_accessible
    verified
  }
}
    `;

/**
 * __useGetExistingBlocksForTopicIdQuery__
 *
 * To run a query within a React component, call `useGetExistingBlocksForTopicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingBlocksForTopicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingBlocksForTopicIdQuery({
 *   variables: {
 *      topicIds: // value for 'topicIds'
 *   },
 * });
 */
export function useGetExistingBlocksForTopicIdQuery(baseOptions?: Apollo.QueryHookOptions<GetExistingBlocksForTopicIdQuery, GetExistingBlocksForTopicIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingBlocksForTopicIdQuery, GetExistingBlocksForTopicIdQueryVariables>(GetExistingBlocksForTopicIdDocument, options);
      }
export function useGetExistingBlocksForTopicIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingBlocksForTopicIdQuery, GetExistingBlocksForTopicIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingBlocksForTopicIdQuery, GetExistingBlocksForTopicIdQueryVariables>(GetExistingBlocksForTopicIdDocument, options);
        }
export type GetExistingBlocksForTopicIdQueryHookResult = ReturnType<typeof useGetExistingBlocksForTopicIdQuery>;
export type GetExistingBlocksForTopicIdLazyQueryHookResult = ReturnType<typeof useGetExistingBlocksForTopicIdLazyQuery>;
export type GetExistingBlocksForTopicIdQueryResult = Apollo.QueryResult<GetExistingBlocksForTopicIdQuery, GetExistingBlocksForTopicIdQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions {
  subscription {
    id
    plan
    monthly_price
    quarterly_price
    yearly_price
    description
  }
}
    `;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($searchTerm: String) {
  search_users(args: {search: $searchTerm}) {
    display_name
    id
    avatar_url
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const GetUsersWithNameDocument = gql`
    query getUsersWithName($searchName: String) {
  users(where: {display_name: {_eq: $searchName}}) {
    display_name
    id
    institution_id
  }
}
    `;

/**
 * __useGetUsersWithNameQuery__
 *
 * To run a query within a React component, call `useGetUsersWithNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWithNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWithNameQuery({
 *   variables: {
 *      searchName: // value for 'searchName'
 *   },
 * });
 */
export function useGetUsersWithNameQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersWithNameQuery, GetUsersWithNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersWithNameQuery, GetUsersWithNameQueryVariables>(GetUsersWithNameDocument, options);
      }
export function useGetUsersWithNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersWithNameQuery, GetUsersWithNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersWithNameQuery, GetUsersWithNameQueryVariables>(GetUsersWithNameDocument, options);
        }
export type GetUsersWithNameQueryHookResult = ReturnType<typeof useGetUsersWithNameQuery>;
export type GetUsersWithNameLazyQueryHookResult = ReturnType<typeof useGetUsersWithNameLazyQuery>;
export type GetUsersWithNameQueryResult = Apollo.QueryResult<GetUsersWithNameQuery, GetUsersWithNameQueryVariables>;
export const GetBlocksForUserDocument = gql`
    query GetBlocksForUser($userId: bigint!, $blockIds: [String!], $limit: Int) {
  block(
    where: {created_by_id: {_eq: $userId}, type: {_in: $blockIds}}
    limit: $limit
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    note
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useGetBlocksForUserQuery__
 *
 * To run a query within a React component, call `useGetBlocksForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlocksForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlocksForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      blockIds: // value for 'blockIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBlocksForUserQuery(baseOptions: Apollo.QueryHookOptions<GetBlocksForUserQuery, GetBlocksForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlocksForUserQuery, GetBlocksForUserQueryVariables>(GetBlocksForUserDocument, options);
      }
export function useGetBlocksForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlocksForUserQuery, GetBlocksForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlocksForUserQuery, GetBlocksForUserQueryVariables>(GetBlocksForUserDocument, options);
        }
export type GetBlocksForUserQueryHookResult = ReturnType<typeof useGetBlocksForUserQuery>;
export type GetBlocksForUserLazyQueryHookResult = ReturnType<typeof useGetBlocksForUserLazyQuery>;
export type GetBlocksForUserQueryResult = Apollo.QueryResult<GetBlocksForUserQuery, GetBlocksForUserQueryVariables>;
export const GetChildrenOfParentWithIdDocument = gql`
    query getChildrenOfParentWithId($parentId: bigint, $limit: Int) {
  block(where: {parent_id: {_eq: $parentId}}, limit: $limit) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    note
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useGetChildrenOfParentWithIdQuery__
 *
 * To run a query within a React component, call `useGetChildrenOfParentWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildrenOfParentWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildrenOfParentWithIdQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetChildrenOfParentWithIdQuery(baseOptions?: Apollo.QueryHookOptions<GetChildrenOfParentWithIdQuery, GetChildrenOfParentWithIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChildrenOfParentWithIdQuery, GetChildrenOfParentWithIdQueryVariables>(GetChildrenOfParentWithIdDocument, options);
      }
export function useGetChildrenOfParentWithIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildrenOfParentWithIdQuery, GetChildrenOfParentWithIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChildrenOfParentWithIdQuery, GetChildrenOfParentWithIdQueryVariables>(GetChildrenOfParentWithIdDocument, options);
        }
export type GetChildrenOfParentWithIdQueryHookResult = ReturnType<typeof useGetChildrenOfParentWithIdQuery>;
export type GetChildrenOfParentWithIdLazyQueryHookResult = ReturnType<typeof useGetChildrenOfParentWithIdLazyQuery>;
export type GetChildrenOfParentWithIdQueryResult = Apollo.QueryResult<GetChildrenOfParentWithIdQuery, GetChildrenOfParentWithIdQueryVariables>;
export const FreeTextSearchOnBlockDocument = gql`
    query FreeTextSearchOnBlock($search: String) {
  search_blocks(
    args: {search: $search}
    where: {publicly_accessible: {_eq: true}}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    note
    has_children
  }
}
    `;

/**
 * __useFreeTextSearchOnBlockQuery__
 *
 * To run a query within a React component, call `useFreeTextSearchOnBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useFreeTextSearchOnBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFreeTextSearchOnBlockQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFreeTextSearchOnBlockQuery(baseOptions?: Apollo.QueryHookOptions<FreeTextSearchOnBlockQuery, FreeTextSearchOnBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FreeTextSearchOnBlockQuery, FreeTextSearchOnBlockQueryVariables>(FreeTextSearchOnBlockDocument, options);
      }
export function useFreeTextSearchOnBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FreeTextSearchOnBlockQuery, FreeTextSearchOnBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FreeTextSearchOnBlockQuery, FreeTextSearchOnBlockQueryVariables>(FreeTextSearchOnBlockDocument, options);
        }
export type FreeTextSearchOnBlockQueryHookResult = ReturnType<typeof useFreeTextSearchOnBlockQuery>;
export type FreeTextSearchOnBlockLazyQueryHookResult = ReturnType<typeof useFreeTextSearchOnBlockLazyQuery>;
export type FreeTextSearchOnBlockQueryResult = Apollo.QueryResult<FreeTextSearchOnBlockQuery, FreeTextSearchOnBlockQueryVariables>;
export const SearchAndFilterBlocksDocument = gql`
    query SearchAndFilterBlocks($search: String, $topicIds: [bigint!], $blockIds: [String!], $limit: Int!, $offset: Int!) {
  search_blocks(
    limit: $limit
    offset: $offset
    args: {search: $search}
    where: {tags: {topic_id: {_in: $topicIds, _is_null: false}}, type: {_in: $blockIds}, publicly_accessible: {_eq: true}}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    note
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useSearchAndFilterBlocksQuery__
 *
 * To run a query within a React component, call `useSearchAndFilterBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAndFilterBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAndFilterBlocksQuery({
 *   variables: {
 *      search: // value for 'search'
 *      topicIds: // value for 'topicIds'
 *      blockIds: // value for 'blockIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchAndFilterBlocksQuery(baseOptions: Apollo.QueryHookOptions<SearchAndFilterBlocksQuery, SearchAndFilterBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAndFilterBlocksQuery, SearchAndFilterBlocksQueryVariables>(SearchAndFilterBlocksDocument, options);
      }
export function useSearchAndFilterBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAndFilterBlocksQuery, SearchAndFilterBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAndFilterBlocksQuery, SearchAndFilterBlocksQueryVariables>(SearchAndFilterBlocksDocument, options);
        }
export type SearchAndFilterBlocksQueryHookResult = ReturnType<typeof useSearchAndFilterBlocksQuery>;
export type SearchAndFilterBlocksLazyQueryHookResult = ReturnType<typeof useSearchAndFilterBlocksLazyQuery>;
export type SearchAndFilterBlocksQueryResult = Apollo.QueryResult<SearchAndFilterBlocksQuery, SearchAndFilterBlocksQueryVariables>;
export const SearchAndFilterBlocksVerifiedDocument = gql`
    query SearchAndFilterBlocksVerified($search: String, $topicIds: [bigint!], $blockIds: [String!], $limit: Int!, $offset: Int!) {
  search_blocks(
    limit: $limit
    offset: $offset
    args: {search: $search}
    where: {tags: {topic_id: {_in: $topicIds, _is_null: false}}, type: {_in: $blockIds}, publicly_accessible: {_eq: true}, verified: {_eq: true}}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    note
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useSearchAndFilterBlocksVerifiedQuery__
 *
 * To run a query within a React component, call `useSearchAndFilterBlocksVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAndFilterBlocksVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAndFilterBlocksVerifiedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      topicIds: // value for 'topicIds'
 *      blockIds: // value for 'blockIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchAndFilterBlocksVerifiedQuery(baseOptions: Apollo.QueryHookOptions<SearchAndFilterBlocksVerifiedQuery, SearchAndFilterBlocksVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAndFilterBlocksVerifiedQuery, SearchAndFilterBlocksVerifiedQueryVariables>(SearchAndFilterBlocksVerifiedDocument, options);
      }
export function useSearchAndFilterBlocksVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAndFilterBlocksVerifiedQuery, SearchAndFilterBlocksVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAndFilterBlocksVerifiedQuery, SearchAndFilterBlocksVerifiedQueryVariables>(SearchAndFilterBlocksVerifiedDocument, options);
        }
export type SearchAndFilterBlocksVerifiedQueryHookResult = ReturnType<typeof useSearchAndFilterBlocksVerifiedQuery>;
export type SearchAndFilterBlocksVerifiedLazyQueryHookResult = ReturnType<typeof useSearchAndFilterBlocksVerifiedLazyQuery>;
export type SearchAndFilterBlocksVerifiedQueryResult = Apollo.QueryResult<SearchAndFilterBlocksVerifiedQuery, SearchAndFilterBlocksVerifiedQueryVariables>;
export const SearchAndFilterWithAuthorBlocksDocument = gql`
    query SearchAndFilterWithAuthorBlocks($search: String, $topicIds: [bigint!], $blockIds: [String!], $authorIds: [bigint!], $limit: Int!, $offset: Int!) {
  search_blocks(
    limit: $limit
    offset: $offset
    args: {search: $search}
    where: {tags: {topic_id: {_in: $topicIds, _is_null: false}}, type: {_in: $blockIds}, created_by_id: {_in: $authorIds}, publicly_accessible: {_eq: true}}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    note
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useSearchAndFilterWithAuthorBlocksQuery__
 *
 * To run a query within a React component, call `useSearchAndFilterWithAuthorBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAndFilterWithAuthorBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAndFilterWithAuthorBlocksQuery({
 *   variables: {
 *      search: // value for 'search'
 *      topicIds: // value for 'topicIds'
 *      blockIds: // value for 'blockIds'
 *      authorIds: // value for 'authorIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchAndFilterWithAuthorBlocksQuery(baseOptions: Apollo.QueryHookOptions<SearchAndFilterWithAuthorBlocksQuery, SearchAndFilterWithAuthorBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAndFilterWithAuthorBlocksQuery, SearchAndFilterWithAuthorBlocksQueryVariables>(SearchAndFilterWithAuthorBlocksDocument, options);
      }
export function useSearchAndFilterWithAuthorBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAndFilterWithAuthorBlocksQuery, SearchAndFilterWithAuthorBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAndFilterWithAuthorBlocksQuery, SearchAndFilterWithAuthorBlocksQueryVariables>(SearchAndFilterWithAuthorBlocksDocument, options);
        }
export type SearchAndFilterWithAuthorBlocksQueryHookResult = ReturnType<typeof useSearchAndFilterWithAuthorBlocksQuery>;
export type SearchAndFilterWithAuthorBlocksLazyQueryHookResult = ReturnType<typeof useSearchAndFilterWithAuthorBlocksLazyQuery>;
export type SearchAndFilterWithAuthorBlocksQueryResult = Apollo.QueryResult<SearchAndFilterWithAuthorBlocksQuery, SearchAndFilterWithAuthorBlocksQueryVariables>;
export const SearchAndFilterWithAuthorBlocksVerifiedDocument = gql`
    query SearchAndFilterWithAuthorBlocksVerified($search: String, $topicIds: [bigint!], $blockIds: [String!], $authorIds: [bigint!], $limit: Int!, $offset: Int!) {
  search_blocks(
    limit: $limit
    offset: $offset
    args: {search: $search}
    where: {tags: {topic_id: {_in: $topicIds, _is_null: false}}, type: {_in: $blockIds}, created_by_id: {_in: $authorIds}, publicly_accessible: {_eq: true}, verified: {_eq: true}}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    note
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useSearchAndFilterWithAuthorBlocksVerifiedQuery__
 *
 * To run a query within a React component, call `useSearchAndFilterWithAuthorBlocksVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAndFilterWithAuthorBlocksVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAndFilterWithAuthorBlocksVerifiedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      topicIds: // value for 'topicIds'
 *      blockIds: // value for 'blockIds'
 *      authorIds: // value for 'authorIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchAndFilterWithAuthorBlocksVerifiedQuery(baseOptions: Apollo.QueryHookOptions<SearchAndFilterWithAuthorBlocksVerifiedQuery, SearchAndFilterWithAuthorBlocksVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAndFilterWithAuthorBlocksVerifiedQuery, SearchAndFilterWithAuthorBlocksVerifiedQueryVariables>(SearchAndFilterWithAuthorBlocksVerifiedDocument, options);
      }
export function useSearchAndFilterWithAuthorBlocksVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAndFilterWithAuthorBlocksVerifiedQuery, SearchAndFilterWithAuthorBlocksVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAndFilterWithAuthorBlocksVerifiedQuery, SearchAndFilterWithAuthorBlocksVerifiedQueryVariables>(SearchAndFilterWithAuthorBlocksVerifiedDocument, options);
        }
export type SearchAndFilterWithAuthorBlocksVerifiedQueryHookResult = ReturnType<typeof useSearchAndFilterWithAuthorBlocksVerifiedQuery>;
export type SearchAndFilterWithAuthorBlocksVerifiedLazyQueryHookResult = ReturnType<typeof useSearchAndFilterWithAuthorBlocksVerifiedLazyQuery>;
export type SearchAndFilterWithAuthorBlocksVerifiedQueryResult = Apollo.QueryResult<SearchAndFilterWithAuthorBlocksVerifiedQuery, SearchAndFilterWithAuthorBlocksVerifiedQueryVariables>;
export const FilterBlocksDocument = gql`
    query filterBlocks($topiclistone: _int4, $topiclisttwo: _int4, $topiclistthree: _int4, $topiclistfour: _int4, $topiclistfive: _int4, $topiclistsix: _int4, $topiclistseven: _int4, $topiclisteight: _int4, $topiclistnine: _int4, $topiclistten: _int4, $topiclisteleven: _int4, $topiclisttwelve: _int4, $topiclistthirteen: _int4, $topiclistfourteen: _int4, $topiclistfifteen: _int4, $blockIds: String, $limit: Int!, $offset: Int!, $verified: bit, $blockauthorids: _int4, $searchterm: String) {
  filter_blocks(
    args: {searchterm: $searchterm, block_limit: $limit, block_offset: $offset, blockverified: $verified, blockauthorids: $blockauthorids, blockidlist: $blockIds, topiclistone: $topiclistone, topiclisttwo: $topiclisttwo, topiclistthree: $topiclistthree, topiclistfour: $topiclistfour, topiclistfive: $topiclistfive, topiclistsix: $topiclistsix, topiclistseven: $topiclistseven, topiclisteight: $topiclisteight, topiclistnine: $topiclistnine, topiclistten: $topiclistten, topiclisteleven: $topiclisteleven, topiclisttwelve: $topiclisttwelve, topiclistthirteen: $topiclistthirteen, topiclistfourteen: $topiclistfourteen, topiclistfifteen: $topiclistfifteen}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    note
    has_children
  }
}
    `;

/**
 * __useFilterBlocksQuery__
 *
 * To run a query within a React component, call `useFilterBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterBlocksQuery({
 *   variables: {
 *      topiclistone: // value for 'topiclistone'
 *      topiclisttwo: // value for 'topiclisttwo'
 *      topiclistthree: // value for 'topiclistthree'
 *      topiclistfour: // value for 'topiclistfour'
 *      topiclistfive: // value for 'topiclistfive'
 *      topiclistsix: // value for 'topiclistsix'
 *      topiclistseven: // value for 'topiclistseven'
 *      topiclisteight: // value for 'topiclisteight'
 *      topiclistnine: // value for 'topiclistnine'
 *      topiclistten: // value for 'topiclistten'
 *      topiclisteleven: // value for 'topiclisteleven'
 *      topiclisttwelve: // value for 'topiclisttwelve'
 *      topiclistthirteen: // value for 'topiclistthirteen'
 *      topiclistfourteen: // value for 'topiclistfourteen'
 *      topiclistfifteen: // value for 'topiclistfifteen'
 *      blockIds: // value for 'blockIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      verified: // value for 'verified'
 *      blockauthorids: // value for 'blockauthorids'
 *      searchterm: // value for 'searchterm'
 *   },
 * });
 */
export function useFilterBlocksQuery(baseOptions: Apollo.QueryHookOptions<FilterBlocksQuery, FilterBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterBlocksQuery, FilterBlocksQueryVariables>(FilterBlocksDocument, options);
      }
export function useFilterBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterBlocksQuery, FilterBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterBlocksQuery, FilterBlocksQueryVariables>(FilterBlocksDocument, options);
        }
export type FilterBlocksQueryHookResult = ReturnType<typeof useFilterBlocksQuery>;
export type FilterBlocksLazyQueryHookResult = ReturnType<typeof useFilterBlocksLazyQuery>;
export type FilterBlocksQueryResult = Apollo.QueryResult<FilterBlocksQuery, FilterBlocksQueryVariables>;
export const FilterBlocksVerifiedDocument = gql`
    query FilterBlocksVerified($topicIds: [bigint!], $blockIds: [String!], $limit: Int!, $offset: Int!) {
  block(
    limit: $limit
    offset: $offset
    where: {tags: {topic_id: {_in: $topicIds, _is_null: false}}, type: {_in: $blockIds}, publicly_accessible: {_eq: true}, verified: {_eq: true}}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    note
    has_children
  }
}
    `;

/**
 * __useFilterBlocksVerifiedQuery__
 *
 * To run a query within a React component, call `useFilterBlocksVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterBlocksVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterBlocksVerifiedQuery({
 *   variables: {
 *      topicIds: // value for 'topicIds'
 *      blockIds: // value for 'blockIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFilterBlocksVerifiedQuery(baseOptions: Apollo.QueryHookOptions<FilterBlocksVerifiedQuery, FilterBlocksVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterBlocksVerifiedQuery, FilterBlocksVerifiedQueryVariables>(FilterBlocksVerifiedDocument, options);
      }
export function useFilterBlocksVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterBlocksVerifiedQuery, FilterBlocksVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterBlocksVerifiedQuery, FilterBlocksVerifiedQueryVariables>(FilterBlocksVerifiedDocument, options);
        }
export type FilterBlocksVerifiedQueryHookResult = ReturnType<typeof useFilterBlocksVerifiedQuery>;
export type FilterBlocksVerifiedLazyQueryHookResult = ReturnType<typeof useFilterBlocksVerifiedLazyQuery>;
export type FilterBlocksVerifiedQueryResult = Apollo.QueryResult<FilterBlocksVerifiedQuery, FilterBlocksVerifiedQueryVariables>;
export const FilterBlocksWithAuthorDocument = gql`
    query FilterBlocksWithAuthor($topicIds: [bigint!], $authorIds: [bigint!], $blockIds: [String!], $limit: Int!, $offset: Int!) {
  block(
    limit: $limit
    offset: $offset
    where: {created_by_id: {_in: $authorIds}, tags: {topic_id: {_in: $topicIds, _is_null: false}}, type: {_in: $blockIds}, publicly_accessible: {_eq: true}}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    note
    has_children
  }
}
    `;

/**
 * __useFilterBlocksWithAuthorQuery__
 *
 * To run a query within a React component, call `useFilterBlocksWithAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterBlocksWithAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterBlocksWithAuthorQuery({
 *   variables: {
 *      topicIds: // value for 'topicIds'
 *      authorIds: // value for 'authorIds'
 *      blockIds: // value for 'blockIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFilterBlocksWithAuthorQuery(baseOptions: Apollo.QueryHookOptions<FilterBlocksWithAuthorQuery, FilterBlocksWithAuthorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterBlocksWithAuthorQuery, FilterBlocksWithAuthorQueryVariables>(FilterBlocksWithAuthorDocument, options);
      }
export function useFilterBlocksWithAuthorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterBlocksWithAuthorQuery, FilterBlocksWithAuthorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterBlocksWithAuthorQuery, FilterBlocksWithAuthorQueryVariables>(FilterBlocksWithAuthorDocument, options);
        }
export type FilterBlocksWithAuthorQueryHookResult = ReturnType<typeof useFilterBlocksWithAuthorQuery>;
export type FilterBlocksWithAuthorLazyQueryHookResult = ReturnType<typeof useFilterBlocksWithAuthorLazyQuery>;
export type FilterBlocksWithAuthorQueryResult = Apollo.QueryResult<FilterBlocksWithAuthorQuery, FilterBlocksWithAuthorQueryVariables>;
export const FilterBlocksWithAuthorVerifiedDocument = gql`
    query FilterBlocksWithAuthorVerified($topicIds: [bigint!], $authorIds: [bigint!], $blockIds: [String!], $limit: Int!, $offset: Int!) {
  block(
    limit: $limit
    offset: $offset
    where: {created_by_id: {_in: $authorIds}, tags: {topic_id: {_in: $topicIds, _is_null: false}}, type: {_in: $blockIds}, publicly_accessible: {_eq: true}, verified: {_eq: true}}
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    created_by_id
    created_time
    note
    has_children
  }
}
    `;

/**
 * __useFilterBlocksWithAuthorVerifiedQuery__
 *
 * To run a query within a React component, call `useFilterBlocksWithAuthorVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterBlocksWithAuthorVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterBlocksWithAuthorVerifiedQuery({
 *   variables: {
 *      topicIds: // value for 'topicIds'
 *      authorIds: // value for 'authorIds'
 *      blockIds: // value for 'blockIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFilterBlocksWithAuthorVerifiedQuery(baseOptions: Apollo.QueryHookOptions<FilterBlocksWithAuthorVerifiedQuery, FilterBlocksWithAuthorVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterBlocksWithAuthorVerifiedQuery, FilterBlocksWithAuthorVerifiedQueryVariables>(FilterBlocksWithAuthorVerifiedDocument, options);
      }
export function useFilterBlocksWithAuthorVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterBlocksWithAuthorVerifiedQuery, FilterBlocksWithAuthorVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterBlocksWithAuthorVerifiedQuery, FilterBlocksWithAuthorVerifiedQueryVariables>(FilterBlocksWithAuthorVerifiedDocument, options);
        }
export type FilterBlocksWithAuthorVerifiedQueryHookResult = ReturnType<typeof useFilterBlocksWithAuthorVerifiedQuery>;
export type FilterBlocksWithAuthorVerifiedLazyQueryHookResult = ReturnType<typeof useFilterBlocksWithAuthorVerifiedLazyQuery>;
export type FilterBlocksWithAuthorVerifiedQueryResult = Apollo.QueryResult<FilterBlocksWithAuthorVerifiedQuery, FilterBlocksWithAuthorVerifiedQueryVariables>;
export const GetUserByEmailDocument = gql`
    query GetUserByEmail($email: String) {
  users(where: {email: {_eq: $email}}) {
    avatar_url
    bio_data
    verified
    school
    active
    created_time
    display_name
    email
    id
    institution_id
    last_edited_time
    subscription_id
    stripe_id
    tags {
      id
      curriculum_id
      topic_id
      topic {
        parent_id
        title
        topic_unit_title
        topic_label_id
        id
      }
      curriculum {
        name
        id
        short_title
      }
    }
  }
}
    `;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
      }
export function useGetUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<typeof useGetUserByEmailLazyQuery>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers($limit: Int, $offset: Int) {
  users(limit: $limit, offset: $offset) {
    active
    display_name
    email
    id
    verified
    subscription_id
    subscription {
      plan
    }
    institution_id
    institution {
      owner
      display_name
    }
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetAllInstitutionDocument = gql`
    query GetAllInstitution($limit: Int, $offset: Int) {
  institution(limit: $limit, offset: $offset) {
    bio
    active
    display_name
    id
    owner
  }
}
    `;

/**
 * __useGetAllInstitutionQuery__
 *
 * To run a query within a React component, call `useGetAllInstitutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInstitutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInstitutionQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllInstitutionQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInstitutionQuery, GetAllInstitutionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInstitutionQuery, GetAllInstitutionQueryVariables>(GetAllInstitutionDocument, options);
      }
export function useGetAllInstitutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInstitutionQuery, GetAllInstitutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInstitutionQuery, GetAllInstitutionQueryVariables>(GetAllInstitutionDocument, options);
        }
export type GetAllInstitutionQueryHookResult = ReturnType<typeof useGetAllInstitutionQuery>;
export type GetAllInstitutionLazyQueryHookResult = ReturnType<typeof useGetAllInstitutionLazyQuery>;
export type GetAllInstitutionQueryResult = Apollo.QueryResult<GetAllInstitutionQuery, GetAllInstitutionQueryVariables>;
export const ApplicationContextDocument = gql`
    query ApplicationContext {
  country {
    code
    id
    name
  }
  language {
    id
    name
    code
  }
  subscription {
    id
    plan
    quarterly_price
    yearly_price
    monthly_price
    last_edited_time
    discount
    description
    created_time
  }
  institution {
    id
    display_name
    bio
    owner
    users {
      active
      display_name
      email
      id
    }
    tags {
      curriculum_id
      id
      topic_id
    }
  }
}
    `;

/**
 * __useApplicationContextQuery__
 *
 * To run a query within a React component, call `useApplicationContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationContextQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationContextQuery, ApplicationContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationContextQuery, ApplicationContextQueryVariables>(ApplicationContextDocument, options);
      }
export function useApplicationContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationContextQuery, ApplicationContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationContextQuery, ApplicationContextQueryVariables>(ApplicationContextDocument, options);
        }
export type ApplicationContextQueryHookResult = ReturnType<typeof useApplicationContextQuery>;
export type ApplicationContextLazyQueryHookResult = ReturnType<typeof useApplicationContextLazyQuery>;
export type ApplicationContextQueryResult = Apollo.QueryResult<ApplicationContextQuery, ApplicationContextQueryVariables>;
export const GetTagForBlockDocument = gql`
    query getTagForBlock($blockId: bigint) {
  tag(where: {block_id: {_eq: $blockId}}) {
    id
    topic_id
    block_id
  }
}
    `;

/**
 * __useGetTagForBlockQuery__
 *
 * To run a query within a React component, call `useGetTagForBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagForBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagForBlockQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useGetTagForBlockQuery(baseOptions?: Apollo.QueryHookOptions<GetTagForBlockQuery, GetTagForBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagForBlockQuery, GetTagForBlockQueryVariables>(GetTagForBlockDocument, options);
      }
export function useGetTagForBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagForBlockQuery, GetTagForBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagForBlockQuery, GetTagForBlockQueryVariables>(GetTagForBlockDocument, options);
        }
export type GetTagForBlockQueryHookResult = ReturnType<typeof useGetTagForBlockQuery>;
export type GetTagForBlockLazyQueryHookResult = ReturnType<typeof useGetTagForBlockLazyQuery>;
export type GetTagForBlockQueryResult = Apollo.QueryResult<GetTagForBlockQuery, GetTagForBlockQueryVariables>;
export const GetTopicsAndLabelsForCurriculumsDocument = gql`
    query getTopicsAndLabelsForCurriculums($curriculumIds: [bigint!]) {
  topic(where: {curriculum_id: {_in: $curriculumIds}}) {
    curriculum_id
    description
    has_children
    active
    url
    id
    parent_id
    title
    topic_label_id
    topic_unit_title
  }
  topic_label(where: {curriculum_id: {_in: $curriculumIds}}) {
    curriculum_id
    id
    title
    order_id
  }
}
    `;

/**
 * __useGetTopicsAndLabelsForCurriculumsQuery__
 *
 * To run a query within a React component, call `useGetTopicsAndLabelsForCurriculumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsAndLabelsForCurriculumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsAndLabelsForCurriculumsQuery({
 *   variables: {
 *      curriculumIds: // value for 'curriculumIds'
 *   },
 * });
 */
export function useGetTopicsAndLabelsForCurriculumsQuery(baseOptions?: Apollo.QueryHookOptions<GetTopicsAndLabelsForCurriculumsQuery, GetTopicsAndLabelsForCurriculumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicsAndLabelsForCurriculumsQuery, GetTopicsAndLabelsForCurriculumsQueryVariables>(GetTopicsAndLabelsForCurriculumsDocument, options);
      }
export function useGetTopicsAndLabelsForCurriculumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsAndLabelsForCurriculumsQuery, GetTopicsAndLabelsForCurriculumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicsAndLabelsForCurriculumsQuery, GetTopicsAndLabelsForCurriculumsQueryVariables>(GetTopicsAndLabelsForCurriculumsDocument, options);
        }
export type GetTopicsAndLabelsForCurriculumsQueryHookResult = ReturnType<typeof useGetTopicsAndLabelsForCurriculumsQuery>;
export type GetTopicsAndLabelsForCurriculumsLazyQueryHookResult = ReturnType<typeof useGetTopicsAndLabelsForCurriculumsLazyQuery>;
export type GetTopicsAndLabelsForCurriculumsQueryResult = Apollo.QueryResult<GetTopicsAndLabelsForCurriculumsQuery, GetTopicsAndLabelsForCurriculumsQueryVariables>;
export const GetActiveTopicsAndLabelsForCurriculumsDocument = gql`
    query getActiveTopicsAndLabelsForCurriculums($curriculumIds: [bigint!]) {
  topic(where: {curriculum_id: {_in: $curriculumIds}, active: {_eq: true}}) {
    curriculum_id
    description
    has_children
    active
    id
    parent_id
    title
    topic_label_id
    topic_unit_title
  }
  topic_label(where: {curriculum_id: {_in: $curriculumIds}}) {
    curriculum_id
    id
    title
    order_id
  }
}
    `;

/**
 * __useGetActiveTopicsAndLabelsForCurriculumsQuery__
 *
 * To run a query within a React component, call `useGetActiveTopicsAndLabelsForCurriculumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTopicsAndLabelsForCurriculumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTopicsAndLabelsForCurriculumsQuery({
 *   variables: {
 *      curriculumIds: // value for 'curriculumIds'
 *   },
 * });
 */
export function useGetActiveTopicsAndLabelsForCurriculumsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveTopicsAndLabelsForCurriculumsQuery, GetActiveTopicsAndLabelsForCurriculumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveTopicsAndLabelsForCurriculumsQuery, GetActiveTopicsAndLabelsForCurriculumsQueryVariables>(GetActiveTopicsAndLabelsForCurriculumsDocument, options);
      }
export function useGetActiveTopicsAndLabelsForCurriculumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTopicsAndLabelsForCurriculumsQuery, GetActiveTopicsAndLabelsForCurriculumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveTopicsAndLabelsForCurriculumsQuery, GetActiveTopicsAndLabelsForCurriculumsQueryVariables>(GetActiveTopicsAndLabelsForCurriculumsDocument, options);
        }
export type GetActiveTopicsAndLabelsForCurriculumsQueryHookResult = ReturnType<typeof useGetActiveTopicsAndLabelsForCurriculumsQuery>;
export type GetActiveTopicsAndLabelsForCurriculumsLazyQueryHookResult = ReturnType<typeof useGetActiveTopicsAndLabelsForCurriculumsLazyQuery>;
export type GetActiveTopicsAndLabelsForCurriculumsQueryResult = Apollo.QueryResult<GetActiveTopicsAndLabelsForCurriculumsQuery, GetActiveTopicsAndLabelsForCurriculumsQueryVariables>;
export const GetActiveCurriculumsDocument = gql`
    query getActiveCurriculums {
  curriculum(where: {publicly_accessible: {_eq: true}}) {
    country_id
    created_time
    description
    id
    institution_id
    last_edited_time
    name
    url
    publicly_accessible
    ref_curriculum_id
    short_title
  }
}
    `;

/**
 * __useGetActiveCurriculumsQuery__
 *
 * To run a query within a React component, call `useGetActiveCurriculumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCurriculumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCurriculumsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveCurriculumsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveCurriculumsQuery, GetActiveCurriculumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveCurriculumsQuery, GetActiveCurriculumsQueryVariables>(GetActiveCurriculumsDocument, options);
      }
export function useGetActiveCurriculumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveCurriculumsQuery, GetActiveCurriculumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveCurriculumsQuery, GetActiveCurriculumsQueryVariables>(GetActiveCurriculumsDocument, options);
        }
export type GetActiveCurriculumsQueryHookResult = ReturnType<typeof useGetActiveCurriculumsQuery>;
export type GetActiveCurriculumsLazyQueryHookResult = ReturnType<typeof useGetActiveCurriculumsLazyQuery>;
export type GetActiveCurriculumsQueryResult = Apollo.QueryResult<GetActiveCurriculumsQuery, GetActiveCurriculumsQueryVariables>;
export const GetAllCurriculumsDocument = gql`
    query getAllCurriculums {
  curriculum {
    country_id
    created_time
    description
    id
    institution_id
    last_edited_time
    name
    publicly_accessible
    ref_curriculum_id
    short_title
  }
}
    `;

/**
 * __useGetAllCurriculumsQuery__
 *
 * To run a query within a React component, call `useGetAllCurriculumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCurriculumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCurriculumsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCurriculumsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCurriculumsQuery, GetAllCurriculumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCurriculumsQuery, GetAllCurriculumsQueryVariables>(GetAllCurriculumsDocument, options);
      }
export function useGetAllCurriculumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCurriculumsQuery, GetAllCurriculumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCurriculumsQuery, GetAllCurriculumsQueryVariables>(GetAllCurriculumsDocument, options);
        }
export type GetAllCurriculumsQueryHookResult = ReturnType<typeof useGetAllCurriculumsQuery>;
export type GetAllCurriculumsLazyQueryHookResult = ReturnType<typeof useGetAllCurriculumsLazyQuery>;
export type GetAllCurriculumsQueryResult = Apollo.QueryResult<GetAllCurriculumsQuery, GetAllCurriculumsQueryVariables>;
export const GetAuthorOfBlockWithIdDocument = gql`
    query getAuthorOfBlockWithId($blockId: Int!) {
  block_by_pk(id: $blockId) {
    id
    created_by_id
    type
  }
}
    `;

/**
 * __useGetAuthorOfBlockWithIdQuery__
 *
 * To run a query within a React component, call `useGetAuthorOfBlockWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorOfBlockWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorOfBlockWithIdQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useGetAuthorOfBlockWithIdQuery(baseOptions: Apollo.QueryHookOptions<GetAuthorOfBlockWithIdQuery, GetAuthorOfBlockWithIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthorOfBlockWithIdQuery, GetAuthorOfBlockWithIdQueryVariables>(GetAuthorOfBlockWithIdDocument, options);
      }
export function useGetAuthorOfBlockWithIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthorOfBlockWithIdQuery, GetAuthorOfBlockWithIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthorOfBlockWithIdQuery, GetAuthorOfBlockWithIdQueryVariables>(GetAuthorOfBlockWithIdDocument, options);
        }
export type GetAuthorOfBlockWithIdQueryHookResult = ReturnType<typeof useGetAuthorOfBlockWithIdQuery>;
export type GetAuthorOfBlockWithIdLazyQueryHookResult = ReturnType<typeof useGetAuthorOfBlockWithIdLazyQuery>;
export type GetAuthorOfBlockWithIdQueryResult = Apollo.QueryResult<GetAuthorOfBlockWithIdQuery, GetAuthorOfBlockWithIdQueryVariables>;
export const GetBlockWithIdDocument = gql`
    query getBlockWithId($blockId: Int) {
  block(where: {id: {_eq: $blockId}}) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    note
    publicly_accessible
    ref_block_id
    note
    topic_id
    url
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useGetBlockWithIdQuery__
 *
 * To run a query within a React component, call `useGetBlockWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockWithIdQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useGetBlockWithIdQuery(baseOptions?: Apollo.QueryHookOptions<GetBlockWithIdQuery, GetBlockWithIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlockWithIdQuery, GetBlockWithIdQueryVariables>(GetBlockWithIdDocument, options);
      }
export function useGetBlockWithIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlockWithIdQuery, GetBlockWithIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlockWithIdQuery, GetBlockWithIdQueryVariables>(GetBlockWithIdDocument, options);
        }
export type GetBlockWithIdQueryHookResult = ReturnType<typeof useGetBlockWithIdQuery>;
export type GetBlockWithIdLazyQueryHookResult = ReturnType<typeof useGetBlockWithIdLazyQuery>;
export type GetBlockWithIdQueryResult = Apollo.QueryResult<GetBlockWithIdQuery, GetBlockWithIdQueryVariables>;
export const CheckBlockIsPublicDocument = gql`
    query checkBlockIsPublic($id: Int!) {
  block_by_pk(id: $id) {
    id
    publicly_accessible
    created_by_id
  }
}
    `;

/**
 * __useCheckBlockIsPublicQuery__
 *
 * To run a query within a React component, call `useCheckBlockIsPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBlockIsPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBlockIsPublicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckBlockIsPublicQuery(baseOptions: Apollo.QueryHookOptions<CheckBlockIsPublicQuery, CheckBlockIsPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckBlockIsPublicQuery, CheckBlockIsPublicQueryVariables>(CheckBlockIsPublicDocument, options);
      }
export function useCheckBlockIsPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckBlockIsPublicQuery, CheckBlockIsPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckBlockIsPublicQuery, CheckBlockIsPublicQueryVariables>(CheckBlockIsPublicDocument, options);
        }
export type CheckBlockIsPublicQueryHookResult = ReturnType<typeof useCheckBlockIsPublicQuery>;
export type CheckBlockIsPublicLazyQueryHookResult = ReturnType<typeof useCheckBlockIsPublicLazyQuery>;
export type CheckBlockIsPublicQueryResult = Apollo.QueryResult<CheckBlockIsPublicQuery, CheckBlockIsPublicQueryVariables>;
export const GetChildrenOfParentWithIdExcludingAnswersDocument = gql`
    query getChildrenOfParentWithIdExcludingAnswers($parentId: bigint, $limit: Int) {
  block(
    where: {parent_id: {_eq: $parentId}, type: {_nin: ["answer", "answer-text", "potential-solutions", "generic-guidance", "additional-guidance", "solution"]}}
    limit: $limit
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    note
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useGetChildrenOfParentWithIdExcludingAnswersQuery__
 *
 * To run a query within a React component, call `useGetChildrenOfParentWithIdExcludingAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildrenOfParentWithIdExcludingAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildrenOfParentWithIdExcludingAnswersQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetChildrenOfParentWithIdExcludingAnswersQuery(baseOptions?: Apollo.QueryHookOptions<GetChildrenOfParentWithIdExcludingAnswersQuery, GetChildrenOfParentWithIdExcludingAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChildrenOfParentWithIdExcludingAnswersQuery, GetChildrenOfParentWithIdExcludingAnswersQueryVariables>(GetChildrenOfParentWithIdExcludingAnswersDocument, options);
      }
export function useGetChildrenOfParentWithIdExcludingAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildrenOfParentWithIdExcludingAnswersQuery, GetChildrenOfParentWithIdExcludingAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChildrenOfParentWithIdExcludingAnswersQuery, GetChildrenOfParentWithIdExcludingAnswersQueryVariables>(GetChildrenOfParentWithIdExcludingAnswersDocument, options);
        }
export type GetChildrenOfParentWithIdExcludingAnswersQueryHookResult = ReturnType<typeof useGetChildrenOfParentWithIdExcludingAnswersQuery>;
export type GetChildrenOfParentWithIdExcludingAnswersLazyQueryHookResult = ReturnType<typeof useGetChildrenOfParentWithIdExcludingAnswersLazyQuery>;
export type GetChildrenOfParentWithIdExcludingAnswersQueryResult = Apollo.QueryResult<GetChildrenOfParentWithIdExcludingAnswersQuery, GetChildrenOfParentWithIdExcludingAnswersQueryVariables>;
export const GetChildrenOfParentWithIdAnswersOnlyDocument = gql`
    query getChildrenOfParentWithIdAnswersOnly($parentId: bigint, $limit: Int) {
  block(
    where: {parent_id: {_eq: $parentId}, type: {_in: ["answer", "answer-text", "potential-solutions", "generic-guidance", "additional-guidance", "solution"]}}
    limit: $limit
  ) {
    id
    tags {
      id
      topic {
        id
        title
      }
    }
    type
    block
    active
    language_id
    last_edited_time
    parent_id
    position
    publicly_accessible
    ref_block_id
    topic_id
    url
    note
    created_by_id
    created_time
    has_children
  }
}
    `;

/**
 * __useGetChildrenOfParentWithIdAnswersOnlyQuery__
 *
 * To run a query within a React component, call `useGetChildrenOfParentWithIdAnswersOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildrenOfParentWithIdAnswersOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildrenOfParentWithIdAnswersOnlyQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetChildrenOfParentWithIdAnswersOnlyQuery(baseOptions?: Apollo.QueryHookOptions<GetChildrenOfParentWithIdAnswersOnlyQuery, GetChildrenOfParentWithIdAnswersOnlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChildrenOfParentWithIdAnswersOnlyQuery, GetChildrenOfParentWithIdAnswersOnlyQueryVariables>(GetChildrenOfParentWithIdAnswersOnlyDocument, options);
      }
export function useGetChildrenOfParentWithIdAnswersOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildrenOfParentWithIdAnswersOnlyQuery, GetChildrenOfParentWithIdAnswersOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChildrenOfParentWithIdAnswersOnlyQuery, GetChildrenOfParentWithIdAnswersOnlyQueryVariables>(GetChildrenOfParentWithIdAnswersOnlyDocument, options);
        }
export type GetChildrenOfParentWithIdAnswersOnlyQueryHookResult = ReturnType<typeof useGetChildrenOfParentWithIdAnswersOnlyQuery>;
export type GetChildrenOfParentWithIdAnswersOnlyLazyQueryHookResult = ReturnType<typeof useGetChildrenOfParentWithIdAnswersOnlyLazyQuery>;
export type GetChildrenOfParentWithIdAnswersOnlyQueryResult = Apollo.QueryResult<GetChildrenOfParentWithIdAnswersOnlyQuery, GetChildrenOfParentWithIdAnswersOnlyQueryVariables>;