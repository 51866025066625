import AltraCooperativeStacked from "@altra-apps/common/assets/icons/AltraCooperativeStacked.svg";
import GraphicResourceBuilder from "@altra-apps/common/assets/icons/GraphicResourceBuilder.svg";
import CurriculumExplorerLogoStacked from "@altra-apps/common/assets/logos/CurriculumExplorerLogoStacked.svg";
import { altraResourceGeneratorTheme } from "@altra-apps/common/src/styling/altra-resource-generator-theme";
import { LandingPageContent } from "@altra-apps/common/src/util/LandingPageContent";
import { Toolbar } from "@mui/material";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { AppPageOverview } from "../components/organisms/AppPageOverview";
import { LandingPageAppVideoPreview } from "../components/organisms/LandingPageAppVideoPreview";
import { LandingPageExploreOtherApps } from "../components/organisms/LandingPageExploreOtherApps";
import { LandingPageFooter } from "../components/organisms/LandingPageFooter";
import { LandingPageTopAppBar } from "../components/organisms/LandingPageTopAppBar";

export const ResourceBuilderLandingScreen: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledScreen>
      <LandingPageTopAppBar />
      <Toolbar />
      <AppPageOverview
        title={LandingPageContent.RESOURCE_BUILDER_NAME}
        description={LandingPageContent.RESOURCE_BUILDER_OVERVIEW_SHORT}
        button1Text={"Open app"}
        button1Link={LandingPageContent.RESOURCE_BUILDER_URL}
        button2Text={"How it works"}
        button2Link={"#howitworks"}
        theme={altraResourceGeneratorTheme}
        image={GraphicResourceBuilder}
      />
      <LandingPageAppVideoPreview
        theme={altraResourceGeneratorTheme}
        bodyText={LandingPageContent.RESOURCE_BUILDER_OVERVIEW_LONG}
        externalLink={LandingPageContent.RESOURCE_BUILDER_URL}
        videoId={"Kj2ihRZ3ScM"}
      />
      {/*<LandingPagePricing*/}
      {/*  theme={altraResourceGeneratorTheme}*/}
      {/*  bodyText={LandingPageContent.RESOURCE_BUILDER_PRICING}*/}
      {/*  openAppLink={LandingPageContent.RESOURCE_BUILDER_URL}*/}
      {/*  whiteBackground*/}
      {/*/>*/}
      <LandingPageExploreOtherApps
        apps={[
          {
            image: CurriculumExplorerLogoStacked,
            link: "/curriculumexplorer",
          },
          {
            image: AltraCooperativeStacked,
            link: "/altracooperative",
          },
        ]}
      />
      <LandingPageFooter />
    </StyledScreen>
  );
};

const StyledScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
