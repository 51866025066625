import { FC } from "react";
import { useParams } from "react-router-dom";
import {
  AccountScreenContainerMode,
  ALTRA_APPS,
} from "../../util/custom-types";
import { AccountScreenVisualsInstitution } from "./AccountScreenVisualsInstitution";
import { AccountScreenVisualsUser } from "./AccountScreenVisualsUser";

/**
 * Logic for all the information needed for the account page
 * @constructor
 */
export interface UserRouteParams {
  mode: string;
  appName: ALTRA_APPS;
}

export const AccountScreenContainer: FC<UserRouteParams> = (prop) => {
  let { mode } = useParams<UserRouteParams>();
  if (AccountScreenContainerMode.USER.toString() === mode)
    return <AccountScreenVisualsUser appName={prop.appName} />;
  else if (AccountScreenContainerMode.INSTITUTION.toString() === mode)
    return <AccountScreenVisualsInstitution />;
  else return <></>;
};
