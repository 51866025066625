import { ApolloProvider } from "@apollo/client";
import React from "react";
import { apolloClientForGuestUser } from "./apolloClient";

export const ApolloClientContext: React.FC = ({ children }) => {
  return (
    <ApolloProvider client={apolloClientForGuestUser}>
      {children}
    </ApolloProvider>
  );
};
