import GraphicNetworkBlue from "@altra-apps/common/assets/icons/GraphicNetworkBlue.svg";
import CurriculumExplorerLogoStacked from "@altra-apps/common/assets/logos/CurriculumExplorerLogoStacked.svg";
import ResourceBuilderLogoStacked from "@altra-apps/common/assets/logos/ResourceBuilderLogoStacked.svg";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { LandingPageContent } from "@altra-apps/common/src/util/LandingPageContent";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Toolbar } from "@mui/material";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { AppPageOverview } from "../components/organisms/AppPageOverview";
import { LandingPageAppVideoPreview } from "../components/organisms/LandingPageAppVideoPreview";
import { LandingPageExploreOtherApps } from "../components/organisms/LandingPageExploreOtherApps";
import { LandingPageFooter } from "../components/organisms/LandingPageFooter";
import { LandingPageTopAppBar } from "../components/organisms/LandingPageTopAppBar";

export const AltraCooperativeLandingScreen: FC = () => {
  const isTabletOrMobile = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledScreen>
      <LandingPageTopAppBar />
      <Toolbar />
      <AppPageOverview
        title={LandingPageContent.ALTRA_COOPERATIVE_NAME}
        description={LandingPageContent.ALTRA_COOPERATIVE_OVERVIEW_SHORT}
        button1Text={"Sign up for free today"}
        button1Link={LandingPageContent.ALTRA_COOPERATIVE_URL}
        button2Text={"How it works"}
        button2Link={"#howitworks"}
        theme={altraTheme}
        image={GraphicNetworkBlue}
      />
      <LandingPageAppVideoPreview
        theme={altraTheme}
        bodyText={LandingPageContent.ALTRA_COOPERATIVE_OVERVIEW_LONG}
        externalLink={LandingPageContent.ALTRA_COOPERATIVE_URL}
        videoId={"ODRHON8PimI"}
      />
      {/*<LandingPagePricing*/}
      {/*  theme={altraTheme}*/}
      {/*  bodyText={LandingPageContent.ALTRA_COOPERATIVE_PRICING}*/}
      {/*  openAppLink={LandingPageContent.ALTRA_COOPERATIVE_URL}*/}
      {/*  whiteBackground*/}
      {/*/>*/}
      {!isTabletOrMobile ? (
        <LandingPageExploreOtherApps
          apps={[
            {
              image: ResourceBuilderLogoStacked,
              link: "/resourcebuilder",
            },
            {
              image: CurriculumExplorerLogoStacked,
              link: "/curriculumexplorer",
            },
          ]}
        />
      ) : (
        <LandingPageExploreOtherApps
          apps={[
            {
              image: CurriculumExplorerLogoStacked,
              link: "/curriculumexplorer",
            },
          ]}
        />
      )}
      <LandingPageFooter />
    </StyledScreen>
  );
};

const StyledScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
