import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { CUSTOM_HEADER_THEME_FOR_RESOURCE } from "../../styling/constants";
import {
  HeadingOneElement,
  HeadingTwoElement,
} from "../../util/custom-editor-types";
import { Leaf } from "../resourceBuilderElements/Leaf";

type ViewHeadingElementProps = {
  block: HeadingOneElement | HeadingTwoElement;
  type: "ONE" | "TWO";
  inline?: boolean;
  doNotLoadChildren?: boolean;
};

/**
 * View only element for displaying Heading blocks
 * @param block
 * @param type
 * @constructor
 */
export const ViewHeadingElement: FC<ViewHeadingElementProps> = ({
  block,
  type,
  inline,
  doNotLoadChildren,
}) => {
  return (
    <StyledDivContainer>
      <span
        style={
          inline
            ? { fontSize: 16, fontWeight: 700 }
            : CUSTOM_HEADER_THEME_FOR_RESOURCE[type]
        }
      >
        {block.children.map((textEl) => (
          <Leaf leaf={textEl}>{textEl.text}</Leaf>
        ))}
      </span>
    </StyledDivContainer>
  );
};
const StyledDivContainer = styled("div")(({ theme }) => ({
  marginTop: "2em",
}));
