import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Typography } from "@mui/material";
import React, { FC } from "react";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

type LandingPageExploreOtherAppsProps = {
  apps: { image: string; link: string }[];
};

export const LandingPageExploreOtherApps: FC<
  LandingPageExploreOtherAppsProps
> = ({ apps }) => {
  const isTabletOrMobile = useIsMobile();

  return (
    <StyledLandingPageExploreOtherApps
      style={{
        backgroundColor: "#FFF",
      }}
    >
      <StyledContainer
        style={{
          display: "flex",
          alignItems: "center",
          width: isTabletOrMobile ? "85%" : "60%",
        }}
      >
        <Typography variant={"h2"} color={"secondary"}>
          Explore our products
        </Typography>

        <StyledContent
          style={{
            flexDirection: isTabletOrMobile ? "column" : "row",
          }}
        >
          {apps.map((app) => (
            <HashLink
              to={app.link}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{ maxHeight: "150px", width: "80%" }}
                src={app.image}
                alt={"Diagram"}
              />
            </HashLink>
          ))}
        </StyledContent>
      </StyledContainer>
    </StyledLandingPageExploreOtherApps>
  );
};

const StyledLandingPageExploreOtherApps = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
  margin-top: 50px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  //background-color: red;
  margin-top: 40px;
`;

const StyledContent = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  min-height: 150px;
  //background-color: #3fa888;
  justify-content: space-around;
`;
