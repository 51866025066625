import { GetSubscriptions } from "@altra-apps/common/src/graphql/queries";
import { PRODUCTS } from "@altra-apps/common/src/util/LandingPageContent";
import {
  DocumentNode,
  gql,
  OperationVariables,
  useApolloClient,
} from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables,
} from "../graphql/types";
import { useAppSelector } from "../redux/hook";
import { UserInfo } from "../redux/user/types";
import { APP_URL } from "../styling/constants";
import { ALTRA_APPS } from "../util/custom-types";
import { prettySubscription } from "../util/pretty-text";
import { useIsMobile } from "../util/useIsMobile";
import { PricingCard } from "./PricingCard";

type ChangeSubscriptionModalProps = {
  open: boolean;
  appName: ALTRA_APPS;
};
function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode
) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables,
      }),
    [client]
  );
}

/**
 * Subscription modal for all apps allowing user to change their subscription
 * TODO: Display current subscription by matching subscription of user in DB to rendered cards
 * TODO: Allow users to change their subscription
 * TODO: Allow users to cancel their subscriptions
 * @param open
 * @constructor
 */
export const ChangeSubscriptionModal: FC<ChangeSubscriptionModalProps> = ({
  open,
  appName,
}) => {
  // Get user details from redux
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const { isAuthenticated } = useAuth0();
  const isTabletOrMobile = useIsMobile();
  const GetSubscriptionsCallback = useLazyQuery<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >(
    gql`
      ${GetSubscriptions}
    `
  );

  const [loadingPrice, setLoadingPrice] = useState<
    keyof typeof PRODUCTS | undefined
  >();
  type returnedSub = {
    plan: string;
    monthly_price: number;
    yearly_price: number;
    description: string;
  };

  const history = useHistory();
  const theme = useTheme();
  const [subscriptions, setSubscriptions] = useState<returnedSub[]>();

  useEffect(() => {
    initialLoadOfSubscriptions();
  }, []);

  const initialLoadOfSubscriptions = async () => {
    const res = await GetSubscriptionsCallback({});

    if (res.data) {
      setSubscriptions(
        res.data.subscription?.map((sub) => ({
          plan: sub.plan || "",
          yearly_price: sub.yearly_price,
          description: sub.description || "",
          monthly_price: sub.monthly_price,
        }))
      );
    } else setSubscriptions([]);
  };

  // Get user subscription plan
  const userSubscriptionPlan: string = useAppSelector((state) => {
    const subs = state.applicationContext.subscriptions;
    const userSub = state.user.userInfo.subscription_id;
    console.log(subs.find((s) => s.id === userSub)?.plan || "Altra Open");
    return subs.find((s) => s.id === userSub)?.plan || "Altra Open";
  });

  /**
   * Calls lambda function to generate stripe checkout URL
   * @param title
   */
  const createSubscription = (title: string) => {
    console.log(title);
    console.log(user.id);
    if (title === "Altra Open") return;

    setLoadingPrice(title as keyof typeof PRODUCTS);

    if (
      process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_URL &&
      process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_API_KEY &&
      user.id
    ) {
      console.log("fetching");
      fetch(process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_URL, {
        method: "POST",
        headers: {
          "X-Api-Key":
            process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_API_KEY,
        },
        body: JSON.stringify({
          product: title,
          isAnnual: false,
          role: "teacher",
          returnUrl: process.env[APP_URL[appName]],
          userId: user.id.toString(),
        }),
      })
        .then((response) => {
          setLoadingPrice(undefined);
          return response.json();
        })
        .then((data) => {
          setLoadingPrice(undefined);
          return (window.location.href = data);
        });
    }
  };

  return (
    <StyledContainer>
      <Dialog open={true}>
        <StyledTopRow>
          <StyledTopRowContent>
            <IconButton
              onClick={() => {
                history.goBack();
              }}
              style={{
                color: theme.palette.error.main,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledTopRowContent>
        </StyledTopRow>
        <Typography sx={{ padding: "1em" }} variant={"h1"}>
          Change subscription
        </Typography>
        {!isAuthenticated && (
          <Typography variant={"subtitle1"} color={"warning"}>
            You must sign in to manage your subscription
          </Typography>
        )}
        <StyledPriceCardRow
          sx={{
            minHeight: isTabletOrMobile ? "auto" : "400px",
            minWidth: isTabletOrMobile ? "auto" : "500px",
            flexDirection: isTabletOrMobile ? "column" : "row",
          }}
        >
          {subscriptions
            ?.filter(
              (s) =>
                s.plan !== "institutionPremium" &&
                s.plan !== "Altra Premium - Individual"
            )
            .map((sub) => (
              <div style={{ margin: "1em" }}>
                <PricingCard
                  title={prettySubscription(sub.plan)}
                  monthlyPrice={sub.monthly_price.toString()}
                  annualPrice={sub.yearly_price.toString()}
                  description={sub.description?.toString() || ""}
                  buttonText={
                    sub.plan === userSubscriptionPlan
                      ? "Already subscribed"
                      : sub.plan === "Altra Open"
                      ? "Cancel membership"
                      : "Change plan"
                  }
                  features={PRODUCTS[sub.plan]?.features || []}
                  buttonLink={
                    sub.plan === userSubscriptionPlan
                      ? {
                          type: "DISABLED",
                        }
                      : sub.plan === "Altra Open"
                      ? {
                          type: "INTERNAL",
                          url: "/cancelSubscription",
                        }
                      : {
                          type: "EXTERNAL",
                          onClick: () => createSubscription(sub.plan),
                          loadingPrice: loadingPrice,
                        }
                  }
                />
              </div>
            ))}
        </StyledPriceCardRow>
      </Dialog>
    </StyledContainer>
  );
};

const StyledContainer = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100vw",
}));

const StyledPriceCardRow = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "20px",

  justifyContent: "space-evenly",
}));
const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
