import { ApolloQueryResult } from "@apollo/client";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ApplicationContextQuery } from "../../graphql/types";
import { getApplicationContextApi } from "./api";
import {
  ApplicationContext,
  Curriculum,
  EditorSideBarStatus,
  LoadingStatus,
  ResourceBuilderToolbar,
  Subscription,
  Topic,
  TopicLabel,
} from "./types";

export const updateApplicationContext = createAction<ApplicationContext>(
  "applicationContext/updateApplicationContext"
);

export const updateLoadingStatus = createAction<LoadingStatus>(
  "applicationContext/updateLoadingStatus"
);

export const applicationContextSubscriptions = createAction<
  Array<Subscription>
>("applicationContext/applicationContextSubscriptions");

export const updateResourceBuilderToolbar =
  createAction<ResourceBuilderToolbar>(
    "applicationContext/updateResourceBuilderToolbar"
  );

export const updateEditorSideBarStatus = createAction<EditorSideBarStatus>(
  "applicationContext/updateEditorSideBarStatus"
);

export const updateTopicsForCurriculum = createAction<{
  curriculumId: number;
  topics: Array<Topic>;
}>("applicationContext/updateTopicsForCurriculum");

export const updateTopicLabelsForCurriculum = createAction<{
  curriculumId: number;
  topicLabels: Array<TopicLabel>;
}>("applicationContext/updateTopicLabelsForCurriculum");

export const updateTopic = createAction<Topic>(
  "applicationContext/updateTopic"
);
export const deleteTopic = createAction<{ curriculum_id: number; id: number }>(
  "applicationContext/deleteTopic"
);

export const createTopic = createAction<Topic>(
  "applicationContext/createTopic"
);

export const createTopicLabel = createAction<TopicLabel>(
  "applicationContext/createTopicLabel"
);

export const updateCurriculum = createAction<Curriculum>(
  "applicationContext/updateCurriculum"
);

export const createCurriculum = createAction<Curriculum>(
  "applicationContext/createCurriculum"
);

export const updateCurriculums = createAction<Array<Curriculum>>(
  "applicationContext/updateCurriculums"
);

export const getApplicationContext = createAsyncThunk(
  "applicationContext/getApplicationContext",
  async (_, thunkApi) => {
    const response: ApolloQueryResult<ApplicationContextQuery> =
      await getApplicationContextApi();
    if (response.errors) {
      console.log("ERROR GETTING APPLICATION CONTEXT");
      console.log(response.errors);
      return thunkApi.rejectWithValue(
        response.errors && response.errors[0].message
      );
    }
    console.log("SUCCESS GETTING APPLICATION CONTEXT");
    console.log(response.data);

    return response.data;
  }
);
