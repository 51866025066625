import { ApolloQueryResult } from "@apollo/client";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetUserByEmailQuery,
  Submission,
  Tag_Insert_Input,
  Users_Obj_Rel_Insert_Input,
  Users_Set_Input,
} from "../../graphql/types";
import { Status } from "../../util/custom-types";
import { createUserApi, getUserByEmailApi, updateUserByIdApi } from "./api";
import {
  Block,
  BlockBeingDragged,
  CurriculumExplorerTutorialStages,
  EditableBlockProp,
  EditableSubmissionAnswerFeedbackProp,
  LoadedUserStatus,
  ManualEditorBlockImport,
  ManualEditorBlockMove,
  OPERATION_TYPE,
  ResourceBeingEdited,
  ResourceBuilderTutorialStages,
  Tag,
  ToggleAnswers,
  UserAppInfo,
} from "./types";
export const userAppInfoSearchTerm = createAction<string>(
  "userAppInfo/userAppInfoSearchTerm"
);

export const toggledFiltersChangedReadyToSearch = createAction<boolean>(
  "userAppInfo/filtersApplied"
);

export const userAppInfoIsDragActive = createAction<BlockBeingDragged>(
  "userAppInfo/blockCurrentlyBeingDragged"
);

export const userAppInfoCreateSubmissionAnswerFeedbackBlocks = createAction<
  Array<EditableSubmissionAnswerFeedbackProp>
>("userAppInfo/userAppInfoCreateSubmissionAnswerFeedbackBlocks");

export const userAppInfoUpdateSubmissionAnswerFeedbackBlock =
  createAction<EditableSubmissionAnswerFeedbackProp>(
    "userAppInfo/userAppInfoUpdateSubmissionAnswerFeedbackBlock"
  );

export const userAppInfoManualBlockMove = createAction<ManualEditorBlockMove>(
  "userAppInfo/manualEditorBlockMove"
);
export const userAppInfoManualBlockImport =
  createAction<ManualEditorBlockImport>("userAppInfo/manualEditorBlockImport");

export const userAppInfoCreateSubmission = createAction<Submission>(
  "userAppInfo/userAppInfoCreateSubmission"
);
export const userAppInfoUpdateSubmission = createAction<{
  submissionId: number;
  title?: string;
  requesterName?: string;
  markerName?: string;
}>("userAppInfo/userAppInfoUpdateSubmission");

export const userAppInfoFirstSearchExecuted = createAction<boolean>(
  "userAppInfo/userAppInfoFirstSearchExecuted"
);

export const blocksSelectedForAddingToResource = createAction<number[]>(
  "userAppInfo/blocksSelectedForAddingToResource"
);

export const resetEditableBlockProps = createAction(
  "userAppInfo/resetEditableBlockProps"
);

export const resetChildBlocks = createAction("userAppInfo/resetChildBlocks");

export const appendToQueueForDatabaseUpdates = createAction<
  {
    type: OPERATION_TYPE;
    ids: string[];
  }[]
>("userAppInfo/appendToQueueForDatabaseUpdates");

export const resetQueueForDatabaseUpdates = createAction(
  "userAppInfo/resetQueueForDatabaseUpdates"
);
export const userAppInfoResourceBeingEdited = createAction<ResourceBeingEdited>(
  "userAppInfo/userAppInforesourceBeingEdited"
);
export const userAppInfoTeacherOrStudent = createAction<
  "TEACHER" | "STUDENT" | undefined
>("userAppInfo/userAppInfoTeacherOrStudent");
export const updateTitleOfResourceBeingEdited = createAction<{
  id: number;
  title: string;
}>("userAppInfo/updateTitleOfResourceBeingEdited");
export const updateDescriptionOfResourceBeingEdited = createAction<{
  id: number;
  description: string;
}>("userAppInfo/updateDescriptionOfResourceBeingEdited");

export const userAppInfoTagModalOpen = createAction<string | undefined>(
  "userAppInfo/userAppInfoTagModalOpen"
);

export const userAppInfoUpdateEditableBlockPropsTags = createAction<{
  id: string;
  tags: Tag[];
}>("userAppInfo/userAppInfoUpdateEditableBlockPropsTags");
export const userAppInfoCreateEditableBlockProps =
  createAction<EditableBlockProp>(
    "userAppInfo/userAppInfoCreateEditableBlockProps"
  );
export const userAppInfoCreateMultipleEditableBlockProps = createAction<
  EditableBlockProp[]
>("userAppInfo/userAppInfoCreateMultipleEditableBlockProps");
export const userAppInfoRemoveEditableBlockProp = createAction<number>(
  "userAppInfo/userAppInfoRemoveEditableBlockProp"
);
export const userAppInfoDarkMode = createAction<boolean>(
  "userAppInfo/userAppInfoDarkMode"
);
export const userAppInfoRemoveMultipleEditableBlockProp = createAction<
  string[]
>("userAppInfo/userAppInfoRemoveMultipleEditableBlockProp");

export const userAppInfo = createAction<UserAppInfo>("userAppInfo/userAppInfo");
export const userAppInfoTriggerResourceSave = createAction<boolean>(
  "userAppInfo/userAppInfoTriggerResourceSave"
);
export const userInfoUpdateSubscription = createAction<{
  customerId: string;
  subId: number;
}>("userInfo/userInfoUpdateSubscription");

export const userInfoRemoveSubscription = createAction(
  "userInfo/userInfoRemoveSubscription"
);
export const userAppInfoRedirectPath = createAction<string | undefined>(
  "userAppInfo/userAppInfoRedirectPath"
);
export const userAppInfoSelectedCurriculumsId = createAction<Array<number>>(
  "userAppInfo/userAppInfoSelectedCurriculumsId"
);
export const userAppInfoResetSelectedTopicsId = createAction(
  "userAppInfo/userAppInfoResetSelectedTopicsId"
);

export const userAppInfoCurriculumExplorerTutorial =
  createAction<CurriculumExplorerTutorialStages>(
    "userAppInfo/userAppInfoCurriculumExplorerTutorial"
  );
export const userAppInfoToggleAnswers = createAction<ToggleAnswers>(
  "userAppInfo/userAppInfoToggleAnswers"
);
export const userAppInfoResourceBuilderTutorial =
  createAction<ResourceBuilderTutorialStages>(
    "userAppInfo/userAppInfoResourceBuilderTutorial"
  );

export const userAppInfoSelectedTopicsId = createAction<{
  topicLabelId: number;
  topicIds: Array<number>;
}>("userAppInfo/userAppInfoSelectedTopicsId");
export const userAppInfoSelectedBlocksId = createAction<{
  blockIds: Array<number>;
}>("userAppInfo/userAppInfoSelectedBlocksId");
export const userAppInfoSelectedAuthors = createAction<{
  authors: Array<{ id: number; name: string }>;
}>("userAppInfo/userAppInfoSelectedAuthors");

export const userAppInfoRole = createAction<string>(
  "userAppInfo/userAppInfoRole"
);
export const userAppInfoExplorerBlocks = createAction<Array<Block>>(
  "userAppInfo/userAppInfoExplorerBlocks"
);
export const userAppInfoAuthorisedToEdit = createAction<boolean>(
  "userAppInfo/userAppInfoAuthorisedToEdit"
);
export const userAppInfoUserCreatedResources = createAction<Array<Block>>(
  "userAppInfo/userAppInfoUserCreatedResources"
);
export const userAppInfoOnlyShowVerifiedBlocks = createAction<boolean>(
  "userAppInfo/userAppInfoOnlyShowVerifiedBlocks"
);
export const userAppInfoExplorerBlockChildren = createAction<{
  parentId: number;
  children: Array<Block>;
}>("userAppInfo/userAppInfoExplorerBlockChildren");
export const userAppInfoExplorerBlocksChildren = createAction<
  {
    parentId: number;
    children: Array<Block>;
  }[]
>("userAppInfo/userAppInfoExplorerBlocksChildren");

export const updateUser = createAction<LoadedUserStatus>("user/updateUser");
export const updateUserVerifiedStatus = createAction<boolean>(
  "user/updateUserVerifiedStatus"
);

export const addUserTags =
  createAction<Array<Tag_Insert_Input>>("user/addUserTags");
export const deleteUserTags = createAction<Array<Tag_Insert_Input>>(
  "user/deleteUserTags"
);
export const getUserByEmail = createAsyncThunk(
  "user/getUserByEmail",
  async (email: string, thunkApi) => {
    const response: ApolloQueryResult<GetUserByEmailQuery> =
      await getUserByEmailApi(email);
    if (response.errors) {
      return thunkApi.rejectWithValue(
        response.errors && response.errors[0].message
      );
    }
    if (response.data?.users?.length === 0) {
      return thunkApi.rejectWithValue(Status.NOT_FOUND);
    }
    return response.data.users[0];
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (userInput: Users_Obj_Rel_Insert_Input, thunkApi) => {
    const response = await createUserApi(userInput);
    if (response.errors) {
      return thunkApi.rejectWithValue(
        response.errors && response.errors[0].message
      );
    }
    return response.data?.insert_users_one;
  }
);

export const updateUserById = createAsyncThunk(
  "user/updateUserById",
  async (userInput: Users_Set_Input, thunkApi) => {
    const response = await updateUserByIdApi(userInput);
    if (response.errors) {
      console.log(response.errors);
      return thunkApi.rejectWithValue(
        response.errors && response.errors[0].message
      );
    }
    return response.data?.update_users_by_pk;
  }
);
