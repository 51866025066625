import AltraNavyLogoLongWithIcon from "@altra-apps/common/assets/logos/AltraNavyLogoLongWithIcon.svg";
import { alterCurriculumExplorerTheme } from "@altra-apps/common/src/styling/altra-curriculum-explorer-theme";
import { DRAWER_WIDTH } from "@altra-apps/common/src/util/custom-types";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import React, { FC, forwardRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

const ElevationScroll: FC = (props) => {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return React.cloneElement(children as any, {
    elevation: trigger ? 4 : 0,
  });
};

type LandingPageTopAppBarProps = {
  ref: any;
  isUserAuthenticated: boolean;
};

/**
 * Top App bar for landing site with logo and pages within app
 * @constructor
 */
export const LandingPageTopAppBarMobile = forwardRef<
  any,
  LandingPageTopAppBarProps
>(({ isUserAuthenticated }, ref) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isTabletOrMobile = useIsMobile();

  return (
    <ElevationScroll>
      <StyledAppBar color="default">
        <StyledToolbar>
          <StyledAppBarCenteredContent>
            {/*Burger icon on mobile device*/}
            {isTabletOrMobile && (
              <IconButton
                color="inherit"
                edge="end"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            {/*Company logo*/}
            <RouterLink
              to={`/`}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{ maxWidth: "125px", width: "max-content" }}
                src={AltraNavyLogoLongWithIcon}
                alt="Altra Logo"
              />
            </RouterLink>
            <div />
            {/*<AuthSignInButtonOrAvatar />*/}
          </StyledAppBarCenteredContent>
          {/*Drawer on mobile*/}
          <Drawer
            onClose={() => setDrawerOpen(false)}
            anchor={"left"}
            open={drawerOpen}
            sx={{ maxWidth: DRAWER_WIDTH }}
          >
            <List>
              <StyledHashLink
                onClick={() => setDrawerOpen(false)}
                smooth
                to="/#home"
              >
                <ListItem button key={"Home"}>
                  <ListItemText primary={"Home"} />
                </ListItem>
              </StyledHashLink>
              <StyledHashLink
                onClick={() => setDrawerOpen(false)}
                smooth
                to="/#apps"
              >
                <ListItem button key={"Apps"}>
                  <ListItemText primary={"Apps"} />
                </ListItem>
              </StyledHashLink>
              <StyledHashLink
                onClick={() => setDrawerOpen(false)}
                to="/#about"
                smooth
              >
                <ListItem button key={"About"}>
                  <ListItemText primary={"About"} />
                </ListItem>
              </StyledHashLink>

              <ListItem button key={"Explore"}>
                <Button
                  sx={{
                    backgroundColor:
                      alterCurriculumExplorerTheme.palette.primary.main,
                  }}
                  size={"small"}
                  variant={"contained"}
                  onClick={() =>
                    process.env.REACT_APP_CURRICULUM_EXPLORER_URL &&
                    window.open(process.env.REACT_APP_CURRICULUM_EXPLORER_URL)
                  }
                >
                  Find SQA Questions
                </Button>
              </ListItem>
              <ListItem button key={"Contact"}>
                <Button
                  onClick={() =>
                    window.open("https://2uluvvlx386.typeform.com/to/CxLRLlOf")
                  }
                  size={"small"}
                  color="primary"
                  variant={"contained"}
                >
                  Contact us
                </Button>
              </ListItem>
              {/*<RouterLink*/}
              {/*  onClick={() => setDrawerOpen(false)}*/}
              {/*  style={{ textDecoration: "none" }}*/}
              {/*  to={`/pricing`}*/}
              {/*>*/}
              {/*  <ListItem button key={"Pricing"}>*/}
              {/*    <ListItemText primary={"Pricing"} />*/}
              {/*  </ListItem>*/}
              {/*</RouterLink>*/}
              {/*<RouterLink*/}
              {/*  onClick={() => setDrawerOpen(false)}*/}
              {/*  style={{ textDecoration: "none" }}*/}
              {/*  to={`/contact`}*/}
              {/*>*/}
              {/*  <ListItem button key={"Contact us"}>*/}
              {/*    <ListItemText primary={"Contact us"} />*/}
              {/*  </ListItem>*/}
              {/*</RouterLink>*/}
            </List>
          </Drawer>
        </StyledToolbar>
        <div ref={ref as any} />
      </StyledAppBar>
    </ElevationScroll>
  );
});

const StyledAppBar = styled(AppBar)`
  display: flex;
  align-items: center;
  //background-color: #4e1efd;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  //background-color: #1ea7fd;
`;

const StyledAppBarCenteredContent = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-around;
  //background-color: red;
`;

const StyledHashLink = styled(HashLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
