import EdinburghInnovations from "@altra-apps/common/assets/partners/EdinburghInnovations.svg";
import EdinburghUni from "@altra-apps/common/assets/partners/EdinburghUni.svg";
import EdVenture from "@altra-apps/common/assets/partners/EdVenture.svg";
import Nile from "@altra-apps/common/assets/partners/Nile.svg";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import React from "react";
import styled from "styled-components";

export const LandingPagePartners = () => {
  const isTabletOrMobile = useIsMobile();

  return (
    <StyledLandingPagePartners
      style={{
        backgroundColor: altraTheme.palette.background.default,
        height: isTabletOrMobile ? "300px" : "auto",
      }}
    >
      <StyledContentContainer
        style={{
          flexDirection: isTabletOrMobile ? "column" : "row",
        }}
      >
        <img
          style={{ maxWidth: "300px", width: "max-content" }}
          src={EdinburghUni}
          alt="EdinburghUni Logo"
        />
        <img
          style={{ maxWidth: "250px", width: "max-content" }}
          src={EdinburghInnovations}
          alt="EdinburghInnovations Logo"
        />
        <img
          style={{ maxWidth: "250px", width: "max-content" }}
          src={EdVenture}
          alt="EdVenture Logo"
        />
        <img
          style={{ maxWidth: "250px", width: "max-content" }}
          src={Nile}
          alt="Nile Logo"
        />
      </StyledContentContainer>
    </StyledLandingPagePartners>
  );
};

const StyledLandingPagePartners = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 100px;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //background-color: red;
  width: 70%;
`;
