import { Toolbar } from "@mui/material";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { LandingPageFooter } from "../components/organisms/LandingPageFooter";
import { LandingPageTopAppBar } from "../components/organisms/LandingPageTopAppBar";
import { PricingTabs } from "../components/organisms/PricingTabs";

export const PricingScreen: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledPricingScreen>
      <LandingPageTopAppBar />

      <Toolbar />

      <PricingTabs />
      <LandingPageFooter />
    </StyledPricingScreen>
  );
};

const StyledPricingScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
