import { Topic } from "@altra-apps/common/src/redux/applicationContext/types";
import { union } from "lodash";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Descendant } from "slate";
import { v1 as uuidv1 } from "uuid";
import {
  Block as AwsBlock,
  Tag_Insert_Input,
  Users_Set_Input,
} from "../graphql/types";
import { ViewHeadingElement } from "../molecules/curriculumExplorerPreviewElements/ViewHeadingElement";
import { ViewParagraphElement } from "../molecules/curriculumExplorerPreviewElements/ViewParagraphElement";
import { TopicLabel } from "../redux/applicationContext/types";
import { Block } from "../redux/user/types";
import { CustomElement } from "./custom-editor-types";
import { BLOCK_TYPES } from "./custom-types";
export const makeStringId = () => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_";
  var charactersLength = characters.length;
  for (var i = 0; i < 15; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const renderBoldFirstLine = (childBlock: Block) => {
  if (childBlock.type === BLOCK_TYPES.PARAGRAPH) {
    return (
      <ViewParagraphElement
        block={{
          id: childBlock.id.toString(),
          type: BLOCK_TYPES.PARAGRAPH,
          children: childBlock.block["children"],
        }}
        doNotLoadChildren={true}
        headerStyle={true}
        trimText={true}
      />
    );
  }
  if (
    childBlock.type === BLOCK_TYPES.HEADING_1 ||
    childBlock.type === BLOCK_TYPES.HEADING_2
  ) {
    return (
      <ViewHeadingElement
        block={{
          id: childBlock.id.toString(),
          type: childBlock.type,
          children: childBlock.block["children"],
        }}
        doNotLoadChildren={true}
        type={childBlock.type === BLOCK_TYPES.HEADING_1 ? "ONE" : "TWO"}
      />
    );
  }
};

export function getStyleForDroppable(style, snapshot) {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  };
}

export const getGraphqlStringFromObject = (obj) => {
  const cleaned = JSON.stringify(obj, null, 2);

  return cleaned.replace(/^[\t ]*"[^:\n\r]+(?<!\\)":/gm, function (match) {
    return match.replace(/"/g, "");
  });
};

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
export const arraysEqual = (array1: any, array2: any) => {
  return (
    array1?.length === array2?.length &&
    array1?.every(function (value, index) {
      return value === array2[index];
    })
  );
};
/**
 * Used for duplicating a block, returns children with their own IDs
 * - Skips text children as these do not need an ID
 * @param element
 */
export const createNewIdsForChildBlocks = (element: CustomElement) => {
  Object.assign(element, {
    ...element,
    id: getUniqueId(),
  });

  if (
    element.type === "paragraph" ||
    element.type === "heading-1" ||
    //@ts-expect-error
    element.type === "answer-text" ||
    //@ts-expect-error
    element.type === "potential-solution" ||
    //@ts-expect-error
    element.type === "answer-text" ||
    element.type === "generic-guidance" ||
    element.type === "additional-guidance"
  )
    return element;
  for (let i = 0; i < element.children?.length; i++) {
    Object.assign(element.children[i], {
      ...element.children[i],
      id: getUniqueId(),
    });

    if (element.children[i]?.children?.length > 0) {
      element.children[i] = createNewIdsForChildBlocks(element.children[i]);
    }
  }
  return element;
};

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) return false;
  const testDummy: TValue = value;
  return true;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type EnumType = { [s: string]: string };

export function mapEnum(enumerable: EnumType, fn: Function): any[] {
  // get all the members of the enum
  let enumMembers: any[] = Object.keys(enumerable).map(
    (key) => enumerable[key]
  );

  // we are only interested in the numeric identifiers as these represent the values
  let enumValues: number[] = enumMembers.filter((v) => typeof v === "string");

  // now map through the enum values
  return enumValues.map((m) => fn(m));
}

/**
 * Given a tier (order_id), get the topic label ids for that tier
 * @param selectedCurriculumsIds
 * @param topicLabels
 * @param tier
 */
export const getTopicLabelIdsForTier = (
  selectedCurriculumsIds: number[],
  topicLabels: { [curriculumId: number]: Array<TopicLabel> },
  tier: number
): number[] => {
  let topicLabelIds: number[] = [];

  selectedCurriculumsIds?.map((curriculumId) => {
    if (topicLabels[curriculumId]) {
      return Array.from(topicLabels[curriculumId])?.map(
        (label) => label.order_id === tier && topicLabelIds.push(label.id)
      );
    }
  });

  return topicLabelIds;
};

/**
 * Returns an array from union of an objects values
 * @param topics
 * @param keys
 */
export function getUnionOfValuesForKeys<T>(
  topics: {
    [key: number]: Array<T>;
  },
  keys?: number[]
): T[] {
  let allValues: T[] = [];

  const topicValuesToIterate = keys
    ? Object.keys(topics)
        .filter((value, index) => keys.includes(parseInt(value)))
        .map((key) => topics[key])
    : Object.values(topics);

  topicValuesToIterate.map((topics) => (allValues = allValues.concat(topics)));
  allValues = union(allValues).filter((value) => !!value);
  return allValues;
}

export const setTokenToLocalStorage = (token: string) => {
  localStorage.setItem("token", token);
};
export const removeTokenFromLocalStorage = () => {
  localStorage.removeItem("token");
};

export const cleanPersistDataFromLocalStorage = () => {
  localStorage.removeItem("persist:root");
};
export const getTokenFromLocalStorage = () =>
  localStorage.getItem("token") || null;

export function getUserTags(
  tags: Array<number>,
  tagType: string,
  userId: number
): Array<Users_Set_Input> {
  if (tags && Object.keys(tags).length !== 0) {
    switch (tagType) {
      case "curriculum": {
        let userTags: Array<Users_Set_Input> = tags?.map((id) => {
          let tag: Tag_Insert_Input = {
            user_interests_id: userId,
            curriculum_id: id,
          };
          return tag;
        });
        return userTags;
      }
      case "topic": {
        let userTags: Array<Users_Set_Input> = tags?.map((id) => {
          let tag: Tag_Insert_Input = {
            user_interests_id: userId,
            topic_id: id,
          };
          return tag;
        });
        return userTags;
      }
      default:
        return new Array<Users_Set_Input>();
    }
  }
  return new Array<Users_Set_Input>();
}

/**
 * Used to get the unique id
 * @returns
 */
export function getUniqueId(): string {
  return `TEMPORARY-${uuidv1()}`;
}

/**
 * Used to get a nested object from a large object
 * - Use case is retreiving a block from the slate editor resource
 * @param theObject
 * @param key
 */
export function getNestedObjectByKey(theObject, key) {
  var result = null;
  if (theObject instanceof Array) {
    for (var i = 0; i < theObject.length; i++) {
      result = getNestedObjectByKey(theObject[i], key);
      if (result) {
        break;
      }
    }
  } else {
    for (var prop in theObject) {
      if (prop === "id") {
        if (theObject[prop] === key) {
          return theObject;
        }
      }
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        result = getNestedObjectByKey(theObject[prop], key);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
}

/**
 * Hook used for determining if component is being hovered
 *  - T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
 *  - hook returns tuple(array) with type [any, boolean]
 *  TODO: Improve hook so hover not enabled if element is parent of child being hovered
 */
export function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);
  useEffect(
    () => {
      const node: any = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

export const getAllChildren = (node: CustomElement): CustomElement[] => {
  let children: CustomElement[] = [];
  node["children"]?.map((c) => {
    // console.log(c);
    children.push(c);
    if (c?.children && c?.children?.length > 0) {
      children = children.concat(getAllChildren(c));
    }
  });
  return children;
};

export const getParentTopicsForTopic = (
  topicId: number,
  topics: Topic[]
): { key: number; value: string }[] => {
  // console.log("GETTING PARENTS FOR", topicId);
  let parents: { key: number; value: string }[] = [];

  const topic: Topic | undefined = topics.find((t1) => t1.id === topicId);
  // console.log("TOPICS IS", topic);

  if (topic && topic.parent_id) {
    const parentTopic: Topic | undefined = topics?.find(
      (t2) => t2.id === topic.parent_id
    );

    // console.log("PARENT TOPIC IS", parentTopic);

    if (parentTopic) {
      parents.push({ key: parentTopic.id, value: parentTopic.title });
    }
    if (parentTopic && parentTopic.parent_id) {
      parents = parents.concat(getParentTopicsForTopic(parentTopic.id, topics));
    }
  }
  return parents;
};

export const sortChildrenIntoBlocks = (
  id: number,
  blocks: AwsBlock[]
): { id: number; children: AwsBlock[] }[] => {
  let returnValue: { id: number; children: AwsBlock[] }[] = [];
  const childBlocks = blocks.filter((b1) => b1.parent_id === id);
  // console.log(childBlocks);
  if (childBlocks.length > 0) {
    childBlocks.map((cb1) => {
      returnValue = returnValue.concat(
        sortChildrenIntoBlocks(
          cb1.id,
          blocks.filter((b1) => b1.parent_id !== id) || []
        )
      );
    });
  }
  returnValue.push({ id: id, children: childBlocks });

  return returnValue;
};

/**
 * Nested function used to iterate over editor tree
 */
export function addNestedChildrenToArray(
  parentBlock: CustomElement,
  blocks: Array<CustomElement>,
  position: number
) {
  blocks.push(parentBlock);
  position = 0;
  parentBlock?.children?.forEach((child) => {
    if (child.type) {
      position = position + 1;
      child = { ...child, position: position };
      addNestedChildrenToArray(child, blocks, position);
    }
  });
}

/**
 * Used to get all custom element blocks from editor
 * - Takes all block from editor and creates 1 by 1 in backend
 * - Checks if parent has child elements, starts position at 0 and increments
 */
export function getAllBlockElements(
  resource: Descendant[]
): Array<CustomElement> {
  const blocks = new Array<CustomElement>();
  resource?.forEach((element) => {
    // TODO: Need to check---assumption is that the root element is always be of resource type
    if ((element as CustomElement).type === "resource") {
      let customElement: CustomElement = element as CustomElement;
      let position: number = 0;
      customElement = { ...customElement, position: position };
      addNestedChildrenToArray(customElement, blocks, position);
    }
  });
  return blocks;
}

/**
 * Used to filter the Jsonb block data for storing in DB.
 * key: list to element to be removed from the Json block data
 * object: root element for the block
 */
export function objectWithoutKey(object, keys: string[]) {
  if ((object as CustomElement).type === "resource") {
    keys = [...keys, "children"];
  }
  return keys.reduce((previousValue, currentValue) => {
    // @ts-ignore
    const { [currentValue]: undefined, ...clean } = previousValue;
    return clean;
  }, object);
}

/**
 * get the parent db id for the block.
 *
 * @param arr root block
 * @param id child block id
 * @returns All parent chain for the block
 */
export function findParent(arr, id) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === id) {
      return [];
      //return null;
    } else if (arr[i].children && arr[i].children.length) {
      const t = findParent(arr[i].children, id);
      if (t !== false) {
        t.push(arr[i].id);
        return t;
      }
    }
  }
  return false;
}
