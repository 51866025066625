import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/system";
import React from "react";
import { useAppSelector } from "../redux/hook";
import { ALTRA_APPS, AppRole } from "../util/custom-types";
import { AppSubBarCurriculumBuilderContents } from "./AppSubBarCurriculumBuilderContens";
import { AppSubBarCurriculumExplorerContents } from "./AppSubBarCurriculumTopicSelectionContents";
import { AppSubBarResourceBuilderContents } from "./AppSubBarResourceBuilderContens";

type AppBarSubComponentProps = {
  appName?: ALTRA_APPS;
};

/**
 * This compoment allows user to manage login, logout and select and filter Curriculum and Subject
 *
 */
const AppBarSubCompoment: React.FC<AppBarSubComponentProps> = (prop) => {
  const { isAuthenticated } = useAuth0();

  const userRole: string = useAppSelector(
    (state) => state.userAppInfo.role
  ) as string;

  return (
    <StyledAppSubCompoment id={"app-sub-bar"}>
      {isAuthenticated &&
        userRole === AppRole.SUPER_ADMIN &&
        prop.appName === ALTRA_APPS.CB && (
          <AppSubBarCurriculumBuilderContents />
        )}
      {prop.appName === ALTRA_APPS.RB ||
        (prop.appName === ALTRA_APPS.CE && (
          <AppSubBarCurriculumExplorerContents />
        ))}
      {prop.appName === ALTRA_APPS.RB && <AppSubBarResourceBuilderContents />}
      {/*<AuthSignInButtonOrAvatar appName={prop.appName} />*/}
    </StyledAppSubCompoment>
  );
};

const StyledAppSubCompoment = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "row",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  minWidth: "45em",
  whiteSpace: "nowrap",
}));

export default AppBarSubCompoment;
