import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { useEffect } from "react";

type ErrorSnackbarProp = {
  error: string;
  severity: AlertColor;
  state: boolean;
};
/**
 * SnackBar wrapper compoment used for handling applications errors
 *
 */
export const ErrorSnackbar: React.FC<ErrorSnackbarProp> = (prop) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(prop.state);
  }, [prop.state]);

  if (prop.error) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert severity={prop.severity} sx={{ width: "100%" }}>
          {prop.error?.concat(". Please contact support team or try later.")}
        </Alert>
      </Snackbar>
    );
  } else {
    return <div />;
  }
};
