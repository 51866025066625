import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Curriculum,
  LoadingStatus,
  Topic,
  TopicLabel,
} from "../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { Block, EditableBlockProp, UserInfo } from "../redux/user/types";
import {
  getTopicLabelIdsForTier,
  getUnionOfValuesForKeys,
} from "../util/helpers";
import { NonEditableDropdown } from "./NonEditableDropdown";
export const AppSubBarResourceBuilderContents: FC = () => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const loadingStatus: LoadingStatus = useAppSelector(
    (state) => state.applicationContext.loadingStatus
  );
  const routerParams = useParams();
  const curriculums: Array<Curriculum> = useAppSelector(
    (state) => state.applicationContext.curriculums
  );
  const selectedCurriculumsIds: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );
  const topicLabels: { [curriculumId: number]: TopicLabel[] } = useAppSelector(
    (state) => state.applicationContext.topicLabels
  );
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext.topics
  );
  const authorised: boolean = useAppSelector(
    (state) => state.userAppInfo.authorisedToEditResource
  );
  const editableBlockProp: Array<EditableBlockProp> = useAppSelector(
    (state) => state.userAppInfo.userEditableBlockProps
  ) as Array<EditableBlockProp>;
  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);

  /**
   * Get top tier topics by finding topic label with order_id 1
   */
  const topTierTopicsForSelectedCurriculums: Array<Topic> = useAppSelector(
    (state) => {
      const topTierTopicLevelIds: number[] = getTopicLabelIdsForTier(
        state.userAppInfo.selectedCurriculumsId || [],
        state.applicationContext.topicLabels,
        1
      );

      return getUnionOfValuesForKeys<Topic>(
        state.applicationContext.topics,
        state.userAppInfo?.selectedCurriculumsId
      ).filter((t) => topTierTopicLevelIds.includes(t.topic_label_id));
    }
  );

  const selectedTopTierTopicIds: Array<number> =
    getUnionOfValuesForKeys<number>(
      useAppSelector((state) => state.userAppInfo?.selectedTopicsId) || {},
      topTierTopicsForSelectedCurriculums.map((t) => t.topic_label_id)
    );

  const history = useHistory();

  if (
    history.location.pathname.includes("editor") &&
    authorised &&
    editableBlockProp.length > 0
  ) {
    return (
      <>
        <NonEditableDropdown
          onClick={() => history.push("/curriculumSelection")}
          label={"Curriculum"}
          value={selectedCurriculumsIds
            .map(
              (scId) =>
                curriculums.find((c) => c.id === scId)?.short_title || ""
            )
            .join(", ")}
        />
        <NonEditableDropdown
          onClick={() => history.push("/topicSelection")}
          label={"Subject"}
          value={
            selectedTopTierTopicIds
              ?.filter(
                (tId) =>
                  getUnionOfValuesForKeys(topicLabels)
                    ?.find(
                      (tl) =>
                        tl.id ===
                        getUnionOfValuesForKeys(topics).find(
                          (t) => t.id === tId
                        )?.topic_label_id
                    )
                    ?.title?.toLowerCase() !== "quick search"
              )
              ?.map(
                (scId) =>
                  topTierTopicsForSelectedCurriculums.find((c) => c.id === scId)
                    ?.title || ""
              )
              .join(", ") || ""
          }
        />
      </>
    );
  } else if (history.location.pathname === "/myResources") {
    return (
      <>
        <NonEditableDropdown
          onClick={() => history.push("/curriculumSelection")}
          label={"Curriculum"}
          value={selectedCurriculumsIds
            ?.map(
              (scId) =>
                curriculums.find((c) => c.id === scId)?.short_title || ""
            )
            .join(", ")}
        />
        <NonEditableDropdown
          onClick={() => history.push("/topicSelection")}
          label={"Subject"}
          value={
            selectedTopTierTopicIds
              ?.filter(
                (tId) =>
                  getUnionOfValuesForKeys(topicLabels)
                    ?.find(
                      (tl) =>
                        tl.id ===
                        getUnionOfValuesForKeys(topics).find(
                          (t) => t.id === tId
                        )?.topic_label_id
                    )
                    ?.title?.toLowerCase() !== "quick search"
              )
              ?.map(
                (scId) =>
                  topTierTopicsForSelectedCurriculums.find((c) => c.id === scId)
                    ?.title || ""
              )
              .join(", ") || ""
          }
        />
      </>
    );
  }
  return <div />;
};
