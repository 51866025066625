import AltraCooperativeStacked from "@altra-apps/common/assets/icons/AltraCooperativeStacked.svg";
import GraphicPastPaperExplorer from "@altra-apps/common/assets/icons/GraphicPastPaperExplorer.svg";
import ResourceBuilderLogoStacked from "@altra-apps/common/assets/logos/ResourceBuilderLogoStacked.svg";
import { alterCurriculumExplorerTheme } from "@altra-apps/common/src/styling/altra-curriculum-explorer-theme";
import { LandingPageContent } from "@altra-apps/common/src/util/LandingPageContent";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Toolbar } from "@mui/material";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { AppPageOverview } from "../components/organisms/AppPageOverview";
import { LandingPageAppVideoPreview } from "../components/organisms/LandingPageAppVideoPreview";
import { LandingPageExploreOtherApps } from "../components/organisms/LandingPageExploreOtherApps";
import { LandingPageFooter } from "../components/organisms/LandingPageFooter";
import { LandingPageTopAppBar } from "../components/organisms/LandingPageTopAppBar";

export const CurriculumExplorerLandingScreen: FC = () => {
  const isTabletOrMobile = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledScreen>
      <LandingPageTopAppBar />
      <Toolbar />
      <AppPageOverview
        title={LandingPageContent.CURRICULUM_EXPLORER_NAME}
        description={LandingPageContent.CURRICULUM_EXPLORER_OVERVIEW_SHORT}
        button1Text={"Open app"}
        button1Link={LandingPageContent.CURRICULUM_EXPLORER_URL}
        button2Text={"How it works"}
        button2Link={"#howitworks"}
        theme={alterCurriculumExplorerTheme}
        image={GraphicPastPaperExplorer}
      />
      <LandingPageAppVideoPreview
        theme={alterCurriculumExplorerTheme}
        bodyText={LandingPageContent.CURRICULUM_EXPLORER_OVERVIEW_LONG}
        externalLink={LandingPageContent.CURRICULUM_EXPLORER_URL}
        videoId={"ODRHON8PimI"}
      />
      {/*<LandingPagePricing*/}
      {/*  theme={alterCurriculumExplorerTheme}*/}
      {/*  bodyText={LandingPageContent.CURRICULUM_EXPLORER_PRICING}*/}
      {/*  openAppLink={LandingPageContent.CURRICULUM_EXPLORER_URL}*/}
      {/*  whiteBackground*/}
      {/*/>*/}
      {!isTabletOrMobile ? (
        <LandingPageExploreOtherApps
          apps={[
            {
              image: ResourceBuilderLogoStacked,
              link: "/resourcebuilder",
            },
            {
              image: AltraCooperativeStacked,
              link: "/altracooperative",
            },
          ]}
        />
      ) : (
        <LandingPageExploreOtherApps
          apps={[
            {
              image: AltraCooperativeStacked,
              link: "/altracooperative",
            },
          ]}
        />
      )}
      <LandingPageFooter />
    </StyledScreen>
  );
};

const StyledScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
