import { ApolloQueryResult } from "@apollo/client";
import {
  ApplicationContextDocument,
  ApplicationContextQuery,
} from "../../graphql/types";
import { apolloClientForGuestUser } from "../../providers/apolloClient";

export async function getApplicationContextApi(): Promise<
  ApolloQueryResult<ApplicationContextQuery>
> {
  const applicationContext: ApolloQueryResult<ApplicationContextQuery> =
    await apolloClientForGuestUser.query({
      query: ApplicationContextDocument,
      context: {
        // We have to add this additinal header for all guest user
        headers: {
          "x-hasura-role": "user",
        },
      },
    });
  return applicationContext;
}
