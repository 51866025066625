import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { altraTheme } from "../../styling/altra-theme";
import { getS3URL } from "../../util/convertToS3Url";
import {
  AnswerTextElement,
  CustomText,
  ParagraphElement,
} from "../../util/custom-editor-types";
import { prettyQuestionText } from "../../util/pretty-text";
import { MathsTypography } from "../MathsTypography";
import { Leaf } from "../resourceBuilderElements/Leaf";

type ViewParagraphElementProps = {
  block: ParagraphElement | AnswerTextElement;
  inline?: boolean;
  doNotLoadChildren?: boolean;
  headerStyle?: boolean;
  trimText?: boolean;
};

/**
 * View only element for displaying Paragraph blocks
 * @param block
 * @param inline
 * @param doNotLoadChildren
 * @param headerStyle
 * @param trimText
 * @constructor
 */
export const ViewParagraphElement: FC<ViewParagraphElementProps> = ({
  block,
  inline,
  doNotLoadChildren,
  headerStyle,
  trimText,
}) => {
  const renderInlineElementsAndText = (node: CustomText) => {
    //@ts-expect-error
    if (node.type === "math") {
      return (
        <MathsTypography
          headerStyle={headerStyle}
          //@ts-expect-error
          latexString={node.latex}
        />
      );
      //@ts-expect-error
    } else if (node.type === "link") {
      return (
        <Button
          //@ts-expect-error
          href={getS3URL(node?.url)}
          target="_blank"
          sx={{
            textDecoration: "underline!important",
          }}
          size={"large"}
          variant={"text"}
        >
          {/*//@ts-expect-error*/}
          {node?.children?.map((c) => (
            <Leaf headerStyle={headerStyle} leaf={node}>
              {c.text}
            </Leaf>
          ))}
        </Button>
      );
    } else {
      return (
        <Leaf headerStyle={headerStyle} leaf={node}>
          {node && node?.text
            ? trimText
              ? prettyQuestionText(node.text).trim() === ""
                ? node.text
                : prettyQuestionText(node.text)
              : node.text
            : ""}
        </Leaf>
      );
    }
  };

  return (
    <StyledSpanContainer
      inline={inline || false}
      style={altraTheme.typography.body1}
      // sx={{
      //   height:
      //     block.children.length === 1 && block.children[0]["text"] === ""
      //       ? "40px"
      //       : "auto",
      // }}
    >
      {block.children.length > 0 ? (
        <div style={{ margin: "0.25em 0" }}>
          {block.children.map((textEl) => renderInlineElementsAndText(textEl))}
        </div>
      ) : (
        <span>
          <Leaf leaf={[{ text: "" }]}>{""}</Leaf>
        </span>
      )}
    </StyledSpanContainer>
  );
};

type StyledSpanContainerProps = {
  inline: boolean;
};
const StyledSpanContainer = styled("span")(
  (props: StyledSpanContainerProps) => ({
    margin: "0.25em 0",
    // maxHeight: props.inline ? "7ch" : "auto",
  })
);
