import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Button, Theme, Typography } from "@mui/material";
import React, { FC } from "react";
import styled from "styled-components";

type LandingPageAppVideoPreviewProps = {
  theme: Theme;
  bodyText: string;
  videoId?: string;
  externalLink?: string;
};

export const LandingPageAppVideoPreview: FC<
  LandingPageAppVideoPreviewProps
> = ({ bodyText, theme, externalLink, videoId }) => {
  const isTabletOrMobile = useIsMobile();

  return (
    <StyledLandingPageAppOverviews
      style={{
        backgroundColor: altraTheme.palette.background.default,
      }}
      id={"howitworks"}
    >
      <StyledAppsContainer style={{ width: isTabletOrMobile ? "85%" : "60%" }}>
        <StyledAppContainer
          style={{ flexDirection: isTabletOrMobile ? "column" : "row" }}
        >
          <StyledInformationColumn
            style={{
              marginRight: "20px",
              marginLeft: "0px",
              width: isTabletOrMobile ? "100%" : "100%",
            }}
          >
            <Typography
              variant={"h1"}
              style={{
                color: theme.palette.primary.main,
              }}
            >
              How it works
            </Typography>
            <Typography
              style={{
                marginTop: 20,
              }}
              color={"textSecondary"}
              variant={"body1"}
            >
              {bodyText}
            </Typography>

            {/*{videoId && <YoutubeEmbed embedId={videoId} />}*/}
            <StyledButtonRow>
              {externalLink && (
                <Button
                  size={"large"}
                  href={externalLink}
                  variant={"contained"}
                  style={{
                    marginRight: "20px",
                    color: "#FFF",
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  Try now
                </Button>
              )}
            </StyledButtonRow>
          </StyledInformationColumn>
        </StyledAppContainer>
      </StyledAppsContainer>
    </StyledLandingPageAppOverviews>
  );
};

const StyledLandingPageAppOverviews = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
  padding-top: 40px;
  margin-bottom: 40px;
`;

const StyledAppsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  //background-color: red;
`;

const StyledAppContainer = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: center;
  //background-color: #3fa888;
`;

const StyledInformationColumn = styled.div`
  display: flex;
  //background-color: purple;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
`;

const StyledButtonRow = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
