import UnVerifiedTick from "@altra-apps/common/assets/icons/UnVerifiedTick.svg";
import VerifiedTick from "@altra-apps/common/assets/icons/VerifiedTick.svg";
import { useAppDispatch } from "@altra-apps/common/src/redux/hook";
import { Button, tableCellClasses } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import * as React from "react";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  useGetAllUsersLazyQuery,
  useUpdateUserByIdMutation,
} from "../graphql/types";
import { useAppSelector } from "../redux/hook";
import { updateUserVerifiedStatus } from "../redux/user/actions";
import { UserInfo } from "../redux/user/types";
import { AccountScreenContainerMode, AppRole } from "../util/custom-types";

enum UserStatus {
  TEACHER,
  ADMIN,
  INACTIVE,
}
type User = {
  id: number;
  name: string;
  status: string;
  email: string;
  institution: string;
  verified: boolean;
};

type UsersTableProps = {
  mode: AccountScreenContainerMode;
};
/**
 * Table representing information about users within the system
 * @constructor
 */
export const UsersTable: FC<UsersTableProps> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loggedInUser: UserInfo = useAppSelector((state) => state.user.userInfo);
  const [user, setUser] = React.useState<Array<User>>();

  const [updateUserByIdMutation] = useUpdateUserByIdMutation();

  const userRole: string = useAppSelector(
    (state) => state.userAppInfo.role
  ) as string;

  // For super user => get all users
  const [getAllUsersCallback, { data: allUsersData, error: allUsesError }] =
    useGetAllUsersLazyQuery();

  const handleUserVerifiedStatus = async (changedUser: User) => {
    console.log("VERIFIED STATUS CHANGING");
    const response = await updateUserByIdMutation({
      variables: {
        userId: changedUser.id,
        userInput: { verified: !changedUser.verified },
      },
    });
    if (response.data) {
      let _users = new Array<User>();
      _users = Object.assign(_users, user!);
      _users?.forEach((f) => {
        if (f.id === response.data?.update_users_by_pk?.id) {
          f.verified = response.data?.update_users_by_pk?.verified;
          // Update redux state if the loggedin user status is changed
          if (loggedInUser?.id === response.data?.update_users_by_pk?.id) {
            // update redux user verified state
            dispatch(
              updateUserVerifiedStatus(
                response.data?.update_users_by_pk?.verified
              )
            );
          }
        }
      });
      setUser(_users);
    }
  };

  useEffect(() => {
    getAllUsersCallback({
      //TODO - Pagination need to be implemented
      variables: { limit: 100, offset: 0 },
    });
    if (allUsesError) {
      console.log(allUsesError);
    }
  }, [userRole]);

  useEffect(() => {
    let _users = new Array<User>();
    if (allUsersData?.users && userRole !== AppRole.SUPER_ADMIN) {
      allUsersData?.users
        .filter((f) => f.institution_id === loggedInUser.institution_id)
        .forEach((element) => {
          const user: User = {
            id: element.id,
            email: element.email,
            institution: element.institution?.display_name!,
            name: element.display_name!,
            status: "TEACHER", //TODO: HARDCODED, NEED TO FIX
            verified: element.verified,
          };
          _users.push(user);
        });
      setUser(_users);
    } else {
      allUsersData?.users.forEach((element) => {
        const user: User = {
          id: element.id,
          email: element.email,
          institution: element.institution?.display_name!,
          name: element.display_name!,
          status: "TEACHER", //TODO: HARDCODED, NEED TO FIX
          //verified: element.active ? "INACTIVE" : "ACTIVE",
          verified: element.verified,
        };
        _users.push(user);
      });
      setUser(_users);
    }
  }, [allUsersData]);

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        sx={{ minWidth: 700, root: { fontSize: "10px" } }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            {/*<StyledTableCell>Topics</StyledTableCell>*/}
            {/*<StyledTableCell>Institution</StyledTableCell>*/}
            <StyledTableCell>Subscription</StyledTableCell>
            <StyledTableCell>Verified</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {user?.map((element) => (
            <TableRow
              key={element.id!}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {element.name!}
              </StyledTableCell>
              <StyledTableCell>{element.status}</StyledTableCell>
              <StyledTableCell>{element.email}</StyledTableCell>
              {/*<StyledTableCell>*/}
              {/*  <Button variant={"outlined"} size={"small"}>*/}
              {/*    Edit preferences*/}
              {/*  </Button>*/}
              {/*</StyledTableCell>*/}
              {/*<StyledTableCell>{element.institution}</StyledTableCell>*/}
              <StyledTableCell>
                <Button
                  onClick={() => history.push("/updateSubscription")}
                  variant={"outlined"}
                  size={"small"}
                >
                  Edit subscription
                </Button>
              </StyledTableCell>

              {element.verified && (
                <StyledTableCell>
                  <img
                    style={{ maxHeight: "20px", cursor: "pointer" }}
                    src={VerifiedTick}
                    alt={"Diagram"}
                    onClick={() => handleUserVerifiedStatus(element)}
                  />
                </StyledTableCell>
              )}

              {!element.verified && (
                <StyledTableCell>
                  <img
                    style={{ maxHeight: "20px", cursor: "pointer" }}
                    src={UnVerifiedTick}
                    alt={"Diagram"}
                    onClick={() => handleUserVerifiedStatus(element)}
                  />
                </StyledTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
  whiteSpace: "nowrap",
}));
