import BodyText from "@altra-apps/common/assets/blockTypeIcons/BodyText.svg";
import Code from "@altra-apps/common/assets/blockTypeIcons/Code.svg";
import Header from "@altra-apps/common/assets/blockTypeIcons/Header1.svg";
import Image from "@altra-apps/common/assets/blockTypeIcons/Image.svg";
import Passage from "@altra-apps/common/assets/blockTypeIcons/Passage.svg";
import Question from "@altra-apps/common/assets/blockTypeIcons/Question.svg";
import Quote from "@altra-apps/common/assets/blockTypeIcons/Quote.svg";
import Resource from "@altra-apps/common/assets/blockTypeIcons/Resource.svg";
import Section from "@altra-apps/common/assets/blockTypeIcons/Section.svg";

export const CurriculumExplorerTutorialContent = [
  {
    title: "Search",
    description:
      "Search by keyword or topic to instantly find learning materials for you",
  },
  {
    title: "Quick search",
    description: "Narrow your results using the most common search terms",
  },
  {
    title: "Filter",
    description:
      "Filter by learning outcomes, topics and levels for your subject",
  },
  {
    title: "Apply filters",
    description: "Don’t forget to apply your filters!",
  },
  {
    title: "Do more with your learning materials",
    description: "Revise questions and get feedback from your teacher",
  },
];

export enum ALTRA_APPS {
  LS = "Landing site",
  // PPE = "Past Paper Explorer",
  // RG = "Resource Generator",
  // ST = "Student Tracker",
  CE = "Curriculum Explorer",
  RB = "Resource Builder",
  CB = "Curriculum Builder",
}
export type ResourceBuilderToolbarProps = {
  bold: boolean;
  italic: boolean;
  maths: boolean;
  backgroundColours: boolean;
  textColours: boolean;
  underline: boolean;
  link: boolean;
};

export const TOOLBAR_PROPS_BY_BLOCK: {
  [key: string]: ResourceBuilderToolbarProps;
} = {
  paragraph: {
    bold: true,
    italic: true,
    maths: true,
    backgroundColours: true,
    textColours: true,
    underline: true,
    link: true,
  },
  "heading-1": {
    bold: false,
    italic: false,
    maths: false,
    backgroundColours: true,
    textColours: true,
    underline: false,
    link: false,
  },
  "heading-2": {
    bold: false,
    italic: false,
    maths: false,
    backgroundColours: true,
    textColours: true,
    underline: false,
    link: false,
  },
  "block-quote": {
    bold: true,
    italic: true,
    maths: false,
    backgroundColours: true,
    textColours: true,
    underline: false,
    link: false,
  },
  "generic-guidance": {
    bold: true,
    italic: true,
    maths: true,
    backgroundColours: true,
    textColours: true,
    underline: true,
    link: true,
  },
  "additional-guidance": {
    bold: true,
    italic: true,
    maths: true,
    backgroundColours: true,
    textColours: true,
    underline: true,
    link: true,
  },
  solution: {
    bold: true,
    italic: true,
    maths: true,
    backgroundColours: true,
    textColours: true,
    underline: true,
    link: true,
  },
  "answer-text": {
    bold: true,
    italic: true,
    maths: true,
    backgroundColours: true,
    textColours: true,
    underline: true,
    link: true,
  },
  code: {
    bold: true,
    italic: true,
    maths: false,
    backgroundColours: true,
    textColours: true,
    underline: false,
    link: true,
  },
  "code-line": {
    bold: true,
    italic: true,
    maths: false,
    backgroundColours: true,
    textColours: true,
    underline: false,
    link: true,
  },
  link: {
    bold: false,
    italic: false,
    maths: false,
    backgroundColours: false,
    textColours: false,
    underline: false,
    link: true,
  },
  math: {
    bold: false,
    italic: false,
    maths: false,
    backgroundColours: false,
    textColours: false,
    underline: false,
    link: false,
  },
};
export enum BLOCK_TYPES {
  RESOURCE = "resource",
  SECTION = "section",
  PASSAGE = "passage",
  GRID = "grid",
  LINK = "link",
  HEADING_1 = "heading-1",
  HEADING_2 = "heading-2",
  QUESTION_WITH_SUB_PART = "question-with-sub-parts",
  QUESTION_TEXT_ONLY = "question-text-only",
  ANSWER = "answer",
  GENERIC_GUIDANCE = "generic-guidance",
  POTENTIAL_SOLUTIONS = "potential-solutions",
  SOLUTION = "solution",
  ADDITIONAL_GUIDANCE = "additional-guidance",
  ANSWER_TEXT = "answer-text",
  PARAGRAPH = "paragraph",
  BLOCK_QUOTE = "block-quote",
  BULLETED_LIST = "bulleted-list",
  NUMBERED_LIST = "numbered-list",
  LIST_ITEM = "list-item",
  DIAGRAM = "diagram",
  CODE = "code",
  CODE_LINE = "code-line",
  MATH = "math",
}

export const BLOCK_ICONS_FOR_EXPLORER: KeyValueDiagram[] = [
  { key: 0, value: "Question", diagram: Question },
  { key: 1, value: "Resource", diagram: Resource },
  { key: 2, value: "Section", diagram: Section },
  { key: 3, value: "Passage", diagram: Passage },
  // { key: 4, value: "Answers & Marking schemes" },
  // { key: 5, value: "Answer" },
  { key: 5, value: "Paragraph", diagram: BodyText },
  { key: 6, value: "Images & Diagrams", diagram: Image },
  { key: 7, value: "Quote", diagram: Quote },
  { key: 8, value: "Code", diagram: Code },
  { key: 9, value: "Heading", diagram: Header },
];

export const BLOCK_DESCRIPTION_FOR_EXPLORER: KeyValueDescription[] = [
  {
    key: 0,
    value: "Question",
    description: "Tasks, activities, exam questions",
  },
  {
    key: 1,
    value: "Resource",
    description: "Lesson plans, textbooks, exam papers",
  },
  {
    key: 2,
    value: "Section",
    description: "Parts of resources, chapters, topics",
  },
  { key: 3, value: "Passage", description: "Sources, news articles, passages" },
  // { key: 4, value: "Answers & Marking schemes" },
  // { key: 5, value: "Answer" },
  { key: 5, value: "Paragraph", description: "ddd" },
  {
    key: 6,
    value: "Images & Diagrams",
    description: "Images, whiteboards, art",
  },
  { key: 7, value: "Quote", description: "Historical quotes, important text" },
  { key: 8, value: "Code", description: "Code snippets, pseudocode" },
  { key: 9, value: "Heading", description: "ddd" },
];

export const BLOCK_TYPES_FOR_EXPLORER: KeyValue[] = [
  { key: 0, value: "Question" },
  { key: 1, value: "Resource" },
  { key: 2, value: "Section" },
  { key: 3, value: "Passage" },
  // { key: 4, value: "Answers & Marking schemes" },
  // { key: 5, value: "Answer" },
  // { key: 5, value: "Paragraph" },
  { key: 6, value: "Images & Diagrams" },
  { key: 7, value: "Quote" },
  { key: 8, value: "Code" },
  // { key: 9, value: "Heading" },
];

export const DRAWER_WIDTH = 300;

export type KeyValue = {
  key: number;
  value: string;
};

export type KeyValueDiagram = {
  key: number;
  value: string;
  diagram: string;
};
export type KeyValueDescription = {
  key: number;
  value: string;
  description: string;
};

export enum Status {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SEARCHING = "SEARCHING",
  IDLE = "IDLE",
  ERROR = "ERROR",
  NOT_FOUND = "NOT_FOUND",
  WARNING = "WARNING",
}

export enum MultiSelectDropDownMode {
  TOP_TIER_TOPIC = "TOP_TIER_TOPIC",
  TOPIC = "TOPIC",
  CURRICULUM = "CURRICULUM",
}

export enum AccountScreenContainerMode {
  INSTITUTION = "institution",
  USER = "user",
}

export enum AppRole {
  USER = "user",
  ADMIN = "admin",
  SUPER_ADMIN = "superAdmin",
}
export enum AppSubscriptionPlan {
  FREE = "free",
  INDIVIDUAL_PREMIUM = "individualPremium",
  INSTITUTION_PREMIUM = "institutionPremium",
}
