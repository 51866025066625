import AltraNavyLogoLongWithIcon from "@altra-apps/common/assets/logos/AltraNavyLogoLongWithIcon.svg";
import CurriculumBuilderLogoLong from "@altra-apps/common/assets/logos/CurriculumBuilderLogoLong.svg";
import CurriculumBuilderLogoStacked from "@altra-apps/common/assets/logos/CurriculumBuilderLogoStacked.svg";
import CurriculumExplorerLogoLong from "@altra-apps/common/assets/logos/CurriculumExplorerLogoLong.svg";
import CurriculumExplorerLogoStacked from "@altra-apps/common/assets/logos/CurriculumExplorerLogoStacked.svg";
import ResourceBuilderLogoLong from "@altra-apps/common/assets/logos/ResourceBuilderLogoLong.svg";
import ResourceBuilderLogoStacked from "@altra-apps/common/assets/logos/ResourceBuilderLogoStacked.svg";
import { ALTRA_APPS } from "../util/custom-types";
import { FONT_WEIGHTS } from "./altra-theme";

export const CUSTOM_HEADER_THEME_FOR_RESOURCE = {
  ONE: {
    fontWeight: FONT_WEIGHTS.SUPER_BOLD_800,
    fontSize: 26,
    letterSpacing: 0.4,
    fontFamily: "Inter",
  },
  TWO: {
    fontWeight: FONT_WEIGHTS.SUPER_BOLD_800,
    fontSize: 20,
    letterSpacing: 0.4,
    fontFamily: "Inter",
  },
};

export type AppTheme = {
  [key in ALTRA_APPS]: {
    primary: string;
    primaryLight: string;
    secondary: string;
    secondaryLight: string;
    ternaryLight: string;
    ternaryDark: string;
    textPrimary: string;
    textSecondary: string;
    textPrimaryDisabled: string;
    textSecondaryDisabled: string;
    textLight: string;
    white: string;
    success: string;
    danger: string;
    link: string;
  };
};

export type AppIcon = {
  [key in ALTRA_APPS]: string;
};

export const APP_THEME: AppTheme = {
  [ALTRA_APPS.LS]: {
    primary: "#03D2FF",
    primaryLight: "rgba(3,210,255,0.08)",
    secondary: "#000034",
    secondaryLight: "rgba(0,0,52,0.08)",
    ternaryLight: "#D8D5D5",
    ternaryDark: "#515151",
    textPrimary: "#FFF",
    textSecondary: "#000034",
    textPrimaryDisabled: "rgba(255,255,255,0.2)",
    textSecondaryDisabled: "rgba(0,0,52,0.2)",
    textLight: "#BBB",
    white: "#FFF",
    success: "#4BB543",
    danger: "#F32013",
    link: "#F4406B",
  },
  // [ALTRA_APPS.PPE]: {
  //   primary: "#FCE503",
  //   primaryLight: "rgba(249,77,118,0.08)",
  //   secondary: "#000034",
  //   secondaryLight: "rgba(0,0,52,0.08)",
  //   ternaryLight: "#D8D5D5",
  //   ternaryDark: "#515151",
  //   textPrimary: "#000034",
  //   textSecondary: "#FFF",
  //   textPrimaryDisabled: "rgba(0,0,52,0.2)",
  //   textSecondaryDisabled: "rgba(255,255,255,0.2)",
  //   textLight: "#BBB",
  //   white: "#FFF",
  //   success: "#4BB543",
  //   danger: "#F32013",
  //   link: "#0499BA",
  // },
  // [ALTRA_APPS.RG]: {
  //   primary: "#F4406B",
  //   primaryLight: "rgba(244,64,107,0.08)",
  //   secondary: "#000034",
  //   secondaryLight: "rgba(0,0,52,0.08)",
  //   ternaryLight: "#D8D5D5",
  //   ternaryDark: "#515151",
  //   textPrimary: "#FFF",
  //   textSecondary: "#000034",
  //   textPrimaryDisabled: "rgba(255,255,255,0.2)",
  //   textSecondaryDisabled: "rgba(0,0,52,0.2)",
  //   textLight: "#BBB",
  //   white: "#FFF",
  //   success: "#4BB543",
  //   danger: "#F32013",
  //   link: "#0499BA",
  // },
  [ALTRA_APPS.CB]: {
    primary: "#F4406B",
    primaryLight: "rgba(244,64,107,0.08)",
    secondary: "#000034",
    secondaryLight: "rgba(0,0,52,0.08)",
    ternaryLight: "#D8D5D5",
    ternaryDark: "#515151",
    textPrimary: "#FFF",
    textSecondary: "#000034",
    textPrimaryDisabled: "rgba(255,255,255,0.2)",
    textSecondaryDisabled: "rgba(0,0,52,0.2)",
    textLight: "#BBB",
    white: "#FFF",
    success: "#4BB543",
    danger: "#F32013",
    link: "#0499BA",
  },
  // [ALTRA_APPS.ST]: {
  //   primary: "#03D2FF",
  //   primaryLight: "rgba(3,210,255,0.08)",
  //   secondary: "#000034",
  //   secondaryLight: "rgba(0,0,52,0.08)",
  //   ternaryLight: "#D8D5D5",
  //   ternaryDark: "#515151",
  //   textPrimary: "#FFF",
  //   textSecondary: "#000034",
  //   textPrimaryDisabled: "rgba(255,255,255,0.2)",
  //   textSecondaryDisabled: "rgba(0,0,52,0.2)",
  //   textLight: "#BBB",
  //   white: "#FFF",
  //   success: "#4BB543",
  //   danger: "#F32013",
  //   link: "#F4406B",
  // },
  [ALTRA_APPS.CE]: {
    primary: "#FCE503",
    primaryLight: "rgba(249,77,118,0.08)",
    secondary: "#000034",
    secondaryLight: "rgba(0,0,52,0.08)",
    ternaryLight: "#D8D5D5",
    ternaryDark: "#515151",
    textPrimary: "#000034",
    textSecondary: "#FFF",
    textPrimaryDisabled: "rgba(0,0,52,0.2)",
    textSecondaryDisabled: "rgba(255,255,255,0.2)",
    textLight: "#BBB",
    white: "#FFF",
    success: "#4BB543",
    danger: "#F32013",
    link: "#0499BA",
  },
  [ALTRA_APPS.RB]: {
    primary: "#F4406B",
    primaryLight: "rgba(244,64,107,0.08)",
    secondary: "#000034",
    secondaryLight: "rgba(0,0,52,0.08)",
    ternaryLight: "#D8D5D5",
    ternaryDark: "#515151",
    textPrimary: "#FFF",
    textSecondary: "#000034",
    textPrimaryDisabled: "rgba(255,255,255,0.2)",
    textSecondaryDisabled: "rgba(0,0,52,0.2)",
    textLight: "#BBB",
    white: "#FFF",
    success: "#4BB543",
    danger: "#F32013",
    link: "#0499BA",
  },
};

export const APP_ICON_LONG: AppIcon = {
  [ALTRA_APPS.LS]: AltraNavyLogoLongWithIcon,
  // [ALTRA_APPS.PPE]: PastPaperExplorerLogoLong,
  // [ALTRA_APPS.RG]: ResourceBuilderLogoLong,
  // [ALTRA_APPS.ST]: AltraNavyLogoLongWithIcon,
  [ALTRA_APPS.CE]: CurriculumExplorerLogoLong,
  [ALTRA_APPS.RB]: ResourceBuilderLogoLong,
  [ALTRA_APPS.CB]: CurriculumBuilderLogoLong,
};

export const APP_ICON_STACKED: AppIcon = {
  [ALTRA_APPS.LS]: AltraNavyLogoLongWithIcon,
  // [ALTRA_APPS.PPE]: PastPaperExplorerLogoStacked,
  // [ALTRA_APPS.RG]: ResourceBuilderLogoStacked,
  // [ALTRA_APPS.ST]: AltraNavyLogoLongWithIcon,
  [ALTRA_APPS.CE]: CurriculumExplorerLogoStacked,
  [ALTRA_APPS.RB]: ResourceBuilderLogoStacked,
  [ALTRA_APPS.CB]: CurriculumBuilderLogoStacked,
};

// export const APP_URL: AppIcon = {
//   [ALTRA_APPS.LS]: "http://localhost:3002",
//   [ALTRA_APPS.PPE]: "http://localhost:3001",
//   [ALTRA_APPS.RG]: "http://localhost:3000",
//   [ALTRA_APPS.ST]: "http://localhost:3000",
// };

export const APP_URL: AppIcon = {
  [ALTRA_APPS.LS]: "REACT_APP_LANDING_URL",
  // [ALTRA_APPS.PPE]: "https://pastpaperexplorer.altra.space",
  // [ALTRA_APPS.RG]: "https://resourcegenerator.altra.space",
  // [ALTRA_APPS.ST]: "https://altra.space",
  [ALTRA_APPS.CE]: "REACT_APP_CURRICULUM_EXPLORER_URL",
  [ALTRA_APPS.RB]: "REACT_APP_RESOURCE_BUILDER_URL",
  [ALTRA_APPS.CB]: "REACT_APP_CURRICULUM_BUILDER_URL",
};

export const THEME_COLOURS = {
  buttonYellow: "#FCE503",
  navy: "#000034",
  editableVeryLightBlue: "#fafaff",
  cardLightBlue: "#ebebff",
  textLightGrey: "#bcbcc8",
  darkBackgroundGrey: "#bcbcc8",
  white: "#FFF",
  successGreen: "#4BB543",
  dangerRed: "#F32013",
  pink: "#F4406B",
};
