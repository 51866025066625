import AltraNavyLogoLongWithIcon from "@altra-apps/common/assets/logos/AltraNavyLogoLongWithIcon.svg";
import { AppBar, Button, Link, Toolbar, useScrollTrigger } from "@mui/material";
import React, { FC, forwardRef } from "react";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

const ElevationScroll: FC = (props) => {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return React.cloneElement(children as any, {
    elevation: trigger ? 4 : 0,
  });
};

type LandingPageTopAppBarProps = {
  ref: any;
  isUserAuthenticated: boolean;
};

/**
 * Top App bar for landing site with logo and pages within app
 * @constructor
 */

export const LandingPageTopAppBarDesktop = forwardRef<
  any,
  LandingPageTopAppBarProps
>(({ isUserAuthenticated }, ref) => {
  return (
    <ElevationScroll>
      <AppBar color="default">
        <StyledToolbar>
          <StyledAppBarCenteredContent>
            {/*Company logo*/}
            <HashLink
              to={`/#home`}
              smooth
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{ maxWidth: "125px", width: "max-content" }}
                src={AltraNavyLogoLongWithIcon}
                alt="Altra Logo"
              />
            </HashLink>
            {/*Page navigation*/}
            <StyledTabs>
              <HashLink
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                smooth
                to="/#about"
              >
                <Link
                  color="secondary"
                  underline="hover"
                  component="button"
                  variant={"h5"}
                >
                  About
                </Link>
              </HashLink>
              <HashLink
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                smooth
                to="/#apps"
              >
                <Link
                  color="secondary"
                  underline="hover"
                  component="button"
                  variant={"h5"}
                >
                  Apps
                </Link>
              </HashLink>

              {/*<RouterLink*/}
              {/*  to={`/pricing`}*/}
              {/*  style={{*/}
              {/*    display: "flex",*/}
              {/*    alignItems: "center",*/}
              {/*    textDecoration: "none",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Link*/}
              {/*    color="secondary"*/}
              {/*    underline="hover"*/}
              {/*    component="button"*/}
              {/*    variant={"h5"}*/}
              {/*  >*/}
              {/*    Pricing*/}
              {/*  </Link>{" "}*/}
              {/*</RouterLink>*/}

              <Button
                onClick={() =>
                  window.open("https://2uluvvlx386.typeform.com/to/CxLRLlOf")
                }
                size={"small"}
                color="primary"
                variant={"contained"}
              >
                Contact us
              </Button>

              {/*<AuthSignInButtonOrAvatar />*/}
            </StyledTabs>
          </StyledAppBarCenteredContent>
        </StyledToolbar>
        <div ref={ref as any} />
      </AppBar>
    </ElevationScroll>
  );
});

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const StyledAppBarCenteredContent = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-around;
`;
const StyledTabs = styled.div`
  display: flex;
  justify-content: space-around;
  width: 45%;
`;
