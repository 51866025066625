import { useAppSelector } from "@altra-apps/common/src/redux/hook";
import { UserInfo } from "@altra-apps/common/src/redux/user/types";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useEffect, useRef } from "react";
import { LandingPageTopAppBarDesktop } from "./LandingPageTopAppBarDesktop";
import { LandingPageTopAppBarMobile } from "./LandingPageTopAppBarMobile";

export const LandingPageTopAppBar = () => {
  const ref = useRef();

  const isTabletOrMobile = useIsMobile();
  const { isAuthenticated } = useAuth0();

  const user: UserInfo = useAppSelector((state) => state.user.userInfo);

  useEffect(() => {}, [user]);

  return isTabletOrMobile ? (
    <ToolbarContext.Provider value={ref}>
      <LandingPageTopAppBarMobile
        isUserAuthenticated={isAuthenticated}
        ref={ref}
      />
    </ToolbarContext.Provider>
  ) : (
    <ToolbarContext.Provider value={ref}>
      <LandingPageTopAppBarDesktop
        isUserAuthenticated={isAuthenticated}
        ref={ref}
      />
    </ToolbarContext.Provider>
  );
};

const ToolbarContext = createContext({ current: undefined });
