import GraphicNetworkBlue from "@altra-apps/common/assets/icons/GraphicNetworkBlue.svg";
import GraphicPastPaperExplorer from "@altra-apps/common/assets/icons/GraphicPastPaperExplorer.svg";
import { alterCurriculumExplorerTheme } from "@altra-apps/common/src/styling/altra-curriculum-explorer-theme";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { openInNewTab } from "@altra-apps/common/src/util/helpers";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Button, Divider, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

export const LandingPageOverview = () => {
  const isTabletOrMobile = useIsMobile();

  return (
    <StyledLandingPageOverview>
      <StyledContentContainer
        style={{
          width: isTabletOrMobile ? "90%" : "70%",
        }}
      >
        <StyledHashLinkContainer
          style={{ flexDirection: isTabletOrMobile ? "column" : "row" }}
          id={"home"}
        >
          <StyledInformationColumn>
            <Typography
              color={alterCurriculumExplorerTheme.palette.primary.main}
              variant={"h1"}
              fontWeight={900}
              fontSize={38}
              sx={{ marginBottom: "20px" }}
            >
              Find SQA Questions and more in seconds
            </Typography>

            <img
              style={{
                maxHeight: isTabletOrMobile ? "300px" : "auto",
                maxWidth: isTabletOrMobile ? "60%" : "200px",
                width: isTabletOrMobile ? "60%" : "250px",
              }}
              src={GraphicPastPaperExplorer}
              alt={"Diagram"}
            />
            <Typography
              color={"textSecondary"}
              variant={"body1"}
              sx={{ marginTop: "20px" }}
            >
              Find thousands of SQA Past Paper questions for Biology, Maths,
              Computing, History, Physics, Geography, Chemistry, Engineering and
              more
            </Typography>
            <StyledButtonRow>
              <Button
                size={"large"}
                onClick={() =>
                  process.env.REACT_APP_CURRICULUM_EXPLORER_URL &&
                  openInNewTab(process.env.REACT_APP_CURRICULUM_EXPLORER_URL)
                }
                variant={"contained"}
                style={{
                  marginRight: "20px",
                  color: "#FFF",
                  backgroundColor:
                    alterCurriculumExplorerTheme.palette.primary.main,
                }}
              >
                Open app
              </Button>
              <Button
                size={"large"}
                href={"/curriculumExplorer"}
                variant={"outlined"}
                style={{
                  borderColor:
                    alterCurriculumExplorerTheme.palette.primary.main,
                  color: alterCurriculumExplorerTheme.palette.primary.main,
                }}
              >
                Learn more
              </Button>
            </StyledButtonRow>
          </StyledInformationColumn>
          {!isTabletOrMobile && (
            <Divider
              sx={{ height: "500px", margin: "0 20px" }}
              orientation={"vertical"}
            />
          )}
          <StyledInformationColumn>
            <Typography
              color={altraTheme.palette.primary.main}
              fontWeight={900}
              fontSize={38}
              sx={{ marginBottom: "20px" }}
              variant={"h1"}
            >
              Collaborate on adaptable learning materials
            </Typography>

            <img
              style={{
                maxHeight: isTabletOrMobile ? "300px" : "auto",
                maxWidth: isTabletOrMobile ? "60%" : "200px",
                width: isTabletOrMobile ? "60%" : "250px",
              }}
              src={GraphicNetworkBlue}
              alt={"Diagram"}
            />
            <Typography
              color={"textSecondary"}
              variant={"body1"}
              sx={{ marginTop: "20px" }}
            >
              Discover our free Altra cooperative events brining teachers,
              academics and Altra together to collaborate on creating new
              resources
            </Typography>
            <StyledButtonRow>
              <Button
                size={"large"}
                onClick={() =>
                  openInNewTab("https://2uluvvlx386.typeform.com/to/uIdpPKQ5")
                }
                variant={"contained"}
                style={{
                  marginRight: "20px",
                  color: "#FFF",
                  backgroundColor: altraTheme.palette.primary.main,
                }}
              >
                Sign up for free today
              </Button>
              <Button
                size={"large"}
                href={"/altracooperative"}
                variant={"outlined"}
                style={{
                  borderColor: altraTheme.palette.primary.main,
                }}
              >
                Learn more
              </Button>
            </StyledButtonRow>
          </StyledInformationColumn>
        </StyledHashLinkContainer>
      </StyledContentContainer>
    </StyledLandingPageOverview>
  );
};

const StyledLandingPageOverview = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
  margin-top: 20px;
  min-height: 70vh;
`;

const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //background-color: red;
`;

const StyledInformationColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 20px;
  //background-color: purple;
  flex-direction: column;
`;

const StyledVideoColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
  //background-color: yellow;
`;

const StyledButtonRow = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const StyledStatsRow = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
`;

const StyledStat = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;
const StyledHashLinkContainer = styled.div`
  margin-top: -100px;
  display: flex;
  padding-top: 100px;
  align-items: center;
  justify-content: space-between;
`;
