import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { Autocomplete } from "@mui/lab";
import { Avatar, Button, Divider, InputBase, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { FC } from "react";
import { HashLink } from "react-router-hash-link";
import { AccountTopAppBar } from "../../molecules/AccountTopAppBar";
import { UsersTable } from "../../molecules/UsersTable";
import { Institution } from "../../redux/applicationContext/types";
import { useAppSelector } from "../../redux/hook";
import { UserInfo } from "../../redux/user/types";
import { AccountScreenContainerMode } from "../../util/custom-types";

/**
 * Visuals for Screen to view institutions account and associated details
 * - On mobile, uses a series of tabs to naviagte between section
 * - On desktop, uses hashlinks within a fixed side bar
 * TODO(JACK): Implement Mobile view
 * TODO(JACK): Implement change subscription flow
 * TODO(YOGESH): Implement consitional rendering and types based on user
 * @constructor
 */
export const AccountScreenVisualsInstitution: FC = () => {
  // Get user details from redux
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  // get user Institution
  const institution: Institution = useAppSelector((state) =>
    state.applicationContext.institutions?.find(
      (element) => element?.owner! === user?.id!
    )
  ) as Institution;
  /**
   * Fixed side menu which uses hash links to scroll to corresponding section
   */
  const renderSideMenuItems = () => (
    <MenuList>
      <StyledHashLink
        style={{ textDecoration: "none" }}
        smooth
        to="/account/institution/#details"
      >
        <MenuItem selected>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <Typography color={"primary"} variant={"body1"}>
            Details
          </Typography>
        </MenuItem>
      </StyledHashLink>

      <StyledHashLink smooth to="/account/institution/#education">
        <MenuItem>
          <ListItemIcon>
            <ThumbUpAltIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Education</ListItemText>
        </MenuItem>
      </StyledHashLink>

      <StyledHashLink smooth to="/account/institution/#subscription">
        <MenuItem>
          <ListItemIcon>
            <CreditCardIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Subscription</ListItemText>
        </MenuItem>
      </StyledHashLink>

      <StyledHashLink smooth to="/account/institution/#users">
        <MenuItem>
          <ListItemIcon>
            <PeopleAltIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Users</ListItemText>
        </MenuItem>
      </StyledHashLink>
    </MenuList>
  );

  /**
   * Institutuion profile picture, verified status, name and password reset
   */
  const renderUserProfileOverview = () => (
    <StyledUserProfileOverview>
      <Avatar
        style={{ width: "75px", height: "75px" }}
        alt={
          user?.display_name && user?.display_name.length > 0
            ? user?.display_name[0]
            : ""
        }
        src="https://bucket-worksheet-gen-digrams230636-altraappsx.s3.eu-west-2.amazonaws.com/school_logo-1.png"
      />
      <StyledUserProfileTextColumn>
        <StyledTextField
          inputProps={{ style: { fontWeight: 600 } }} // font size of input text
          id="outlined-basic"
          label="Display name"
          variant="outlined"
          size={"small"}
          value={"Craigmount High School"}
        />
        <StyledTextField
          id="outlined-basic"
          label="Bio"
          variant="outlined"
          size={"small"}
          multiline
          value={
            "Craigmount High School is a non-denominational secondary school in Edinburgh, Scotland with 1,268 pupils located in the west of Edinburgh, Scotland. It is situated between Corstorphine, East Craigs and Drumbrae and it moved into new premises at the beginning of the 2003 academic year. In April 2016, the new building was closed down by Edinburgh Council due to safety concerns; measures were put in place to continue classes in other premises. The new building reopened as of August 2016 when the building met the minimum safety standards."
          }
        />
      </StyledUserProfileTextColumn>
    </StyledUserProfileOverview>
  );

  /**
   * Dropdowns to update curriculum and topic interests
   */
  const renderEducationSection = () => (
    <StyledAccountPanelSection>
      <Typography variant={"h5"}>My interests</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <StyledAutocomplete
          disablePortal
          id="combo-box-demo"
          options={[
            { label: "The Shawshank Redemption", year: 1994 },
            { label: "The Godfather", year: 1972 },
          ]}
          sx={{ width: 300, marginRight: "40px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Movie"
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
        <StyledAutocomplete
          disablePortal
          id="combo-box-demo"
          options={[
            { label: "The Shawshank Redemption", year: 1994 },
            { label: "The Godfather", year: 1972 },
          ]}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Movie"
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
      </div>
    </StyledAccountPanelSection>
  );

  /**
   * Section detailing subsctiption status
   */
  const renderSubscriptionSection = () => (
    <StyledAccountPanelSection>
      <Typography variant={"h5"}>Subscription</Typography>
      <Typography sx={{ margin: "10px 0" }} variant={"subtitle1"}>
        This institution is subscribed to the
        <span style={{ color: altraTheme.palette.primary.main }}>
          {" "}
          Altra Enterprise - School plan
        </span>
        , get in touch if you would like to discuss this plan
      </Typography>
      <Button size={"small"} sx={{ width: "20%" }} variant={"contained"}>
        Change plan
      </Button>
    </StyledAccountPanelSection>
  );

  /**
   * Section detailing all system users
   */
  const renderUsersSection = () => (
    <StyledAccountPanelSection>
      <Typography sx={{ marginBottom: "20px" }} variant={"h5"}>
        Users
      </Typography>
      <UsersTable mode={AccountScreenContainerMode.INSTITUTION} />
    </StyledAccountPanelSection>
  );

  return (
    <StyledAccountScreenContainer>
      <AccountTopAppBar />
      <StyledAccountScreenContents>
        <StyledSideMenu>
          <Typography variant={"h3"}>My account</Typography>
          {renderSideMenuItems()}
        </StyledSideMenu>
        <StyledAccountDetailsContainer>
          <StyledHashLinkContainer id={"details"} />

          <StyledAccountDetailsPanel>
            {renderUserProfileOverview()}
            <StyledHashLinkContainer id={"education"}>
              <Divider />
            </StyledHashLinkContainer>
            {renderEducationSection()}
            <StyledHashLinkContainer id={"subscription"}>
              <Divider />
            </StyledHashLinkContainer>
            {renderSubscriptionSection()}{" "}
            <StyledHashLinkContainer id={"users"}>
              <Divider />
            </StyledHashLinkContainer>
            {renderUsersSection()}
          </StyledAccountDetailsPanel>
        </StyledAccountDetailsContainer>
      </StyledAccountScreenContents>
    </StyledAccountScreenContainer>
  );
};

const StyledAccountScreenContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  backgroundColor: theme.palette.background.default,
}));

const StyledAccountScreenContents = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "100px",
  justifyContent: "flex-end",
}));

const StyledSideMenu = styled("div")(({ theme }) => ({
  width: "15%",
  left: 0,
  position: "fixed",
  flexDirection: "column",
  paddingLeft: "40px",
  display: "flex",
}));

const StyledAccountDetailsContainer = styled("div")(({ theme }) => ({
  width: "80%",
  display: "flex",
  justifyContent: "center",
  paddingBottom: "600px",
}));

const StyledAccountDetailsPanel = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  width: "70%",
  padding: "40px",
}));

const StyledAccountPanelSection = styled("div")(({ theme }) => ({
  marginTop: "30px",
  marginBottom: "30px",
  display: "flex",
  flexDirection: "column",
}));

const StyledUserProfileOverview = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  marginBottom: "30px",
}));

const StyledVerifiedTextRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const StyledUserProfileTextColumn = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const StyledTextField = styled(TextField)({
  marginTop: "10px",
});
const StyledAutocomplete = styled(Autocomplete)({
  marginTop: "15px",
});

const StyledHashLinkContainer = styled("div")(({ theme }) => ({
  marginTop: "-50px",
  paddingTop: "50px",
  display: "block",
}));

const StyledHashLink = styled(HashLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const StyledNakedTextInput = styled(InputBase)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "transparent",
  outline: "0 solid transparent",
  overflow: "auto",
  whiteSpace: "nowrap",
  ...theme.typography.h3,
}));
