import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CardContent,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUpdateUserByIdMutation } from "../graphql/types";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { userInfoRemoveSubscription } from "../redux/user/actions";
import { UserInfo } from "../redux/user/types";

/**
 * Subscription modal for all apps allowing user to change their subscription
 * @param open
 * @constructor
 */
export const CancelSubscriptionModal: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { session_id, user_id } =
    useParams<{ session_id: string; user_id: string }>();

  // Get user details from redux
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const { isAuthenticated } = useAuth0();

  const [cancelState, setCancelledState] = useState<
    | "INITIAL"
    | "CANCELLING"
    | "CANCELLED_STRIPE"
    | "CANCELLED_DATABASE"
    | "CANCELLED_ERROR"
  >("INITIAL");

  const [updateUserSubscriptionMutation] = useUpdateUserByIdMutation();

  const removeSubscription = async () => {
    if (user.id) {
      const res = await updateUserSubscriptionMutation({
        variables: {
          userId: user.id,
          userInput: {
            subscription_id: null,
          },
        },
      });
      if (res.data) {
        dispatch(userInfoRemoveSubscription());
        setCancelledState("CANCELLED_DATABASE");
      }
      if (res.errors) {
        console.error(res.errors);
        setCancelledState("CANCELLED_ERROR");
      }
    }
  };

  useEffect(() => {
    if (cancelState === "CANCELLED_STRIPE") {
      removeSubscription();
    }
  }, [cancelState]);

  /**
   * Calls lambda function to generate stripe checkout URL
   * @param title
   */
  const cancelSubscription = async () => {
    setCancelledState("CANCELLING");

    if (
      process.env.REACT_APP_STRIPE_CANCEL_SUBSCRIPTION_LAMBDA_URL &&
      process.env.REACT_APP_STRIPE_CANCEL_SUBSCRIPTION_LAMBDA_API_KEY &&
      user.stripe_id
    ) {
      const { body } = await fetch(
        process.env.REACT_APP_STRIPE_CANCEL_SUBSCRIPTION_LAMBDA_URL,
        {
          method: "POST",
          headers: {
            "X-Api-Key":
              process.env.REACT_APP_STRIPE_CANCEL_SUBSCRIPTION_LAMBDA_API_KEY,
          },
          body: JSON.stringify({
            customerId: user.stripe_id,
          }),
        }
      );
    }
    setCancelledState("CANCELLED_STRIPE");
  };

  return (
    <StyledContainer>
      <Dialog open={true}>
        <CardContent>
          <StyledTopRow>
            <StyledTopRowContent>
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  color: theme.palette.error.main,
                  right: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </StyledTopRowContent>
          </StyledTopRow>
          <Typography sx={{ padding: "1em" }} variant={"h1"}>
            Cancel subscription
          </Typography>
          {!isAuthenticated && (
            <Typography variant={"subtitle1"} color={"warning"}>
              You must sign in to manage your subscription
            </Typography>
          )}

          <StyledPriceCardRow>
            <Typography>
              {cancelState === "CANCELLED_DATABASE" && "Subscription cancelled"}
              {cancelState === "CANCELLED_ERROR" &&
                "There was a problem cancelling your subscription, please try again or contact support"}
              {cancelState !== "CANCELLED_DATABASE" &&
                "Are you sure you want to switch to the free plan? You will lose access to all your premium features"}
            </Typography>
          </StyledPriceCardRow>
          <StyledButtonMiddle>
            <Button
              sx={{ width: "60%", marginBottom: "2%" }}
              size={"large"}
              onClick={() => history.goBack()}
              variant={"contained"}
            >
              {cancelState !== "CANCELLED_DATABASE"
                ? "No, go back to my subscription"
                : "Go my subscriptions"}
            </Button>
            {cancelState !== "CANCELLED_DATABASE" && (
              <LoadingButton
                sx={{ width: "60%" }}
                loading={
                  cancelState === "CANCELLING" ||
                  cancelState === "CANCELLED_STRIPE"
                }
                size={"large"}
                color={"warning"}
                onClick={cancelSubscription}
                variant={"outlined"}
              >
                Cancel
              </LoadingButton>
            )}
          </StyledButtonMiddle>
        </CardContent>
      </Dialog>
    </StyledContainer>
  );
};

const StyledContainer = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100vw",
}));

const StyledButtonMiddle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));
const StyledPriceCardRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "20px",
  minHeight: "400px",
  minWidth: "500px",
  justifyContent: "space-evenly",
}));
const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
