export const LandingPageContent = {
  TAGLINE: "Scalable Personalised Learning",
  OVERVIEW:
    "At Altra, we are building time-saving tools to assist educators in delivering a more personalised education to their students",
  OVERVIEW_STAT_1_TITLE: "Questions",
  OVERVIEW_STAT_1_VALUE: "900+",
  OVERVIEW_STAT_2_TITLE: "Subjects",
  OVERVIEW_STAT_2_VALUE: "2",
  OVERVIEW_STAT_3_TITLE: "Apps",
  OVERVIEW_STAT_3_VALUE: "2",
  CURRICULUM_EXPLORER_URL: "https://curriculumexplorer.altra.space",
  CURRICULUM_EXPLORER_NAME: "Curriculum Explorer",
  CURRICULUM_EXPLORER_TAGLINE:
    "Find SQA Questions and learning materials created by teachers",
  CURRICULUM_EXPLORER_OVERVIEW_SHORT:
    "Quickly search and filter over 4000 SQA questions for more than 12 subjects. You can search for keywords within the question or filter by level, topic, year, question type and more",
  CURRICULUM_EXPLORER_OVERVIEW_LONG:
    "Whether you are a teacher with a class in need of a quick question for your lesson or a student looking for specific topics to revise in preparation for your exams, our Curriculum Explorer allows you to find any SQA question in seconds. You can search for keywords or filter by level, topic, year, question type and more.",
  CURRICULUM_EXPLORER_PRICING:
    "We believe that educational resources and knowledge should be open and easily accessible for all. You can find revision materials and Past Paper Questions created by teachers on the Curriculum Explorer app for free.",

  PAST_PAPER_EXPLORER_URL: "https://pastpaperexplorer.altra.space",
  PAST_PAPER_EXPLORER_NAME: "Past Paper Explorer",
  PAST_PAPER_EXPLORER_TAGLINE: "Quickly search and filter SQA questions",
  PAST_PAPER_EXPLORER_OVERVIEW_SHORT:
    "Quickly search and filter SQA questions. You can search for keywords within the question or filter by level, topic, year, question type and more",
  PAST_PAPER_EXPLORER_OVERVIEW_LONG:
    "Whether you are a teacher with a class in need of a quick question for your lesson or a student looking for specific topics to revise in preparation for your exams, our Past Paper Explorer allows you to find any SQA question in seconds. You can search for keywords or filter by level, topic, year, question type and more.",
  PAST_PAPER_EXPLORER_PRICING:
    "We believe that educational resources and knowledge should be open and easily accessible for all. The Past Paper Explorer app is free to use forever for everyone",
  RESOURCE_BUILDER_NAME: "Resource Builder",
  RESOURCE_BUILDER_URL: "https://resourcebuilder.altra.space",
  RESOURCE_BUILDER_TAGLINE:
    "Adapt, Create and Share your own learning materials",
  RESOURCE_BUILDER_OVERVIEW_SHORT:
    "Create and Export high quality resources with ease",
  RESOURCE_BUILDER_OVERVIEW_LONG:
    "Every student's needs are different. Creating custom resources catered to individual needs can be a time-consuming task. Our Resource Builder enables you to cherry pick questions, add them to your own custom worksheet, edit the question then export to a PDF with no effort at all.",
  ALTRA_COOPERATIVE_NAME: "Altra Cooperative",
  ALTRA_COOPERATIVE_PRICING:
    "We want to support teachers in sharing their resources for free and therefore all of our Altra Cooperative events are free to attend.",
  ALTRA_COOPERATIVE_URL: "https://2uluvvlx386.typeform.com/to/uIdpPKQ5",
  ALTRA_COOPERATIVE_TAGLINE:
    "Collaborate with education experts to create resources",
  ALTRA_COOPERATIVE_OVERVIEW_SHORT:
    "Discover our free Altra cooperative events brining teachers, academics and Altra together to collaborate on creating new resources",
  RESOURCE_BUILDER_PRICING:
    "We believe in providing students with personalised resources catered to their needs. That is why we offer a basic version of our resource builder for free. To use our premium features, check out our subscription packages. Sign up now for a discount!",
  ALTRA_COOPERATIVE_OVERVIEW_LONG:
    "As part of our work, we are looking to facilitate a series of events brining teachers, academics and Altra together to collaborate on designing, creating and sharing resources and knowledge. \n" +
    "\n" +
    "If you are in need of a resource, looking to be part of a support group for a specific aspect of your teaching or if you just want to discuss ideas with like minded individuals, book into one of our events today.",

  PROGRESS_MANAGER_NAME: "Progress Manager",
  PROGRESS_MANAGER_PRICING:
    "Sign up today for a discount and be the first to have access to our new progress manger as it is released",
  PROGRESS_MANAGER_TAGLINE: "Monitor student's progress all in one place",
  PROGRESS_MANAGER_OVERVIEW_SHORT:
    "Monitor student's progress all in one place. Coming soon!",
  PROGRESS_MANAGER_OVERVIEW_LONG:
    "The progress manager is the only tool you will need to monitor student's progress throughout the year. Say goodbye to the folders of complex spreadsheets and the hours spent producing individual reports, our app facilitates monitoring of student's grades for homeworks, mock exams, unit assessments and more.",
  QUOTE_1_TEXT:
    "Hi Jack, I cannot tell you how helpful this has been for me - I think I use it every day now. Really easy to navigate and the students are using it too",
  QUOTE_1_NAME: "Miss Glover",
  QUOTE_1_POSITION: "Holy Rood RC High School, Edinburgh",
  QUOTE_3_TEXT:
    "The Altra team listened carefully to the details of what I wanted. They were responsive but also took the initiative to make an intuitive tool. I’m delighted.",
  QUOTE_3_NAME: "Mrs Hunter",
  QUOTE_3_POSITION: "St Thomas of Aquin’s High School, Edinburgh",
  QUOTE_2_TEXT:
    "Absolutely love the Past Paper Explorer, it has been so useful, and I've shared it with all my History classes and every teacher I've encountered too",
  QUOTE_2_NAME: "Miss McMillan",
  QUOTE_2_POSITION: "Brannock High School, North Lanarkshire",
  TEACHER_OVERVIEW:
    "We know that teachers are spending hours adapting content for their classes and carrying out time-consuming admin tasks. Our straight-forward apps help you to provide a more personalised learning experience for your students. Save time and energy by simplifying your existing workflows; finding past paper questions, building educational resources, managing your classes & inputting grades. So you can take back time to focus on what you do best – teaching.",
  SENIOR_MANAGEMENT_OVERVIEW:
    "Our 3 apps work together to provide a technology solution that assists Scottish schools with tailoring learning to individual students. At Altra, we are passionate about saving teachers time by providing simple and intuitive apps mapped directly to the Scottish Curriculum. We know how time-consuming the task of understanding the needs of each student can be, as well as the lack of technical support available to schools. Our apps are already reducing tasks that can take hours to just a few seconds.",
  PRIVATE_TUTOR_OVERVIEW:
    "We know that as a tutor, you are expected to tailor content to your students on an individual basis. Where one student may be ready for a challenge in a certain topic, another may be struggling and require additional support. Our straight-forward apps help you to provide a more personalised learning experience for your students. Save time and energy by simplifying your existing workflows; finding past paper questions, building educational resources, managing your classes and inputting grades.",
  PARENT_STUDENT_OVERVIEW:
    "We understand that as a parent, you want to provide the best support possible for your child. The pandemic has seen student's working from home and as schools return, it is vital that we tailor learning to individuals in order to provide a fair and equitable opportunity to learn for everyone. Our applications enable students and parents to source content relevant to their individual needs with ease, as well as the ability to create custom worksheets for revision in seconds.",
  LANDING_PAGE_PRICING:
    "We believe that educational resources and knowledge should be open and easily accessible for all. You can find revision materials and Past Paper Questions created by teachers on the Curriculum Explorer app for free.",
  SUCCESS_PAGE_TITLE: "Thanks for subscribing",
  SUCCESS_PAGE_SUBTITLE:
    "Your payment was successful. You will receive an email shortly.",
  SUCCESS_PAGE_INFO:
    "We are in the process of creating customer accounts! Keep your eyes on your email over the next few weeks for your account information. ",
};

export const CUSTOMERS = [
  "Teacher",
  "Senior Management",
  "Private Tutor",
  "Parents & Students",
];

type CUSTOMERS_KEYS =
  | "Teacher"
  | "Senior Management"
  | "Private Tutor"
  | "Parents & Students";

export type ButtonLink =
  | { type: "EXTERNAL" }
  | { type: "INTERNAL"; url: string }
  | { type: "DISABLED" };

type LandingPagePricingCustomer = {
  title: string;
  monthlyPrice: string;
  annualPrice: string;
  description: string;
  buttonText: string;
  buttonLink: ButtonLink;
  features: string[];
  discount?: boolean;
};

type LandingPagePricingContent = {
  [key in CUSTOMERS_KEYS]: LandingPagePricingCustomer[];
};

const AltraOpen: LandingPagePricingCustomer = {
  title: "Altra Open",
  annualPrice: "0",
  monthlyPrice: "0",
  buttonText: "Open app",
  description:
    "Search for past paper questions and create high quality PDF resources",
  features: [
    "Past Paper Explorer",
    "Edit SQA questions",
    "Create basic PDF resources",
  ],
  buttonLink: {
    type: "INTERNAL",
    url: "/#apps",
  },
};

const AltraPremiumIndividual: LandingPagePricingCustomer = {
  title: "Altra Premium - Individual",
  annualPrice: "99.99",
  monthlyPrice: "11.99",
  buttonText: "Contact us",
  description: "Enhance your resources with our premium package",
  features: [
    "Store unlimited resources",
    "Export as online form",
    "Custom resource theming",
    "No watermarks",
    "Access to new features",
    "Altra Open ",
  ],
  buttonLink: {
    type: "DISABLED",
  },
};

const AltraPremiumEarlyBirdIndividual: LandingPagePricingCustomer = {
  title: "Altra Early bird - Individual",
  annualPrice: "49.99",
  monthlyPrice: "4.99",
  buttonText: "Buy now",
  description:
    "Buy now for 1 year of discounted access and new features as they are released",
  features: [
    "Store unlimited resources",
    "Export as online form",
    "Custom resource theming",
    "No watermarks",
    "Access to new features",
    "Altra Open ",
  ],
  discount: true,
  buttonLink: {
    type: "EXTERNAL",
  },
};

const AltraEnterpriseOneSubject: LandingPagePricingCustomer = {
  title: "Altra Enterprise - 1 Subject",
  annualPrice: "Flexible",
  monthlyPrice: "Flexible",
  buttonText: "Contact us",
  description:
    "Maximise your departments efficiency with time-saving applications",
  features: [
    "Multiple teacher accounts",
    "Store unlimited resources",
    "Export as online form",
    "Custom resource theming",
    "No watermarks",
    "Access to new features",
    "Altra Open ",
  ],
  buttonLink: {
    type: "INTERNAL",
    url: "/contact",
  },
};

const AltraEnterpriseSchool: LandingPagePricingCustomer = {
  title: "Altra Enterprise - School",
  annualPrice: "Flexible",
  monthlyPrice: "Flexible",
  buttonText: "Contact us",
  description:
    "Maximise your departments efficiency with time-saving applications",
  features: [
    "Access to system training",
    "Management usage statistics",
    "Priority feature requests",
    "Altra Enterprise - Subject ",
  ],
  buttonLink: {
    type: "INTERNAL",
    url: "/contact",
  },
};

export const PRODUCTS = {
  "Altra Open": AltraOpen,
  "Altra Premium - Individual": AltraPremiumIndividual,
  "Altra Early bird - Individual": AltraPremiumEarlyBirdIndividual,
  "Altra Enterprise - 1 Subject": AltraEnterpriseOneSubject,
  "Altra Enterprise - School": AltraEnterpriseSchool,
};

export const LandingPagePricingContent: LandingPagePricingContent = {
  Teacher: [AltraOpen, AltraPremiumEarlyBirdIndividual, AltraPremiumIndividual],
  "Senior Management": [
    AltraOpen,
    AltraEnterpriseOneSubject,
    AltraEnterpriseSchool,
  ],
  "Private Tutor": [
    AltraOpen,
    AltraPremiumEarlyBirdIndividual,
    AltraPremiumIndividual,
  ],
  "Parents & Students": [
    AltraOpen,
    AltraPremiumEarlyBirdIndividual,
    AltraPremiumIndividual,
  ],
};
