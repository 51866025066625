import {
  APP_ICON_LONG,
  APP_ICON_STACKED,
  APP_URL,
} from "@altra-apps/common/src/styling/constants";
import AppsIcon from "@mui/icons-material/Apps";
import {
  AppBar,
  Drawer,
  IconButton,
  ListItemButton,
  Toolbar,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { createContext, forwardRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ALTRA_APPS } from "../util/custom-types";
import { openInNewTab } from "../util/helpers";

type AccountTopAppBarProps = {
  ref: any;
};

/**
 * Top App bar with Altra Logo and links to other Altra products
 * @constructor
 */
export const AccountTopAppBar = forwardRef<any, AccountTopAppBarProps>(
  (children, ref) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
      <AppBar color="default">
        <Toolbar>
          <IconButton
            style={{ marginRight: "10px" }}
            color="inherit"
            edge="end"
            onClick={() => setDrawerOpen(true)}
          >
            <AppsIcon />
          </IconButton>
          <StyliedRouterLink>
            <RouterLink
              to={`/`}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={APP_ICON_LONG[ALTRA_APPS.LS]} alt="Altra Logo" />
            </RouterLink>
          </StyliedRouterLink>
          <Box sx={{ flexGrow: 2 }} />
        </Toolbar>
        <div ref={ref as any} />
        <Drawer
          onClose={() => setDrawerOpen(false)}
          anchor={"left"}
          open={drawerOpen}
        >
          <StyledDrawerHeader>
            <Typography style={{ margin: 20 }} variant={"h5"}>
              Explore our products
            </Typography>
          </StyledDrawerHeader>

          <List>
            {Object.values(ALTRA_APPS).map((id) => (
              <ListItemButton
                key={id}
                onClick={() => openInNewTab(APP_URL[id])}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  minHeight: "150px",
                }}
              >
                <img
                  style={{ maxWidth: "200px", marginBottom: 10 }}
                  src={APP_ICON_STACKED[id]}
                  alt="Altra Logo"
                />
              </ListItemButton>
            ))}
          </List>
        </Drawer>
      </AppBar>
    );
  }
);

const StyliedRouterLink = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const StyledDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
}));

export const ToolbarContext = createContext({ current: undefined });
