import LargeQuotationMarks from "@altra-apps/common/assets/icons/LargeQuotationMarks.svg";
import annette from "@altra-apps/common/assets/images/annette.png";
import KatieHead from "@altra-apps/common/assets/images/KatieHead.png";
import loveAltra from "@altra-apps/common/assets/images/loveAltra.png";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { LandingPageContent } from "@altra-apps/common/src/util/LandingPageContent";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Avatar, Divider, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

export const LandingPageTeacherQuotes = () => {
  const isTabletOrMobile = useIsMobile();

  const renderQuote = (
    quote: string,
    name: string,
    position: string,
    image: string
  ) => (
    <StyledQuoteOuterContainer
      style={{
        display: "flex",
        flexDirection: isTabletOrMobile ? "column" : "row",
        alignItems: isTabletOrMobile ? "center" : "auto",
        width: "100%",
      }}
    >
      <Avatar
        variant={name === "Mrs Hunter" ? "circular" : "rounded"}
        sx={{ width: "150px", height: "150px" }}
      >
        <img
          style={{
            width: name !== "Mrs Hunter" ? "100%" : "auto",
          }}
          src={image}
          alt="TeacherFillerImage Logo"
        />
      </Avatar>
      <StyledQuoteContainer
        style={{ marginTop: isTabletOrMobile ? "20px" : "0" }}
      >
        <Typography
          style={{ marginBottom: 20 }}
          variant={"body1"}
          color={"textPrimary"}
        >
          {quote}
        </Typography>
        <Divider />
        <Typography
          style={{ marginTop: 20 }}
          variant={"body1"}
          color={"primary"}
        >
          {name}
        </Typography>
        <Typography variant={"body1"} color={"textSecondary"}>
          {`${position}`}
        </Typography>
      </StyledQuoteContainer>
    </StyledQuoteOuterContainer>
  );

  return (
    <StyledLandingPageTeacherQuotes
      style={{ backgroundColor: altraTheme.palette.background.default }}
    >
      <StyledCenteredContainer
        style={{ width: isTabletOrMobile ? "85%" : "60%" }}
      >
        <StyledHashLinkContainer id={"benefits"}>
          <StyledHeaderRow>
            <Typography
              style={{ width: isTabletOrMobile ? "100%" : "50%" }}
              variant={"h2"}
              color={"secondary"}
            >
              Teachers love Altra
            </Typography>
            {!isTabletOrMobile && (
              <img
                style={{ maxWidth: "200px", width: "max-content" }}
                src={LargeQuotationMarks}
                alt="LargeQuotationMarks"
              />
            )}
          </StyledHeaderRow>
          <StyledQuotesContainer
            style={{
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            {renderQuote(
              LandingPageContent.QUOTE_1_TEXT,
              LandingPageContent.QUOTE_1_NAME,
              LandingPageContent.QUOTE_1_POSITION,
              annette
            )}
            {renderQuote(
              LandingPageContent.QUOTE_3_TEXT,
              LandingPageContent.QUOTE_3_NAME,
              LandingPageContent.QUOTE_3_POSITION,
              KatieHead
            )}
            {renderQuote(
              LandingPageContent.QUOTE_2_TEXT,
              LandingPageContent.QUOTE_2_NAME,
              LandingPageContent.QUOTE_2_POSITION,
              loveAltra
            )}
          </StyledQuotesContainer>
        </StyledHashLinkContainer>
      </StyledCenteredContainer>
    </StyledLandingPageTeacherQuotes>
  );
};

const StyledLandingPageTeacherQuotes = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
  margin-top: 30px;
`;

const StyledCenteredContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  //background-color: red;
  margin-top: 30px;
  margin-bottom: 40px;
  flex-direction: column;
`;

const StyledQuotesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //background-color: hotpink;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledQuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 8px 36px rgba(66, 84, 102, 0.08);
  border-radius: 8px;
  min-height: 100px;
  margin-left: 20px;
  padding: 20px;
  margin-bottom: 20px;
`;
const StyledQuoteOuterContainer = styled.div`
  display: flex;
`;

const StyledHeaderRow = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const StyledHashLinkContainer = styled.div`
  margin-top: -150px;
  padding-top: 150px;
`;
