import GraphicNetworkBlue from "@altra-apps/common/assets/icons/GraphicNetworkBlue.svg";
import GraphicPastPaperExplorer from "@altra-apps/common/assets/icons/GraphicPastPaperExplorer.svg";
import GraphicResourceBuilder from "@altra-apps/common/assets/icons/GraphicResourceBuilder.svg";
import { alterCurriculumExplorerTheme } from "@altra-apps/common/src/styling/altra-curriculum-explorer-theme";
import { altraResourceGeneratorTheme } from "@altra-apps/common/src/styling/altra-resource-generator-theme";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { LandingPageContent } from "@altra-apps/common/src/util/LandingPageContent";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Button, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

export const LandingPageAppOverviews = () => {
  const isTabletOrMobile = useIsMobile();

  /**
   * Function to render preview of each app
   * @param headerColor
   * @param header
   * @param subheader
   * @param body
   * @param image
   * @param externalLink
   * @param internalLink
   * @param reverse
   */
  const renderApp = (
    headerColor: string,
    header: string,
    subheader: string,
    body: string,
    image: string,
    externalLink: string,
    internalLink: string,
    reverse?: boolean
  ) => (
    <StyledAppContainer
      style={{
        padding: "0.6em 1.2em",
        flexDirection: isTabletOrMobile
          ? "column"
          : reverse
          ? "row-reverse"
          : "row",
      }}
    >
      <StyledPreviewColumn
        style={{ width: isTabletOrMobile ? "100%" : "auto" }}
      >
        <img
          style={{
            maxHeight: isTabletOrMobile ? "300px" : "auto",
            maxWidth: isTabletOrMobile ? "60%" : "200px",
            width: isTabletOrMobile ? "60%" : "250px",
          }}
          src={image}
          alt={"Diagram"}
        />
      </StyledPreviewColumn>
      <StyledInformationColumn
        style={{
          marginRight: reverse || !isTabletOrMobile ? "50px" : "0px",
          marginLeft: reverse || isTabletOrMobile ? "0px" : "50px",
          width: isTabletOrMobile ? "100%" : "60%",
        }}
      >
        <Typography
          style={{ marginBottom: 10 }}
          variant={"h4"}
          color={"textSecondary"}
        >
          {subheader}
        </Typography>
        <Typography
          variant={"h1"}
          fontWeight={900}
          style={{
            color: headerColor,
          }}
        >
          {header}
        </Typography>
        <Typography
          style={{
            marginTop: 20,
          }}
          color={"textSecondary"}
          variant={"body1"}
        >
          {body}
        </Typography>
        <StyledButtonRow>
          {header !== "Progress Manager" && (
            <Button
              size={"large"}
              href={externalLink}
              variant={"contained"}
              style={{
                marginRight: "20px",
                color: "#FFF",
                backgroundColor: headerColor,
              }}
            >
              {header === "Altra Cooperative"
                ? "Sign up for free today"
                : "Open app"}
            </Button>
          )}
          <Button
            size={"large"}
            href={internalLink}
            variant={"outlined"}
            style={{ borderColor: headerColor, color: headerColor }}
          >
            Learn more
          </Button>
        </StyledButtonRow>
      </StyledInformationColumn>
    </StyledAppContainer>
  );

  return (
    <StyledLandingPageAppOverviews>
      <StyledAppsContainer style={{ width: isTabletOrMobile ? "85%" : "60%" }}>
        <StyledHashLinkContainer id={"apps"}>
          <Typography
            style={{ marginBottom: 30 }}
            variant={"h2"}
            color={"secondary"}
          >
            Altra Apps
          </Typography>

          {/*Past paper explorer*/}
          {renderApp(
            alterCurriculumExplorerTheme.palette.primary.main,
            LandingPageContent.CURRICULUM_EXPLORER_NAME,
            LandingPageContent.CURRICULUM_EXPLORER_TAGLINE,
            LandingPageContent.CURRICULUM_EXPLORER_OVERVIEW_LONG,
            GraphicPastPaperExplorer,
            LandingPageContent.CURRICULUM_EXPLORER_URL,
            "/curriculumexplorer"
          )}

          {/*Resource Builder*/}
          {!isTabletOrMobile &&
            renderApp(
              altraResourceGeneratorTheme.palette.primary.main,
              LandingPageContent.RESOURCE_BUILDER_NAME,
              LandingPageContent.RESOURCE_BUILDER_TAGLINE,
              LandingPageContent.RESOURCE_BUILDER_OVERVIEW_LONG,
              GraphicResourceBuilder,
              LandingPageContent.RESOURCE_BUILDER_URL,

              "/resourcebuilder",

              true
            )}

          {/*Progress Manager*/}
          {renderApp(
            altraTheme.palette.primary.main,
            LandingPageContent.ALTRA_COOPERATIVE_NAME,
            LandingPageContent.ALTRA_COOPERATIVE_TAGLINE,
            LandingPageContent.ALTRA_COOPERATIVE_OVERVIEW_LONG,
            GraphicNetworkBlue,
            "https://2uluvvlx386.typeform.com/to/uIdpPKQ5",

            "/altracooperative"
          )}
        </StyledHashLinkContainer>
      </StyledAppsContainer>
    </StyledLandingPageAppOverviews>
  );
};

const StyledLandingPageAppOverviews = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
  margin-top: 50px;

  min-height: 70vh;
`;

const StyledAppsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  //background-color: red;
`;

const StyledAppContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
  //background-color: #3fa888;
`;

const StyledInformationColumn = styled.div`
  display: flex;
  //background-color: purple;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledPreviewColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledButtonRow = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
const StyledHashLinkContainer = styled.div`
  margin-top: -100px;
  padding-top: 100px;
`;
