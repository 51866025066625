import FeatureCheckCircle from "@altra-apps/common/assets/icons/FeatureCheckCircle.svg";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { FC } from "react";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import { PRODUCTS } from "../util/LandingPageContent";
import { useIsMobile } from "../util/useIsMobile";

interface PricingCardProps {
  title: string;
  monthlyPrice: string;
  annualPrice: string;
  description: string;
  buttonText: string;
  features: string[];
  buttonLink:
    | {
        type: "EXTERNAL";
        onClick?: () => void;
        loadingPrice?: keyof typeof PRODUCTS;
      }
    | { type: "INTERNAL"; url: string }
    | { type: "DISABLED" };
  discount?: boolean;
}

export const PricingCard: FC<PricingCardProps> = ({
  annualPrice,
  description,
  monthlyPrice,
  title,
  buttonLink,
  buttonText,
  discount,
  features,
}) => {
  //TODO: Update
  const isTabletOrMobile = useIsMobile();
  const theme = useTheme();
  const showAnnualPrice = false;

  const renderFeatureRow = (text: string) => (
    <StyledButtonRow>
      <StyledImageColumn>
        <img
          style={{ maxHeight: "30px", width: "100%" }}
          src={FeatureCheckCircle}
          alt={"Diagram"}
        />
      </StyledImageColumn>

      <Typography variant={"caption"} color={"textSecondary"}>
        {text}
      </Typography>
    </StyledButtonRow>
  );

  return (
    <StyledPricingCardContainer style={{ width: "90%" }}>
      <Typography
        style={{ marginBottom: 5, fontWeight: discount ? 800 : 500 }}
        variant={"h5"}
        color={"textPrimary"}
      >
        {title}
      </Typography>
      <StyledPricingRow>
        <Typography
          variant={"h2"}
          fontWeight={discount ? 800 : 600}
          style={{ color: theme.palette.primary.main }}
        >
          {annualPrice !== "Flexible"
            ? showAnnualPrice
              ? annualPrice === "0"
                ? "Free"
                : `£${annualPrice}`
              : monthlyPrice === "0"
              ? "Free"
              : `£${monthlyPrice}`
            : "Flexible"}
        </Typography>
        <Typography style={{ marginLeft: 10 }} variant={"h5"} color={"primary"}>
          {annualPrice !== "0" &&
            annualPrice !== "Flexible" &&
            (showAnnualPrice ? "/ year" : "/ month")}
        </Typography>
      </StyledPricingRow>

      <Divider />
      <Typography
        style={{
          fontWeight: discount ? 600 : 500,
          marginTop: 20,
          marginBottom: 20,
        }}
        variant={"body1"}
        color={discount ? "textPrimary" : "textSecondary"}
      >
        {description}
      </Typography>
      {buttonLink.type === "INTERNAL" && (
        <HashLink
          to={buttonLink.url}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textDecoration: "none",
          }}
        >
          <Button
            style={{ width: "100%" }}
            variant={"contained"}
            color={"primary"}
            onClick={() => console.log(buttonLink)}
          >
            {buttonText}
          </Button>
        </HashLink>
      )}

      {buttonLink.type === "EXTERNAL" && (
        <Button
          onClick={buttonLink.onClick}
          variant={"contained"}
          color={"primary"}
          endIcon={
            buttonLink.loadingPrice === title ? (
              <CircularProgress size={15} color={"inherit"} />
            ) : null
          }
        >
          {buttonText}
        </Button>
      )}

      {buttonLink.type === "DISABLED" && (
        <Button variant={"contained"} color={"primary"} disabled={true}>
          {buttonText}
        </Button>
      )}

      {features.map((feature) => renderFeatureRow(feature))}
    </StyledPricingCardContainer>
  );
};

const StyledPricingTabs = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: red;
`;

const StyledTabsHeaderContainer = styled.div`
  margin-top: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  //background-color: hotpink;
  align-items: center;
`;

const StyledTabContent = styled.div`
  //background-color: green;
  min-height: 300px;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
`;
const StyledInfoRow = styled.div`
  //background-color: blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledButtonRow = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-content: space-between;
  align-items: center;
  flex-direction: row;
  //background-color: yellow;
`;

const StyledPricingTabsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
`;

const StyledCenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  //background-color: red;
  margin-top: 30px;
  margin-bottom: 40px;
`;

const StyledCenteredInnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  //background-color: hotpink;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledPricingCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 8px 36px rgba(66, 84, 102, 0.08);
  border-radius: 8px;
  min-height: 250px;
  padding: 20px;
  margin-bottom: 20px;
`;

const StyledImageColumn = styled.div`
  width: 20%;
`;

const StyledDropdownContainer = styled.div`
  width: 90%;
`;

const StyledCenteredGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledPricingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
`;
