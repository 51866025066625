import VerifiedTick from "@altra-apps/common/assets/icons/VerifiedTick.svg";
import { UsersTable } from "@altra-apps/common/src/molecules/UsersTable";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { useAuth0 } from "@auth0/auth0-react";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DescriptionIcon from "@mui/icons-material/Description";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import {
  Autocomplete,
  Avatar,
  Button,
  Divider,
  InputBase,
  Typography,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Subscription } from "../../graphql/types";
import { AltraTopAppBar } from "../../molecules/AltraTopAppBar";
import { InstitututionsTable } from "../../molecules/InstitututionsTable";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { updateUserById } from "../../redux/user/actions";
import { UserInfo } from "../../redux/user/types";
import { APP_URL } from "../../styling/constants";
import {
  AccountScreenContainerMode,
  ALTRA_APPS,
  AppRole,
  AppSubscriptionPlan,
} from "../../util/custom-types";
import { openInNewTab } from "../../util/helpers";
import { prettySubscription } from "../../util/pretty-text";
import { useDebounce } from "../../util/use-debounce";

// user extension type is use to trigger autosave event for all user events
type UserExtension = UserInfo & {
  currIds: Array<number>;
  topicIds: Array<number>;
};

/**import ApartmentIcon from '@mui/icons-material/Apartment';
 * Visuals for Screen to view users account and associated details
 * - On mobile, uses a series of tabs to naviagte between section
 * - On desktop, uses hashlinks within a fixed side bar
 * TODO(JACK): Implement Mobile view
 * TODO(JACK): Implement change subscription flow
 * TODO(YOGESH): Split this into multiple views
 * @constructor
 */

interface AccountScreenVisualsUserProps {
  appName: ALTRA_APPS;
}

export const AccountScreenVisualsUser: FC<AccountScreenVisualsUserProps> = ({
  appName,
}) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();

  // Get user details from redux
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const userRole: string = useAppSelector(
    (state) => state.userAppInfo.role
  ) as string;
  // Get user subscription plan
  const userSubscriptionPlan: Subscription = useAppSelector((state) =>
    state.applicationContext.subscriptions?.find(
      (element) => element.id === user.subscription_id!
    )
  ) as Subscription;

  // Get delayed event for user update
  const dispatch = useAppDispatch();

  const [displayName, setDisplayName] = useState("");
  const debouncedDisplayName = useDebounce(displayName, 1000);

  const detailsChangeHandler = async (name: string) => {
    const resultActionForUpdateUserById = await dispatch(
      updateUserById({ id: user.id, display_name: name })
    );
    if (updateUserById.rejected.match(resultActionForUpdateUserById)) {
      console.log("error");
    }
  };

  useEffect(() => {
    console.log(user);
    console.log(user.display_name);
    user.display_name && setDisplayName(user.display_name);
  }, [user]);

  useEffect(() => {
    detailsChangeHandler(debouncedDisplayName);
  }, [debouncedDisplayName]);

  /**
   * Fixed side menu which uses hash links to scroll to corresponding section
   */
  const renderSideMenuItems = () => (
    <MenuList>
      <StyledHashLink
        style={{
          textDecoration: "none",
        }}
        smooth
        to="/account/user/#my-details"
      >
        <MenuItem selected>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <Typography color={"primary"} variant={"body1"}>
            Details
          </Typography>
        </MenuItem>
      </StyledHashLink>

      <StyledHashLink smooth to="/account/user/#my-subscription">
        <MenuItem>
          <ListItemIcon>
            <CreditCardIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Subscription</ListItemText>
        </MenuItem>
      </StyledHashLink>
      {userRole === AppRole.SUPER_ADMIN && (
        <>
          <StyledHashLink smooth to="/account/user/#users">
            <MenuItem>
              <ListItemIcon>
                <PeopleAltIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Users</ListItemText>
            </MenuItem>
          </StyledHashLink>

          <StyledHashLink smooth to="/account/user/#my-curriculums">
            <MenuItem>
              <ListItemIcon>
                <AccountTreeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Curriculums</ListItemText>
            </MenuItem>
          </StyledHashLink>

          <StyledHashLink smooth to="/account/user/#my-blocks">
            <MenuItem>
              <ListItemIcon>
                <DescriptionIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Resources</ListItemText>
            </MenuItem>
          </StyledHashLink>
        </>
      )}
    </MenuList>
  );

  /**
   * User profile picture, verified status, name and password reset
   */
  const renderUserProfileOverview = () => (
    <StyledUserProfileOverview>
      <Avatar
        style={{
          width: "75px",
          height: "75px",
        }}
      >
        {displayName
          .split(/\s/)
          .reduce((response, word) => (response += word.slice(0, 1)), "")
          .slice(0, 5)}
      </Avatar>
      <StyledUserProfileTextColumn>
        <StyledVerifiedTextRow>
          <Typography variant={"h3"}>{displayName}</Typography>

          {user.verified && (
            <img
              style={{
                maxHeight: "20px",
                marginLeft: "10px",
              }}
              src={VerifiedTick}
              alt={"Diagram"}
            />
          )}
        </StyledVerifiedTextRow>
      </StyledUserProfileTextColumn>
    </StyledUserProfileOverview>
  );

  /**
   * Text fields to change school, bio etc
   */
  const renderMyDetailsSection = (localDisplayName: string) => (
    <StyledAccountPanelSection>
      <Typography
        sx={{
          marginBottom: "10px",
        }}
        variant={"h5"}
      >
        Details
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            width: "40%",
          }}
        >
          <StyledTextField
            InputLabelProps={{
              style: {
                fontSize: 14,
              },
            }} // font size of input label
            inputProps={{
              style: {
                fontSize: 14,
              },
            }} // font size of input text
            id="email"
            label="Email"
            variant="outlined"
            size={"small"}
            name="email"
            disabled
            value={user.email}
          />

          <StyledTextField
            InputLabelProps={{
              style: {
                fontSize: 14,
              },
            }} // font size of input label
            inputProps={{
              maxLength: 50,
              style: {
                fontSize: 14,
              },
            }} // font size of input text
            id="outlined-basic"
            label="Display name"
            variant="outlined"
            size={"small"}
            name="display_name"
            value={localDisplayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </div>
      </div>
    </StyledAccountPanelSection>
  );

  /**
   * Section detailing subsctiption status
   */
  const renderMySubscriptionSection = () => (
    <StyledAccountPanelSection>
      <Typography
        sx={{
          marginBottom: "10px",
        }}
        variant={"h5"}
      >
        Subscription
      </Typography>
      {isAuthenticated ? (
        <>
          <Typography
            sx={{
              margin: "10px 0",
            }}
            variant={"subtitle1"}
          >
            You are currently{" "}
            {userSubscriptionPlan
              ? `subscribed to our `
              : "subscribed to our free plan"}
            <span
              style={{
                color: altraTheme.palette.primary.main,
              }}
            >
              {prettySubscription(userSubscriptionPlan?.plan)}
            </span>
          </Typography>
          <Button
            onClick={() => history.push("/updateSubscription")}
            size={"small"}
            sx={{
              width: "20%",
            }}
            variant={"contained"}
          >
            Change plan
          </Button>
        </>
      ) : (
        <Typography variant={"subtitle1"} color={"warning"}>
          You must sign in to manage your subscription
        </Typography>
      )}
    </StyledAccountPanelSection>
  );

  /**
   * Section detailing all system users
   */
  const renderUsersSection = () => (
    <StyledAccountPanelSection>
      <Typography
        sx={{
          marginBottom: "20px",
        }}
        variant={"h5"}
      >
        Users
      </Typography>
      <UsersTable mode={AccountScreenContainerMode.USER} />
    </StyledAccountPanelSection>
  );

  /**
   * Section detailing all system institutions
   */
  const renderInstitutionsSection = () => (
    <StyledAccountPanelSection>
      <Typography
        sx={{
          marginBottom: "20px",
        }}
        variant={"h5"}
      >
        Institutions
      </Typography>
      <InstitututionsTable />
    </StyledAccountPanelSection>
  );

  /**
   * Section detailing link to curriculum builder
   */
  const renderMyCurriculumsSection = () => (
    <StyledAccountPanelSection>
      <Typography
        sx={{
          marginBottom: "10px",
        }}
        variant={"h5"}
      >
        Curriculums
      </Typography>
      <Button
        size={"small"}
        sx={{
          width: "20%",
          minWidth: "max-content",
        }}
        variant={"contained"}
        onClick={() =>
          process.env[APP_URL[ALTRA_APPS.CB]] &&
          //@ts-expect-error
          openInNewTab(process.env[APP_URL[ALTRA_APPS.CB]])
        }
      >
        Go to Curriculum Builder
      </Button>
    </StyledAccountPanelSection>
  );

  /**
   * Section detailing link to resource builder
   */
  const renderMyBlocksSection = () => (
    <StyledAccountPanelSection>
      <Typography
        sx={{
          marginBottom: "10px",
        }}
        variant={"h5"}
      >
        Blocks and Resources
      </Typography>
      <Button
        size={"small"}
        sx={{
          width: "20%",
          minWidth: "max-content",
        }}
        variant={"contained"}
        onClick={() => {
          process.env[APP_URL[ALTRA_APPS.RB]] &&
            //@ts-expect-error
            openInNewTab(process.env[APP_URL[ALTRA_APPS.RB]]);
        }}
      >
        Go to Resource Builder
      </Button>
    </StyledAccountPanelSection>
  );

  /**
   * Other section to verify teachers
   */
  const renderMyVerificationSection = () => (
    <StyledAccountPanelSection>
      <Typography variant={"h5"}>Verification</Typography>
      {userSubscriptionPlan?.plan === AppSubscriptionPlan.FREE && (
        <Button
          size={"small"}
          sx={{
            width: "25%",
            marginTop: "10px",
          }}
          variant={"contained"}
        >
          Become a Verify Teachers
        </Button>
      )}
      {userSubscriptionPlan?.plan !== AppSubscriptionPlan.FREE &&
        (user.verified || userRole === AppRole.SUPER_ADMIN) && (
          <Button
            size={"small"}
            sx={{
              width: "25%",
              marginTop: "10px",
            }}
            variant={"contained"}
          >
            Verify Other Teachers
          </Button>
        )}
    </StyledAccountPanelSection>
  );

  return (
    <StyledAccountScreenContainer>
      <AltraTopAppBar appName={appName} />

      {isAuthenticated ? (
        <StyledAccountScreenContents>
          <StyledSideMenu>
            <Typography variant={"h3"}>My account</Typography>
            {renderSideMenuItems()}
          </StyledSideMenu>
          <StyledAccountDetailsContainer>
            <StyledAccountDetailsPanel>
              {renderUserProfileOverview()}
              <StyledHashLinkContainer id={"my-details"}>
                <Divider />
              </StyledHashLinkContainer>
              {renderMyDetailsSection(displayName)}

              <StyledHashLinkContainer id={"my-subscription"}>
                <Divider />
              </StyledHashLinkContainer>
              {renderMySubscriptionSection()}
              {userRole === AppRole.SUPER_ADMIN && (
                <>
                  <StyledHashLinkContainer id={"users"}>
                    <Divider />
                  </StyledHashLinkContainer>
                  {renderUsersSection()}
                  <StyledHashLinkContainer id={"my-curriculums"}>
                    <Divider />
                  </StyledHashLinkContainer>
                  {renderMyCurriculumsSection()}
                  <StyledHashLinkContainer id={"my-blocks"}>
                    <Divider />
                  </StyledHashLinkContainer>
                  {renderMyBlocksSection()}
                </>
              )}
            </StyledAccountDetailsPanel>
          </StyledAccountDetailsContainer>
        </StyledAccountScreenContents>
      ) : (
        <StyledCenteredErrorContainer>
          <StyledCenteredError>
            <Typography variant={"h1"} sx={{ marginBottom: "20px" }}>
              You are not logged in
            </Typography>
            <Button
              size={"large"}
              variant={"contained"}
              onClick={() => history.push("/")}
            >
              Take me back
            </Button>
          </StyledCenteredError>
        </StyledCenteredErrorContainer>
      )}
    </StyledAccountScreenContainer>
  );
};

const StyledAccountScreenContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  backgroundColor: theme.palette.background.default,
}));

const StyledAccountScreenContents = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "100px",
  justifyContent: "flex-end",
}));

const StyledSideMenu = styled("div")(({ theme }) => ({
  width: "15%",
  left: 0,
  position: "fixed",
  flexDirection: "column",
  paddingLeft: "40px",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const StyledAccountDetailsContainer = styled("div")(({ theme }) => ({
  width: "80%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  display: "flex",
  justifyContent: "center",
  paddingBottom: "600px",
}));

const StyledAccountDetailsPanel = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  width: "70%",
  padding: "40px",
}));

const StyledAccountPanelSection = styled("div")(({ theme }) => ({
  marginTop: "30px",
  marginBottom: "30px",
  display: "flex",
  flexDirection: "column",
}));

const StyledUserProfileOverview = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  marginBottom: "30px",
}));

const StyledVerifiedTextRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const StyledUserProfileTextColumn = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const StyledTextField = styled(TextField)({ marginTop: "10px" });
const StyledAutocomplete = styled(Autocomplete)({ marginTop: "15px" });

const StyledHashLinkContainer = styled("div")(({ theme }) => ({
  marginTop: "-50px",
  paddingTop: "50px",
  display: "block",
}));

const StyledHashLink = styled(HashLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const StyledNakedTextInput = styled(InputBase)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "transparent",
  outline: "0 solid transparent",
  overflow: "auto",
  whiteSpace: "nowrap",
  ...theme.typography.h3,
}));

const StyledCenteredError = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5em",
  flexDirection: "column",
  width: "40%",
}));
const StyledCenteredErrorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));
