import TwitterLogo from "@altra-apps/common/assets/icons/TwitterLogo.svg";
import AltraWhiteLogoLongWithIcon from "@altra-apps/common/assets/logos/AltraWhiteLogoLongWithIcon.svg";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { Link, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

export const LandingPageFooter = () => {
  const isTabletOrMobile = useIsMobile();

  return (
    <StyledFooter
      style={{
        backgroundColor: altraTheme.palette.secondary.main,
      }}
    >
      <StyledFooterContent>
        <div>
          {/*Company logo*/}
          <RouterLink
            to={`/`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{ maxWidth: "125px", width: "max-content" }}
              src={AltraWhiteLogoLongWithIcon}
              alt="Altra Logo"
            />
          </RouterLink>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isTabletOrMobile ? "column" : "row",
            marginTop: "30px",
            color: "#FFF",
          }}
        >
          {/*Page navigation*/}
          <HashLink
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              marginRight: "10px",
            }}
            smooth
            to="/#apps"
          >
            <Link
              style={{ color: "#FFF" }}
              underline="hover"
              component="button"
              variant={"h5"}
            >
              Apps
            </Link>
          </HashLink>
          {/*<HashLink*/}
          {/*  style={{*/}
          {/*    display: "flex",*/}
          {/*    alignItems: "center",*/}
          {/*    textDecoration: "none",*/}
          {/*  }}*/}
          {/*  smooth*/}
          {/*  to="/#benefits"*/}
          {/*>*/}
          {/*  <Link color="secondary" component="button" variant={"h5"}>*/}
          {/*    Benefits*/}
          {/*  </Link>*/}
          {/*</HashLink>*/}
          <HashLink
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              marginRight: "10px",
              color: "#FFF",
            }}
            smooth
            to="/#about"
          >
            <Link
              style={{ color: "#FFF" }}
              underline="hover"
              component="button"
              variant={"h5"}
            >
              About
            </Link>
          </HashLink>
          {/*<RouterLink*/}
          {/*  to={`/pricing`}*/}
          {/*  style={{*/}
          {/*    display: "flex",*/}
          {/*    alignItems: "center",*/}
          {/*    textDecoration: "none",*/}
          {/*    marginRight: "10px",*/}
          {/*    color: "#FFF",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Link*/}
          {/*    style={{ color: "#FFF" }}*/}
          {/*    underline="hover"*/}
          {/*    component="button"*/}
          {/*    variant={"h5"}*/}
          {/*  >*/}
          {/*    Pricing*/}
          {/*  </Link>{" "}*/}
          {/*</RouterLink>*/}
          <Link
            target={"_blank"}
            onClick={() =>
              window.open("https://2uluvvlx386.typeform.com/to/CxLRLlOf")
            }
            style={{ color: "#FFF", display: "flex" }}
            component="button"
            variant={"h5"}
            underline="hover"
          >
            Contact
          </Link>
        </div>
        <StyledTwitterLogo>
          <img
            style={{ width: "20px", marginRight: "10px" }}
            src={TwitterLogo}
            alt={"Diagram"}
          />
          <Link
            target={"_blank"}
            onClick={() => window.open("https://twitter.com/altraERC")}
            style={{ color: "#FFF" }}
            component="button"
            variant={"h5"}
            underline="hover"
          >
            Follow us on Twitter
          </Link>
        </StyledTwitterLogo>
        <div>
          <Typography
            variant={"caption"}
            style={{
              marginTop: "30px",
              color: "#FFF",
            }}
          >
            © 2022 Altra. All rights reserved
          </Typography>
        </div>
      </StyledFooterContent>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  //height: 300px;
  width: 100%;
`;

const StyledFooterContent = styled.div`
  width: 60%;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledTwitterLogo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
`;
