import FeatureCheckCircle from "@altra-apps/common/assets/icons/FeatureCheckCircle.svg";
import { useUpdateUserByIdMutation } from "@altra-apps/common/src/graphql/types";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import { alterCurriculumExplorerTheme } from "@altra-apps/common/src/styling/altra-curriculum-explorer-theme";
import { altraResourceGeneratorTheme } from "@altra-apps/common/src/styling/altra-resource-generator-theme";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { APP_URL } from "@altra-apps/common/src/styling/constants";
import { ALTRA_APPS } from "@altra-apps/common/src/util/custom-types";
import { openInNewTab } from "@altra-apps/common/src/util/helpers";
import {
  LandingPageContent,
  PRODUCTS,
} from "@altra-apps/common/src/util/LandingPageContent";
import { prettySubscription } from "@altra-apps/common/src/util/pretty-text";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import {
  Button,
  CircularProgress,
  Divider,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Subscription } from "../redux/applicationContext/types";
import { userInfoUpdateSubscription } from "../redux/user/actions";

interface SuccessScreenProps {
  renderTopBar: any;
}

type ReturnCustomerJson =
  | { type: "ERROR" }
  | {
      type: "SUCCESS";
      customerStripeId: string;
      email: string;
      price: string;
      interval: string;
      amountDecimal: string;
      plan: string;
    };

/**
 *  Screen user is returned to on successfull payment
 *  - Gets session from stripe and displays message
 *  TODO: UPDATE OUR DATABASE USING TO INCLUDE USERS CUSTOMER ID IN USER TABLE
 * @constructor
 */
export const SuccessScreen: FC<SuccessScreenProps> = ({ renderTopBar }) => {
  const isTabletOrMobile = useIsMobile();
  const theme = useTheme();

  const { session_id, user_id } =
    useParams<{ session_id: string; user_id: string }>();
  const { features, title } = PRODUCTS["Altra Early bird - Individual"];
  const dispatch = useAppDispatch();
  const subscriptions: Subscription[] = useAppSelector(
    (state) => state.applicationContext.subscriptions
  );

  const [returnCustomerJson, setReturnCustomerJson] =
    useState<ReturnCustomerJson | null>(null);

  const [updateUserSubscriptionMutation] = useUpdateUserByIdMutation();

  const updateSubscription = async (
    customerId: string,
    subscriptionPlan: string
  ) => {
    const subscriptionId = subscriptions.find(
      (s) => s.plan === subscriptionPlan
    )?.id;

    console.log(subscriptionId);

    if (user_id && subscriptionId) {
      const res = await updateUserSubscriptionMutation({
        variables: {
          userId: parseInt(user_id),
          userInput: {
            stripe_id: customerId,
            subscription_id: subscriptionId,
          },
        },
      });
      if (res.data) {
        console.log(res.data);

        dispatch(
          userInfoUpdateSubscription({
            subId: subscriptionId,
            customerId: customerId,
          })
        );
      }
      if (res.errors) {
        console.error(res.errors);
      }
    }
  };

  useEffect(() => {
    if (returnCustomerJson?.type === "SUCCESS") {
      updateSubscription(
        returnCustomerJson.customerStripeId,
        returnCustomerJson.plan
      );
    }
  }, [returnCustomerJson]);

  const getCustomerIDFromSession = () => {
    if (
      process.env[
        "REACT_APP_STRIPE_GET_CUSTOMER_FROM_SESSION_LAMBDA_API_KEY"
      ] &&
      process.env["REACT_APP_STRIPE_GET_CUSTOMER_FROM_SESSION_URL"]
    )
      fetch(process.env["REACT_APP_STRIPE_GET_CUSTOMER_FROM_SESSION_URL"], {
        method: "POST",
        headers: {
          "X-Api-Key":
            process.env[
              "REACT_APP_STRIPE_GET_CUSTOMER_FROM_SESSION_LAMBDA_API_KEY"
            ],
        },
        body: JSON.stringify({
          session_id: session_id,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json(); //then consume it again, the error happens
          }
        })
        .then((data) => {
          console.log(data);
          if (data["customer"] && data["product"]) {
            setReturnCustomerJson({
              type: "SUCCESS",
              customerStripeId: data["customer"]["id"],
              email: data["customer"]["email"],
              price:
                data["customer"]["subscriptions"]["data"][0]["plan"]["price"],
              interval:
                data["customer"]["subscriptions"]["data"][0]["plan"][
                  "interval"
                ],
              amountDecimal:
                data["customer"]["subscriptions"]["data"][0]["plan"][
                  "amount_decimal"
                ],
              plan: data["product"]["name"],
            });
          }
          return data;
        })
        .catch((error) => {
          setReturnCustomerJson({ type: "ERROR" });
        });
  };

  useEffect(() => {
    getCustomerIDFromSession();
  }, [session_id]);

  const renderFeatureRow = (text: string) => (
    <StyledButtonRow>
      <StyledImageColumn>
        <img
          style={{ maxHeight: "30px", width: "100%" }}
          src={FeatureCheckCircle}
          alt={"Diagram"}
        />
      </StyledImageColumn>

      <Typography variant={"caption"} color={"textSecondary"}>
        {text}
      </Typography>
    </StyledButtonRow>
  );

  return (
    <StyledSuccessScreen>
      {renderTopBar}
      <Toolbar />
      <StyledTabsHeaderContainer>
        <StyledInfoRow>
          <Typography
            variant={"h1"}
            style={{ marginBottom: 10, color: theme.palette.success.main }}
          >
            {LandingPageContent.SUCCESS_PAGE_TITLE}
          </Typography>
          <Typography variant={"body1"} color={"textSecondary"}>
            {LandingPageContent.SUCCESS_PAGE_SUBTITLE}
          </Typography>
        </StyledInfoRow>
      </StyledTabsHeaderContainer>
      <StyledPricingTabsContent
        style={{ backgroundColor: altraTheme.palette.background.default }}
      >
        <StyledCenteredContainer
          style={{ width: isTabletOrMobile ? "90%" : "60%" }}
        >
          <StyledCenteredInnerContainer
            style={{
              flexDirection: isTabletOrMobile ? "column" : "row",
            }}
          >
            <StyledPricingCardContainer
              style={{
                width: isTabletOrMobile ? "90%" : "40%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ marginBottom: 5, fontWeight: 500 }}
                variant={"h5"}
                color={"textPrimary"}
              >
                {title}
              </Typography>

              <Divider />

              {returnCustomerJson && returnCustomerJson.type === "SUCCESS" && (
                <Typography
                  style={{
                    fontWeight: 500,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  variant={"body1"}
                  color={"textSecondary"}
                >
                  You are now subscribed to our{" "}
                  {prettySubscription(returnCustomerJson.plan)}, check your
                  email {returnCustomerJson.email} for details.
                </Typography>
              )}

              {returnCustomerJson && returnCustomerJson.type === "ERROR" && (
                <Typography
                  style={{
                    fontWeight: 500,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  variant={"body1"}
                  color={"error"}
                >
                  There was an issue creating your subscription
                </Typography>
              )}

              {!returnCustomerJson && <CircularProgress />}

              <Button
                onClick={() => {
                  process.env[APP_URL[ALTRA_APPS.CE]] !== undefined &&
                    // @ts-expect-error
                    openInNewTab(process.env[APP_URL[ALTRA_APPS.CE]]);
                }}
                style={{
                  width: "100%",
                  backgroundColor:
                    alterCurriculumExplorerTheme.palette.primary.main,
                  color: "#FFF",
                  marginBottom: "10px",
                }}
                variant={"contained"}
              >
                Curriculum Explorer
              </Button>

              <Button
                onClick={() => {
                  process.env[APP_URL[ALTRA_APPS.RB]] &&
                    // @ts-expect-error
                    openInNewTab(process.env[APP_URL[ALTRA_APPS.RB]]);
                }}
                style={{
                  width: "100%",
                  backgroundColor:
                    altraResourceGeneratorTheme.palette.primary.main,
                  color: "#FFF",
                  marginBottom: "10px",
                }}
                variant={"contained"}
              >
                Resource Builder{" "}
              </Button>

              {features.map((feature) => renderFeatureRow(feature))}
            </StyledPricingCardContainer>
          </StyledCenteredInnerContainer>
        </StyledCenteredContainer>
      </StyledPricingTabsContent>
    </StyledSuccessScreen>
  );
};

const StyledSuccessScreen = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTabsHeaderContainer = styled.div`
  margin-top: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  //background-color: hotpink;
  align-items: center;
`;

const StyledInfoRow = styled.div`
  //background-color: blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledButtonRow = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-content: space-between;
  align-items: center;
  flex-direction: row;
  //background-color: yellow;
`;

const StyledPricingTabsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  //background-color: green;
`;

const StyledCenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  //background-color: red;
  margin-top: 30px;
  margin-bottom: 40px;
`;

const StyledCenteredInnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  //background-color: hotpink;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledPricingCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 8px 36px rgba(66, 84, 102, 0.08);
  border-radius: 8px;
  min-height: 250px;
  padding: 20px;
  margin-bottom: 20px;
`;

const StyledImageColumn = styled.div`
  width: 20%;
`;
