import { CancelSubscriptionModal } from "@altra-apps/common/src/molecules/CancelSubscriptionModal";
import { ChangeSubscriptionModal } from "@altra-apps/common/src/molecules/ChangeSubscriptionModal";
import { AccountScreen } from "@altra-apps/common/src/screens";
import { SuccessScreen } from "@altra-apps/common/src/screens/SuccessScreen";
import { ALTRA_APPS } from "@altra-apps/common/src/util/custom-types";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { LandingPageTopAppBar } from "../components/organisms/LandingPageTopAppBar";
import { AltraCooperativeLandingScreen } from "../screens/AltraCooperativeLandingScreen";
import { ContactScreen } from "../screens/ContactScreen";
import { CurriculumExplorerLandingScreen } from "../screens/CurriculumExplorerLandingScreen";
import { HomeScreen } from "../screens/HomeScreen";
import { PricingScreen } from "../screens/PricingScreen";
import { ResourceBuilderLandingScreen } from "../screens/ResourceBuilderLandingScreen";

export const MainRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomeScreen} />

      <Route exact path="/pricing" component={PricingScreen} />
      <Route exact path="/account" component={AccountScreen} />

      <Route exact path="/contact" component={ContactScreen} />
      <Route
        exact
        path="/pastpaperexplorer"
        component={CurriculumExplorerLandingScreen}
      />
      <Route
        exact
        path="/curriculumExplorer"
        component={CurriculumExplorerLandingScreen}
      />
      <Route
        exact
        path="/altracooperative"
        component={AltraCooperativeLandingScreen}
      />
      <Route
        exact
        path="/updateSubscription"
        render={() => (
          <ChangeSubscriptionModal open={true} appName={ALTRA_APPS.LS} />
        )}
      />
      <Route
        path="/cancelSubscription"
        render={() => <CancelSubscriptionModal />}
      />
      <Route
        exact
        path="/resourcebuilder"
        component={ResourceBuilderLandingScreen}
      />
      <Route
        exact
        path="/progressmanager"
        component={AltraCooperativeLandingScreen}
      />
      <Route
        path="/account/:mode"
        render={() => <AccountScreen appName={ALTRA_APPS.LS} />}
      />
      <Route
        path="/success/:session_id/:user_id"
        render={() => <SuccessScreen renderTopBar={<LandingPageTopAppBar />} />}
      />
    </Switch>
  );
};
