import EditIcon from "@mui/icons-material/Edit";
import { Card, CardActionArea, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { FC } from "react";
type NonEditableDropdownProps = {
  value: string;
  label: string;
  onClick: () => void;
};

/**
 * Button used in top menu bar for navigating to curriculum and topic seletion pages
 * @param value
 * @param label
 * @param onClick
 * @constructor
 */
export const NonEditableDropdown: FC<NonEditableDropdownProps> = ({
  value,
  label,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <div>
      <Tooltip title={value} placement={"bottom"}>
        <StyledBox>
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              backgroundColor: theme.palette.primary.main,
            }}
            onClick={onClick}
          >
            <div
              style={{
                width: "80%",
                paddingLeft: "1em",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <StyledLabel>
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                  }}
                  variant={"body2"}
                  color={"white"}
                >
                  {label}:{" "}
                </Typography>
              </StyledLabel>
              <Typography
                variant={"h6"}
                color={"white"}
                sx={{
                  width: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "1em",
                }}
                noWrap
              >
                {value}
              </Typography>
            </div>
            <EditIcon
              fontSize={"small"}
              sx={{
                width: "10%",
                height: "90%",
                color: "white",
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
              }}
            />
          </CardActionArea>
        </StyledBox>
      </Tooltip>
    </div>
  );
};

const StyledBox = styled(Card)(({ theme }) => ({
  maxWidth: "200px",
  borderRadius: "8px",
  height: "27px",
  backgroundColor: "#f5f5f5",
  margin: "0 0.5em",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
  display: "flex",
  alignItems: "center",
}));
const StyledLabel = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
