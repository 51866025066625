import { persistor, store } from "@altra-apps/common/src/redux/store";
import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN!;
const auth0ClientID = process.env.REACT_APP_AUTH0_CLIENT_ID!;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientID}
          redirectUri={process.env.REACT_APP_LANDING_URL}
          cacheLocation={"localstorage"}
        >
          <App />
        </Auth0Provider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
