// Define a React component to render leaves with bold text
import { styled } from "@mui/material/styles";
import { COLORS } from "../../util/colors";

/**
 * A leaf is part of a block within the editor and is used to control the styling of text inline
 * - Leafs are rendered depending on the marks wrapped around them
 * @param props
 * @constructor
 */
export const Leaf = (props) => {
  let children = props.children;

  if (props.leaf.bold) {
    children = <strong style={{ display: "inline" }}>{children}</strong>;
  }

  if (props.leaf.code) {
    children = <code>{children}</code>;
  }

  if (props.leaf.italic) {
    children = <em>{children}</em>;
  }

  if (props.leaf.underline) {
    children = <u>{children}</u>;
  }

  if (props.headerStyle) {
    children = (
      <span style={{ fontSize: "22px", fontWeight: 800 }}>{children}</span>
    );
  }

  // Map over all the background colours and render element if mark assocaoited to leaf
  Object.keys(COLORS).map((color) => {
    if (props.leaf[`${color}_background`]) {
      children = (
        <span
          style={{
            backgroundColor: COLORS[color],
            color: "#FFF",
            fontWeight: 600,
          }}
        >
          {children}
        </span>
      );
    } else if (props.leaf[color]) {
      children = <span style={{ color: COLORS[color] }}>{children}</span>;
    }
  });

  return (
    <StyledContainer
      style={{
        transform: "none !important",
        cursor: props?.type === "link" ? "pointer" : "text",
      }}
      {...props.attributes}
    >
      {children}
    </StyledContainer>
  );
};

//TODO(Jack): Figure out where to highliht border on edit
const StyledContainer = styled("span")(({ theme }) => ({
  display: "inline",
}));
