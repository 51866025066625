import { Button } from "@mui/material";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { PublishedButton } from "../atoms/PublishedButton";
import { Curriculum } from "../redux/applicationContext/types";
import { useAppSelector } from "../redux/hook";
import { AppRole } from "../util/custom-types";

export const AppSubBarCurriculumBuilderContents: FC = () => {
  const userRole: string = useAppSelector(
    (state) => state.userAppInfo.role
  ) as string;

  const history = useHistory();

  // Get all curriculums from redux
  const selectedCurriculumsId: number | undefined = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId![0]
  );
  const curriculums: Array<Curriculum> = useAppSelector(
    (state) => state.applicationContext.curriculums
  );

  const [published, setPublished] = useState<boolean>(
    curriculums.find((c) => c.id === selectedCurriculumsId)
      ?.publicly_accessible || false
  );

  console.log(history.location.pathname);
  switch (history.location.pathname) {
    case "/edit":
    case "/edit/":
      return (
        <PublishedButton
          ref={null}
          onClick={() => history.push(`/publish`)}
          published={published}
          publishedText={"Share"}
          unpublishedText={"Share"}
          tooltipText={
            "Share this curriculum with the community by making it public"
          }
          disabled={userRole !== AppRole.SUPER_ADMIN}
        />
      );
    case "/curriculumSelection":
      return <div />;
    default:
      return (
        <Button
          size="small"
          variant={"contained"}
          onClick={() => history.push("/create")}
          disabled={userRole !== AppRole.SUPER_ADMIN}
        >
          Create a curriculum
        </Button>
      );
  }
};
