import { Button, tableCellClasses } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import * as React from "react";
import { FC } from "react";
import { useGetAllInstitutionQuery } from "../graphql/types";

enum InstitutionStatus {
  ACTIVE,
  INACTIVE,
}

function createInstitutionsData(
  name: string,
  description: string,
  status: InstitutionStatus
) {
  return { name, description, status };
}

const rows = [
  createInstitutionsData(
    "Craigmount High School",
    "Craigmount High School is a non-denominational secondary school in",
    InstitutionStatus.ACTIVE
  ),
  createInstitutionsData(
    "Royal High",
    "Royal High School is a non-denominational secondary school in",
    InstitutionStatus.ACTIVE
  ),
];

/**
 * Table representing information about institutions within the system
 * @constructor
 */
export const InstitututionsTable: FC = () => {
  const { data, loading, error } = useGetAllInstitutionQuery({
    //TODO - Pagination need to be implemented
    variables: {
      limit: 100,
      offset: 0,
    },
  });
  if (error) {
    console.log(error);
  }

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        sx={{ minWidth: 700, root: { fontSize: "10px" } }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Topics</StyledTableCell>
            <StyledTableCell>Staff</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.institution?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {row.display_name}
              </StyledTableCell>
              <StyledTableCell>{row.bio!}</StyledTableCell>
              <StyledTableCell>
                {row.active! && "ACTIVE"}
                {!row.active! && "INACTIVE"}
              </StyledTableCell>
              <StyledTableCell>
                <Button variant={"outlined"} size={"small"}>
                  Edit preferences
                </Button>
              </StyledTableCell>
              <StyledTableCell>
                <Button variant={"outlined"} size={"small"}>
                  Edit staff
                </Button>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

//@ts-expect-error - Not sure what is causing styling error
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: "noWrap",
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));
