export const MATH_REGEX = new RegExp("(/maths'.*?'/maths)");

export const prettySubscription = (subscriptionName: string): string => {
  switch (subscriptionName) {
    case "free":
      return "Free plan";
    case "individualPremium":
      return "Individual premium";
    case "institutionPremium":
      return "Institution premium";
    default:
      return subscriptionName;
  }
};

export const prettyBlockType = (
  blockType: string | undefined | null
): string => {
  switch (blockType) {
    case "heading-1":
      return "Heading 1";
    case "heading-2":
      return "Heading 2";
    case "block-quote":
      return "Quote";
    case "paragraph":
      return "Body Text";
    case "question-with-sub-parts":
    case "question-text-only":
      return "Question";
    case "answer":
      return "Answer";
    case "answer-text":
      return "Answer";
    case "generic-guidance":
      return "Generic guidance";
    case "potential-solutions":
      return "Potential solution";
    case "solution":
      return "Solution";
    case "additional-guidance":
      return "Additional guidance";
    case undefined:
    case null:
      return "";
    default:
      return blockType.charAt(0).toUpperCase() + blockType.slice(1);
  }
};

//TODO(Jack): Remove all occurrences, this is now done during data prep stage
export const getLocalMathFormat = (mathpixMath: string): string => {
  let localMath = mathpixMath;
  // localMath = localMath.replaceAll("\\(", "/maths'");
  // localMath = localMath.replaceAll("\\)", "'/maths");

  return localMath;
};

export const removeMathFromString = (textStringWithLatex: string): string => {
  const strippedString = textStringWithLatex
    .split(MATH_REGEX)
    .map((value) => (value.includes("/maths") ? "" : value))
    .join("");
  return strippedString;
};

/**
 * Takes out the question number from an SQA question and
 * Cuts out the speel at the end of questions that teachers don't want to see
 *
 * @param questionText
 * @param subject
 */
export const prettyQuestionText = (questionText: string): string => {
  //Match question with groups
  const regexp = RegExp(
    "(^[0-9][0-9]?[0-9]?.)([^(\\n•]*)(\\([^(\\n•]*\\))?(.*•.*)?",
    "g"
  );
  const matches = questionText.matchAll(regexp);

  //@ts-ignore - doesn't like iterating an itertor
  for (const match of matches) {
    //If match contains • then trim all text up to last full stop
    if (match[4]) return match[2].substring(0, match[2].lastIndexOf(".") + 1);

    //Return text before brackets if present, if not return all text
    return match[2];
  }
  return questionText;
};
