import { createReducer } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { Status } from "../../util/custom-types";
import {
  applicationContextSubscriptions,
  createCurriculum,
  createTopic,
  createTopicLabel,
  deleteTopic,
  getApplicationContext,
  updateApplicationContext,
  updateCurriculum,
  updateCurriculums,
  updateEditorSideBarStatus,
  updateLoadingStatus,
  updateResourceBuilderToolbar,
  updateTopic,
  updateTopicLabelsForCurriculum,
  updateTopicsForCurriculum,
} from "./actions";
import { ApplicationContext } from "./types";
export const INITIAL_STATE: ApplicationContext = {
  loadingStatus: { status: Status.IDLE, message: "" },
  subscriptions: [],
  countries: [],
  curriculums: [],
  topicLabels: [],
  topics: [],
  languages: [],
  institutions: [],
  resourceBuilderToolbar: {
    open: false,
  },
  editorSideBar: "CREATE",
};

export const applicationContextReducer = createReducer(
  INITIAL_STATE,
  (builder) => {
    builder
      .addCase(getApplicationContext.rejected, (state, action) => {
        state.loadingStatus = {
          status: Status.ERROR,
          message: "Error loading application context",
        };
      })
      .addCase(updateApplicationContext, (state, { payload }) => {
        Object.assign(state, payload);
      })
      .addCase(updateEditorSideBarStatus, (state, { payload }) => {
        state.editorSideBar = payload;
      })
      .addCase(updateResourceBuilderToolbar, (state, { payload }) => {
        state.resourceBuilderToolbar = payload;
      })
      .addCase(updateLoadingStatus, (state, { payload }) => {
        state.loadingStatus = payload;
      })
      .addCase(updateTopic, (state, { payload }) => {
        state.topics[payload.curriculum_id] = state.topics[
          payload.curriculum_id
        ].map((t) => (t.id === payload?.id ? payload : t));

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Topic updated",
        };

        return state;
      })
      .addCase(deleteTopic, (state, { payload }) => {
        state.topics[payload.curriculum_id] = state.topics[
          payload.curriculum_id
        ].filter((t) => t.id !== payload?.id);

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Topic updated",
        };

        return state;
      })
      .addCase(updateTopicsForCurriculum, (state, { payload }) => {
        state.topics[payload.curriculumId] = payload.topics;

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Curriculum updated with topics",
        };

        return state;
      })
      .addCase(applicationContextSubscriptions, (state, { payload }) => {
        state.subscriptions = payload;
        return state;
      })
      .addCase(updateTopicLabelsForCurriculum, (state, { payload }) => {
        console.log(state.topicLabels);
        state.topicLabels[payload.curriculumId] = uniqBy(
          payload.topicLabels,
          "id"
        );

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Curriculum updated with topic labels",
        };
        console.log(uniqBy(payload.topicLabels, "id"));

        return state;
      })
      .addCase(createTopic, (state, { payload }) => {
        if (payload) {
          state.topics[payload.curriculum_id].push(payload);
        }

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Topic created",
        };

        return state;
      })
      .addCase(createTopicLabel, (state, { payload }) => {
        console.log(state.topicLabels);

        if (payload.curriculum_id) {
          let localTopicLabelsForCurriculum = [
            ...state.topicLabels[payload.curriculum_id],
          ];

          localTopicLabelsForCurriculum
            ? localTopicLabelsForCurriculum.push(payload)
            : (localTopicLabelsForCurriculum = [payload]);

          state.topicLabels[payload.curriculum_id] = uniqBy(
            localTopicLabelsForCurriculum,
            "id"
          );
          console.log(uniqBy(localTopicLabelsForCurriculum, "id"));
        }

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Topic label created",
        };
        // return uniqBy(localTopicLabels, (tl) => tl.id);
      })
      .addCase(updateCurriculum, (state, { payload }) => {
        Object.assign(
          state.curriculums,
          state.curriculums.map((c) => (c.id === payload?.id ? payload : c))
        );

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Curriculum updated",
        };
        return state;
      })
      .addCase(updateCurriculums, (state, { payload }) => {
        // console.log(payload);
        state.curriculums = payload;

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Curriculums updated",
        };
        return state;
      })
      .addCase(createCurriculum, (state, { payload }) => {
        if (payload) {
          Object.assign(state.curriculums, state.curriculums.push(payload));
        }

        state.loadingStatus = {
          status: Status.IDLE,
          message: "Curriculum created",
        };

        return state;
      })
      .addCase(getApplicationContext.fulfilled, (state, { payload }) => {
        console.log(state);
        console.log(payload);
        //TODO(JACK): Fix typing issues
        if (state.subscriptions?.length === 0) {
          // @ts-ignore
          state.subscriptions.push(...payload.subscription);
        }
        if (state.countries?.length === 0) {
          // @ts-ignore
          state.countries.push(...payload.country);
        }
        if (state.languages?.length === 0) {
          // @ts-ignore
          state.languages.push(...payload.language);
        }
        // if (state.topics?.length === 0) state.topics.push(...payload.topic);
        // if (state.topicLabels?.length === 0)
        //   state.topicLabels.push(...payload.topic_label);
        if (state.institutions?.length === 0) {
          // @ts-ignore
          state.institutions.push(...payload.institution);
        }
        state.loadingStatus = {
          status: Status.IDLE,
          message: "Application loaded",
        };
      });
  }
);
