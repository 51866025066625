import ShareIcon from "@mui/icons-material/Share";
import { Button, Tooltip } from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/system";
import React, { FC } from "react";
interface PublishedButtonProps {
  published: boolean;
  publishedText: string;
  unpublishedText: string;
  tooltipText: string;
  disabled: boolean;
  onClick: () => void;
  ref?: any;
  large?: boolean;
}

export const PublishedButton: FC<PublishedButtonProps> = ({
  publishedText,
  published,
  tooltipText,
  unpublishedText,
  disabled,
  onClick,
  ref,
  large,
}) => {
  const renderButton = () => (
    <Tooltip title={tooltipText}>
      <Button
        id={"share-button"}
        disabled={disabled}
        size="small"
        color={published ? "success" : "primary"}
        variant={"contained"}
        onClick={onClick}
        sx={{ margin: "0 1em" }}
        startIcon={<ShareIcon />}
      >
        {published ? publishedText : unpublishedText}
      </Button>
    </Tooltip>
  );

  return published ? (
    <StyledLiveBadge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
    >
      {renderButton()}
    </StyledLiveBadge>
  ) : (
    <StyledOfflineBadge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
    >
      {renderButton()}
    </StyledOfflineBadge>
  );
};

const StyledOfflineBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const StyledLiveBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
